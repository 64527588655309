/* tslint:disable */
/* eslint-disable */
/**
 * Conard application
 * Documentation of REST API services
 *
 * The version of the OpenAPI document: v0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AdrRidCodeDto
 */
export interface AdrRidCodeDto {
    /**
     * Id of object
     * @type {number}
     * @memberof AdrRidCodeDto
     */
    id?: number;
    /**
     * UN code
     * @type {string}
     * @memberof AdrRidCodeDto
     */
    unCode?: string;
    /**
     * RID class
     * @type {string}
     * @memberof AdrRidCodeDto
     */
    ridClass?: string;
    /**
     *  Czech name
     * @type {string}
     * @memberof AdrRidCodeDto
     */
    nameCz?: string;
    /**
     * English name
     * @type {string}
     * @memberof AdrRidCodeDto
     */
    nameEn?: string;
    /**
     * Classification code
     * @type {string}
     * @memberof AdrRidCodeDto
     */
    classificationCode?: string;
    /**
     * Packing group
     * @type {string}
     * @memberof AdrRidCodeDto
     */
    packingGroup?: string;
    /**
     * Safety sign
     * @type {string}
     * @memberof AdrRidCodeDto
     */
    safetySign?: string;
    /**
     * Hazard identification sign
     * @type {string}
     * @memberof AdrRidCodeDto
     */
    hazardIdentificationSign?: string;
}
/**
 * 
 * @export
 * @interface AdrRidCodeSearchDto
 */
export interface AdrRidCodeSearchDto {
    /**
     * UN code
     * @type {string}
     * @memberof AdrRidCodeSearchDto
     */
    unCode?: string;
    /**
     * RID class
     * @type {string}
     * @memberof AdrRidCodeSearchDto
     */
    ridClass?: string;
    /**
     *  Czech name
     * @type {string}
     * @memberof AdrRidCodeSearchDto
     */
    nameCz?: string;
    /**
     * English name
     * @type {string}
     * @memberof AdrRidCodeSearchDto
     */
    nameEn?: string;
    /**
     * Classification code
     * @type {string}
     * @memberof AdrRidCodeSearchDto
     */
    classificationCode?: string;
    /**
     * Packing group
     * @type {string}
     * @memberof AdrRidCodeSearchDto
     */
    packingGroup?: string;
    /**
     * Safety sign
     * @type {string}
     * @memberof AdrRidCodeSearchDto
     */
    safetySign?: string;
    /**
     * Hazard identification sign
     * @type {string}
     * @memberof AdrRidCodeSearchDto
     */
    hazardIdentificationSign?: string;
}
/**
 * 
 * @export
 * @interface AsparkingBarcodeDto
 */
export interface AsparkingBarcodeDto {
    /**
     * 
     * @type {string}
     * @memberof AsparkingBarcodeDto
     */
    Request?: string;
    /**
     * 
     * @type {number}
     * @memberof AsparkingBarcodeDto
     */
    SenderDevice?: number;
    /**
     * 
     * @type {number}
     * @memberof AsparkingBarcodeDto
     */
    TargetDevice?: number;
    /**
     * 
     * @type {string}
     * @memberof AsparkingBarcodeDto
     */
    Vendor?: string;
    /**
     * 
     * @type {string}
     * @memberof AsparkingBarcodeDto
     */
    SystemId?: string;
    /**
     * 
     * @type {string}
     * @memberof AsparkingBarcodeDto
     */
    Barcode?: string;
    /**
     * 
     * @type {string}
     * @memberof AsparkingBarcodeDto
     */
    LicencePlate?: string;
}
/**
 * 
 * @export
 * @interface AsparkingCardDto
 */
export interface AsparkingCardDto {
    /**
     * Id of object
     * @type {number}
     * @memberof AsparkingCardDto
     */
    id?: number;
    /**
     * Number of a card
     * @type {number}
     * @memberof AsparkingCardDto
     */
    cardNumber: number;
    /**
     * 
     * @type {AsparkingLicensePlateDto}
     * @memberof AsparkingCardDto
     */
    licensePlate?: AsparkingLicensePlateDto;
    /**
     * Start validity date
     * @type {string}
     * @memberof AsparkingCardDto
     */
    validFrom?: string;
    /**
     * End validity date
     * @type {string}
     * @memberof AsparkingCardDto
     */
    validTo?: string;
    /**
     * Card is disabled
     * @type {boolean}
     * @memberof AsparkingCardDto
     */
    disabled: boolean;
    /**
     * Card logs
     * @type {Array<AsparkingCardLogDto>}
     * @memberof AsparkingCardDto
     */
    cardLogs?: Array<AsparkingCardLogDto>;
}
/**
 * Card logs
 * @export
 * @interface AsparkingCardLogDto
 */
export interface AsparkingCardLogDto {
    /**
     * Id of object
     * @type {number}
     * @memberof AsparkingCardLogDto
     */
    id?: number;
    /**
     * Card log type
     * @type {string}
     * @memberof AsparkingCardLogDto
     */
    cardLogType: AsparkingCardLogDtoCardLogTypeEnum;
    /**
     * Date time of card log creation
     * @type {string}
     * @memberof AsparkingCardLogDto
     */
    createdAt: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AsparkingCardLogDtoCardLogTypeEnum {
    SuccessIn = 'WIEGAND_READ_SUCCESS_IN',
    SuccessOut = 'WIEGAND_READ_SUCCESS_OUT',
    Failed = 'WIEGAND_READ_FAILED'
}

/**
 * 
 * @export
 * @interface AsparkingCardSearchDto
 */
export interface AsparkingCardSearchDto {
    /**
     * 
     * @type {number}
     * @memberof AsparkingCardSearchDto
     */
    cardNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof AsparkingCardSearchDto
     */
    licensePlate?: string;
    /**
     * 
     * @type {string}
     * @memberof AsparkingCardSearchDto
     */
    validFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof AsparkingCardSearchDto
     */
    validTo?: string;
    /**
     * 
     * @type {string}
     * @memberof AsparkingCardSearchDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof AsparkingCardSearchDto
     */
    surname?: string;
    /**
     * 
     * @type {string}
     * @memberof AsparkingCardSearchDto
     */
    phone?: string;
    /**
     * 
     * @type {number}
     * @memberof AsparkingCardSearchDto
     */
    carrierId?: number;
    /**
     * 
     * @type {string}
     * @memberof AsparkingCardSearchDto
     */
    disabled?: string;
}
/**
 * 
 * @export
 * @interface AsparkingGatePositionDto
 */
export interface AsparkingGatePositionDto {
    /**
     * 
     * @type {string}
     * @memberof AsparkingGatePositionDto
     */
    Request?: string;
    /**
     * 
     * @type {number}
     * @memberof AsparkingGatePositionDto
     */
    SenderDevice?: number;
    /**
     * 
     * @type {number}
     * @memberof AsparkingGatePositionDto
     */
    TargetDevice?: number;
    /**
     * 
     * @type {string}
     * @memberof AsparkingGatePositionDto
     */
    Vendor?: string;
    /**
     * 
     * @type {string}
     * @memberof AsparkingGatePositionDto
     */
    SystemId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AsparkingGatePositionDto
     */
    IsOpen?: boolean;
}
/**
 * 
 * @export
 * @interface AsparkingGatePropertiesDto
 */
export interface AsparkingGatePropertiesDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof AsparkingGatePropertiesDto
     */
    gateDevices?: Array<number>;
}
/**
 * 
 * @export
 * @interface AsparkingLicencePlateDto
 */
export interface AsparkingLicencePlateDto {
    /**
     * 
     * @type {string}
     * @memberof AsparkingLicencePlateDto
     */
    Request?: string;
    /**
     * 
     * @type {number}
     * @memberof AsparkingLicencePlateDto
     */
    SenderDevice?: number;
    /**
     * 
     * @type {number}
     * @memberof AsparkingLicencePlateDto
     */
    TargetDevice?: number;
    /**
     * 
     * @type {string}
     * @memberof AsparkingLicencePlateDto
     */
    Vendor?: string;
    /**
     * 
     * @type {string}
     * @memberof AsparkingLicencePlateDto
     */
    SystemId?: string;
    /**
     * 
     * @type {string}
     * @memberof AsparkingLicencePlateDto
     */
    LicencePlate?: string;
}
/**
 * Type of entity
 * @export
 * @interface AsparkingLicensePlateDto
 */
export interface AsparkingLicensePlateDto {
    /**
     * Id of object
     * @type {number}
     * @memberof AsparkingLicensePlateDto
     */
    id?: number;
    /**
     * License plate.
     * @type {string}
     * @memberof AsparkingLicensePlateDto
     */
    licensePlate: string;
    /**
     * Driver\'s first name
     * @type {string}
     * @memberof AsparkingLicensePlateDto
     */
    firstName?: string;
    /**
     * Driver\'s surname
     * @type {string}
     * @memberof AsparkingLicensePlateDto
     */
    surname?: string;
    /**
     * Driver\'s phone number
     * @type {string}
     * @memberof AsparkingLicensePlateDto
     */
    phone?: string;
    /**
     * Whether license plate is valid.
     * @type {boolean}
     * @memberof AsparkingLicensePlateDto
     */
    valid: boolean;
    /**
     * Permission for checkpoint one.
     * @type {boolean}
     * @memberof AsparkingLicensePlateDto
     */
    checkpointOneAllowed: boolean;
    /**
     * Permission for checkpoint two.
     * @type {boolean}
     * @memberof AsparkingLicensePlateDto
     */
    checkpointTwoAllowed: boolean;
    /**
     * Permission for checkpoint three.
     * @type {boolean}
     * @memberof AsparkingLicensePlateDto
     */
    checkpointThreeAllowed: boolean;
    /**
     * Description of the license plate.
     * @type {string}
     * @memberof AsparkingLicensePlateDto
     */
    note?: string;
    /**
     * Special flag.
     * @type {string}
     * @memberof AsparkingLicensePlateDto
     */
    flag?: string;
    /**
     * 
     * @type {CarrierDto}
     * @memberof AsparkingLicensePlateDto
     */
    carrier?: CarrierDto;
    /**
     * Start validity date
     * @type {string}
     * @memberof AsparkingLicensePlateDto
     */
    validFrom?: string;
    /**
     * End validity date
     * @type {string}
     * @memberof AsparkingLicensePlateDto
     */
    validTo?: string;
    /**
     * 
     * @type {number}
     * @memberof AsparkingLicensePlateDto
     */
    Card_for_license_plate?: number;
}
/**
 * 
 * @export
 * @interface AsparkingLicensePlatePatchDto
 */
export interface AsparkingLicensePlatePatchDto {
    /**
     * Id of object
     * @type {number}
     * @memberof AsparkingLicensePlatePatchDto
     */
    id?: number;
    /**
     * Carrier ID
     * @type {number}
     * @memberof AsparkingLicensePlatePatchDto
     */
    carrierId?: number;
}
/**
 * 
 * @export
 * @interface AsparkingLicensePlateSearchDto
 */
export interface AsparkingLicensePlateSearchDto {
    /**
     * 
     * @type {string}
     * @memberof AsparkingLicensePlateSearchDto
     */
    licensePlate?: string;
    /**
     * 
     * @type {string}
     * @memberof AsparkingLicensePlateSearchDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof AsparkingLicensePlateSearchDto
     */
    surname?: string;
    /**
     * 
     * @type {string}
     * @memberof AsparkingLicensePlateSearchDto
     */
    phone?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AsparkingLicensePlateSearchDto
     */
    valid?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AsparkingLicensePlateSearchDto
     */
    checkpointOneAllowed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AsparkingLicensePlateSearchDto
     */
    checkpointTwoAllowed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AsparkingLicensePlateSearchDto
     */
    checkpointThreeAllowed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AsparkingLicensePlateSearchDto
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof AsparkingLicensePlateSearchDto
     */
    flag?: string;
    /**
     * 
     * @type {number}
     * @memberof AsparkingLicensePlateSearchDto
     */
    carrierId?: number;
    /**
     * 
     * @type {string}
     * @memberof AsparkingLicensePlateSearchDto
     */
    validFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof AsparkingLicensePlateSearchDto
     */
    validTo?: string;
    /**
     * 
     * @type {number}
     * @memberof AsparkingLicensePlateSearchDto
     */
    cardId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AsparkingLicensePlateSearchDto
     */
    hasCard?: boolean;
}
/**
 * 
 * @export
 * @interface AsparkingPresenceCarDto
 */
export interface AsparkingPresenceCarDto {
    /**
     * 
     * @type {string}
     * @memberof AsparkingPresenceCarDto
     */
    Request?: string;
    /**
     * 
     * @type {number}
     * @memberof AsparkingPresenceCarDto
     */
    SenderDevice?: number;
    /**
     * 
     * @type {number}
     * @memberof AsparkingPresenceCarDto
     */
    TargetDevice?: number;
    /**
     * 
     * @type {string}
     * @memberof AsparkingPresenceCarDto
     */
    Vendor?: string;
    /**
     * 
     * @type {string}
     * @memberof AsparkingPresenceCarDto
     */
    SystemId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AsparkingPresenceCarDto
     */
    IsCarPresent?: boolean;
}
/**
 * 
 * @export
 * @interface AsparkingReadResponseDto
 */
export interface AsparkingReadResponseDto {
    /**
     * 
     * @type {string}
     * @memberof AsparkingReadResponseDto
     */
    Request?: string;
    /**
     * 
     * @type {number}
     * @memberof AsparkingReadResponseDto
     */
    SenderDevice?: number;
    /**
     * 
     * @type {number}
     * @memberof AsparkingReadResponseDto
     */
    TargetDevice?: number;
    /**
     * 
     * @type {string}
     * @memberof AsparkingReadResponseDto
     */
    Vendor?: string;
    /**
     * 
     * @type {string}
     * @memberof AsparkingReadResponseDto
     */
    SystemId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AsparkingReadResponseDto
     */
    OpenGate?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AsparkingReadResponseDto
     */
    Message?: string;
}
/**
 * asparkingTicket object to create
 * @export
 * @interface AsparkingTicketDto
 */
export interface AsparkingTicketDto {
    /**
     * Id of object
     * @type {number}
     * @memberof AsparkingTicketDto
     */
    id?: number;
    /**
     * Licence plate of truck for which is ticket designated
     * @type {string}
     * @memberof AsparkingTicketDto
     */
    licencePlate: string;
    /**
     * Barcode of ticket
     * @type {string}
     * @memberof AsparkingTicketDto
     */
    barcode: string;
    /**
     * 
     * @type {CompanyDto}
     * @memberof AsparkingTicketDto
     */
    company?: CompanyDto;
    /**
     * Permission for truck to leave
     * @type {boolean}
     * @memberof AsparkingTicketDto
     */
    departurePermission?: boolean;
    /**
     * Date and time of printing the ticket
     * @type {string}
     * @memberof AsparkingTicketDto
     */
    arrivedAt?: string;
    /**
     * Date and time of truck departure
     * @type {string}
     * @memberof AsparkingTicketDto
     */
    leaveAt?: string;
}
/**
 * 
 * @export
 * @interface AsparkingTicketSearchDto
 */
export interface AsparkingTicketSearchDto {
    /**
     * 
     * @type {number}
     * @memberof AsparkingTicketSearchDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AsparkingTicketSearchDto
     */
    licencePlate?: string;
    /**
     * 
     * @type {string}
     * @memberof AsparkingTicketSearchDto
     */
    barcode?: string;
    /**
     * 
     * @type {number}
     * @memberof AsparkingTicketSearchDto
     */
    companyId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AsparkingTicketSearchDto
     */
    departurePermission?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AsparkingTicketSearchDto
     */
    arrivedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof AsparkingTicketSearchDto
     */
    arrivedAtFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof AsparkingTicketSearchDto
     */
    arrivedAtTo?: string;
    /**
     * 
     * @type {string}
     * @memberof AsparkingTicketSearchDto
     */
    leaveAt?: string;
    /**
     * 
     * @type {string}
     * @memberof AsparkingTicketSearchDto
     */
    leaveAtFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof AsparkingTicketSearchDto
     */
    leaveAtTo?: string;
}
/**
 * 
 * @export
 * @interface AsparkingWiegandDto
 */
export interface AsparkingWiegandDto {
    /**
     * 
     * @type {string}
     * @memberof AsparkingWiegandDto
     */
    Request?: string;
    /**
     * 
     * @type {number}
     * @memberof AsparkingWiegandDto
     */
    SenderDevice?: number;
    /**
     * 
     * @type {number}
     * @memberof AsparkingWiegandDto
     */
    TargetDevice?: number;
    /**
     * 
     * @type {string}
     * @memberof AsparkingWiegandDto
     */
    Vendor?: string;
    /**
     * 
     * @type {string}
     * @memberof AsparkingWiegandDto
     */
    SystemId?: string;
    /**
     * 
     * @type {number}
     * @memberof AsparkingWiegandDto
     */
    Card?: number;
}
/**
 * 
 * @export
 * @interface AuditLogDto
 */
export interface AuditLogDto {
    /**
     * Id of object
     * @type {number}
     * @memberof AuditLogDto
     */
    id?: number;
    /**
     * Type of entity
     * @type {number}
     * @memberof AuditLogDto
     */
    entityId: number;
    /**
     * Type of audit log event
     * @type {string}
     * @memberof AuditLogDto
     */
    auditLogEventType: AuditLogDtoAuditLogEventTypeEnum;
    /**
     * Message of audit log
     * @type {string}
     * @memberof AuditLogDto
     */
    message?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof AuditLogDto
     */
    createdBy?: UserDto;
    /**
     * When audit log had been created
     * @type {string}
     * @memberof AuditLogDto
     */
    createdAt?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AuditLogDtoAuditLogEventTypeEnum {
    CompanyCreate = 'COMPANY_CREATE',
    CompanyUpdate = 'COMPANY_UPDATE',
    UserCreate = 'USER_CREATE',
    UserUpdate = 'USER_UPDATE',
    ShippingContainerGateInUpdate = 'SHIPPING_CONTAINER_GATE_IN_UPDATE',
    ShippingContainerGateOutUpdate = 'SHIPPING_CONTAINER_GATE_OUT_UPDATE',
    SemitrailerGateInUpdate = 'SEMITRAILER_GATE_IN_UPDATE',
    SemitrailerGateOutUpdate = 'SEMITRAILER_GATE_OUT_UPDATE',
    ShippingContainerPlannedGateIn = 'SHIPPING_CONTAINER_PLANNED_GATE_IN',
    ShippingContainerProcessingGateIn = 'SHIPPING_CONTAINER_PROCESSING_GATE_IN',
    ShippingContainerFinishedGateIn = 'SHIPPING_CONTAINER_FINISHED_GATE_IN',
    ShippingContainerProcessingGateOut = 'SHIPPING_CONTAINER_PROCESSING_GATE_OUT',
    ShippingContainerBefore3RdCheckpointGo = 'SHIPPING_CONTAINER_BEFORE_3RD_CHECKPOINT_GO',
    ShippingContainerBefore2NdCheckpointGo = 'SHIPPING_CONTAINER_BEFORE_2ND_CHECKPOINT_GO',
    ShippingContainerBefore1StCheckpointGo = 'SHIPPING_CONTAINER_BEFORE_1ST_CHECKPOINT_GO',
    ShippingContainerFinishedGateOut = 'SHIPPING_CONTAINER_FINISHED_GATE_OUT',
    SemitrailerPlannedGateIn = 'SEMITRAILER_PLANNED_GATE_IN',
    SemitrailerProcessingGateIn = 'SEMITRAILER_PROCESSING_GATE_IN',
    SemitrailerFinishedGateIn = 'SEMITRAILER_FINISHED_GATE_IN',
    SemitrailerProcessingGateOut = 'SEMITRAILER_PROCESSING_GATE_OUT',
    SemitrailerBefore3RdCheckpointGo = 'SEMITRAILER_BEFORE_3RD_CHECKPOINT_GO',
    SemitrailerBefore2NdCheckpointGo = 'SEMITRAILER_BEFORE_2ND_CHECKPOINT_GO',
    SemitrailerBefore1StCheckpointGo = 'SEMITRAILER_BEFORE_1ST_CHECKPOINT_GO',
    SemitrailerFinishedGateOut = 'SEMITRAILER_FINISHED_GATE_OUT',
    ProcessPlannedGateIn = 'PROCESS_PLANNED_GATE_IN',
    TrainCreate = 'TRAIN_CREATE',
    TrainUpdate = 'TRAIN_UPDATE',
    TrainImportUpdateGateIn = 'TRAIN_IMPORT_UPDATE_GATE_IN',
    TrainImportUpdateGateOut = 'TRAIN_IMPORT_UPDATE_GATE_OUT',
    TrainUpdateToIncomingArrivalCustoms = 'TRAIN_UPDATE_TO_INCOMING_ARRIVAL_CUSTOMS',
    TrainUpdateToIncomingAccepted = 'TRAIN_UPDATE_TO_INCOMING_ACCEPTED',
    TrainUpdateToIncomingFinished = 'TRAIN_UPDATE_TO_INCOMING_FINISHED',
    TrainUpdateToOutgoingPrepared = 'TRAIN_UPDATE_TO_OUTGOING_PREPARED',
    TrainUpdateToOutgoingLoaded = 'TRAIN_UPDATE_TO_OUTGOING_LOADED',
    TrainUpdateToOutgoingDispatched = 'TRAIN_UPDATE_TO_OUTGOING_DISPATCHED',
    TrainStartOfUnloading = 'TRAIN_START_OF_UNLOADING',
    TrainEndOfLoading = 'TRAIN_END_OF_LOADING',
    ShipownerCreate = 'SHIPOWNER_CREATE',
    ShipownerUpdate = 'SHIPOWNER_UPDATE',
    EmailSend = 'EMAIL_SEND',
    CdCargoEmailSend = 'CD_CARGO_EMAIL_SEND',
    ShippingContainerT1EmailUpdate = 'SHIPPING_CONTAINER_T1_EMAIL_UPDATE',
    SemitrailerT1EmailUpdate = 'SEMITRAILER_T1_EMAIL_UPDATE',
    CantUpdateToT1 = 'CANT_UPDATE_TO_T1',
    AddCustomsClearance = 'ADD_CUSTOMS_CLEARANCE',
    ChangeCustomsClearanceToT1 = 'CHANGE_CUSTOMS_CLEARANCE_TO_T1',
    ChangeCustomsClearanceToSadExternal = 'CHANGE_CUSTOMS_CLEARANCE_TO_SAD_EXTERNAL',
    ChangeCustomsClearanceToSadPortService = 'CHANGE_CUSTOMS_CLEARANCE_TO_SAD_PORT_SERVICE',
    ChangeCustomsClearanceToCustomsStop = 'CHANGE_CUSTOMS_CLEARANCE_TO_CUSTOMS_STOP',
    DeleteCustomsClearance = 'DELETE_CUSTOMS_CLEARANCE',
    AddCustomsClearanceAttachment = 'ADD_CUSTOMS_CLEARANCE_ATTACHMENT',
    DeleteCustomsClearanceAttachment = 'DELETE_CUSTOMS_CLEARANCE_ATTACHMENT',
    CodecoSend = 'CODECO_SEND',
    MedlogBoardImported = 'MEDLOG_BOARD_IMPORTED',
    ManipulationRequestCreated = 'MANIPULATION_REQUEST_CREATED',
    ContainerReplaced = 'CONTAINER_REPLACED',
    ManipulationRequestCanceled = 'MANIPULATION_REQUEST_CANCELED',
    CreateDriverArrival = 'CREATE_DRIVER_ARRIVAL',
    UpdateDriverArrival = 'UPDATE_DRIVER_ARRIVAL',
    CancelDriverArrival = 'CANCEL_DRIVER_ARRIVAL',
    DriverCreatePlannedTransition = 'DRIVER_CREATE_PLANNED_TRANSITION',
    DriverUpdatePlannedTransition = 'DRIVER_UPDATE_PLANNED_TRANSITION',
    CoparnCreate = 'COPARN_CREATE',
    CoparnCancel = 'COPARN_CANCEL',
    VgmWeighted = 'VGM_WEIGHTED',
    ExternCreate = 'EXTERN_CREATE',
    VgmRequest = 'VGM_REQUEST'
}

/**
 * 
 * @export
 * @interface AuditLogSearchDto
 */
export interface AuditLogSearchDto {
    /**
     * 
     * @type {number}
     * @memberof AuditLogSearchDto
     */
    entityId?: number;
    /**
     * 
     * @type {string}
     * @memberof AuditLogSearchDto
     */
    auditLogEventType?: AuditLogSearchDtoAuditLogEventTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AuditLogSearchDto
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditLogSearchDto
     */
    iluCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditLogSearchDto
     */
    createdAtFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditLogSearchDto
     */
    createdAtTo?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AuditLogSearchDtoAuditLogEventTypeEnum {
    CompanyCreate = 'COMPANY_CREATE',
    CompanyUpdate = 'COMPANY_UPDATE',
    UserCreate = 'USER_CREATE',
    UserUpdate = 'USER_UPDATE',
    ShippingContainerGateInUpdate = 'SHIPPING_CONTAINER_GATE_IN_UPDATE',
    ShippingContainerGateOutUpdate = 'SHIPPING_CONTAINER_GATE_OUT_UPDATE',
    SemitrailerGateInUpdate = 'SEMITRAILER_GATE_IN_UPDATE',
    SemitrailerGateOutUpdate = 'SEMITRAILER_GATE_OUT_UPDATE',
    ShippingContainerPlannedGateIn = 'SHIPPING_CONTAINER_PLANNED_GATE_IN',
    ShippingContainerProcessingGateIn = 'SHIPPING_CONTAINER_PROCESSING_GATE_IN',
    ShippingContainerFinishedGateIn = 'SHIPPING_CONTAINER_FINISHED_GATE_IN',
    ShippingContainerProcessingGateOut = 'SHIPPING_CONTAINER_PROCESSING_GATE_OUT',
    ShippingContainerBefore3RdCheckpointGo = 'SHIPPING_CONTAINER_BEFORE_3RD_CHECKPOINT_GO',
    ShippingContainerBefore2NdCheckpointGo = 'SHIPPING_CONTAINER_BEFORE_2ND_CHECKPOINT_GO',
    ShippingContainerBefore1StCheckpointGo = 'SHIPPING_CONTAINER_BEFORE_1ST_CHECKPOINT_GO',
    ShippingContainerFinishedGateOut = 'SHIPPING_CONTAINER_FINISHED_GATE_OUT',
    SemitrailerPlannedGateIn = 'SEMITRAILER_PLANNED_GATE_IN',
    SemitrailerProcessingGateIn = 'SEMITRAILER_PROCESSING_GATE_IN',
    SemitrailerFinishedGateIn = 'SEMITRAILER_FINISHED_GATE_IN',
    SemitrailerProcessingGateOut = 'SEMITRAILER_PROCESSING_GATE_OUT',
    SemitrailerBefore3RdCheckpointGo = 'SEMITRAILER_BEFORE_3RD_CHECKPOINT_GO',
    SemitrailerBefore2NdCheckpointGo = 'SEMITRAILER_BEFORE_2ND_CHECKPOINT_GO',
    SemitrailerBefore1StCheckpointGo = 'SEMITRAILER_BEFORE_1ST_CHECKPOINT_GO',
    SemitrailerFinishedGateOut = 'SEMITRAILER_FINISHED_GATE_OUT',
    ProcessPlannedGateIn = 'PROCESS_PLANNED_GATE_IN',
    TrainCreate = 'TRAIN_CREATE',
    TrainUpdate = 'TRAIN_UPDATE',
    TrainImportUpdateGateIn = 'TRAIN_IMPORT_UPDATE_GATE_IN',
    TrainImportUpdateGateOut = 'TRAIN_IMPORT_UPDATE_GATE_OUT',
    TrainUpdateToIncomingArrivalCustoms = 'TRAIN_UPDATE_TO_INCOMING_ARRIVAL_CUSTOMS',
    TrainUpdateToIncomingAccepted = 'TRAIN_UPDATE_TO_INCOMING_ACCEPTED',
    TrainUpdateToIncomingFinished = 'TRAIN_UPDATE_TO_INCOMING_FINISHED',
    TrainUpdateToOutgoingPrepared = 'TRAIN_UPDATE_TO_OUTGOING_PREPARED',
    TrainUpdateToOutgoingLoaded = 'TRAIN_UPDATE_TO_OUTGOING_LOADED',
    TrainUpdateToOutgoingDispatched = 'TRAIN_UPDATE_TO_OUTGOING_DISPATCHED',
    TrainStartOfUnloading = 'TRAIN_START_OF_UNLOADING',
    TrainEndOfLoading = 'TRAIN_END_OF_LOADING',
    ShipownerCreate = 'SHIPOWNER_CREATE',
    ShipownerUpdate = 'SHIPOWNER_UPDATE',
    EmailSend = 'EMAIL_SEND',
    CdCargoEmailSend = 'CD_CARGO_EMAIL_SEND',
    ShippingContainerT1EmailUpdate = 'SHIPPING_CONTAINER_T1_EMAIL_UPDATE',
    SemitrailerT1EmailUpdate = 'SEMITRAILER_T1_EMAIL_UPDATE',
    CantUpdateToT1 = 'CANT_UPDATE_TO_T1',
    AddCustomsClearance = 'ADD_CUSTOMS_CLEARANCE',
    ChangeCustomsClearanceToT1 = 'CHANGE_CUSTOMS_CLEARANCE_TO_T1',
    ChangeCustomsClearanceToSadExternal = 'CHANGE_CUSTOMS_CLEARANCE_TO_SAD_EXTERNAL',
    ChangeCustomsClearanceToSadPortService = 'CHANGE_CUSTOMS_CLEARANCE_TO_SAD_PORT_SERVICE',
    ChangeCustomsClearanceToCustomsStop = 'CHANGE_CUSTOMS_CLEARANCE_TO_CUSTOMS_STOP',
    DeleteCustomsClearance = 'DELETE_CUSTOMS_CLEARANCE',
    AddCustomsClearanceAttachment = 'ADD_CUSTOMS_CLEARANCE_ATTACHMENT',
    DeleteCustomsClearanceAttachment = 'DELETE_CUSTOMS_CLEARANCE_ATTACHMENT',
    CodecoSend = 'CODECO_SEND',
    MedlogBoardImported = 'MEDLOG_BOARD_IMPORTED',
    ManipulationRequestCreated = 'MANIPULATION_REQUEST_CREATED',
    ContainerReplaced = 'CONTAINER_REPLACED',
    ManipulationRequestCanceled = 'MANIPULATION_REQUEST_CANCELED',
    CreateDriverArrival = 'CREATE_DRIVER_ARRIVAL',
    UpdateDriverArrival = 'UPDATE_DRIVER_ARRIVAL',
    CancelDriverArrival = 'CANCEL_DRIVER_ARRIVAL',
    DriverCreatePlannedTransition = 'DRIVER_CREATE_PLANNED_TRANSITION',
    DriverUpdatePlannedTransition = 'DRIVER_UPDATE_PLANNED_TRANSITION',
    CoparnCreate = 'COPARN_CREATE',
    CoparnCancel = 'COPARN_CANCEL',
    VgmWeighted = 'VGM_WEIGHTED',
    ExternCreate = 'EXTERN_CREATE',
    VgmRequest = 'VGM_REQUEST'
}

/**
 * 
 * @export
 * @interface AutocompleteDto
 */
export interface AutocompleteDto {
    /**
     * 
     * @type {number}
     * @memberof AutocompleteDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AutocompleteDto
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface AvailableContainerDto
 */
export interface AvailableContainerDto {
    /**
     * 
     * @type {string}
     * @memberof AvailableContainerDto
     */
    iluCode?: string;
    /**
     * 
     * @type {number}
     * @memberof AvailableContainerDto
     */
    shipownerId?: number;
    /**
     * 
     * @type {string}
     * @memberof AvailableContainerDto
     */
    coparnContainerQuality?: AvailableContainerDtoCoparnContainerQualityEnum;
    /**
     * 
     * @type {string}
     * @memberof AvailableContainerDto
     */
    shippingContainerType?: AvailableContainerDtoShippingContainerTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AvailableContainerDtoCoparnContainerQualityEnum {
    A = 'A',
    B = 'B',
    C = 'C',
    All = 'ALL'
}
/**
    * @export
    * @enum {string}
    */
export enum AvailableContainerDtoShippingContainerTypeEnum {
    Iso20G1 = 'ISO_20G1',
    Iso22G0 = 'ISO_22G0',
    Iso22G1 = 'ISO_22G1',
    Iso22K2 = 'ISO_22K2',
    Iso22P4 = 'ISO_22P4',
    Iso22R0 = 'ISO_22R0',
    Iso22R1 = 'ISO_22R1',
    Iso22U0 = 'ISO_22U0',
    Iso22U1 = 'ISO_22U1',
    Iso25G0 = 'ISO_25G0',
    Iso2Mn9 = 'ISO_2MN9',
    Iso42G0 = 'ISO_42G0',
    Iso42G1 = 'ISO_42G1',
    Iso42P1 = 'ISO_42P1',
    Iso42P4 = 'ISO_42P4',
    Iso42R0 = 'ISO_42R0',
    Iso42R1 = 'ISO_42R1',
    Iso42U0 = 'ISO_42U0',
    Iso42U1 = 'ISO_42U1',
    Iso45G0 = 'ISO_45G0',
    Iso45G1 = 'ISO_45G1',
    Iso45R1 = 'ISO_45R1',
    Iso45U1 = 'ISO_45U1',
    IsoL5G0 = 'ISO_L5G0',
    IsoL5G1 = 'ISO_L5G1',
    P20 = 'P20',
    P22 = 'P22',
    P28 = 'P28',
    Tc23 = 'TC23',
    Xm = 'XM',
    Xxm = 'XXM',
    Xl = 'XL',
    Xxl = 'XXL',
    XxlLight = 'XXL_LIGHT',
    XxlMtl = 'XXL_MTL',
    Mt = 'MT',
    RtOre = 'RT_ORE',
    Graintainer = 'GRAINTAINER',
    Xml = 'XML',
    HtM = 'HT_M',
    HtXxl = 'HT_XXL',
    AtOt = 'AT_OT',
    AtHt = 'AT_HT',
    Citytainer = 'CITYTAINER',
    XxlSd = 'XXL_SD',
    Chemietainer = 'CHEMIETAINER',
    Cemtainer = 'CEMTAINER',
    Xx20 = 'XX20',
    Sb = 'SB'
}

/**
 * 
 * @export
 * @interface BuildProperties
 */
export interface BuildProperties {
    /**
     * 
     * @type {string}
     * @memberof BuildProperties
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof BuildProperties
     */
    time?: string;
    /**
     * 
     * @type {string}
     * @memberof BuildProperties
     */
    version?: string;
    /**
     * 
     * @type {string}
     * @memberof BuildProperties
     */
    group?: string;
    /**
     * 
     * @type {string}
     * @memberof BuildProperties
     */
    artifact?: string;
}
/**
 * 
 * @export
 * @interface CameraTruckDto
 */
export interface CameraTruckDto {
    /**
     * Truck front license plate
     * @type {string}
     * @memberof CameraTruckDto
     */
    licensePlateFront?: string;
    /**
     * Truck rear license plate
     * @type {string}
     * @memberof CameraTruckDto
     */
    licensePlateRear?: string;
    /**
     * Array of recognized containers.
     * @type {Array<M2mWagonDto>}
     * @memberof CameraTruckDto
     */
    containers?: Array<M2mWagonDto>;
    /**
     * Camera direction
     * @type {string}
     * @memberof CameraTruckDto
     */
    direction?: CameraTruckDtoDirectionEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CameraTruckDtoDirectionEnum {
    In = 'IN',
    Out = 'OUT',
    Extern = 'EXTERN'
}

/**
 * carrier object to create
 * @export
 * @interface CarrierDto
 */
export interface CarrierDto {
    /**
     * Id of object
     * @type {number}
     * @memberof CarrierDto
     */
    id?: number;
    /**
     * Carrier name
     * @type {string}
     * @memberof CarrierDto
     */
    name: string;
    /**
     * Type of contract with carrier
     * @type {string}
     * @memberof CarrierDto
     */
    contractType?: CarrierDtoContractTypeEnum;
    /**
     * Beginning of contract
     * @type {string}
     * @memberof CarrierDto
     */
    contractBeginning?: string;
    /**
     * End of contract
     * @type {string}
     * @memberof CarrierDto
     */
    contractEnd?: string;
    /**
     * Type of entity
     * @type {string}
     * @memberof CarrierDto
     */
    phone?: string;
    /**
     * 
     * @type {CompanyDto}
     * @memberof CarrierDto
     */
    company?: CompanyDto;
    /**
     * Type of entity
     * @type {Array<AsparkingLicensePlateDto>}
     * @memberof CarrierDto
     */
    licencePlates?: Array<AsparkingLicensePlateDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum CarrierDtoContractTypeEnum {
    CustomerContract = 'CUSTOMER_CONTRACT',
    SupplierContract = 'SUPPLIER_CONTRACT',
    ParkingContract = 'PARKING_CONTRACT',
    EmploymentContract = 'EMPLOYMENT_CONTRACT'
}

/**
 * 
 * @export
 * @interface CarrierSearchDto
 */
export interface CarrierSearchDto {
    /**
     * 
     * @type {string}
     * @memberof CarrierSearchDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierSearchDto
     */
    contractType?: CarrierSearchDtoContractTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CarrierSearchDto
     */
    contractBeginning?: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierSearchDto
     */
    contractEnd?: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierSearchDto
     */
    phone?: string;
    /**
     * 
     * @type {number}
     * @memberof CarrierSearchDto
     */
    companyId?: number;
    /**
     * 
     * @type {string}
     * @memberof CarrierSearchDto
     */
    licensePlate?: string;
    /**
     * 
     * @type {number}
     * @memberof CarrierSearchDto
     */
    cardNumber?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum CarrierSearchDtoContractTypeEnum {
    CustomerContract = 'CUSTOMER_CONTRACT',
    SupplierContract = 'SUPPLIER_CONTRACT',
    ParkingContract = 'PARKING_CONTRACT',
    EmploymentContract = 'EMPLOYMENT_CONTRACT'
}

/**
 * carrier of driver
 * @export
 * @interface CarrierShortDto
 */
export interface CarrierShortDto {
    /**
     * Id of object
     * @type {number}
     * @memberof CarrierShortDto
     */
    id?: number;
    /**
     * Carrier name
     * @type {string}
     * @memberof CarrierShortDto
     */
    name: string;
}
/**
 * 
 * @export
 * @interface CdCargoRequestDto
 */
export interface CdCargoRequestDto {
    /**
     * Used train id
     * @type {number}
     * @memberof CdCargoRequestDto
     */
    trainId: number;
    /**
     * Which template should use
     * @type {number}
     * @memberof CdCargoRequestDto
     */
    templateId: number;
}
/**
 * 
 * @export
 * @interface CdCargoTemplateDto
 */
export interface CdCargoTemplateDto {
    /**
     * Id of object
     * @type {number}
     * @memberof CdCargoTemplateDto
     */
    id?: number;
    /**
     * Template Name
     * @type {string}
     * @memberof CdCargoTemplateDto
     */
    name: string;
    /**
     * XML template
     * @type {string}
     * @memberof CdCargoTemplateDto
     */
    template?: string;
}
/**
 * 
 * @export
 * @interface ColumnSelectDto
 */
export interface ColumnSelectDto {
    /**
     * Sector letter where column is placed
     * @type {string}
     * @memberof ColumnSelectDto
     */
    sectorLetter?: string;
    /**
     * Index of column
     * @type {number}
     * @memberof ColumnSelectDto
     */
    sectorColumn?: number;
}
/**
 * company object to create
 * @export
 * @interface CompanyDto
 */
export interface CompanyDto {
    /**
     * Id of object
     * @type {number}
     * @memberof CompanyDto
     */
    id?: number;
    /**
     * Name of company
     * @type {string}
     * @memberof CompanyDto
     */
    name: string;
    /**
     * Address of company
     * @type {string}
     * @memberof CompanyDto
     */
    address?: string;
    /**
     * Company identification number of company
     * @type {string}
     * @memberof CompanyDto
     */
    companyIdentificationNumber: string;
    /**
     * Company tax number of company
     * @type {string}
     * @memberof CompanyDto
     */
    companyTaxNumber: string;
    /**
     * Id of parent company
     * @type {number}
     * @memberof CompanyDto
     */
    parentCompanyId?: number;
    /**
     * Name of parent company
     * @type {string}
     * @memberof CompanyDto
     */
    parentCompanyName?: string;
    /**
     * Number of free days for containers
     * @type {number}
     * @memberof CompanyDto
     */
    containerFreeDays?: number;
    /**
     * Number of free days for empty containers
     * @type {number}
     * @memberof CompanyDto
     */
    containerEmptyFreeDays?: number;
    /**
     * Number of free days for semitrailers
     * @type {number}
     * @memberof CompanyDto
     */
    semitrailerFreeDays?: number;
    /**
     * Color of the company in the app
     * @type {string}
     * @memberof CompanyDto
     */
    color?: string;
}
/**
 * 
 * @export
 * @interface CompanySearchDto
 */
export interface CompanySearchDto {
    /**
     * 
     * @type {number}
     * @memberof CompanySearchDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanySearchDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanySearchDto
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanySearchDto
     */
    companyIdentificationNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanySearchDto
     */
    companyTaxNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof CompanySearchDto
     */
    parentCompanyId?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanySearchDto
     */
    containerFreeDays?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanySearchDto
     */
    containerEmptyFreeDays?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanySearchDto
     */
    semitrailerFreeDays?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CompanySearchDto
     */
    showOnlyParents?: boolean;
}
/**
 * 
 * @export
 * @interface CompareDto
 */
export interface CompareDto {
    /**
     * 
     * @type {string}
     * @memberof CompareDto
     */
    identifier?: string;
    /**
     * 
     * @type {string}
     * @memberof CompareDto
     */
    compareStatus?: CompareDtoCompareStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CompareDtoCompareStatusEnum {
    Missing = 'MISSING',
    Redundant = 'REDUNDANT',
    Correct = 'CORRECT'
}

/**
 * Specific container information
 * @export
 * @interface ContainerDto
 */
export interface ContainerDto {
    /**
     * Id of object
     * @type {number}
     * @memberof ContainerDto
     */
    id?: number;
    /**
     * Unique ILU code of container
     * @type {string}
     * @memberof ContainerDto
     */
    iluCode: string;
    /**
     * Container type
     * @type {string}
     * @memberof ContainerDto
     */
    containerType: ContainerDtoContainerTypeEnum;
    /**
     * TEU value
     * @type {number}
     * @memberof ContainerDto
     */
    teu: number;
    /**
     * 
     * @type {ShipownerDto}
     * @memberof ContainerDto
     */
    shipowner?: ShipownerDto;
    /**
     * 
     * @type {CompanyDto}
     * @memberof ContainerDto
     */
    company?: CompanyDto;
    /**
     * Type of shipping container
     * @type {string}
     * @memberof ContainerDto
     */
    shippingContainerType?: ContainerDtoShippingContainerTypeEnum;
    /**
     * Type of semitrailer
     * @type {string}
     * @memberof ContainerDto
     */
    semitrailerType?: ContainerDtoSemitrailerTypeEnum;
    /**
     * Licence plate of semitrailer
     * @type {string}
     * @memberof ContainerDto
     */
    semitrailerLicencePlate?: string;
    /**
     * Ilu photo from damage log
     * @type {string}
     * @memberof ContainerDto
     */
    iluPhoto?: string;
    /**
     * Container tarra weight
     * @type {number}
     * @memberof ContainerDto
     */
    tarraWeight?: number;
    /**
     * Heavy tested
     * @type {boolean}
     * @memberof ContainerDto
     */
    heavyTested?: boolean;
    /**
     * Heavy tested value
     * @type {number}
     * @memberof ContainerDto
     */
    heavyTestedValue?: number;
    /**
     * Inner width
     * @type {boolean}
     * @memberof ContainerDto
     */
    innerWidth?: boolean;
    /**
     * Lashing rings
     * @type {boolean}
     * @memberof ContainerDto
     */
    lashingRings?: boolean;
    /**
     * Wooden floor
     * @type {boolean}
     * @memberof ContainerDto
     */
    woodenFloor?: boolean;
    /**
     * Flexi tanks
     * @type {boolean}
     * @memberof ContainerDto
     */
    flexiTanks?: boolean;
    /**
     * Max gross value
     * @type {number}
     * @memberof ContainerDto
     */
    maxGross?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum ContainerDtoContainerTypeEnum {
    ShippingContainer = 'SHIPPING_CONTAINER',
    Semitrailer = 'SEMITRAILER'
}
/**
    * @export
    * @enum {string}
    */
export enum ContainerDtoShippingContainerTypeEnum {
    Iso20G1 = 'ISO_20G1',
    Iso22G0 = 'ISO_22G0',
    Iso22G1 = 'ISO_22G1',
    Iso22K2 = 'ISO_22K2',
    Iso22P4 = 'ISO_22P4',
    Iso22R0 = 'ISO_22R0',
    Iso22R1 = 'ISO_22R1',
    Iso22U0 = 'ISO_22U0',
    Iso22U1 = 'ISO_22U1',
    Iso25G0 = 'ISO_25G0',
    Iso2Mn9 = 'ISO_2MN9',
    Iso42G0 = 'ISO_42G0',
    Iso42G1 = 'ISO_42G1',
    Iso42P1 = 'ISO_42P1',
    Iso42P4 = 'ISO_42P4',
    Iso42R0 = 'ISO_42R0',
    Iso42R1 = 'ISO_42R1',
    Iso42U0 = 'ISO_42U0',
    Iso42U1 = 'ISO_42U1',
    Iso45G0 = 'ISO_45G0',
    Iso45G1 = 'ISO_45G1',
    Iso45R1 = 'ISO_45R1',
    Iso45U1 = 'ISO_45U1',
    IsoL5G0 = 'ISO_L5G0',
    IsoL5G1 = 'ISO_L5G1',
    P20 = 'P20',
    P22 = 'P22',
    P28 = 'P28',
    Tc23 = 'TC23',
    Xm = 'XM',
    Xxm = 'XXM',
    Xl = 'XL',
    Xxl = 'XXL',
    XxlLight = 'XXL_LIGHT',
    XxlMtl = 'XXL_MTL',
    Mt = 'MT',
    RtOre = 'RT_ORE',
    Graintainer = 'GRAINTAINER',
    Xml = 'XML',
    HtM = 'HT_M',
    HtXxl = 'HT_XXL',
    AtOt = 'AT_OT',
    AtHt = 'AT_HT',
    Citytainer = 'CITYTAINER',
    XxlSd = 'XXL_SD',
    Chemietainer = 'CHEMIETAINER',
    Cemtainer = 'CEMTAINER',
    Xx20 = 'XX20',
    Sb = 'SB'
}
/**
    * @export
    * @enum {string}
    */
export enum ContainerDtoSemitrailerTypeEnum {
    Standard = 'STANDARD',
    Mega = 'MEGA'
}

/**
 * 
 * @export
 * @interface ContainerSearchDto
 */
export interface ContainerSearchDto {
    /**
     * 
     * @type {number}
     * @memberof ContainerSearchDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ContainerSearchDto
     */
    iluCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ContainerSearchDto
     */
    containerType?: ContainerSearchDtoContainerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ContainerSearchDto
     */
    containerState?: ContainerSearchDtoContainerStateEnum;
    /**
     * 
     * @type {string}
     * @memberof ContainerSearchDto
     */
    semitrailerLicencePlate?: string;
    /**
     * 
     * @type {ShipownerSearchDto}
     * @memberof ContainerSearchDto
     */
    shipowner?: ShipownerSearchDto;
    /**
     * 
     * @type {CompanySearchDto}
     * @memberof ContainerSearchDto
     */
    company?: CompanySearchDto;
    /**
     * 
     * @type {string}
     * @memberof ContainerSearchDto
     */
    shippingContainerType?: ContainerSearchDtoShippingContainerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ContainerSearchDto
     */
    semitrailerType?: ContainerSearchDtoSemitrailerTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ContainerSearchDtoContainerTypeEnum {
    ShippingContainer = 'SHIPPING_CONTAINER',
    Semitrailer = 'SEMITRAILER'
}
/**
    * @export
    * @enum {string}
    */
export enum ContainerSearchDtoContainerStateEnum {
    PlannedGateIn = 'PLANNED_GATE_IN',
    ProcessingGateIn = 'PROCESSING_GATE_IN',
    FinishedGateIn = 'FINISHED_GATE_IN',
    ProcessingGateOut = 'PROCESSING_GATE_OUT',
    Before3RdCheckpointGo = 'BEFORE_3RD_CHECKPOINT_GO',
    Before2NdCheckpointGo = 'BEFORE_2ND_CHECKPOINT_GO',
    FinishedGateOut = 'FINISHED_GATE_OUT',
    ProcessingExtern = 'PROCESSING_EXTERN',
    FinishedExtern = 'FINISHED_EXTERN'
}
/**
    * @export
    * @enum {string}
    */
export enum ContainerSearchDtoShippingContainerTypeEnum {
    Iso20G1 = 'ISO_20G1',
    Iso22G0 = 'ISO_22G0',
    Iso22G1 = 'ISO_22G1',
    Iso22K2 = 'ISO_22K2',
    Iso22P4 = 'ISO_22P4',
    Iso22R0 = 'ISO_22R0',
    Iso22R1 = 'ISO_22R1',
    Iso22U0 = 'ISO_22U0',
    Iso22U1 = 'ISO_22U1',
    Iso25G0 = 'ISO_25G0',
    Iso2Mn9 = 'ISO_2MN9',
    Iso42G0 = 'ISO_42G0',
    Iso42G1 = 'ISO_42G1',
    Iso42P1 = 'ISO_42P1',
    Iso42P4 = 'ISO_42P4',
    Iso42R0 = 'ISO_42R0',
    Iso42R1 = 'ISO_42R1',
    Iso42U0 = 'ISO_42U0',
    Iso42U1 = 'ISO_42U1',
    Iso45G0 = 'ISO_45G0',
    Iso45G1 = 'ISO_45G1',
    Iso45R1 = 'ISO_45R1',
    Iso45U1 = 'ISO_45U1',
    IsoL5G0 = 'ISO_L5G0',
    IsoL5G1 = 'ISO_L5G1',
    P20 = 'P20',
    P22 = 'P22',
    P28 = 'P28',
    Tc23 = 'TC23',
    Xm = 'XM',
    Xxm = 'XXM',
    Xl = 'XL',
    Xxl = 'XXL',
    XxlLight = 'XXL_LIGHT',
    XxlMtl = 'XXL_MTL',
    Mt = 'MT',
    RtOre = 'RT_ORE',
    Graintainer = 'GRAINTAINER',
    Xml = 'XML',
    HtM = 'HT_M',
    HtXxl = 'HT_XXL',
    AtOt = 'AT_OT',
    AtHt = 'AT_HT',
    Citytainer = 'CITYTAINER',
    XxlSd = 'XXL_SD',
    Chemietainer = 'CHEMIETAINER',
    Cemtainer = 'CEMTAINER',
    Xx20 = 'XX20',
    Sb = 'SB'
}
/**
    * @export
    * @enum {string}
    */
export enum ContainerSearchDtoSemitrailerTypeEnum {
    Standard = 'STANDARD',
    Mega = 'MEGA'
}

/**
 * Specific container information
 * @export
 * @interface ContainerSimpleDto
 */
export interface ContainerSimpleDto {
    /**
     * Id of object
     * @type {number}
     * @memberof ContainerSimpleDto
     */
    id?: number;
    /**
     * Unique ILU code of container
     * @type {string}
     * @memberof ContainerSimpleDto
     */
    iluCode: string;
    /**
     * Container type
     * @type {string}
     * @memberof ContainerSimpleDto
     */
    containerType: ContainerSimpleDtoContainerTypeEnum;
    /**
     * Type of shipping container
     * @type {string}
     * @memberof ContainerSimpleDto
     */
    shippingContainerType?: ContainerSimpleDtoShippingContainerTypeEnum;
    /**
     * Type of semitrailer
     * @type {string}
     * @memberof ContainerSimpleDto
     */
    semitrailerType?: ContainerSimpleDtoSemitrailerTypeEnum;
    /**
     * Container tarra weight
     * @type {number}
     * @memberof ContainerSimpleDto
     */
    tarraWeight?: number;
    /**
     * TEU of the container
     * @type {number}
     * @memberof ContainerSimpleDto
     */
    teu?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum ContainerSimpleDtoContainerTypeEnum {
    ShippingContainer = 'SHIPPING_CONTAINER',
    Semitrailer = 'SEMITRAILER'
}
/**
    * @export
    * @enum {string}
    */
export enum ContainerSimpleDtoShippingContainerTypeEnum {
    Iso20G1 = 'ISO_20G1',
    Iso22G0 = 'ISO_22G0',
    Iso22G1 = 'ISO_22G1',
    Iso22K2 = 'ISO_22K2',
    Iso22P4 = 'ISO_22P4',
    Iso22R0 = 'ISO_22R0',
    Iso22R1 = 'ISO_22R1',
    Iso22U0 = 'ISO_22U0',
    Iso22U1 = 'ISO_22U1',
    Iso25G0 = 'ISO_25G0',
    Iso2Mn9 = 'ISO_2MN9',
    Iso42G0 = 'ISO_42G0',
    Iso42G1 = 'ISO_42G1',
    Iso42P1 = 'ISO_42P1',
    Iso42P4 = 'ISO_42P4',
    Iso42R0 = 'ISO_42R0',
    Iso42R1 = 'ISO_42R1',
    Iso42U0 = 'ISO_42U0',
    Iso42U1 = 'ISO_42U1',
    Iso45G0 = 'ISO_45G0',
    Iso45G1 = 'ISO_45G1',
    Iso45R1 = 'ISO_45R1',
    Iso45U1 = 'ISO_45U1',
    IsoL5G0 = 'ISO_L5G0',
    IsoL5G1 = 'ISO_L5G1',
    P20 = 'P20',
    P22 = 'P22',
    P28 = 'P28',
    Tc23 = 'TC23',
    Xm = 'XM',
    Xxm = 'XXM',
    Xl = 'XL',
    Xxl = 'XXL',
    XxlLight = 'XXL_LIGHT',
    XxlMtl = 'XXL_MTL',
    Mt = 'MT',
    RtOre = 'RT_ORE',
    Graintainer = 'GRAINTAINER',
    Xml = 'XML',
    HtM = 'HT_M',
    HtXxl = 'HT_XXL',
    AtOt = 'AT_OT',
    AtHt = 'AT_HT',
    Citytainer = 'CITYTAINER',
    XxlSd = 'XXL_SD',
    Chemietainer = 'CHEMIETAINER',
    Cemtainer = 'CEMTAINER',
    Xx20 = 'XX20',
    Sb = 'SB'
}
/**
    * @export
    * @enum {string}
    */
export enum ContainerSimpleDtoSemitrailerTypeEnum {
    Standard = 'STANDARD',
    Mega = 'MEGA'
}

/**
 * 
 * @export
 * @interface ContainerStateDto
 */
export interface ContainerStateDto {
    /**
     * 
     * @type {string}
     * @memberof ContainerStateDto
     */
    status?: ContainerStateDtoStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ContainerStateDtoStatusEnum {
    PlannedGateIn = 'PLANNED_GATE_IN',
    ProcessingGateIn = 'PROCESSING_GATE_IN',
    FinishedGateIn = 'FINISHED_GATE_IN',
    ProcessingGateOut = 'PROCESSING_GATE_OUT',
    Before3RdCheckpointGo = 'BEFORE_3RD_CHECKPOINT_GO',
    Before2NdCheckpointGo = 'BEFORE_2ND_CHECKPOINT_GO',
    FinishedGateOut = 'FINISHED_GATE_OUT',
    ProcessingExtern = 'PROCESSING_EXTERN',
    FinishedExtern = 'FINISHED_EXTERN'
}

/**
 * Coparn container
 * @export
 * @interface CoparnContainerDto
 */
export interface CoparnContainerDto {
    /**
     * Id of object
     * @type {number}
     * @memberof CoparnContainerDto
     */
    id?: number;
    /**
     * Coparn container status.
     * @type {string}
     * @memberof CoparnContainerDto
     */
    containerType?: CoparnContainerDtoContainerTypeEnum;
    /**
     * Coparn container quality.
     * @type {string}
     * @memberof CoparnContainerDto
     */
    coparnContainerQuality?: CoparnContainerDtoCoparnContainerQualityEnum;
    /**
     * Heavy tested.
     * @type {boolean}
     * @memberof CoparnContainerDto
     */
    heavyTested?: boolean;
    /**
     * Inner width.
     * @type {boolean}
     * @memberof CoparnContainerDto
     */
    innerWidth?: boolean;
    /**
     * Lashing rings.
     * @type {boolean}
     * @memberof CoparnContainerDto
     */
    lashingRings?: boolean;
    /**
     * Wooden floor.
     * @type {boolean}
     * @memberof CoparnContainerDto
     */
    woodenFloor?: boolean;
    /**
     * Flexitanks.
     * @type {boolean}
     * @memberof CoparnContainerDto
     */
    flexitanks?: boolean;
    /**
     * Cargo specification.
     * @type {string}
     * @memberof CoparnContainerDto
     */
    cargoSpecification?: string;
    /**
     * Other service information.
     * @type {string}
     * @memberof CoparnContainerDto
     */
    otherServiceInformation?: string;
    /**
     * Status.
     * @type {string}
     * @memberof CoparnContainerDto
     */
    status?: CoparnContainerDtoStatusEnum;
    /**
     * 
     * @type {CoparnDto}
     * @memberof CoparnContainerDto
     */
    coparn?: CoparnDto;
    /**
     * 
     * @type {TransitionDto}
     * @memberof CoparnContainerDto
     */
    selectedTransition?: TransitionDto;
}

/**
    * @export
    * @enum {string}
    */
export enum CoparnContainerDtoContainerTypeEnum {
    Iso20G1 = 'ISO_20G1',
    Iso22G0 = 'ISO_22G0',
    Iso22G1 = 'ISO_22G1',
    Iso22K2 = 'ISO_22K2',
    Iso22P4 = 'ISO_22P4',
    Iso22R0 = 'ISO_22R0',
    Iso22R1 = 'ISO_22R1',
    Iso22U0 = 'ISO_22U0',
    Iso22U1 = 'ISO_22U1',
    Iso25G0 = 'ISO_25G0',
    Iso2Mn9 = 'ISO_2MN9',
    Iso42G0 = 'ISO_42G0',
    Iso42G1 = 'ISO_42G1',
    Iso42P1 = 'ISO_42P1',
    Iso42P4 = 'ISO_42P4',
    Iso42R0 = 'ISO_42R0',
    Iso42R1 = 'ISO_42R1',
    Iso42U0 = 'ISO_42U0',
    Iso42U1 = 'ISO_42U1',
    Iso45G0 = 'ISO_45G0',
    Iso45G1 = 'ISO_45G1',
    Iso45R1 = 'ISO_45R1',
    Iso45U1 = 'ISO_45U1',
    IsoL5G0 = 'ISO_L5G0',
    IsoL5G1 = 'ISO_L5G1',
    P20 = 'P20',
    P22 = 'P22',
    P28 = 'P28',
    Tc23 = 'TC23',
    Xm = 'XM',
    Xxm = 'XXM',
    Xl = 'XL',
    Xxl = 'XXL',
    XxlLight = 'XXL_LIGHT',
    XxlMtl = 'XXL_MTL',
    Mt = 'MT',
    RtOre = 'RT_ORE',
    Graintainer = 'GRAINTAINER',
    Xml = 'XML',
    HtM = 'HT_M',
    HtXxl = 'HT_XXL',
    AtOt = 'AT_OT',
    AtHt = 'AT_HT',
    Citytainer = 'CITYTAINER',
    XxlSd = 'XXL_SD',
    Chemietainer = 'CHEMIETAINER',
    Cemtainer = 'CEMTAINER',
    Xx20 = 'XX20',
    Sb = 'SB'
}
/**
    * @export
    * @enum {string}
    */
export enum CoparnContainerDtoCoparnContainerQualityEnum {
    A = 'A',
    B = 'B',
    C = 'C',
    All = 'ALL'
}
/**
    * @export
    * @enum {string}
    */
export enum CoparnContainerDtoStatusEnum {
    New = 'NEW',
    Processed = 'PROCESSED',
    Cancelled = 'CANCELLED'
}

/**
 * Coparn ID.
 * @export
 * @interface CoparnDto
 */
export interface CoparnDto {
    /**
     * Id of object
     * @type {number}
     * @memberof CoparnDto
     */
    id?: number;
    /**
     * Coparn status.
     * @type {string}
     * @memberof CoparnDto
     */
    coparnStatus?: CoparnDtoCoparnStatusEnum;
    /**
     * Coparn modify mode.
     * @type {string}
     * @memberof CoparnDto
     */
    coparnModifyMode?: CoparnDtoCoparnModifyModeEnum;
    /**
     * Coparn haulage information.
     * @type {string}
     * @memberof CoparnDto
     */
    coparnHaulageInformation?: CoparnDtoCoparnHaulageInformationEnum;
    /**
     * Medlog reference.
     * @type {string}
     * @memberof CoparnDto
     */
    medlogReference?: string;
    /**
     * Booking reference agency.
     * @type {string}
     * @memberof CoparnDto
     */
    bookingReferenceAgency?: string;
    /**
     * Port of discharge.
     * @type {string}
     * @memberof CoparnDto
     */
    portOfDischarge?: string;
    /**
     * Contract holder.
     * @type {string}
     * @memberof CoparnDto
     */
    contractHolder?: string;
    /**
     * Consignee.
     * @type {string}
     * @memberof CoparnDto
     */
    consignee?: string;
    /**
     * Named account.
     * @type {string}
     * @memberof CoparnDto
     */
    namedAccount?: string;
    /**
     * When Coparn had been created.
     * @type {string}
     * @memberof CoparnDto
     */
    createdAt?: string;
    /**
     * 
     * @type {ShipownerDto}
     * @memberof CoparnDto
     */
    shipowner?: ShipownerDto;
    /**
     * Number of containers.
     * @type {number}
     * @memberof CoparnDto
     */
    numberOfContainers?: number;
    /**
     * Number of processed containers.
     * @type {number}
     * @memberof CoparnDto
     */
    numberOfProcessedContainers?: number;
    /**
     * Shipping container type, if it is the same for a whole coparn.
     * @type {string}
     * @memberof CoparnDto
     */
    uniqueShippingContainerType?: CoparnDtoUniqueShippingContainerTypeEnum;
    /**
     * Coparn container quality, if it is the same for a whole coparn.
     * @type {string}
     * @memberof CoparnDto
     */
    uniqueCoparnContainerQuality?: CoparnDtoUniqueCoparnContainerQualityEnum;
    /**
     * 
     * @type {UserNameDto}
     * @memberof CoparnDto
     */
    user?: UserNameDto;
}

/**
    * @export
    * @enum {string}
    */
export enum CoparnDtoCoparnStatusEnum {
    New = 'NEW',
    Processed = 'PROCESSED',
    Cancelled = 'CANCELLED'
}
/**
    * @export
    * @enum {string}
    */
export enum CoparnDtoCoparnModifyModeEnum {
    Accept = 'ACCEPT',
    Cancel = 'CANCEL',
    Update = 'UPDATE'
}
/**
    * @export
    * @enum {string}
    */
export enum CoparnDtoCoparnHaulageInformationEnum {
    Carrier = 'CARRIER',
    Merchant = 'MERCHANT'
}
/**
    * @export
    * @enum {string}
    */
export enum CoparnDtoUniqueShippingContainerTypeEnum {
    Iso20G1 = 'ISO_20G1',
    Iso22G0 = 'ISO_22G0',
    Iso22G1 = 'ISO_22G1',
    Iso22K2 = 'ISO_22K2',
    Iso22P4 = 'ISO_22P4',
    Iso22R0 = 'ISO_22R0',
    Iso22R1 = 'ISO_22R1',
    Iso22U0 = 'ISO_22U0',
    Iso22U1 = 'ISO_22U1',
    Iso25G0 = 'ISO_25G0',
    Iso2Mn9 = 'ISO_2MN9',
    Iso42G0 = 'ISO_42G0',
    Iso42G1 = 'ISO_42G1',
    Iso42P1 = 'ISO_42P1',
    Iso42P4 = 'ISO_42P4',
    Iso42R0 = 'ISO_42R0',
    Iso42R1 = 'ISO_42R1',
    Iso42U0 = 'ISO_42U0',
    Iso42U1 = 'ISO_42U1',
    Iso45G0 = 'ISO_45G0',
    Iso45G1 = 'ISO_45G1',
    Iso45R1 = 'ISO_45R1',
    Iso45U1 = 'ISO_45U1',
    IsoL5G0 = 'ISO_L5G0',
    IsoL5G1 = 'ISO_L5G1',
    P20 = 'P20',
    P22 = 'P22',
    P28 = 'P28',
    Tc23 = 'TC23',
    Xm = 'XM',
    Xxm = 'XXM',
    Xl = 'XL',
    Xxl = 'XXL',
    XxlLight = 'XXL_LIGHT',
    XxlMtl = 'XXL_MTL',
    Mt = 'MT',
    RtOre = 'RT_ORE',
    Graintainer = 'GRAINTAINER',
    Xml = 'XML',
    HtM = 'HT_M',
    HtXxl = 'HT_XXL',
    AtOt = 'AT_OT',
    AtHt = 'AT_HT',
    Citytainer = 'CITYTAINER',
    XxlSd = 'XXL_SD',
    Chemietainer = 'CHEMIETAINER',
    Cemtainer = 'CEMTAINER',
    Xx20 = 'XX20',
    Sb = 'SB'
}
/**
    * @export
    * @enum {string}
    */
export enum CoparnDtoUniqueCoparnContainerQualityEnum {
    A = 'A',
    B = 'B',
    C = 'C',
    All = 'ALL'
}

/**
 * 
 * @export
 * @interface CoparnSearchDto
 */
export interface CoparnSearchDto {
    /**
     * 
     * @type {string}
     * @memberof CoparnSearchDto
     */
    medlogReference?: string;
    /**
     * 
     * @type {string}
     * @memberof CoparnSearchDto
     */
    coparnStatus?: CoparnSearchDtoCoparnStatusEnum;
    /**
     * 
     * @type {ShipownerDto}
     * @memberof CoparnSearchDto
     */
    shipowner?: ShipownerDto;
}

/**
    * @export
    * @enum {string}
    */
export enum CoparnSearchDtoCoparnStatusEnum {
    New = 'NEW',
    Processed = 'PROCESSED',
    Cancelled = 'CANCELLED'
}

/**
 * Coparn truck
 * @export
 * @interface CoparnTruckDto
 */
export interface CoparnTruckDto {
    /**
     * Id of object
     * @type {number}
     * @memberof CoparnTruckDto
     */
    id?: number;
    /**
     * Front license plate.
     * @type {string}
     * @memberof CoparnTruckDto
     */
    licensePlateFront?: string;
    /**
     * Rear license plate.
     * @type {string}
     * @memberof CoparnTruckDto
     */
    licensePlateRear?: string;
    /**
     * 
     * @type {TransitionDto}
     * @memberof CoparnTruckDto
     */
    selectedTransition?: TransitionDto;
    /**
     * 
     * @type {CoparnDto}
     * @memberof CoparnTruckDto
     */
    coparn?: CoparnDto;
    /**
     * Carrier
     * @type {string}
     * @memberof CoparnTruckDto
     */
    carrier?: string;
    /**
     * Driver
     * @type {string}
     * @memberof CoparnTruckDto
     */
    driver?: string;
    /**
     * 
     * @type {CarrierShortDto}
     * @memberof CoparnTruckDto
     */
    carrierShort?: CarrierShortDto;
    /**
     * 
     * @type {DriverShortDto}
     * @memberof CoparnTruckDto
     */
    driverShort?: DriverShortDto;
    /**
     * 
     * @type {LicensePlateDto}
     * @memberof CoparnTruckDto
     */
    frontLicensePlate?: LicensePlateDto;
    /**
     * 
     * @type {LicensePlateDto}
     * @memberof CoparnTruckDto
     */
    rearLicensePlate?: LicensePlateDto;
}
/**
 * Damage log entries
 * @export
 * @interface DamageLogEntryDto
 */
export interface DamageLogEntryDto {
    /**
     * Id of object
     * @type {number}
     * @memberof DamageLogEntryDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DamageLogEntryDto
     */
    side: DamageLogEntryDtoSideEnum;
    /**
     * 
     * @type {number}
     * @memberof DamageLogEntryDto
     */
    positionX: number;
    /**
     * 
     * @type {number}
     * @memberof DamageLogEntryDto
     */
    positionY: number;
    /**
     * 
     * @type {string}
     * @memberof DamageLogEntryDto
     */
    photo: string;
    /**
     * 
     * @type {boolean}
     * @memberof DamageLogEntryDto
     */
    inside: boolean;
    /**
     * 
     * @type {string}
     * @memberof DamageLogEntryDto
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageLogEntryDto
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageLogEntryDto
     */
    causedDamageCz?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageLogEntryDto
     */
    causedDamageEn?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageLogEntryDto
     */
    damageTypeShippingContainer?: DamageLogEntryDtoDamageTypeShippingContainerEnum;
    /**
     * 
     * @type {string}
     * @memberof DamageLogEntryDto
     */
    damageTypeSemitrailer?: DamageLogEntryDtoDamageTypeSemitrailerEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum DamageLogEntryDtoSideEnum {
    Left = 'LEFT',
    Front = 'FRONT',
    Right = 'RIGHT',
    Back = 'BACK',
    Top = 'TOP'
}
/**
    * @export
    * @enum {string}
    */
export enum DamageLogEntryDtoDamageTypeShippingContainerEnum {
    Dented = 'DENTED',
    Broken = 'BROKEN',
    Hole = 'HOLE',
    Cut = 'CUT',
    Scratched = 'SCRATCHED',
    Missing = 'MISSING',
    Loose = 'LOOSE',
    Rusted = 'RUSTED',
    NotPrimedPaint = 'NOT_PRIMED_PAINT',
    CrackedWeld = 'CRACKED_WELD',
    NotSealed = 'NOT_SEALED',
    NotOriginalMaterial = 'NOT_ORIGINAL_MATERIAL',
    SmellingStrongly = 'SMELLING_STRONGLY',
    StainedStrongly = 'STAINED_STRONGLY',
    WrappingMaterial = 'WRAPPING_MATERIAL',
    CondensedMoisture = 'CONDENSED_MOISTURE'
}
/**
    * @export
    * @enum {string}
    */
export enum DamageLogEntryDtoDamageTypeSemitrailerEnum {
    SqueezedBackDoor = 'SQUEEZED_BACK_DOOR',
    SqueezedFender = 'SQUEEZED_FENDER',
    SqueezedSideBar = 'SQUEEZED_SIDE_BAR',
    SqueezedSideUnderrun = 'SQUEEZED_SIDE_UNDERRUN',
    BrokenBumper = 'BROKEN_BUMPER',
    DeformationOfTheBumperCurved = 'DEFORMATION_OF_THE_BUMPER_CURVED',
    NotProperlyClosedSideClosingBar = 'NOT_PROPERLY_CLOSED_SIDE_CLOSING_BAR',
    DamagedCoverOfTheTrailer = 'DAMAGED_COVER_OF_THE_TRAILER',
    BrokenPositionLight = 'BROKEN_POSITION_LIGHT',
    MissingPositionLight = 'MISSING_POSITION_LIGHT',
    SideClipOfTheCoverCutOut = 'SIDE_CLIP_OF_THE_COVER_CUT_OUT',
    NotProperlyClosedBackDoor = 'NOT_PROPERLY_CLOSED_BACK_DOOR',
    CutCustomsCable = 'CUT_CUSTOMS_CABLE',
    MissingCustomsCable = 'MISSING_CUSTOMS_CABLE',
    MovedCargoInside = 'MOVED_CARGO_INSIDE',
    CargoNotFixed = 'CARGO_NOT_FIXED',
    GraffitiOnTheCover = 'GRAFFITI_ON_THE_COVER',
    MissingCoveringRubberOfTheRoof = 'MISSING_COVERING_RUBBER_OF_THE_ROOF',
    DamagedCoveringRubberOfTheRoof = 'DAMAGED_COVERING_RUBBER_OF_THE_ROOF',
    TireDamage = 'TIRE_DAMAGE',
    BrokenLeg = 'BROKEN_LEG',
    DamagedLegsRetractableSystem = 'DAMAGED_LEGS_RETRACTABLE_SYSTEM',
    DamagedYellowHandlingSpot = 'DAMAGED_YELLOW_HANDLING_SPOT',
    Other = 'OTHER'
}

/**
 * 
 * @export
 * @interface DemoDto
 */
export interface DemoDto {
    /**
     * Id of object
     * @type {number}
     * @memberof DemoDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DemoDto
     */
    textField?: string;
    /**
     * 
     * @type {string}
     * @memberof DemoDto
     */
    enumField?: DemoDtoEnumFieldEnum;
    /**
     * 
     * @type {boolean}
     * @memberof DemoDto
     */
    booleanField?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DemoDto
     */
    dateTimeField?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum DemoDtoEnumFieldEnum {
    Option1 = 'OPTION1',
    Option2 = 'OPTION2'
}

/**
 * Driver arrival
 * @export
 * @interface DriverArrivalDto
 */
export interface DriverArrivalDto {
    /**
     * Id of object
     * @type {number}
     * @memberof DriverArrivalDto
     */
    id?: number;
    /**
     * 
     * @type {DriverDto}
     * @memberof DriverArrivalDto
     */
    driver?: DriverDto;
    /**
     * Time of arrival
     * @type {string}
     * @memberof DriverArrivalDto
     */
    arrivalDateTime: string;
    /**
     * Used if there is no Gate In
     * @type {boolean}
     * @memberof DriverArrivalDto
     */
    withoutGateIn?: boolean;
    /**
     * 
     * @type {TransitionDto}
     * @memberof DriverArrivalDto
     */
    plannedTransition?: TransitionDto;
    /**
     * Used if there is no Gate Out
     * @type {boolean}
     * @memberof DriverArrivalDto
     */
    withoutGateOut?: boolean;
    /**
     * Gate Out Container type
     * @type {string}
     * @memberof DriverArrivalDto
     */
    containerType?: DriverArrivalDtoContainerTypeEnum;
    /**
     * Gate Out Container ILU code
     * @type {string}
     * @memberof DriverArrivalDto
     */
    iluCode?: string;
    /**
     * Gate Out Reference
     * @type {string}
     * @memberof DriverArrivalDto
     */
    reference?: string;
    /**
     * Gate Out - container is repleted
     * @type {boolean}
     * @memberof DriverArrivalDto
     */
    repleted?: boolean;
    /**
     * State of arrival
     * @type {string}
     * @memberof DriverArrivalDto
     */
    state?: DriverArrivalDtoStateEnum;
    /**
     * Driver pass
     * @type {string}
     * @memberof DriverArrivalDto
     */
    driverPass?: DriverArrivalDtoDriverPassEnum;
    /**
     * Driver pass for Gate IN
     * @type {string}
     * @memberof DriverArrivalDto
     */
    driverPassGateIn?: DriverArrivalDtoDriverPassGateInEnum;
    /**
     * Driver pass for Gate OUT
     * @type {string}
     * @memberof DriverArrivalDto
     */
    driverPassGateOut?: DriverArrivalDtoDriverPassGateOutEnum;
    /**
     * Cause of Driver pass for Gate OUT
     * @type {string}
     * @memberof DriverArrivalDto
     */
    driverPassCauseGateOut?: DriverArrivalDtoDriverPassCauseGateOutEnum;
    /**
     * 
     * @type {TransitionDto}
     * @memberof DriverArrivalDto
     */
    gateOutTransition?: TransitionDto;
    /**
     * 
     * @type {TransitionDto}
     * @memberof DriverArrivalDto
     */
    candidateForGateOutTransition?: TransitionDto;
    /**
     * Licence plate of semitrailer
     * @type {string}
     * @memberof DriverArrivalDto
     */
    semitrailerLicencePlate?: string;
    /**
     * Instant green pass for driver
     * @type {boolean}
     * @memberof DriverArrivalDto
     */
    instantGreenPass?: boolean;
    /**
     * Planned time of Gate IN train arrival of drivers Gate OUT cargo
     * @type {string}
     * @memberof DriverArrivalDto
     */
    incomingTrainPlannedTime?: string;
    /**
     * Time of start of unloading Gate In train with drivers Gate OUT cargo
     * @type {string}
     * @memberof DriverArrivalDto
     */
    unloadingDateTime?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum DriverArrivalDtoContainerTypeEnum {
    ShippingContainer = 'SHIPPING_CONTAINER',
    Semitrailer = 'SEMITRAILER'
}
/**
    * @export
    * @enum {string}
    */
export enum DriverArrivalDtoStateEnum {
    New = 'NEW',
    Processed = 'PROCESSED',
    Canceled = 'CANCELED'
}
/**
    * @export
    * @enum {string}
    */
export enum DriverArrivalDtoDriverPassEnum {
    Green = 'GREEN',
    Orange = 'ORANGE',
    Red = 'RED'
}
/**
    * @export
    * @enum {string}
    */
export enum DriverArrivalDtoDriverPassGateInEnum {
    Green = 'GREEN',
    Orange = 'ORANGE',
    Red = 'RED'
}
/**
    * @export
    * @enum {string}
    */
export enum DriverArrivalDtoDriverPassGateOutEnum {
    Green = 'GREEN',
    Orange = 'ORANGE',
    Red = 'RED'
}
/**
    * @export
    * @enum {string}
    */
export enum DriverArrivalDtoDriverPassCauseGateOutEnum {
    WithoutGateOut = 'WITHOUT_GATE_OUT',
    GateOutTrainStillOnTheWay = 'GATE_OUT_TRAIN_STILL_ON_THE_WAY',
    GateOutTrainStillCustoms = 'GATE_OUT_TRAIN_STILL_CUSTOMS',
    GateOutTrainStillProcessing = 'GATE_OUT_TRAIN_STILL_PROCESSING',
    GateOutTrainStillUnloading = 'GATE_OUT_TRAIN_STILL_UNLOADING',
    GateOutNotFilled = 'GATE_OUT_NOT_FILLED',
    GateOutReady = 'GATE_OUT_READY',
    GateOutWithoutReference = 'GATE_OUT_WITHOUT_REFERENCE',
    GateOutNotFound = 'GATE_OUT_NOT_FOUND',
    GateOutMoreThanOneFound = 'GATE_OUT_MORE_THAN_ONE_FOUND',
    GateOutCoparnNotFound = 'GATE_OUT_COPARN_NOT_FOUND',
    GateOutCoparnMoreThanOneFound = 'GATE_OUT_COPARN_MORE_THAN_ONE_FOUND',
    GateOutCoparnAlreadyProcessed = 'GATE_OUT_COPARN_ALREADY_PROCESSED',
    GateOutCustomsStop = 'GATE_OUT_CUSTOMS_STOP',
    GateOutLockdown = 'GATE_OUT_LOCKDOWN',
    GateOutSemitrailer = 'GATE_OUT_SEMITRAILER'
}

/**
 * 
 * @export
 * @interface DriverCarrierDto
 */
export interface DriverCarrierDto {
    /**
     * Id of object
     * @type {number}
     * @memberof DriverCarrierDto
     */
    id?: number;
    /**
     * Name of driver
     * @type {string}
     * @memberof DriverCarrierDto
     */
    name: string;
    /**
     * 
     * @type {DriverArrivalDto}
     * @memberof DriverCarrierDto
     */
    driverArrival?: DriverArrivalDto;
}
/**
 * 
 * @export
 * @interface DriverCarrierSearchDto
 */
export interface DriverCarrierSearchDto {
    /**
     * Name of driver
     * @type {string}
     * @memberof DriverCarrierSearchDto
     */
    name?: string;
    /**
     * Id of carrier
     * @type {number}
     * @memberof DriverCarrierSearchDto
     */
    carrierId?: number;
}
/**
 * driver object to create
 * @export
 * @interface DriverDto
 */
export interface DriverDto {
    /**
     * Id of object
     * @type {number}
     * @memberof DriverDto
     */
    id?: number;
    /**
     * Name of driver
     * @type {string}
     * @memberof DriverDto
     */
    name: string;
    /**
     * 
     * @type {LicensePlateDto}
     * @memberof DriverDto
     */
    frontLicensePlate?: LicensePlateDto;
    /**
     * 
     * @type {LicensePlateDto}
     * @memberof DriverDto
     */
    rearLicensePlate?: LicensePlateDto;
    /**
     * 
     * @type {CarrierShortDto}
     * @memberof DriverDto
     */
    carrierShort?: CarrierShortDto;
}
/**
 * 
 * @export
 * @interface DriverSearchDto
 */
export interface DriverSearchDto {
    /**
     * 
     * @type {string}
     * @memberof DriverSearchDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DriverSearchDto
     */
    frontLicensePlate?: string;
    /**
     * 
     * @type {string}
     * @memberof DriverSearchDto
     */
    rearLicensePlate?: string;
    /**
     * 
     * @type {string}
     * @memberof DriverSearchDto
     */
    carrierName?: string;
}
/**
 * Driver entity
 * @export
 * @interface DriverShortDto
 */
export interface DriverShortDto {
    /**
     * Id of object
     * @type {number}
     * @memberof DriverShortDto
     */
    id?: number;
    /**
     * Driver name
     * @type {string}
     * @memberof DriverShortDto
     */
    name: string;
}
/**
 * Collection of transition extras (additional services)
 * @export
 * @interface ExtraDto
 */
export interface ExtraDto {
    /**
     * Id of object
     * @type {number}
     * @memberof ExtraDto
     */
    id?: number;
    /**
     * Name of service
     * @type {string}
     * @memberof ExtraDto
     */
    name?: string;
    /**
     * Price of service
     * @type {number}
     * @memberof ExtraDto
     */
    price?: number;
    /**
     * Type of export
     * @type {string}
     * @memberof ExtraDto
     */
    extraType?: ExtraDtoExtraTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ExtraDtoExtraTypeEnum {
    Default = 'DEFAULT'
}

/**
 * Related gate
 * @export
 * @interface GateDto
 */
export interface GateDto {
    /**
     * Id of object
     * @type {number}
     * @memberof GateDto
     */
    id?: number;
    /**
     * AsParking Gate number
     * @type {number}
     * @memberof GateDto
     */
    asparkingNumber: number;
    /**
     * Gate Type
     * @type {string}
     * @memberof GateDto
     */
    gateType?: GateDtoGateTypeEnum;
    /**
     * Gate Number
     * @type {number}
     * @memberof GateDto
     */
    gateNumber?: number;
    /**
     * Gate position, true means open
     * @type {boolean}
     * @memberof GateDto
     */
    open?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum GateDtoGateTypeEnum {
    In = 'IN',
    Out = 'OUT',
    Main = 'MAIN'
}

/**
 * 
 * @export
 * @interface GateSearchDto
 */
export interface GateSearchDto {
    /**
     * 
     * @type {number}
     * @memberof GateSearchDto
     */
    asparkingNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof GateSearchDto
     */
    gateType?: GateSearchDtoGateTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof GateSearchDto
     */
    gateNumber?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GateSearchDto
     */
    open?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum GateSearchDtoGateTypeEnum {
    In = 'IN',
    Out = 'OUT',
    Main = 'MAIN'
}

/**
 * invoice filter object for generating invoice data excel
 * @export
 * @interface InvoiceDataRequestDto
 */
export interface InvoiceDataRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InvoiceDataRequestDto
     */
    invoiceType?: InvoiceDataRequestDtoInvoiceTypeEnum;
    /**
     * 
     * @type {CompanyDto}
     * @memberof InvoiceDataRequestDto
     */
    company?: CompanyDto;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDataRequestDto
     */
    dateTimeFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDataRequestDto
     */
    dateTimeTo?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum InvoiceDataRequestDtoInvoiceTypeEnum {
    BrnoSemitrailerManipulation = 'BRNO_SEMITRAILER_MANIPULATION',
    BrnoSemitrailerParking = 'BRNO_SEMITRAILER_PARKING',
    BrnoShippingContainersManipulation = 'BRNO_SHIPPING_CONTAINERS_MANIPULATION',
    BrnoShippingContainersStored = 'BRNO_SHIPPING_CONTAINERS_STORED',
    MosnovShippingContainerEmptyManipulation = 'MOSNOV_SHIPPING_CONTAINER_EMPTY_MANIPULATION',
    MosnovShippingContainerFullManipulation = 'MOSNOV_SHIPPING_CONTAINER_FULL_MANIPULATION',
    MosnovShippingContainerFullStore = 'MOSNOV_SHIPPING_CONTAINER_FULL_STORE',
    MosnovShippingContainerEmptyStore = 'MOSNOV_SHIPPING_CONTAINER_EMPTY_STORE',
    MosnovSemitrailerManipulationTrain = 'MOSNOV_SEMITRAILER_MANIPULATION_TRAIN',
    MosnovSemitrailerManipulationRoad = 'MOSNOV_SEMITRAILER_MANIPULATION_ROAD',
    MosnovSemitrailerStore = 'MOSNOV_SEMITRAILER_STORE',
    MosnovExtras = 'MOSNOV_EXTRAS'
}

/**
 * 
 * @export
 * @interface JoinCoparnTruckContainerDto
 */
export interface JoinCoparnTruckContainerDto {
    /**
     * 
     * @type {CoparnContainerDto}
     * @memberof JoinCoparnTruckContainerDto
     */
    coparnContainerDto: CoparnContainerDto;
    /**
     * 
     * @type {CoparnTruckDto}
     * @memberof JoinCoparnTruckContainerDto
     */
    coparnTruckDto: CoparnTruckDto;
}
/**
 * 
 * @export
 * @interface LayoutContainerDto
 */
export interface LayoutContainerDto {
    /**
     * Id of object
     * @type {number}
     * @memberof LayoutContainerDto
     */
    id?: number;
    /**
     * Unique ILU code of container
     * @type {string}
     * @memberof LayoutContainerDto
     */
    iluCode: string;
    /**
     * TEU value
     * @type {number}
     * @memberof LayoutContainerDto
     */
    teu: number;
    /**
     * If container is repleted
     * @type {boolean}
     * @memberof LayoutContainerDto
     */
    repleted: boolean;
    /**
     * Container type
     * @type {string}
     * @memberof LayoutContainerDto
     */
    containerType?: LayoutContainerDtoContainerTypeEnum;
    /**
     * Type of shipping container
     * @type {string}
     * @memberof LayoutContainerDto
     */
    shippingContainerType?: LayoutContainerDtoShippingContainerTypeEnum;
    /**
     * Type of semitrailer
     * @type {string}
     * @memberof LayoutContainerDto
     */
    semitrailerType?: LayoutContainerDtoSemitrailerTypeEnum;
    /**
     * If container has some damage
     * @type {boolean}
     * @memberof LayoutContainerDto
     */
    damaged?: boolean;
    /**
     * Quality of container
     * @type {string}
     * @memberof LayoutContainerDto
     */
    qualityType?: LayoutContainerDtoQualityTypeEnum;
    /**
     * Shipowner name
     * @type {string}
     * @memberof LayoutContainerDto
     */
    shipownerName?: string;
    /**
     * Company name
     * @type {string}
     * @memberof LayoutContainerDto
     */
    companyName?: string;
    /**
     * Customs state of the transition
     * @type {string}
     * @memberof LayoutContainerDto
     */
    customsState?: LayoutContainerDtoCustomsStateEnum;
    /**
     * If container has ADR/RID
     * @type {boolean}
     * @memberof LayoutContainerDto
     */
    adrRid?: boolean;
    /**
     * Status of manipulation request
     * @type {string}
     * @memberof LayoutContainerDto
     */
    manipulationRequestStatus?: LayoutContainerDtoManipulationRequestStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof LayoutContainerDto
     */
    reserved?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum LayoutContainerDtoContainerTypeEnum {
    ShippingContainer = 'SHIPPING_CONTAINER',
    Semitrailer = 'SEMITRAILER'
}
/**
    * @export
    * @enum {string}
    */
export enum LayoutContainerDtoShippingContainerTypeEnum {
    Iso20G1 = 'ISO_20G1',
    Iso22G0 = 'ISO_22G0',
    Iso22G1 = 'ISO_22G1',
    Iso22K2 = 'ISO_22K2',
    Iso22P4 = 'ISO_22P4',
    Iso22R0 = 'ISO_22R0',
    Iso22R1 = 'ISO_22R1',
    Iso22U0 = 'ISO_22U0',
    Iso22U1 = 'ISO_22U1',
    Iso25G0 = 'ISO_25G0',
    Iso2Mn9 = 'ISO_2MN9',
    Iso42G0 = 'ISO_42G0',
    Iso42G1 = 'ISO_42G1',
    Iso42P1 = 'ISO_42P1',
    Iso42P4 = 'ISO_42P4',
    Iso42R0 = 'ISO_42R0',
    Iso42R1 = 'ISO_42R1',
    Iso42U0 = 'ISO_42U0',
    Iso42U1 = 'ISO_42U1',
    Iso45G0 = 'ISO_45G0',
    Iso45G1 = 'ISO_45G1',
    Iso45R1 = 'ISO_45R1',
    Iso45U1 = 'ISO_45U1',
    IsoL5G0 = 'ISO_L5G0',
    IsoL5G1 = 'ISO_L5G1',
    P20 = 'P20',
    P22 = 'P22',
    P28 = 'P28',
    Tc23 = 'TC23',
    Xm = 'XM',
    Xxm = 'XXM',
    Xl = 'XL',
    Xxl = 'XXL',
    XxlLight = 'XXL_LIGHT',
    XxlMtl = 'XXL_MTL',
    Mt = 'MT',
    RtOre = 'RT_ORE',
    Graintainer = 'GRAINTAINER',
    Xml = 'XML',
    HtM = 'HT_M',
    HtXxl = 'HT_XXL',
    AtOt = 'AT_OT',
    AtHt = 'AT_HT',
    Citytainer = 'CITYTAINER',
    XxlSd = 'XXL_SD',
    Chemietainer = 'CHEMIETAINER',
    Cemtainer = 'CEMTAINER',
    Xx20 = 'XX20',
    Sb = 'SB'
}
/**
    * @export
    * @enum {string}
    */
export enum LayoutContainerDtoSemitrailerTypeEnum {
    Standard = 'STANDARD',
    Mega = 'MEGA'
}
/**
    * @export
    * @enum {string}
    */
export enum LayoutContainerDtoQualityTypeEnum {
    New = 'NEW',
    Food = 'FOOD',
    Paper = 'PAPER',
    Standard = 'STANDARD',
    Scrap = 'SCRAP'
}
/**
    * @export
    * @enum {string}
    */
export enum LayoutContainerDtoCustomsStateEnum {
    T1ByUser = 'T1_BY_USER',
    T1ByEmail = 'T1_BY_EMAIL',
    SadExternal = 'SAD_EXTERNAL',
    SadPortService = 'SAD_PORT_SERVICE',
    Stop = 'STOP'
}
/**
    * @export
    * @enum {string}
    */
export enum LayoutContainerDtoManipulationRequestStatusEnum {
    Pending = 'PENDING',
    Finished = 'FINISHED',
    Canceled = 'CANCELED'
}

/**
 * rear license plate of driver
 * @export
 * @interface LicensePlateDto
 */
export interface LicensePlateDto {
    /**
     * Id of object
     * @type {number}
     * @memberof LicensePlateDto
     */
    id?: number;
    /**
     * License plate.
     * @type {string}
     * @memberof LicensePlateDto
     */
    licensePlate: string;
}
/**
 * Array of recognized containers.
 * @export
 * @interface M2mContainerDto
 */
export interface M2mContainerDto {
    /**
     * Id of object
     * @type {number}
     * @memberof M2mContainerDto
     */
    id?: number;
    /**
     * ilu code
     * @type {string}
     * @memberof M2mContainerDto
     */
    identifier?: string;
    /**
     * Train camera result
     * @type {string}
     * @memberof M2mContainerDto
     */
    checksum?: M2mContainerDtoChecksumEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum M2mContainerDtoChecksumEnum {
    Passed = 'PASSED',
    Failed = 'FAILED'
}

/**
 * 
 * @export
 * @interface M2mTrainDto
 */
export interface M2mTrainDto {
    /**
     * Id of object
     * @type {number}
     * @memberof M2mTrainDto
     */
    id?: number;
    /**
     * Array of recognized wagons.
     * @type {Array<M2mWagonDto>}
     * @memberof M2mTrainDto
     */
    wagons?: Array<M2mWagonDto>;
    /**
     * Array of recognized containers.
     * @type {Array<M2mContainerDto>}
     * @memberof M2mTrainDto
     */
    containers?: Array<M2mContainerDto>;
    /**
     * Camera direction
     * @type {string}
     * @memberof M2mTrainDto
     */
    direction?: M2mTrainDtoDirectionEnum;
    /**
     * Date of create
     * @type {string}
     * @memberof M2mTrainDto
     */
    createdAt?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum M2mTrainDtoDirectionEnum {
    In = 'IN',
    Out = 'OUT',
    Extern = 'EXTERN'
}

/**
 * 
 * @export
 * @interface M2mTrainSearchDto
 */
export interface M2mTrainSearchDto {
    /**
     * 
     * @type {string}
     * @memberof M2mTrainSearchDto
     */
    direction?: M2mTrainSearchDtoDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof M2mTrainSearchDto
     */
    createdAt?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum M2mTrainSearchDtoDirectionEnum {
    In = 'IN',
    Out = 'OUT',
    Extern = 'EXTERN'
}

/**
 * Array of recognized wagons.
 * @export
 * @interface M2mWagonDto
 */
export interface M2mWagonDto {
    /**
     * Id of object
     * @type {number}
     * @memberof M2mWagonDto
     */
    id?: number;
    /**
     * Wagon number
     * @type {string}
     * @memberof M2mWagonDto
     */
    identifier?: string;
    /**
     * Train camera result
     * @type {string}
     * @memberof M2mWagonDto
     */
    checksum?: M2mWagonDtoChecksumEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum M2mWagonDtoChecksumEnum {
    Passed = 'PASSED',
    Failed = 'FAILED'
}

/**
 * 
 * @export
 * @interface ManipulationRequestDto
 */
export interface ManipulationRequestDto {
    /**
     * Id of object
     * @type {number}
     * @memberof ManipulationRequestDto
     */
    id?: number;
    /**
     * Transition for manipulation
     * @type {number}
     * @memberof ManipulationRequestDto
     */
    transitionId: number;
    /**
     * Status of manipulation request
     * @type {string}
     * @memberof ManipulationRequestDto
     */
    manipulationRequestStatus?: ManipulationRequestDtoManipulationRequestStatusEnum;
    /**
     * Sector letter where container is stored
     * @type {string}
     * @memberof ManipulationRequestDto
     */
    sectorLetter?: string;
    /**
     * Column where container is stored
     * @type {number}
     * @memberof ManipulationRequestDto
     */
    sectorColumn?: number;
    /**
     * Row where container is stored
     * @type {number}
     * @memberof ManipulationRequestDto
     */
    sectorRow?: number;
    /**
     * Floor where container is stored
     * @type {number}
     * @memberof ManipulationRequestDto
     */
    sectorFloor?: number;
    /**
     * If the container should be weighted
     * @type {boolean}
     * @memberof ManipulationRequestDto
     */
    vgmRequest?: boolean;
    /**
     * If container should be moved in or out of Repair Shop
     * @type {string}
     * @memberof ManipulationRequestDto
     */
    repairShopDirection?: ManipulationRequestDtoRepairShopDirectionEnum;
    /**
     * Date and time
     * @type {string}
     * @memberof ManipulationRequestDto
     */
    createdAt?: string;
    /**
     * Container ILU
     * @type {string}
     * @memberof ManipulationRequestDto
     */
    iluCode?: string;
    /**
     * Original sector letter
     * @type {string}
     * @memberof ManipulationRequestDto
     */
    oldSectorLetter?: string;
    /**
     * Original sector column
     * @type {number}
     * @memberof ManipulationRequestDto
     */
    oldSectorColumn?: number;
    /**
     * Original sector row
     * @type {number}
     * @memberof ManipulationRequestDto
     */
    oldSectorRow?: number;
    /**
     * Original sector floor
     * @type {number}
     * @memberof ManipulationRequestDto
     */
    oldSectorFloor?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum ManipulationRequestDtoManipulationRequestStatusEnum {
    Pending = 'PENDING',
    Finished = 'FINISHED',
    Canceled = 'CANCELED'
}
/**
    * @export
    * @enum {string}
    */
export enum ManipulationRequestDtoRepairShopDirectionEnum {
    In = 'IN',
    Out = 'OUT'
}

/**
 * 
 * @export
 * @interface NhmCodeDto
 */
export interface NhmCodeDto {
    /**
     * Id of object
     * @type {number}
     * @memberof NhmCodeDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof NhmCodeDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof NhmCodeDto
     */
    nameCz: string;
    /**
     * 
     * @type {string}
     * @memberof NhmCodeDto
     */
    noteCz: string;
    /**
     * 
     * @type {string}
     * @memberof NhmCodeDto
     */
    nameEn: string;
    /**
     * 
     * @type {string}
     * @memberof NhmCodeDto
     */
    noteEn: string;
    /**
     * 
     * @type {NhmGroupDto}
     * @memberof NhmCodeDto
     */
    nhmChapter?: NhmGroupDto;
    /**
     * 
     * @type {NhmCommodityGroupDto}
     * @memberof NhmCodeDto
     */
    nhmCommodityGroupDto?: NhmCommodityGroupDto;
}
/**
 * 
 * @export
 * @interface NhmCodeSearchDto
 */
export interface NhmCodeSearchDto {
    /**
     * 
     * @type {string}
     * @memberof NhmCodeSearchDto
     */
    codeOrNameCz?: string;
    /**
     * 
     * @type {string}
     * @memberof NhmCodeSearchDto
     */
    codeOrNameEn?: string;
    /**
     * 
     * @type {string}
     * @memberof NhmCodeSearchDto
     */
    nameCz?: string;
    /**
     * 
     * @type {string}
     * @memberof NhmCodeSearchDto
     */
    noteCz?: string;
    /**
     * 
     * @type {string}
     * @memberof NhmCodeSearchDto
     */
    nameEn?: string;
    /**
     * 
     * @type {string}
     * @memberof NhmCodeSearchDto
     */
    noteEn?: string;
    /**
     * 
     * @type {NhmGroupDto}
     * @memberof NhmCodeSearchDto
     */
    nhmGroupDto?: NhmGroupDto;
    /**
     * 
     * @type {NhmCommodityGroupDto}
     * @memberof NhmCodeSearchDto
     */
    nhmCommodityGroupDto?: NhmCommodityGroupDto;
}
/**
 * 
 * @export
 * @interface NhmCommodityGroupDto
 */
export interface NhmCommodityGroupDto {
    /**
     * Id of object
     * @type {number}
     * @memberof NhmCommodityGroupDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof NhmCommodityGroupDto
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof NhmCommodityGroupDto
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface NhmCommodityGroupSearchDto
 */
export interface NhmCommodityGroupSearchDto {
    /**
     * 
     * @type {string}
     * @memberof NhmCommodityGroupSearchDto
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof NhmCommodityGroupSearchDto
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface NhmGroupDto
 */
export interface NhmGroupDto {
    /**
     * Id of object
     * @type {number}
     * @memberof NhmGroupDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof NhmGroupDto
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof NhmGroupDto
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface NhmGroupSearchDto
 */
export interface NhmGroupSearchDto {
    /**
     * 
     * @type {string}
     * @memberof NhmGroupSearchDto
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof NhmGroupSearchDto
     */
    name?: string;
}
/**
 * notification object to create
 * @export
 * @interface NotificationDto
 */
export interface NotificationDto {
    /**
     * Id of object
     * @type {number}
     * @memberof NotificationDto
     */
    id?: number;
    /**
     * Type of notification
     * @type {string}
     * @memberof NotificationDto
     */
    notificationType: NotificationDtoNotificationTypeEnum;
    /**
     * License plate of truck
     * @type {string}
     * @memberof NotificationDto
     */
    licensePlateFront?: string;
    /**
     * License plate of semitrailer
     * @type {string}
     * @memberof NotificationDto
     */
    licensePlateRear?: string;
    /**
     * Container ilu code from truck
     * @type {string}
     * @memberof NotificationDto
     */
    iluCode?: string;
    /**
     * Date time of notification closing
     * @type {string}
     * @memberof NotificationDto
     */
    createdAt?: string;
    /**
     * Date time of notification closing
     * @type {string}
     * @memberof NotificationDto
     */
    closedAt?: string;
    /**
     * List of notified users
     * @type {Array<UserDto>}
     * @memberof NotificationDto
     */
    notifiedUsers?: Array<UserDto>;
    /**
     * Is notification enabled
     * @type {boolean}
     * @memberof NotificationDto
     */
    enabled?: boolean;
    /**
     * 
     * @type {GateDto}
     * @memberof NotificationDto
     */
    gate?: GateDto;
    /**
     * Id of related M2M train
     * @type {number}
     * @memberof NotificationDto
     */
    m2mTrainId?: number;
    /**
     * Special flag for license plates
     * @type {string}
     * @memberof NotificationDto
     */
    flag?: string;
    /**
     * Transition ID
     * @type {number}
     * @memberof NotificationDto
     */
    transitionId?: number;
    /**
     * 
     * @type {DriverArrivalDto}
     * @memberof NotificationDto
     */
    driverArrival?: DriverArrivalDto;
    /**
     * Verified Gross Mass
     * @type {number}
     * @memberof NotificationDto
     */
    vgm?: number;
    /**
     * Coparn Medlog reference
     * @type {string}
     * @memberof NotificationDto
     */
    reference?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum NotificationDtoNotificationTypeEnum {
    GiCheckpoint2 = 'GI_CHECKPOINT_2',
    GiCheckpoint2WithoutIluCode = 'GI_CHECKPOINT_2_WITHOUT_ILU_CODE',
    GoCheckpoint2 = 'GO_CHECKPOINT_2',
    GoCheckpoint2WithoutIluCode = 'GO_CHECKPOINT_2_WITHOUT_ILU_CODE',
    UnknownVehicle = 'UNKNOWN_VEHICLE',
    IluReadError = 'ILU_READ_ERROR',
    LicensePlateReadError = 'LICENSE_PLATE_READ_ERROR',
    M2MTrainCameraRead = 'M2M_TRAIN_CAMERA_READ',
    GateOutCoparn = 'GATE_OUT_COPARN',
    LicensePlateWithFlag = 'LICENSE_PLATE_WITH_FLAG',
    SuccessfulGreenPass = 'SUCCESSFUL_GREEN_PASS',
    FailedGreenPass = 'FAILED_GREEN_PASS',
    DriverArrivalWithGateIn = 'DRIVER_ARRIVAL_WITH_GATE_IN',
    DriverArrivalWithGateOutEmpty = 'DRIVER_ARRIVAL_WITH_GATE_OUT_EMPTY',
    DriverArrivalWithGateOutFull = 'DRIVER_ARRIVAL_WITH_GATE_OUT_FULL',
    DriverArrivalWithGateOutEmptyFailed = 'DRIVER_ARRIVAL_WITH_GATE_OUT_EMPTY_FAILED',
    DriverArrivalWithGateOutFullFailed = 'DRIVER_ARRIVAL_WITH_GATE_OUT_FULL_FAILED',
    DriverArrivalWithGateOutSemitrailer = 'DRIVER_ARRIVAL_WITH_GATE_OUT_SEMITRAILER',
    AmbiguousAdrCode = 'AMBIGUOUS_ADR_CODE',
    VgmWeighted = 'VGM_WEIGHTED',
    ContainerOverload = 'CONTAINER_OVERLOAD',
    BadCoparnMessageGateIn = 'BAD_COPARN_MESSAGE_GATE_IN',
    BadCoparnMessageGateOut = 'BAD_COPARN_MESSAGE_GATE_OUT'
}

/**
 * 
 * @export
 * @interface NotificationSearchDto
 */
export interface NotificationSearchDto {
    /**
     * 
     * @type {number}
     * @memberof NotificationSearchDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof NotificationSearchDto
     */
    notificationType?: NotificationSearchDtoNotificationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationSearchDto
     */
    licencePlate?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationSearchDto
     */
    iluCode?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationSearchDto
     */
    closedAt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSearchDto
     */
    enabled?: boolean;
    /**
     * 
     * @type {GateSearchDto}
     * @memberof NotificationSearchDto
     */
    gate?: GateSearchDto;
}

/**
    * @export
    * @enum {string}
    */
export enum NotificationSearchDtoNotificationTypeEnum {
    GiCheckpoint2 = 'GI_CHECKPOINT_2',
    GiCheckpoint2WithoutIluCode = 'GI_CHECKPOINT_2_WITHOUT_ILU_CODE',
    GoCheckpoint2 = 'GO_CHECKPOINT_2',
    GoCheckpoint2WithoutIluCode = 'GO_CHECKPOINT_2_WITHOUT_ILU_CODE',
    UnknownVehicle = 'UNKNOWN_VEHICLE',
    IluReadError = 'ILU_READ_ERROR',
    LicensePlateReadError = 'LICENSE_PLATE_READ_ERROR',
    M2MTrainCameraRead = 'M2M_TRAIN_CAMERA_READ',
    GateOutCoparn = 'GATE_OUT_COPARN',
    LicensePlateWithFlag = 'LICENSE_PLATE_WITH_FLAG',
    SuccessfulGreenPass = 'SUCCESSFUL_GREEN_PASS',
    FailedGreenPass = 'FAILED_GREEN_PASS',
    DriverArrivalWithGateIn = 'DRIVER_ARRIVAL_WITH_GATE_IN',
    DriverArrivalWithGateOutEmpty = 'DRIVER_ARRIVAL_WITH_GATE_OUT_EMPTY',
    DriverArrivalWithGateOutFull = 'DRIVER_ARRIVAL_WITH_GATE_OUT_FULL',
    DriverArrivalWithGateOutEmptyFailed = 'DRIVER_ARRIVAL_WITH_GATE_OUT_EMPTY_FAILED',
    DriverArrivalWithGateOutFullFailed = 'DRIVER_ARRIVAL_WITH_GATE_OUT_FULL_FAILED',
    DriverArrivalWithGateOutSemitrailer = 'DRIVER_ARRIVAL_WITH_GATE_OUT_SEMITRAILER',
    AmbiguousAdrCode = 'AMBIGUOUS_ADR_CODE',
    VgmWeighted = 'VGM_WEIGHTED',
    ContainerOverload = 'CONTAINER_OVERLOAD',
    BadCoparnMessageGateIn = 'BAD_COPARN_MESSAGE_GATE_IN',
    BadCoparnMessageGateOut = 'BAD_COPARN_MESSAGE_GATE_OUT'
}

/**
 * 
 * @export
 * @interface OperatorTransitionDto
 */
export interface OperatorTransitionDto {
    /**
     * Id
     * @type {string}
     * @memberof OperatorTransitionDto
     */
    id?: string;
    /**
     * Specific transition id
     * @type {number}
     * @memberof OperatorTransitionDto
     */
    transitionId?: number;
    /**
     * Direction of receipt
     * @type {string}
     * @memberof OperatorTransitionDto
     */
    direction?: OperatorTransitionDtoDirectionEnum;
    /**
     * State of the transition
     * @type {string}
     * @memberof OperatorTransitionDto
     */
    containerState?: OperatorTransitionDtoContainerStateEnum;
    /**
     * Receipt method of container
     * @type {string}
     * @memberof OperatorTransitionDto
     */
    receiptMethod?: OperatorTransitionDtoReceiptMethodEnum;
    /**
     * Unique ILU code of container
     * @type {string}
     * @memberof OperatorTransitionDto
     */
    iluCode?: string;
    /**
     * Container type
     * @type {string}
     * @memberof OperatorTransitionDto
     */
    containerType?: OperatorTransitionDtoContainerTypeEnum;
    /**
     * Type of shipping container
     * @type {string}
     * @memberof OperatorTransitionDto
     */
    shippingContainerType?: OperatorTransitionDtoShippingContainerTypeEnum;
    /**
     * Type of semitrailer
     * @type {string}
     * @memberof OperatorTransitionDto
     */
    semitrailerType?: OperatorTransitionDtoSemitrailerTypeEnum;
    /**
     * If container is for import/export
     * @type {string}
     * @memberof OperatorTransitionDto
     */
    importExport?: OperatorTransitionDtoImportExportEnum;
    /**
     * Truck licence plate
     * @type {string}
     * @memberof OperatorTransitionDto
     */
    truckLicencePlate?: string;
    /**
     * Train id
     * @type {number}
     * @memberof OperatorTransitionDto
     */
    trainId?: number;
    /**
     * Train name
     * @type {string}
     * @memberof OperatorTransitionDto
     */
    trainName?: string;
    /**
     * Quality of container
     * @type {string}
     * @memberof OperatorTransitionDto
     */
    qualityType?: OperatorTransitionDtoQualityTypeEnum;
    /**
     * Sector letter where container is stored
     * @type {string}
     * @memberof OperatorTransitionDto
     */
    sectorLetter?: string;
    /**
     * Column where container is stored
     * @type {number}
     * @memberof OperatorTransitionDto
     */
    sectorColumn?: number;
    /**
     * Row where container is stored
     * @type {number}
     * @memberof OperatorTransitionDto
     */
    sectorRow?: number;
    /**
     * Floor where container is stored
     * @type {number}
     * @memberof OperatorTransitionDto
     */
    sectorFloor?: number;
    /**
     * Note
     * @type {string}
     * @memberof OperatorTransitionDto
     */
    note?: string;
    /**
     * Coparn Truck Id
     * @type {number}
     * @memberof OperatorTransitionDto
     */
    coparnTruckId?: number;
    /**
     * Coparn Id
     * @type {number}
     * @memberof OperatorTransitionDto
     */
    coparnId?: number;
    /**
     * Medlog reference
     * @type {string}
     * @memberof OperatorTransitionDto
     */
    medlogReference?: string;
    /**
     * If container is repleted
     * @type {boolean}
     * @memberof OperatorTransitionDto
     */
    repleted?: boolean;
    /**
     * If container has some damage
     * @type {boolean}
     * @memberof OperatorTransitionDto
     */
    damaged?: boolean;
    /**
     * Date Time of transition or Coparn
     * @type {string}
     * @memberof OperatorTransitionDto
     */
    dateTime?: string;
    /**
     * Name of shipowner
     * @type {string}
     * @memberof OperatorTransitionDto
     */
    shipownerName?: string;
    /**
     * Name of driver
     * @type {string}
     * @memberof OperatorTransitionDto
     */
    driver?: string;
    /**
     * Payload
     * @type {number}
     * @memberof OperatorTransitionDto
     */
    heavyTestedValue?: number;
    /**
     * Is container locked
     * @type {boolean}
     * @memberof OperatorTransitionDto
     */
    lockdown?: boolean;
    /**
     * VGM
     * @type {boolean}
     * @memberof OperatorTransitionDto
     */
    weighted?: boolean;
    /**
     * Color of the company
     * @type {string}
     * @memberof OperatorTransitionDto
     */
    companyColor?: string;
    /**
     * VGM - Verified Gross Mass
     * @type {number}
     * @memberof OperatorTransitionDto
     */
    vgm?: number;
    /**
     * Id of manipulation request
     * @type {number}
     * @memberof OperatorTransitionDto
     */
    manipulationRequestId?: number;
    /**
     * Sector letter where container is stored
     * @type {string}
     * @memberof OperatorTransitionDto
     */
    sectorLetterForManipulation?: string;
    /**
     * Column where container is stored
     * @type {number}
     * @memberof OperatorTransitionDto
     */
    sectorColumnForManipulation?: number;
    /**
     * Row where container is stored
     * @type {number}
     * @memberof OperatorTransitionDto
     */
    sectorRowForManipulation?: number;
    /**
     * Floor where container is stored
     * @type {number}
     * @memberof OperatorTransitionDto
     */
    sectorFloorForManipulation?: number;
    /**
     * Request for weighing
     * @type {boolean}
     * @memberof OperatorTransitionDto
     */
    vgmRequest?: boolean;
    /**
     * If container should be moved in or out of Repair Shop
     * @type {string}
     * @memberof OperatorTransitionDto
     */
    repairShopDirection?: OperatorTransitionDtoRepairShopDirectionEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum OperatorTransitionDtoDirectionEnum {
    In = 'IN',
    Out = 'OUT',
    Extern = 'EXTERN'
}
/**
    * @export
    * @enum {string}
    */
export enum OperatorTransitionDtoContainerStateEnum {
    PlannedGateIn = 'PLANNED_GATE_IN',
    ProcessingGateIn = 'PROCESSING_GATE_IN',
    FinishedGateIn = 'FINISHED_GATE_IN',
    ProcessingGateOut = 'PROCESSING_GATE_OUT',
    Before3RdCheckpointGo = 'BEFORE_3RD_CHECKPOINT_GO',
    Before2NdCheckpointGo = 'BEFORE_2ND_CHECKPOINT_GO',
    FinishedGateOut = 'FINISHED_GATE_OUT',
    ProcessingExtern = 'PROCESSING_EXTERN',
    FinishedExtern = 'FINISHED_EXTERN'
}
/**
    * @export
    * @enum {string}
    */
export enum OperatorTransitionDtoReceiptMethodEnum {
    Road = 'ROAD',
    Train = 'TRAIN'
}
/**
    * @export
    * @enum {string}
    */
export enum OperatorTransitionDtoContainerTypeEnum {
    ShippingContainer = 'SHIPPING_CONTAINER',
    Semitrailer = 'SEMITRAILER'
}
/**
    * @export
    * @enum {string}
    */
export enum OperatorTransitionDtoShippingContainerTypeEnum {
    Iso20G1 = 'ISO_20G1',
    Iso22G0 = 'ISO_22G0',
    Iso22G1 = 'ISO_22G1',
    Iso22K2 = 'ISO_22K2',
    Iso22P4 = 'ISO_22P4',
    Iso22R0 = 'ISO_22R0',
    Iso22R1 = 'ISO_22R1',
    Iso22U0 = 'ISO_22U0',
    Iso22U1 = 'ISO_22U1',
    Iso25G0 = 'ISO_25G0',
    Iso2Mn9 = 'ISO_2MN9',
    Iso42G0 = 'ISO_42G0',
    Iso42G1 = 'ISO_42G1',
    Iso42P1 = 'ISO_42P1',
    Iso42P4 = 'ISO_42P4',
    Iso42R0 = 'ISO_42R0',
    Iso42R1 = 'ISO_42R1',
    Iso42U0 = 'ISO_42U0',
    Iso42U1 = 'ISO_42U1',
    Iso45G0 = 'ISO_45G0',
    Iso45G1 = 'ISO_45G1',
    Iso45R1 = 'ISO_45R1',
    Iso45U1 = 'ISO_45U1',
    IsoL5G0 = 'ISO_L5G0',
    IsoL5G1 = 'ISO_L5G1',
    P20 = 'P20',
    P22 = 'P22',
    P28 = 'P28',
    Tc23 = 'TC23',
    Xm = 'XM',
    Xxm = 'XXM',
    Xl = 'XL',
    Xxl = 'XXL',
    XxlLight = 'XXL_LIGHT',
    XxlMtl = 'XXL_MTL',
    Mt = 'MT',
    RtOre = 'RT_ORE',
    Graintainer = 'GRAINTAINER',
    Xml = 'XML',
    HtM = 'HT_M',
    HtXxl = 'HT_XXL',
    AtOt = 'AT_OT',
    AtHt = 'AT_HT',
    Citytainer = 'CITYTAINER',
    XxlSd = 'XXL_SD',
    Chemietainer = 'CHEMIETAINER',
    Cemtainer = 'CEMTAINER',
    Xx20 = 'XX20',
    Sb = 'SB'
}
/**
    * @export
    * @enum {string}
    */
export enum OperatorTransitionDtoSemitrailerTypeEnum {
    Standard = 'STANDARD',
    Mega = 'MEGA'
}
/**
    * @export
    * @enum {string}
    */
export enum OperatorTransitionDtoImportExportEnum {
    Import = 'IMPORT',
    Export = 'EXPORT',
    Positioning = 'POSITIONING',
    None = 'NONE'
}
/**
    * @export
    * @enum {string}
    */
export enum OperatorTransitionDtoQualityTypeEnum {
    New = 'NEW',
    Food = 'FOOD',
    Paper = 'PAPER',
    Standard = 'STANDARD',
    Scrap = 'SCRAP'
}
/**
    * @export
    * @enum {string}
    */
export enum OperatorTransitionDtoRepairShopDirectionEnum {
    In = 'IN',
    Out = 'OUT'
}

/**
 * 
 * @export
 * @interface OperatorTransitionSearchDto
 */
export interface OperatorTransitionSearchDto {
    /**
     * 
     * @type {string}
     * @memberof OperatorTransitionSearchDto
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof OperatorTransitionSearchDto
     */
    transitionId?: number;
    /**
     * 
     * @type {string}
     * @memberof OperatorTransitionSearchDto
     */
    direction?: OperatorTransitionSearchDtoDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof OperatorTransitionSearchDto
     */
    receiptMethod?: OperatorTransitionSearchDtoReceiptMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof OperatorTransitionSearchDto
     */
    iluCode?: string;
    /**
     * 
     * @type {string}
     * @memberof OperatorTransitionSearchDto
     */
    containerType?: OperatorTransitionSearchDtoContainerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof OperatorTransitionSearchDto
     */
    shippingContainerType?: OperatorTransitionSearchDtoShippingContainerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof OperatorTransitionSearchDto
     */
    semitrailerType?: OperatorTransitionSearchDtoSemitrailerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof OperatorTransitionSearchDto
     */
    importExport?: OperatorTransitionSearchDtoImportExportEnum;
    /**
     * 
     * @type {string}
     * @memberof OperatorTransitionSearchDto
     */
    truckLicencePlate?: string;
    /**
     * 
     * @type {number}
     * @memberof OperatorTransitionSearchDto
     */
    trainId?: number;
    /**
     * 
     * @type {string}
     * @memberof OperatorTransitionSearchDto
     */
    trainName?: string;
    /**
     * 
     * @type {string}
     * @memberof OperatorTransitionSearchDto
     */
    qualityType?: OperatorTransitionSearchDtoQualityTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof OperatorTransitionSearchDto
     */
    sectorLetter?: string;
    /**
     * 
     * @type {number}
     * @memberof OperatorTransitionSearchDto
     */
    sectorColumn?: number;
    /**
     * 
     * @type {number}
     * @memberof OperatorTransitionSearchDto
     */
    sectorRow?: number;
    /**
     * 
     * @type {number}
     * @memberof OperatorTransitionSearchDto
     */
    sectorFloor?: number;
    /**
     * 
     * @type {string}
     * @memberof OperatorTransitionSearchDto
     */
    note?: string;
    /**
     * 
     * @type {number}
     * @memberof OperatorTransitionSearchDto
     */
    coparnTruckId?: number;
    /**
     * 
     * @type {number}
     * @memberof OperatorTransitionSearchDto
     */
    coparnId?: number;
    /**
     * 
     * @type {string}
     * @memberof OperatorTransitionSearchDto
     */
    medlogReference?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OperatorTransitionSearchDto
     */
    repleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OperatorTransitionSearchDto
     */
    damaged?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OperatorTransitionSearchDto
     */
    dateTime?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum OperatorTransitionSearchDtoDirectionEnum {
    In = 'IN',
    Out = 'OUT',
    Extern = 'EXTERN'
}
/**
    * @export
    * @enum {string}
    */
export enum OperatorTransitionSearchDtoReceiptMethodEnum {
    Road = 'ROAD',
    Train = 'TRAIN'
}
/**
    * @export
    * @enum {string}
    */
export enum OperatorTransitionSearchDtoContainerTypeEnum {
    ShippingContainer = 'SHIPPING_CONTAINER',
    Semitrailer = 'SEMITRAILER'
}
/**
    * @export
    * @enum {string}
    */
export enum OperatorTransitionSearchDtoShippingContainerTypeEnum {
    Iso20G1 = 'ISO_20G1',
    Iso22G0 = 'ISO_22G0',
    Iso22G1 = 'ISO_22G1',
    Iso22K2 = 'ISO_22K2',
    Iso22P4 = 'ISO_22P4',
    Iso22R0 = 'ISO_22R0',
    Iso22R1 = 'ISO_22R1',
    Iso22U0 = 'ISO_22U0',
    Iso22U1 = 'ISO_22U1',
    Iso25G0 = 'ISO_25G0',
    Iso2Mn9 = 'ISO_2MN9',
    Iso42G0 = 'ISO_42G0',
    Iso42G1 = 'ISO_42G1',
    Iso42P1 = 'ISO_42P1',
    Iso42P4 = 'ISO_42P4',
    Iso42R0 = 'ISO_42R0',
    Iso42R1 = 'ISO_42R1',
    Iso42U0 = 'ISO_42U0',
    Iso42U1 = 'ISO_42U1',
    Iso45G0 = 'ISO_45G0',
    Iso45G1 = 'ISO_45G1',
    Iso45R1 = 'ISO_45R1',
    Iso45U1 = 'ISO_45U1',
    IsoL5G0 = 'ISO_L5G0',
    IsoL5G1 = 'ISO_L5G1',
    P20 = 'P20',
    P22 = 'P22',
    P28 = 'P28',
    Tc23 = 'TC23',
    Xm = 'XM',
    Xxm = 'XXM',
    Xl = 'XL',
    Xxl = 'XXL',
    XxlLight = 'XXL_LIGHT',
    XxlMtl = 'XXL_MTL',
    Mt = 'MT',
    RtOre = 'RT_ORE',
    Graintainer = 'GRAINTAINER',
    Xml = 'XML',
    HtM = 'HT_M',
    HtXxl = 'HT_XXL',
    AtOt = 'AT_OT',
    AtHt = 'AT_HT',
    Citytainer = 'CITYTAINER',
    XxlSd = 'XXL_SD',
    Chemietainer = 'CHEMIETAINER',
    Cemtainer = 'CEMTAINER',
    Xx20 = 'XX20',
    Sb = 'SB'
}
/**
    * @export
    * @enum {string}
    */
export enum OperatorTransitionSearchDtoSemitrailerTypeEnum {
    Standard = 'STANDARD',
    Mega = 'MEGA'
}
/**
    * @export
    * @enum {string}
    */
export enum OperatorTransitionSearchDtoImportExportEnum {
    Import = 'IMPORT',
    Export = 'EXPORT',
    Positioning = 'POSITIONING',
    None = 'NONE'
}
/**
    * @export
    * @enum {string}
    */
export enum OperatorTransitionSearchDtoQualityTypeEnum {
    New = 'NEW',
    Food = 'FOOD',
    Paper = 'PAPER',
    Standard = 'STANDARD',
    Scrap = 'SCRAP'
}

/**
 * 
 * @export
 * @interface PageAdrRidCodeDto
 */
export interface PageAdrRidCodeDto {
    /**
     * 
     * @type {number}
     * @memberof PageAdrRidCodeDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageAdrRidCodeDto
     */
    totalElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageAdrRidCodeDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageAdrRidCodeDto
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageAdrRidCodeDto
     */
    size?: number;
    /**
     * 
     * @type {Array<AdrRidCodeDto>}
     * @memberof PageAdrRidCodeDto
     */
    content?: Array<AdrRidCodeDto>;
    /**
     * 
     * @type {number}
     * @memberof PageAdrRidCodeDto
     */
    number?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageAdrRidCodeDto
     */
    sort?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageAdrRidCodeDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageAdrRidCodeDto
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageAdrRidCodeDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageAsparkingCardDto
 */
export interface PageAsparkingCardDto {
    /**
     * 
     * @type {number}
     * @memberof PageAsparkingCardDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageAsparkingCardDto
     */
    totalElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageAsparkingCardDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageAsparkingCardDto
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageAsparkingCardDto
     */
    size?: number;
    /**
     * 
     * @type {Array<AsparkingCardDto>}
     * @memberof PageAsparkingCardDto
     */
    content?: Array<AsparkingCardDto>;
    /**
     * 
     * @type {number}
     * @memberof PageAsparkingCardDto
     */
    number?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageAsparkingCardDto
     */
    sort?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageAsparkingCardDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageAsparkingCardDto
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageAsparkingCardDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageAsparkingLicensePlateDto
 */
export interface PageAsparkingLicensePlateDto {
    /**
     * 
     * @type {number}
     * @memberof PageAsparkingLicensePlateDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageAsparkingLicensePlateDto
     */
    totalElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageAsparkingLicensePlateDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageAsparkingLicensePlateDto
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageAsparkingLicensePlateDto
     */
    size?: number;
    /**
     * 
     * @type {Array<AsparkingLicensePlateDto>}
     * @memberof PageAsparkingLicensePlateDto
     */
    content?: Array<AsparkingLicensePlateDto>;
    /**
     * 
     * @type {number}
     * @memberof PageAsparkingLicensePlateDto
     */
    number?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageAsparkingLicensePlateDto
     */
    sort?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageAsparkingLicensePlateDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageAsparkingLicensePlateDto
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageAsparkingLicensePlateDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageAsparkingTicketDto
 */
export interface PageAsparkingTicketDto {
    /**
     * 
     * @type {number}
     * @memberof PageAsparkingTicketDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageAsparkingTicketDto
     */
    totalElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageAsparkingTicketDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageAsparkingTicketDto
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageAsparkingTicketDto
     */
    size?: number;
    /**
     * 
     * @type {Array<AsparkingTicketDto>}
     * @memberof PageAsparkingTicketDto
     */
    content?: Array<AsparkingTicketDto>;
    /**
     * 
     * @type {number}
     * @memberof PageAsparkingTicketDto
     */
    number?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageAsparkingTicketDto
     */
    sort?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageAsparkingTicketDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageAsparkingTicketDto
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageAsparkingTicketDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageAuditLogDto
 */
export interface PageAuditLogDto {
    /**
     * 
     * @type {number}
     * @memberof PageAuditLogDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageAuditLogDto
     */
    totalElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageAuditLogDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageAuditLogDto
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageAuditLogDto
     */
    size?: number;
    /**
     * 
     * @type {Array<AuditLogDto>}
     * @memberof PageAuditLogDto
     */
    content?: Array<AuditLogDto>;
    /**
     * 
     * @type {number}
     * @memberof PageAuditLogDto
     */
    number?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageAuditLogDto
     */
    sort?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageAuditLogDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageAuditLogDto
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageAuditLogDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageCarrierDto
 */
export interface PageCarrierDto {
    /**
     * 
     * @type {number}
     * @memberof PageCarrierDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageCarrierDto
     */
    totalElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageCarrierDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageCarrierDto
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageCarrierDto
     */
    size?: number;
    /**
     * 
     * @type {Array<CarrierDto>}
     * @memberof PageCarrierDto
     */
    content?: Array<CarrierDto>;
    /**
     * 
     * @type {number}
     * @memberof PageCarrierDto
     */
    number?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageCarrierDto
     */
    sort?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageCarrierDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageCarrierDto
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageCarrierDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageCdCargoTemplateDto
 */
export interface PageCdCargoTemplateDto {
    /**
     * 
     * @type {number}
     * @memberof PageCdCargoTemplateDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageCdCargoTemplateDto
     */
    totalElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageCdCargoTemplateDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageCdCargoTemplateDto
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageCdCargoTemplateDto
     */
    size?: number;
    /**
     * 
     * @type {Array<CdCargoTemplateDto>}
     * @memberof PageCdCargoTemplateDto
     */
    content?: Array<CdCargoTemplateDto>;
    /**
     * 
     * @type {number}
     * @memberof PageCdCargoTemplateDto
     */
    number?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageCdCargoTemplateDto
     */
    sort?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageCdCargoTemplateDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageCdCargoTemplateDto
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageCdCargoTemplateDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageCompanyDto
 */
export interface PageCompanyDto {
    /**
     * 
     * @type {number}
     * @memberof PageCompanyDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageCompanyDto
     */
    totalElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageCompanyDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageCompanyDto
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageCompanyDto
     */
    size?: number;
    /**
     * 
     * @type {Array<CompanyDto>}
     * @memberof PageCompanyDto
     */
    content?: Array<CompanyDto>;
    /**
     * 
     * @type {number}
     * @memberof PageCompanyDto
     */
    number?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageCompanyDto
     */
    sort?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageCompanyDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageCompanyDto
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageCompanyDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageContainerDto
 */
export interface PageContainerDto {
    /**
     * 
     * @type {number}
     * @memberof PageContainerDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageContainerDto
     */
    totalElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageContainerDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageContainerDto
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageContainerDto
     */
    size?: number;
    /**
     * 
     * @type {Array<ContainerDto>}
     * @memberof PageContainerDto
     */
    content?: Array<ContainerDto>;
    /**
     * 
     * @type {number}
     * @memberof PageContainerDto
     */
    number?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageContainerDto
     */
    sort?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageContainerDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageContainerDto
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageContainerDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageCoparnContainerDto
 */
export interface PageCoparnContainerDto {
    /**
     * 
     * @type {number}
     * @memberof PageCoparnContainerDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageCoparnContainerDto
     */
    totalElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageCoparnContainerDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageCoparnContainerDto
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageCoparnContainerDto
     */
    size?: number;
    /**
     * 
     * @type {Array<CoparnContainerDto>}
     * @memberof PageCoparnContainerDto
     */
    content?: Array<CoparnContainerDto>;
    /**
     * 
     * @type {number}
     * @memberof PageCoparnContainerDto
     */
    number?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageCoparnContainerDto
     */
    sort?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageCoparnContainerDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageCoparnContainerDto
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageCoparnContainerDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageCoparnDto
 */
export interface PageCoparnDto {
    /**
     * 
     * @type {number}
     * @memberof PageCoparnDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageCoparnDto
     */
    totalElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageCoparnDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageCoparnDto
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageCoparnDto
     */
    size?: number;
    /**
     * 
     * @type {Array<CoparnDto>}
     * @memberof PageCoparnDto
     */
    content?: Array<CoparnDto>;
    /**
     * 
     * @type {number}
     * @memberof PageCoparnDto
     */
    number?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageCoparnDto
     */
    sort?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageCoparnDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageCoparnDto
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageCoparnDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageCoparnTruckDto
 */
export interface PageCoparnTruckDto {
    /**
     * 
     * @type {number}
     * @memberof PageCoparnTruckDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageCoparnTruckDto
     */
    totalElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageCoparnTruckDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageCoparnTruckDto
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageCoparnTruckDto
     */
    size?: number;
    /**
     * 
     * @type {Array<CoparnTruckDto>}
     * @memberof PageCoparnTruckDto
     */
    content?: Array<CoparnTruckDto>;
    /**
     * 
     * @type {number}
     * @memberof PageCoparnTruckDto
     */
    number?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageCoparnTruckDto
     */
    sort?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageCoparnTruckDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageCoparnTruckDto
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageCoparnTruckDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageDemoDto
 */
export interface PageDemoDto {
    /**
     * 
     * @type {number}
     * @memberof PageDemoDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDemoDto
     */
    totalElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageDemoDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageDemoDto
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageDemoDto
     */
    size?: number;
    /**
     * 
     * @type {Array<DemoDto>}
     * @memberof PageDemoDto
     */
    content?: Array<DemoDto>;
    /**
     * 
     * @type {number}
     * @memberof PageDemoDto
     */
    number?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageDemoDto
     */
    sort?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageDemoDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageDemoDto
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageDemoDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageDriverCarrierDto
 */
export interface PageDriverCarrierDto {
    /**
     * 
     * @type {number}
     * @memberof PageDriverCarrierDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDriverCarrierDto
     */
    totalElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageDriverCarrierDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageDriverCarrierDto
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageDriverCarrierDto
     */
    size?: number;
    /**
     * 
     * @type {Array<DriverCarrierDto>}
     * @memberof PageDriverCarrierDto
     */
    content?: Array<DriverCarrierDto>;
    /**
     * 
     * @type {number}
     * @memberof PageDriverCarrierDto
     */
    number?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageDriverCarrierDto
     */
    sort?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageDriverCarrierDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageDriverCarrierDto
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageDriverCarrierDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageDriverDto
 */
export interface PageDriverDto {
    /**
     * 
     * @type {number}
     * @memberof PageDriverDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDriverDto
     */
    totalElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageDriverDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageDriverDto
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageDriverDto
     */
    size?: number;
    /**
     * 
     * @type {Array<DriverDto>}
     * @memberof PageDriverDto
     */
    content?: Array<DriverDto>;
    /**
     * 
     * @type {number}
     * @memberof PageDriverDto
     */
    number?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageDriverDto
     */
    sort?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageDriverDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageDriverDto
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageDriverDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageExtraDto
 */
export interface PageExtraDto {
    /**
     * 
     * @type {number}
     * @memberof PageExtraDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageExtraDto
     */
    totalElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageExtraDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageExtraDto
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageExtraDto
     */
    size?: number;
    /**
     * 
     * @type {Array<ExtraDto>}
     * @memberof PageExtraDto
     */
    content?: Array<ExtraDto>;
    /**
     * 
     * @type {number}
     * @memberof PageExtraDto
     */
    number?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageExtraDto
     */
    sort?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageExtraDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageExtraDto
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageExtraDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageM2mTrainDto
 */
export interface PageM2mTrainDto {
    /**
     * 
     * @type {number}
     * @memberof PageM2mTrainDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageM2mTrainDto
     */
    totalElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageM2mTrainDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageM2mTrainDto
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageM2mTrainDto
     */
    size?: number;
    /**
     * 
     * @type {Array<M2mTrainDto>}
     * @memberof PageM2mTrainDto
     */
    content?: Array<M2mTrainDto>;
    /**
     * 
     * @type {number}
     * @memberof PageM2mTrainDto
     */
    number?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageM2mTrainDto
     */
    sort?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageM2mTrainDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageM2mTrainDto
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageM2mTrainDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageNhmCodeDto
 */
export interface PageNhmCodeDto {
    /**
     * 
     * @type {number}
     * @memberof PageNhmCodeDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageNhmCodeDto
     */
    totalElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageNhmCodeDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageNhmCodeDto
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageNhmCodeDto
     */
    size?: number;
    /**
     * 
     * @type {Array<NhmCodeDto>}
     * @memberof PageNhmCodeDto
     */
    content?: Array<NhmCodeDto>;
    /**
     * 
     * @type {number}
     * @memberof PageNhmCodeDto
     */
    number?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageNhmCodeDto
     */
    sort?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageNhmCodeDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageNhmCodeDto
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageNhmCodeDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageNhmCommodityGroupDto
 */
export interface PageNhmCommodityGroupDto {
    /**
     * 
     * @type {number}
     * @memberof PageNhmCommodityGroupDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageNhmCommodityGroupDto
     */
    totalElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageNhmCommodityGroupDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageNhmCommodityGroupDto
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageNhmCommodityGroupDto
     */
    size?: number;
    /**
     * 
     * @type {Array<NhmCommodityGroupDto>}
     * @memberof PageNhmCommodityGroupDto
     */
    content?: Array<NhmCommodityGroupDto>;
    /**
     * 
     * @type {number}
     * @memberof PageNhmCommodityGroupDto
     */
    number?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageNhmCommodityGroupDto
     */
    sort?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageNhmCommodityGroupDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageNhmCommodityGroupDto
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageNhmCommodityGroupDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageNhmGroupDto
 */
export interface PageNhmGroupDto {
    /**
     * 
     * @type {number}
     * @memberof PageNhmGroupDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageNhmGroupDto
     */
    totalElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageNhmGroupDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageNhmGroupDto
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageNhmGroupDto
     */
    size?: number;
    /**
     * 
     * @type {Array<NhmGroupDto>}
     * @memberof PageNhmGroupDto
     */
    content?: Array<NhmGroupDto>;
    /**
     * 
     * @type {number}
     * @memberof PageNhmGroupDto
     */
    number?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageNhmGroupDto
     */
    sort?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageNhmGroupDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageNhmGroupDto
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageNhmGroupDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageNotificationDto
 */
export interface PageNotificationDto {
    /**
     * 
     * @type {number}
     * @memberof PageNotificationDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageNotificationDto
     */
    totalElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageNotificationDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageNotificationDto
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageNotificationDto
     */
    size?: number;
    /**
     * 
     * @type {Array<NotificationDto>}
     * @memberof PageNotificationDto
     */
    content?: Array<NotificationDto>;
    /**
     * 
     * @type {number}
     * @memberof PageNotificationDto
     */
    number?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageNotificationDto
     */
    sort?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageNotificationDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageNotificationDto
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageNotificationDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageOperatorTransitionDto
 */
export interface PageOperatorTransitionDto {
    /**
     * 
     * @type {number}
     * @memberof PageOperatorTransitionDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOperatorTransitionDto
     */
    totalElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageOperatorTransitionDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageOperatorTransitionDto
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageOperatorTransitionDto
     */
    size?: number;
    /**
     * 
     * @type {Array<OperatorTransitionDto>}
     * @memberof PageOperatorTransitionDto
     */
    content?: Array<OperatorTransitionDto>;
    /**
     * 
     * @type {number}
     * @memberof PageOperatorTransitionDto
     */
    number?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageOperatorTransitionDto
     */
    sort?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageOperatorTransitionDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageOperatorTransitionDto
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageOperatorTransitionDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PagePriceListDto
 */
export interface PagePriceListDto {
    /**
     * 
     * @type {number}
     * @memberof PagePriceListDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PagePriceListDto
     */
    totalElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PagePriceListDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PagePriceListDto
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PagePriceListDto
     */
    size?: number;
    /**
     * 
     * @type {Array<PriceListDto>}
     * @memberof PagePriceListDto
     */
    content?: Array<PriceListDto>;
    /**
     * 
     * @type {number}
     * @memberof PagePriceListDto
     */
    number?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PagePriceListDto
     */
    sort?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PagePriceListDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PagePriceListDto
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PagePriceListDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageProcessedFileDto
 */
export interface PageProcessedFileDto {
    /**
     * 
     * @type {number}
     * @memberof PageProcessedFileDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageProcessedFileDto
     */
    totalElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageProcessedFileDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageProcessedFileDto
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageProcessedFileDto
     */
    size?: number;
    /**
     * 
     * @type {Array<ProcessedFileDto>}
     * @memberof PageProcessedFileDto
     */
    content?: Array<ProcessedFileDto>;
    /**
     * 
     * @type {number}
     * @memberof PageProcessedFileDto
     */
    number?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageProcessedFileDto
     */
    sort?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageProcessedFileDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageProcessedFileDto
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageProcessedFileDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageShipownerDto
 */
export interface PageShipownerDto {
    /**
     * 
     * @type {number}
     * @memberof PageShipownerDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageShipownerDto
     */
    totalElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageShipownerDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageShipownerDto
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageShipownerDto
     */
    size?: number;
    /**
     * 
     * @type {Array<ShipownerDto>}
     * @memberof PageShipownerDto
     */
    content?: Array<ShipownerDto>;
    /**
     * 
     * @type {number}
     * @memberof PageShipownerDto
     */
    number?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageShipownerDto
     */
    sort?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageShipownerDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageShipownerDto
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageShipownerDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageShortTermPassageDto
 */
export interface PageShortTermPassageDto {
    /**
     * 
     * @type {number}
     * @memberof PageShortTermPassageDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageShortTermPassageDto
     */
    totalElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageShortTermPassageDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageShortTermPassageDto
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageShortTermPassageDto
     */
    size?: number;
    /**
     * 
     * @type {Array<ShortTermPassageDto>}
     * @memberof PageShortTermPassageDto
     */
    content?: Array<ShortTermPassageDto>;
    /**
     * 
     * @type {number}
     * @memberof PageShortTermPassageDto
     */
    number?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageShortTermPassageDto
     */
    sort?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageShortTermPassageDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageShortTermPassageDto
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageShortTermPassageDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageTrainDto
 */
export interface PageTrainDto {
    /**
     * 
     * @type {number}
     * @memberof PageTrainDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTrainDto
     */
    totalElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageTrainDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageTrainDto
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageTrainDto
     */
    size?: number;
    /**
     * 
     * @type {Array<TrainDto>}
     * @memberof PageTrainDto
     */
    content?: Array<TrainDto>;
    /**
     * 
     * @type {number}
     * @memberof PageTrainDto
     */
    number?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageTrainDto
     */
    sort?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageTrainDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageTrainDto
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageTrainDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageTrainExportConfigurationDto
 */
export interface PageTrainExportConfigurationDto {
    /**
     * 
     * @type {number}
     * @memberof PageTrainExportConfigurationDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTrainExportConfigurationDto
     */
    totalElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageTrainExportConfigurationDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageTrainExportConfigurationDto
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageTrainExportConfigurationDto
     */
    size?: number;
    /**
     * 
     * @type {Array<TrainExportConfigurationDto>}
     * @memberof PageTrainExportConfigurationDto
     */
    content?: Array<TrainExportConfigurationDto>;
    /**
     * 
     * @type {number}
     * @memberof PageTrainExportConfigurationDto
     */
    number?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageTrainExportConfigurationDto
     */
    sort?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageTrainExportConfigurationDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageTrainExportConfigurationDto
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageTrainExportConfigurationDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageTrainViewDto
 */
export interface PageTrainViewDto {
    /**
     * 
     * @type {number}
     * @memberof PageTrainViewDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTrainViewDto
     */
    totalElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageTrainViewDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageTrainViewDto
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageTrainViewDto
     */
    size?: number;
    /**
     * 
     * @type {Array<TrainViewDto>}
     * @memberof PageTrainViewDto
     */
    content?: Array<TrainViewDto>;
    /**
     * 
     * @type {number}
     * @memberof PageTrainViewDto
     */
    number?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageTrainViewDto
     */
    sort?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageTrainViewDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageTrainViewDto
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageTrainViewDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageTransitionDto
 */
export interface PageTransitionDto {
    /**
     * 
     * @type {number}
     * @memberof PageTransitionDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTransitionDto
     */
    totalElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageTransitionDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageTransitionDto
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageTransitionDto
     */
    size?: number;
    /**
     * 
     * @type {Array<TransitionDto>}
     * @memberof PageTransitionDto
     */
    content?: Array<TransitionDto>;
    /**
     * 
     * @type {number}
     * @memberof PageTransitionDto
     */
    number?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageTransitionDto
     */
    sort?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageTransitionDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageTransitionDto
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageTransitionDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageTransitionSearchListDto
 */
export interface PageTransitionSearchListDto {
    /**
     * 
     * @type {number}
     * @memberof PageTransitionSearchListDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTransitionSearchListDto
     */
    totalElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageTransitionSearchListDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageTransitionSearchListDto
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageTransitionSearchListDto
     */
    size?: number;
    /**
     * 
     * @type {Array<TransitionSearchListDto>}
     * @memberof PageTransitionSearchListDto
     */
    content?: Array<TransitionSearchListDto>;
    /**
     * 
     * @type {number}
     * @memberof PageTransitionSearchListDto
     */
    number?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageTransitionSearchListDto
     */
    sort?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageTransitionSearchListDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageTransitionSearchListDto
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageTransitionSearchListDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageTruckMonitoringLogDto
 */
export interface PageTruckMonitoringLogDto {
    /**
     * 
     * @type {number}
     * @memberof PageTruckMonitoringLogDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTruckMonitoringLogDto
     */
    totalElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageTruckMonitoringLogDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageTruckMonitoringLogDto
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageTruckMonitoringLogDto
     */
    size?: number;
    /**
     * 
     * @type {Array<TruckMonitoringLogDto>}
     * @memberof PageTruckMonitoringLogDto
     */
    content?: Array<TruckMonitoringLogDto>;
    /**
     * 
     * @type {number}
     * @memberof PageTruckMonitoringLogDto
     */
    number?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageTruckMonitoringLogDto
     */
    sort?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageTruckMonitoringLogDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageTruckMonitoringLogDto
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageTruckMonitoringLogDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageUserDto
 */
export interface PageUserDto {
    /**
     * 
     * @type {number}
     * @memberof PageUserDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageUserDto
     */
    totalElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageUserDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageUserDto
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageUserDto
     */
    size?: number;
    /**
     * 
     * @type {Array<UserDto>}
     * @memberof PageUserDto
     */
    content?: Array<UserDto>;
    /**
     * 
     * @type {number}
     * @memberof PageUserDto
     */
    number?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageUserDto
     */
    sort?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageUserDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageUserDto
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageUserDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageableObject
 */
export interface PageableObject {
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    offset?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageableObject
     */
    sort?: SortObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    paged?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    unpaged?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    pageSize?: number;
}
/**
 * Price list item object to updated
 * @export
 * @interface PriceListDto
 */
export interface PriceListDto {
    /**
     * Id of object
     * @type {number}
     * @memberof PriceListDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PriceListDto
     */
    itemCode?: PriceListDtoItemCodeEnum;
    /**
     * 
     * @type {number}
     * @memberof PriceListDto
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof PriceListDto
     */
    minAmount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PriceListDto
     */
    defaultValue?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum PriceListDtoItemCodeEnum {
    BrnoShippingContainerManipulation = 'BRNO_SHIPPING_CONTAINER_MANIPULATION',
    BrnoShippingContainerStoreSafe = 'BRNO_SHIPPING_CONTAINER_STORE_SAFE',
    BrnoShippingContainerStoreAdrRid = 'BRNO_SHIPPING_CONTAINER_STORE_ADR_RID',
    BrnoSemitrailerStoreTerminal = 'BRNO_SEMITRAILER_STORE_TERMINAL',
    BrnoSemitrailerStoreNotByTrain = 'BRNO_SEMITRAILER_STORE_NOT_BY_TRAIN',
    BrnoSemitrailerManipulation = 'BRNO_SEMITRAILER_MANIPULATION',
    MosnovShippingContainerManipulation = 'MOSNOV_SHIPPING_CONTAINER_MANIPULATION',
    MosnovSemitrailerManipulation = 'MOSNOV_SEMITRAILER_MANIPULATION',
    MosnovShippingContainerEmptyStore = 'MOSNOV_SHIPPING_CONTAINER_EMPTY_STORE',
    MosnovShippingContainerFullStore = 'MOSNOV_SHIPPING_CONTAINER_FULL_STORE',
    MosnovSemitrailerStore = 'MOSNOV_SEMITRAILER_STORE'
}

/**
 * Attached file
 * @export
 * @interface ProcessedFileDto
 */
export interface ProcessedFileDto {
    /**
     * Id of object
     * @type {number}
     * @memberof ProcessedFileDto
     */
    id?: number;
    /**
     * Name of the file
     * @type {string}
     * @memberof ProcessedFileDto
     */
    fileName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessedFileDto
     */
    received?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessedFileDto
     */
    processed?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessedFileDto
     */
    contentId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessedFileDto
     */
    processedFileType?: ProcessedFileDtoProcessedFileTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ProcessedFileDto
     */
    fileStatus?: ProcessedFileDtoFileStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ProcessedFileDtoProcessedFileTypeEnum {
    Coparn = 'COPARN',
    Coedor = 'COEDOR',
    Codeco = 'CODECO',
    CustomsXml = 'CUSTOMS_XML',
    CustomsPdf = 'CUSTOMS_PDF',
    CimFile = 'CIM_FILE',
    MedlogBoard = 'MEDLOG_BOARD',
    Verona = 'VERONA',
    Vgm = 'VGM'
}
/**
    * @export
    * @enum {string}
    */
export enum ProcessedFileDtoFileStatusEnum {
    Imported = 'IMPORTED',
    Exported = 'EXPORTED'
}

/**
 * 
 * @export
 * @interface ProcessedFileSearchDto
 */
export interface ProcessedFileSearchDto {
    /**
     * 
     * @type {string}
     * @memberof ProcessedFileSearchDto
     */
    fileName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessedFileSearchDto
     */
    processedAtFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessedFileSearchDto
     */
    processedAtTo?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessedFileSearchDto
     */
    processedFileType?: ProcessedFileSearchDtoProcessedFileTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ProcessedFileSearchDtoProcessedFileTypeEnum {
    Coparn = 'COPARN',
    Coedor = 'COEDOR',
    Codeco = 'CODECO',
    CustomsXml = 'CUSTOMS_XML',
    CustomsPdf = 'CUSTOMS_PDF',
    CimFile = 'CIM_FILE',
    MedlogBoard = 'MEDLOG_BOARD',
    Verona = 'VERONA',
    Vgm = 'VGM'
}

/**
 * Repair shop stays
 * @export
 * @interface RepairShopStayDto
 */
export interface RepairShopStayDto {
    /**
     * Id of object
     * @type {number}
     * @memberof RepairShopStayDto
     */
    id?: number;
    /**
     * Repair start date
     * @type {string}
     * @memberof RepairShopStayDto
     */
    start?: string;
    /**
     * Repair end date
     * @type {string}
     * @memberof RepairShopStayDto
     */
    end?: string;
}
/**
 * 
 * @export
 * @interface SectorSelectDto
 */
export interface SectorSelectDto {
    /**
     * Letter of selected sector
     * @type {string}
     * @memberof SectorSelectDto
     */
    sectorLetter?: string;
}
/**
 * shipowner object to create
 * @export
 * @interface ShipownerDto
 */
export interface ShipownerDto {
    /**
     * Id of object
     * @type {number}
     * @memberof ShipownerDto
     */
    id?: number;
    /**
     * name of shipowner
     * @type {string}
     * @memberof ShipownerDto
     */
    name: string;
    /**
     * Id of company
     * @type {number}
     * @memberof ShipownerDto
     */
    companyId?: number;
    /**
     * Name of company
     * @type {string}
     * @memberof ShipownerDto
     */
    companyName?: string;
    /**
     * Color of the company
     * @type {string}
     * @memberof ShipownerDto
     */
    companyColor?: string;
    /**
     * email address of shipowner
     * @type {string}
     * @memberof ShipownerDto
     */
    emailAddress?: string;
}
/**
 * 
 * @export
 * @interface ShipownerSearchDto
 */
export interface ShipownerSearchDto {
    /**
     * 
     * @type {number}
     * @memberof ShipownerSearchDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ShipownerSearchDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof ShipownerSearchDto
     */
    companyId?: number;
}
/**
 * short term passage object to create
 * @export
 * @interface ShortTermPassageDto
 */
export interface ShortTermPassageDto {
    /**
     * Id of object
     * @type {number}
     * @memberof ShortTermPassageDto
     */
    id?: number;
    /**
     * First name of driver
     * @type {string}
     * @memberof ShortTermPassageDto
     */
    firstName?: string;
    /**
     * Surname of driver
     * @type {string}
     * @memberof ShortTermPassageDto
     */
    surname?: string;
    /**
     * Truck license plate
     * @type {string}
     * @memberof ShortTermPassageDto
     */
    licensePlate?: string;
    /**
     * Permission for checkpoint one
     * @type {boolean}
     * @memberof ShortTermPassageDto
     */
    checkpointOneAllowed?: boolean;
    /**
     * Permission for checkpoint two
     * @type {boolean}
     * @memberof ShortTermPassageDto
     */
    checkpointTwoAllowed?: boolean;
    /**
     * Permission for checkpoint three
     * @type {boolean}
     * @memberof ShortTermPassageDto
     */
    checkpointThreeAllowed?: boolean;
    /**
     * Start validity date
     * @type {string}
     * @memberof ShortTermPassageDto
     */
    validFrom?: string;
    /**
     * End validity date
     * @type {string}
     * @memberof ShortTermPassageDto
     */
    validTo?: string;
    /**
     * Whether short term passage is valid.
     * @type {boolean}
     * @memberof ShortTermPassageDto
     */
    valid?: boolean;
    /**
     * Company
     * @type {string}
     * @memberof ShortTermPassageDto
     */
    company?: string;
}
/**
 * 
 * @export
 * @interface ShortTermPassageSearchDto
 */
export interface ShortTermPassageSearchDto {
    /**
     * 
     * @type {string}
     * @memberof ShortTermPassageSearchDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortTermPassageSearchDto
     */
    surname?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortTermPassageSearchDto
     */
    licensePlate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ShortTermPassageSearchDto
     */
    checkpointOneAllowed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShortTermPassageSearchDto
     */
    checkpointTwoAllowed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShortTermPassageSearchDto
     */
    checkpointThreeAllowed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShortTermPassageSearchDto
     */
    validFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortTermPassageSearchDto
     */
    validTo?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortTermPassageSearchDto
     */
    company?: string;
}
/**
 * 
 * @export
 * @interface SortObject
 */
export interface SortObject {
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    sorted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    unsorted?: boolean;
}
/**
 * 
 * @export
 * @interface StackContainerDto
 */
export interface StackContainerDto {
    /**
     * Id of object
     * @type {number}
     * @memberof StackContainerDto
     */
    id?: number;
    /**
     * Unique ILU code of container
     * @type {string}
     * @memberof StackContainerDto
     */
    iluCode: string;
    /**
     * If container is repleted
     * @type {boolean}
     * @memberof StackContainerDto
     */
    repleted: boolean;
    /**
     * TEU of the container
     * @type {number}
     * @memberof StackContainerDto
     */
    teu?: number;
    /**
     * Status of manipulation request
     * @type {string}
     * @memberof StackContainerDto
     */
    manipulationRequestStatus?: StackContainerDtoManipulationRequestStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof StackContainerDto
     */
    reserved?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum StackContainerDtoManipulationRequestStatusEnum {
    Pending = 'PENDING',
    Finished = 'FINISHED',
    Canceled = 'CANCELED'
}

/**
 * 
 * @export
 * @interface StackContainerSearchDto
 */
export interface StackContainerSearchDto {
    /**
     * Sector letter where floor is placed
     * @type {string}
     * @memberof StackContainerSearchDto
     */
    sectorLetter?: string;
    /**
     * Column where floor is placed
     * @type {number}
     * @memberof StackContainerSearchDto
     */
    sectorColumn?: number;
    /**
     * Row where floor is placed
     * @type {number}
     * @memberof StackContainerSearchDto
     */
    sectorRow?: number;
}
/**
 * Columns in this row
 * @export
 * @interface TerminalCellLayoutDto
 */
export interface TerminalCellLayoutDto {
    /**
     * Column number of this cell
     * @type {number}
     * @memberof TerminalCellLayoutDto
     */
    columnNumber: number;
    /**
     * How many containers are occupied in this cell
     * @type {number}
     * @memberof TerminalCellLayoutDto
     */
    containerCount: number;
    /**
     * Size of container
     * @type {number}
     * @memberof TerminalCellLayoutDto
     */
    teu: number;
}
/**
 * Rows in this sector
 * @export
 * @interface TerminalRowLayoutDto
 */
export interface TerminalRowLayoutDto {
    /**
     * Number of this row
     * @type {number}
     * @memberof TerminalRowLayoutDto
     */
    rowNumber: number;
    /**
     * Columns in this row
     * @type {Array<TerminalCellLayoutDto>}
     * @memberof TerminalRowLayoutDto
     */
    columns: Array<TerminalCellLayoutDto>;
}
/**
 * 
 * @export
 * @interface TerminalSectorLayoutDto
 */
export interface TerminalSectorLayoutDto {
    /**
     * Id of object
     * @type {number}
     * @memberof TerminalSectorLayoutDto
     */
    id?: number;
    /**
     * Unique name of the sector
     * @type {string}
     * @memberof TerminalSectorLayoutDto
     */
    name: string;
    /**
     * Number of columns
     * @type {number}
     * @memberof TerminalSectorLayoutDto
     */
    columnCount?: number;
    /**
     * Number of rows
     * @type {number}
     * @memberof TerminalSectorLayoutDto
     */
    rowCount?: number;
    /**
     * Number of floors
     * @type {number}
     * @memberof TerminalSectorLayoutDto
     */
    floorCount?: number;
    /**
     * Maximum container capacity without precise coordinates
     * @type {number}
     * @memberof TerminalSectorLayoutDto
     */
    unorderedCapacity?: number;
    /**
     * X position on the terminal layout
     * @type {number}
     * @memberof TerminalSectorLayoutDto
     */
    positionX?: number;
    /**
     * Y position on the terminal layout
     * @type {number}
     * @memberof TerminalSectorLayoutDto
     */
    positionY?: number;
    /**
     * Angle of the sector on terminal layout
     * @type {number}
     * @memberof TerminalSectorLayoutDto
     */
    angle?: number;
    /**
     * Order type of sector
     * @type {string}
     * @memberof TerminalSectorLayoutDto
     */
    sectorOrder?: TerminalSectorLayoutDtoSectorOrderEnum;
    /**
     * Rows in this sector
     * @type {Array<TerminalRowLayoutDto>}
     * @memberof TerminalSectorLayoutDto
     */
    rows?: Array<TerminalRowLayoutDto>;
    /**
     * Number of unseated containers
     * @type {number}
     * @memberof TerminalSectorLayoutDto
     */
    unorderedCount?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum TerminalSectorLayoutDtoSectorOrderEnum {
    Ordered = 'ORDERED',
    Unordered = 'UNORDERED'
}

/**
 * 
 * @export
 * @interface TrainCompareResultDto
 */
export interface TrainCompareResultDto {
    /**
     * 
     * @type {Array<CompareDto>}
     * @memberof TrainCompareResultDto
     */
    wagonCompareDtoList?: Array<CompareDto>;
    /**
     * 
     * @type {Array<CompareDto>}
     * @memberof TrainCompareResultDto
     */
    containerCompareDtoList?: Array<CompareDto>;
}
/**
 * 
 * @export
 * @interface TrainDto
 */
export interface TrainDto {
    /**
     * Id of object
     * @type {number}
     * @memberof TrainDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TrainDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TrainDto
     */
    shipownerNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof TrainDto
     */
    trainState?: TrainDtoTrainStateEnum;
    /**
     * 
     * @type {string}
     * @memberof TrainDto
     */
    trainType?: TrainDtoTrainTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof TrainDto
     */
    customsClearance?: boolean;
    /**
     * 
     * @type {Array<TrainWagonDto>}
     * @memberof TrainDto
     */
    wagons?: Array<TrainWagonDto>;
    /**
     * 
     * @type {Array<TransitionSimpleDto>}
     * @memberof TrainDto
     */
    unseatedTransitions?: Array<TransitionSimpleDto>;
    /**
     * 
     * @type {string}
     * @memberof TrainDto
     */
    departureDateTime?: string;
    /**
     * 
     * @type {string}
     * @memberof TrainDto
     */
    unloadingDateTime?: string;
    /**
     * 
     * @type {string}
     * @memberof TrainDto
     */
    plannedDateTime?: string;
    /**
     * 
     * @type {number}
     * @memberof TrainDto
     */
    departureDelayMinutes?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum TrainDtoTrainStateEnum {
    IncomingPlanned = 'INCOMING_PLANNED',
    IncomingArrivalCustoms = 'INCOMING_ARRIVAL_CUSTOMS',
    IncomingAccepted = 'INCOMING_ACCEPTED',
    IncomingFinished = 'INCOMING_FINISHED',
    OutgoingPlanned = 'OUTGOING_PLANNED',
    OutgoingPrepared = 'OUTGOING_PREPARED',
    OutgoingLoaded = 'OUTGOING_LOADED',
    OutgoingDispatched = 'OUTGOING_DISPATCHED'
}
/**
    * @export
    * @enum {string}
    */
export enum TrainDtoTrainTypeEnum {
    Complete = 'COMPLETE',
    Partial = 'PARTIAL'
}

/**
 * configuration object to create
 * @export
 * @interface TrainExportConfigurationDto
 */
export interface TrainExportConfigurationDto {
    /**
     * Id of object
     * @type {number}
     * @memberof TrainExportConfigurationDto
     */
    id?: number;
    /**
     * Name of configuration
     * @type {string}
     * @memberof TrainExportConfigurationDto
     */
    configurationName: string;
    /**
     * Sender of CIM
     * @type {string}
     * @memberof TrainExportConfigurationDto
     */
    sender?: string;
    /**
     * Customer code of sender
     * @type {string}
     * @memberof TrainExportConfigurationDto
     */
    senderCustomerCode?: string;
    /**
     * Code of sender
     * @type {string}
     * @memberof TrainExportConfigurationDto
     */
    senderCode?: string;
    /**
     * Recipient of CIM
     * @type {string}
     * @memberof TrainExportConfigurationDto
     */
    recipient?: string;
    /**
     * Customer code of recipient
     * @type {string}
     * @memberof TrainExportConfigurationDto
     */
    recipientCustomerCode?: string;
    /**
     * Code of recipient
     * @type {string}
     * @memberof TrainExportConfigurationDto
     */
    recipientCode?: string;
    /**
     * Sender\'s statement
     * @type {string}
     * @memberof TrainExportConfigurationDto
     */
    senderStatement?: string;
    /**
     * Code of recipient
     * @type {string}
     * @memberof TrainExportConfigurationDto
     */
    recipientReference?: string;
    /**
     * Attachments
     * @type {string}
     * @memberof TrainExportConfigurationDto
     */
    attachments?: string;
    /**
     * Delivery place station
     * @type {string}
     * @memberof TrainExportConfigurationDto
     */
    deliveryPlaceStation?: string;
    /**
     * Delivery place country
     * @type {string}
     * @memberof TrainExportConfigurationDto
     */
    deliveryPlaceCountry?: string;
    /**
     * Delivery place code
     * @type {string}
     * @memberof TrainExportConfigurationDto
     */
    deliveryPlaceCode?: string;
    /**
     * Station code
     * @type {string}
     * @memberof TrainExportConfigurationDto
     */
    stationCode?: string;
    /**
     * Terms and conditions
     * @type {string}
     * @memberof TrainExportConfigurationDto
     */
    termsAndConditions?: string;
    /**
     * Customer agreement
     * @type {string}
     * @memberof TrainExportConfigurationDto
     */
    customerAgreement?: string;
    /**
     * Records
     * @type {string}
     * @memberof TrainExportConfigurationDto
     */
    records?: string;
    /**
     * Take-over place
     * @type {string}
     * @memberof TrainExportConfigurationDto
     */
    takeoverPlace?: string;
    /**
     * Take-over place code
     * @type {string}
     * @memberof TrainExportConfigurationDto
     */
    takeoverPlaceCode?: string;
    /**
     * Extraordinary shipment
     * @type {boolean}
     * @memberof TrainExportConfigurationDto
     */
    extraordinaryShipment?: boolean;
    /**
     * NHM code
     * @type {string}
     * @memberof TrainExportConfigurationDto
     */
    nhmCode?: string;
    /**
     * Weight
     * @type {string}
     * @memberof TrainExportConfigurationDto
     */
    weight?: string;
    /**
     * Place of issuance
     * @type {string}
     * @memberof TrainExportConfigurationDto
     */
    placeOfIssuance?: string;
    /**
     * Wagon report - sender name
     * @type {string}
     * @memberof TrainExportConfigurationDto
     */
    wrSenderName?: string;
    /**
     * Wagon report - sender street
     * @type {string}
     * @memberof TrainExportConfigurationDto
     */
    wrSenderStreet?: string;
    /**
     * Wagon report - sender town
     * @type {string}
     * @memberof TrainExportConfigurationDto
     */
    wrSenderTown?: string;
    /**
     * Wagon report - post number
     * @type {string}
     * @memberof TrainExportConfigurationDto
     */
    wrPostNumber?: string;
    /**
     * Wagon report - sender DIC
     * @type {string}
     * @memberof TrainExportConfigurationDto
     */
    wrSenderDic?: string;
    /**
     * Wagon report - sender telephone
     * @type {string}
     * @memberof TrainExportConfigurationDto
     */
    wrSenderTel?: string;
    /**
     * Wagon report - sender fax
     * @type {string}
     * @memberof TrainExportConfigurationDto
     */
    wrSenderFax?: string;
    /**
     * Wagon report - country
     * @type {string}
     * @memberof TrainExportConfigurationDto
     */
    wrCountry?: string;
    /**
     * Wagon report - station
     * @type {string}
     * @memberof TrainExportConfigurationDto
     */
    wrStation?: string;
    /**
     * Wagon report - company
     * @type {string}
     * @memberof TrainExportConfigurationDto
     */
    wrCompany?: string;
    /**
     * Wagon report - serial number
     * @type {string}
     * @memberof TrainExportConfigurationDto
     */
    wrSerialNumber?: string;
    /**
     * Wagon report - contract
     * @type {string}
     * @memberof TrainExportConfigurationDto
     */
    wrContract?: string;
    /**
     * Wagon report - depot
     * @type {string}
     * @memberof TrainExportConfigurationDto
     */
    wrDepot?: string;
    /**
     * Wagon report - destination
     * @type {string}
     * @memberof TrainExportConfigurationDto
     */
    wrDestination?: string;
    /**
     * Wagon report - route
     * @type {string}
     * @memberof TrainExportConfigurationDto
     */
    wrRoute?: string;
    /**
     * Wagon report - receiver
     * @type {string}
     * @memberof TrainExportConfigurationDto
     */
    wrReceiver?: string;
    /**
     * Wagon report - payer
     * @type {string}
     * @memberof TrainExportConfigurationDto
     */
    wrPayer?: string;
}
/**
 * The train the GI transition came on
 * @export
 * @interface TrainNameDto
 */
export interface TrainNameDto {
    /**
     * Id of object
     * @type {number}
     * @memberof TrainNameDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TrainNameDto
     */
    name: string;
}
/**
 * 
 * @export
 * @interface TrainSearchDto
 */
export interface TrainSearchDto {
    /**
     * 
     * @type {string}
     * @memberof TrainSearchDto
     */
    direction?: TrainSearchDtoDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof TrainSearchDto
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TrainSearchDto
     */
    requiredTrainStates?: Array<TrainSearchDtoRequiredTrainStatesEnum>;
}

/**
    * @export
    * @enum {string}
    */
export enum TrainSearchDtoDirectionEnum {
    In = 'IN',
    Out = 'OUT',
    Extern = 'EXTERN'
}
/**
    * @export
    * @enum {string}
    */
export enum TrainSearchDtoRequiredTrainStatesEnum {
    IncomingPlanned = 'INCOMING_PLANNED',
    IncomingArrivalCustoms = 'INCOMING_ARRIVAL_CUSTOMS',
    IncomingAccepted = 'INCOMING_ACCEPTED',
    IncomingFinished = 'INCOMING_FINISHED',
    OutgoingPlanned = 'OUTGOING_PLANNED',
    OutgoingPrepared = 'OUTGOING_PREPARED',
    OutgoingLoaded = 'OUTGOING_LOADED',
    OutgoingDispatched = 'OUTGOING_DISPATCHED'
}

/**
 * 
 * @export
 * @interface TrainViewDto
 */
export interface TrainViewDto {
    /**
     * Id of object
     * @type {number}
     * @memberof TrainViewDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TrainViewDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof TrainViewDto
     */
    trainState?: TrainViewDtoTrainStateEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof TrainViewDto
     */
    companies?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof TrainViewDto
     */
    departureDateTime?: string;
    /**
     * 
     * @type {string}
     * @memberof TrainViewDto
     */
    unloadingDateTime?: string;
    /**
     * 
     * @type {string}
     * @memberof TrainViewDto
     */
    plannedDateTime?: string;
    /**
     * 
     * @type {number}
     * @memberof TrainViewDto
     */
    departureDelayMinutes?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum TrainViewDtoTrainStateEnum {
    IncomingPlanned = 'INCOMING_PLANNED',
    IncomingArrivalCustoms = 'INCOMING_ARRIVAL_CUSTOMS',
    IncomingAccepted = 'INCOMING_ACCEPTED',
    IncomingFinished = 'INCOMING_FINISHED',
    OutgoingPlanned = 'OUTGOING_PLANNED',
    OutgoingPrepared = 'OUTGOING_PREPARED',
    OutgoingLoaded = 'OUTGOING_LOADED',
    OutgoingDispatched = 'OUTGOING_DISPATCHED'
}

/**
 * 
 * @export
 * @interface TrainWagonContentDto
 */
export interface TrainWagonContentDto {
    /**
     * Id of object
     * @type {number}
     * @memberof TrainWagonContentDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof TrainWagonContentDto
     */
    wagonContentOrder: number;
    /**
     * 
     * @type {TransitionSimpleDto}
     * @memberof TrainWagonContentDto
     */
    transition: TransitionSimpleDto;
}
/**
 * 
 * @export
 * @interface TrainWagonDto
 */
export interface TrainWagonDto {
    /**
     * Id of object
     * @type {number}
     * @memberof TrainWagonDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TrainWagonDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof TrainWagonDto
     */
    wagonOrder: number;
    /**
     * 
     * @type {number}
     * @memberof TrainWagonDto
     */
    weight?: number;
    /**
     * 
     * @type {number}
     * @memberof TrainWagonDto
     */
    wagonCapacity?: number;
    /**
     * 
     * @type {number}
     * @memberof TrainWagonDto
     */
    wagonContainerSum?: number;
    /**
     * 
     * @type {number}
     * @memberof TrainWagonDto
     */
    wagonLength?: number;
    /**
     * 
     * @type {number}
     * @memberof TrainWagonDto
     */
    axleCount?: number;
    /**
     * 
     * @type {Array<TrainWagonContentDto>}
     * @memberof TrainWagonDto
     */
    content?: Array<TrainWagonContentDto>;
}
/**
 * Additional info for transition
 * @export
 * @interface TransitionAdditionalInfoDto
 */
export interface TransitionAdditionalInfoDto {
    /**
     * Id of object
     * @type {number}
     * @memberof TransitionAdditionalInfoDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TransitionAdditionalInfoDto
     */
    fileHeaderNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof TransitionAdditionalInfoDto
     */
    medlogReference?: string;
    /**
     * 
     * @type {string}
     * @memberof TransitionAdditionalInfoDto
     */
    haulageInformation?: string;
    /**
     * 
     * @type {string}
     * @memberof TransitionAdditionalInfoDto
     */
    portOfDischarge?: string;
    /**
     * 
     * @type {string}
     * @memberof TransitionAdditionalInfoDto
     */
    namedAccount?: string;
    /**
     * 
     * @type {string}
     * @memberof TransitionAdditionalInfoDto
     */
    unLocation?: string;
    /**
     * 
     * @type {string}
     * @memberof TransitionAdditionalInfoDto
     */
    coparnDepot?: string;
    /**
     * 
     * @type {string}
     * @memberof TransitionAdditionalInfoDto
     */
    depotName?: string;
    /**
     * 
     * @type {string}
     * @memberof TransitionAdditionalInfoDto
     */
    transitionDateTime?: string;
    /**
     * 
     * @type {string}
     * @memberof TransitionAdditionalInfoDto
     */
    transitionExpiryDateTime?: string;
    /**
     * 
     * @type {string}
     * @memberof TransitionAdditionalInfoDto
     */
    otherServiceInformation?: string;
}
/**
 * Customs info for transition
 * @export
 * @interface TransitionCustomsInfoDto
 */
export interface TransitionCustomsInfoDto {
    /**
     * Id of object
     * @type {number}
     * @memberof TransitionCustomsInfoDto
     */
    id?: number;
    /**
     * 
     * @type {TrainNameDto}
     * @memberof TransitionCustomsInfoDto
     */
    gateInTrain?: TrainNameDto;
    /**
     * Customs state of the transition
     * @type {string}
     * @memberof TransitionCustomsInfoDto
     */
    state?: TransitionCustomsInfoDtoStateEnum;
    /**
     * Date of a control
     * @type {string}
     * @memberof TransitionCustomsInfoDto
     */
    dateOfControl?: string;
    /**
     * Note
     * @type {string}
     * @memberof TransitionCustomsInfoDto
     */
    note?: string;
    /**
     * 
     * @type {ProcessedFileDto}
     * @memberof TransitionCustomsInfoDto
     */
    processedFile?: ProcessedFileDto;
    /**
     * 
     * @type {UserNameDto}
     * @memberof TransitionCustomsInfoDto
     */
    updatedBy?: UserNameDto;
    /**
     * When was this customs info last updated
     * @type {string}
     * @memberof TransitionCustomsInfoDto
     */
    updatedAt?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum TransitionCustomsInfoDtoStateEnum {
    T1ByUser = 'T1_BY_USER',
    T1ByEmail = 'T1_BY_EMAIL',
    SadExternal = 'SAD_EXTERNAL',
    SadPortService = 'SAD_PORT_SERVICE',
    Stop = 'STOP'
}

/**
 * 
 * @export
 * @interface TransitionCustomsInfoSearchDto
 */
export interface TransitionCustomsInfoSearchDto {
    /**
     * 
     * @type {string}
     * @memberof TransitionCustomsInfoSearchDto
     */
    gateInTrainName?: string;
    /**
     * 
     * @type {string}
     * @memberof TransitionCustomsInfoSearchDto
     */
    state?: TransitionCustomsInfoSearchDtoStateEnum;
    /**
     * 
     * @type {string}
     * @memberof TransitionCustomsInfoSearchDto
     */
    dateOfControl?: string;
    /**
     * 
     * @type {string}
     * @memberof TransitionCustomsInfoSearchDto
     */
    dateOfControlFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof TransitionCustomsInfoSearchDto
     */
    dateOfControlTo?: string;
    /**
     * 
     * @type {string}
     * @memberof TransitionCustomsInfoSearchDto
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof TransitionCustomsInfoSearchDto
     */
    documentUrl?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum TransitionCustomsInfoSearchDtoStateEnum {
    T1ByUser = 'T1_BY_USER',
    T1ByEmail = 'T1_BY_EMAIL',
    SadExternal = 'SAD_EXTERNAL',
    SadPortService = 'SAD_PORT_SERVICE',
    Stop = 'STOP'
}

/**
 * 
 * @export
 * @interface TransitionCustomsInfoSearchListDto
 */
export interface TransitionCustomsInfoSearchListDto {
    /**
     * Id of object
     * @type {number}
     * @memberof TransitionCustomsInfoSearchListDto
     */
    id?: number;
    /**
     * Customs state of the transition
     * @type {string}
     * @memberof TransitionCustomsInfoSearchListDto
     */
    state?: TransitionCustomsInfoSearchListDtoStateEnum;
    /**
     * 
     * @type {ProcessedFileDto}
     * @memberof TransitionCustomsInfoSearchListDto
     */
    processedFile?: ProcessedFileDto;
}

/**
    * @export
    * @enum {string}
    */
export enum TransitionCustomsInfoSearchListDtoStateEnum {
    T1ByUser = 'T1_BY_USER',
    T1ByEmail = 'T1_BY_EMAIL',
    SadExternal = 'SAD_EXTERNAL',
    SadPortService = 'SAD_PORT_SERVICE',
    Stop = 'STOP'
}

/**
 * 
 * @export
 * @interface TransitionDto
 */
export interface TransitionDto {
    /**
     * Id of object
     * @type {number}
     * @memberof TransitionDto
     */
    id?: number;
    /**
     * 
     * @type {ContainerDto}
     * @memberof TransitionDto
     */
    container: ContainerDto;
    /**
     * Transition datetime
     * @type {string}
     * @memberof TransitionDto
     */
    transitionDateTime?: string;
    /**
     * If container has some damage
     * @type {boolean}
     * @memberof TransitionDto
     */
    damaged?: boolean;
    /**
     * Quality of container
     * @type {string}
     * @memberof TransitionDto
     */
    qualityType?: TransitionDtoQualityTypeEnum;
    /**
     * If container has signet
     * @type {boolean}
     * @memberof TransitionDto
     */
    hasSignet?: boolean;
    /**
     * Value of signet
     * @type {string}
     * @memberof TransitionDto
     */
    signetValue?: string;
    /**
     * Receipt method of container
     * @type {string}
     * @memberof TransitionDto
     */
    receiptMethod?: TransitionDtoReceiptMethodEnum;
    /**
     * Reference of container
     * @type {string}
     * @memberof TransitionDto
     */
    bookingReference?: string;
    /**
     * If container has ADR/RID
     * @type {boolean}
     * @memberof TransitionDto
     */
    adrRid?: boolean;
    /**
     * If container has agency
     * @type {boolean}
     * @memberof TransitionDto
     */
    agency?: boolean;
    /**
     * If container is repleted
     * @type {boolean}
     * @memberof TransitionDto
     */
    repleted?: boolean;
    /**
     * Direction of receipt
     * @type {string}
     * @memberof TransitionDto
     */
    direction?: TransitionDtoDirectionEnum;
    /**
     * If container is weighted
     * @type {boolean}
     * @memberof TransitionDto
     */
    weighted?: boolean;
    /**
     * Container net weight
     * @type {number}
     * @memberof TransitionDto
     */
    netWeight?: number;
    /**
     * Which goods are in container
     * @type {string}
     * @memberof TransitionDto
     */
    goods?: string;
    /**
     * Note
     * @type {string}
     * @memberof TransitionDto
     */
    note?: string;
    /**
     * If container is for import/export
     * @type {string}
     * @memberof TransitionDto
     */
    importExport?: TransitionDtoImportExportEnum;
    /**
     * State of container
     * @type {string}
     * @memberof TransitionDto
     */
    containerState?: TransitionDtoContainerStateEnum;
    /**
     * Sector letter where container is stored
     * @type {string}
     * @memberof TransitionDto
     */
    sectorLetter?: string;
    /**
     * Column where container is stored
     * @type {number}
     * @memberof TransitionDto
     */
    sectorColumn?: number;
    /**
     * Row where container is stored
     * @type {number}
     * @memberof TransitionDto
     */
    sectorRow?: number;
    /**
     * Floor where container is stored
     * @type {number}
     * @memberof TransitionDto
     */
    sectorFloor?: number;
    /**
     * Damage log entries
     * @type {Array<DamageLogEntryDto>}
     * @memberof TransitionDto
     */
    damageLogEntries?: Array<DamageLogEntryDto>;
    /**
     * Number of manipulations
     * @type {number}
     * @memberof TransitionDto
     */
    manipulation?: number;
    /**
     * If container is loaded on train
     * @type {boolean}
     * @memberof TransitionDto
     */
    loadedOnTrain?: boolean;
    /**
     * Gate IN id for Gate OUT transition
     * @type {number}
     * @memberof TransitionDto
     */
    gateInTransitionId?: number;
    /**
     * Latest date to which the container can be stored on depot
     * @type {string}
     * @memberof TransitionDto
     */
    storageEndDate?: string;
    /**
     * When the carrier will possibly gate-out the container
     * @type {string}
     * @memberof TransitionDto
     */
    detentionEndDatetime?: string;
    /**
     * 
     * @type {Array<NhmCodeDto>}
     * @memberof TransitionDto
     */
    nhmCodes?: Array<NhmCodeDto>;
    /**
     * 
     * @type {Array<AdrRidCodeDto>}
     * @memberof TransitionDto
     */
    adrRidCodes?: Array<AdrRidCodeDto>;
    /**
     * 
     * @type {TransitionAdditionalInfoDto}
     * @memberof TransitionDto
     */
    transitionAdditionalInfo?: TransitionAdditionalInfoDto;
    /**
     * 
     * @type {TransitionCustomsInfoDto}
     * @memberof TransitionDto
     */
    transitionCustomsInfo?: TransitionCustomsInfoDto;
    /**
     * Transition MRNs
     * @type {Array<TransitionMrnDto>}
     * @memberof TransitionDto
     */
    transitionMrns?: Array<TransitionMrnDto>;
    /**
     * id of train of unseated transition
     * @type {number}
     * @memberof TransitionDto
     */
    unseatedTrainId?: number;
    /**
     * Medlog board id
     * @type {number}
     * @memberof TransitionDto
     */
    medlogBoardId?: number;
    /**
     * Transition lockdown
     * @type {boolean}
     * @memberof TransitionDto
     */
    lockdown?: boolean;
    /**
     * Datetime when the object was created
     * @type {string}
     * @memberof TransitionDto
     */
    createdAt?: string;
    /**
     * Uploaded files
     * @type {Array<UploadedFileDto>}
     * @memberof TransitionDto
     */
    uploadedFiles?: Array<UploadedFileDto>;
    /**
     * 
     * @type {CarrierShortDto}
     * @memberof TransitionDto
     */
    carrierShort?: CarrierShortDto;
    /**
     * 
     * @type {DriverShortDto}
     * @memberof TransitionDto
     */
    driverShort?: DriverShortDto;
    /**
     * 
     * @type {LicensePlateDto}
     * @memberof TransitionDto
     */
    frontLicensePlate?: LicensePlateDto;
    /**
     * 
     * @type {LicensePlateDto}
     * @memberof TransitionDto
     */
    rearLicensePlate?: LicensePlateDto;
    /**
     * 
     * @type {CompanyDto}
     * @memberof TransitionDto
     */
    temporaryCustomer?: CompanyDto;
    /**
     * Collection of transition extras (additional services)
     * @type {Array<ExtraDto>}
     * @memberof TransitionDto
     */
    extras?: Array<ExtraDto>;
    /**
     * VGM - Verified Gross Mass
     * @type {number}
     * @memberof TransitionDto
     */
    vgm?: number;
    /**
     * ID of the last GI train for this container
     * @type {number}
     * @memberof TransitionDto
     */
    lastTrainId?: number;
    /**
     * Repair shop stays
     * @type {Array<RepairShopStayDto>}
     * @memberof TransitionDto
     */
    repairShopStays?: Array<RepairShopStayDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum TransitionDtoQualityTypeEnum {
    New = 'NEW',
    Food = 'FOOD',
    Paper = 'PAPER',
    Standard = 'STANDARD',
    Scrap = 'SCRAP'
}
/**
    * @export
    * @enum {string}
    */
export enum TransitionDtoReceiptMethodEnum {
    Road = 'ROAD',
    Train = 'TRAIN'
}
/**
    * @export
    * @enum {string}
    */
export enum TransitionDtoDirectionEnum {
    In = 'IN',
    Out = 'OUT',
    Extern = 'EXTERN'
}
/**
    * @export
    * @enum {string}
    */
export enum TransitionDtoImportExportEnum {
    Import = 'IMPORT',
    Export = 'EXPORT',
    Positioning = 'POSITIONING',
    None = 'NONE'
}
/**
    * @export
    * @enum {string}
    */
export enum TransitionDtoContainerStateEnum {
    PlannedGateIn = 'PLANNED_GATE_IN',
    ProcessingGateIn = 'PROCESSING_GATE_IN',
    FinishedGateIn = 'FINISHED_GATE_IN',
    ProcessingGateOut = 'PROCESSING_GATE_OUT',
    Before3RdCheckpointGo = 'BEFORE_3RD_CHECKPOINT_GO',
    Before2NdCheckpointGo = 'BEFORE_2ND_CHECKPOINT_GO',
    FinishedGateOut = 'FINISHED_GATE_OUT',
    ProcessingExtern = 'PROCESSING_EXTERN',
    FinishedExtern = 'FINISHED_EXTERN'
}

/**
 * Transition MRNs
 * @export
 * @interface TransitionMrnDto
 */
export interface TransitionMrnDto {
    /**
     * Id of object
     * @type {number}
     * @memberof TransitionMrnDto
     */
    id?: number;
    /**
     * MRN value
     * @type {string}
     * @memberof TransitionMrnDto
     */
    value: string;
}
/**
 * 
 * @export
 * @interface TransitionMrnSearchDto
 */
export interface TransitionMrnSearchDto {
    /**
     * 
     * @type {string}
     * @memberof TransitionMrnSearchDto
     */
    value?: string;
}
/**
 * 
 * @export
 * @interface TransitionSearchDto
 */
export interface TransitionSearchDto {
    /**
     * 
     * @type {ContainerSearchDto}
     * @memberof TransitionSearchDto
     */
    container?: ContainerSearchDto;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchDto
     */
    direction?: TransitionSearchDtoDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchDto
     */
    containerState?: TransitionSearchDtoContainerStateEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof TransitionSearchDto
     */
    containerStates?: Array<TransitionSearchDtoContainerStatesEnum>;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchDto
     */
    receiptMethod?: TransitionSearchDtoReceiptMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchDto
     */
    transitionDateTime?: string;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchDto
     */
    transitionDateTimeFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchDto
     */
    transitionDateTimeTo?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TransitionSearchDto
     */
    gateInTransitionsWithoutGateOut?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof TransitionSearchDto
     */
    excludeIds?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof TransitionSearchDto
     */
    onSite?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TransitionSearchDto
     */
    repleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchDto
     */
    sectorLetter?: string;
    /**
     * 
     * @type {number}
     * @memberof TransitionSearchDto
     */
    sectorColumn?: number;
    /**
     * 
     * @type {number}
     * @memberof TransitionSearchDto
     */
    sectorRow?: number;
    /**
     * 
     * @type {number}
     * @memberof TransitionSearchDto
     */
    sectorFloor?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TransitionSearchDto
     */
    damaged?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TransitionSearchDto
     */
    loadedOnTrain?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchDto
     */
    qualityType?: TransitionSearchDtoQualityTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchDto
     */
    carrier?: string;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchDto
     */
    bookingReference?: string;
    /**
     * 
     * @type {TransitionCustomsInfoSearchDto}
     * @memberof TransitionSearchDto
     */
    transitionCustomsInfo?: TransitionCustomsInfoSearchDto;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchDto
     */
    customsClearance?: TransitionSearchDtoCustomsClearanceEnum;
    /**
     * 
     * @type {Array<TransitionMrnSearchDto>}
     * @memberof TransitionSearchDto
     */
    transitionMrns?: Array<TransitionMrnSearchDto>;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchDto
     */
    trainName?: string;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchDto
     */
    mrn?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TransitionSearchDto
     */
    hasAdrRid?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TransitionSearchDto
     */
    extraId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TransitionSearchDto
     */
    weighted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchDto
     */
    repairState?: TransitionSearchDtoRepairStateEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum TransitionSearchDtoDirectionEnum {
    In = 'IN',
    Out = 'OUT',
    Extern = 'EXTERN'
}
/**
    * @export
    * @enum {string}
    */
export enum TransitionSearchDtoContainerStateEnum {
    PlannedGateIn = 'PLANNED_GATE_IN',
    ProcessingGateIn = 'PROCESSING_GATE_IN',
    FinishedGateIn = 'FINISHED_GATE_IN',
    ProcessingGateOut = 'PROCESSING_GATE_OUT',
    Before3RdCheckpointGo = 'BEFORE_3RD_CHECKPOINT_GO',
    Before2NdCheckpointGo = 'BEFORE_2ND_CHECKPOINT_GO',
    FinishedGateOut = 'FINISHED_GATE_OUT',
    ProcessingExtern = 'PROCESSING_EXTERN',
    FinishedExtern = 'FINISHED_EXTERN'
}
/**
    * @export
    * @enum {string}
    */
export enum TransitionSearchDtoContainerStatesEnum {
    PlannedGateIn = 'PLANNED_GATE_IN',
    ProcessingGateIn = 'PROCESSING_GATE_IN',
    FinishedGateIn = 'FINISHED_GATE_IN',
    ProcessingGateOut = 'PROCESSING_GATE_OUT',
    Before3RdCheckpointGo = 'BEFORE_3RD_CHECKPOINT_GO',
    Before2NdCheckpointGo = 'BEFORE_2ND_CHECKPOINT_GO',
    FinishedGateOut = 'FINISHED_GATE_OUT',
    ProcessingExtern = 'PROCESSING_EXTERN',
    FinishedExtern = 'FINISHED_EXTERN'
}
/**
    * @export
    * @enum {string}
    */
export enum TransitionSearchDtoReceiptMethodEnum {
    Road = 'ROAD',
    Train = 'TRAIN'
}
/**
    * @export
    * @enum {string}
    */
export enum TransitionSearchDtoQualityTypeEnum {
    New = 'NEW',
    Food = 'FOOD',
    Paper = 'PAPER',
    Standard = 'STANDARD',
    Scrap = 'SCRAP'
}
/**
    * @export
    * @enum {string}
    */
export enum TransitionSearchDtoCustomsClearanceEnum {
    All = 'ALL',
    DutyFree = 'DUTY_FREE',
    AllCustoms = 'ALL_CUSTOMS',
    Approved = 'APPROVED',
    T1 = 'T1',
    Stopped = 'STOPPED'
}
/**
    * @export
    * @enum {string}
    */
export enum TransitionSearchDtoRepairStateEnum {
    All = 'ALL',
    Without = 'WITHOUT',
    AllStays = 'ALL_STAYS',
    Processing = 'PROCESSING',
    Finished = 'FINISHED'
}

/**
 * 
 * @export
 * @interface TransitionSearchListDto
 */
export interface TransitionSearchListDto {
    /**
     * Id of object
     * @type {number}
     * @memberof TransitionSearchListDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchListDto
     */
    transitionDateTime?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TransitionSearchListDto
     */
    damaged?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchListDto
     */
    qualityType?: TransitionSearchListDtoQualityTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchListDto
     */
    receiptMethod?: TransitionSearchListDtoReceiptMethodEnum;
    /**
     * 
     * @type {boolean}
     * @memberof TransitionSearchListDto
     */
    adrRid?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TransitionSearchListDto
     */
    repleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchListDto
     */
    direction?: TransitionSearchListDtoDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchListDto
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchListDto
     */
    truckLicencePlate?: string;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchListDto
     */
    containerState?: TransitionSearchListDtoContainerStateEnum;
    /**
     * 
     * @type {number}
     * @memberof TransitionSearchListDto
     */
    sectorColumn?: number;
    /**
     * 
     * @type {number}
     * @memberof TransitionSearchListDto
     */
    sectorRow?: number;
    /**
     * 
     * @type {number}
     * @memberof TransitionSearchListDto
     */
    sectorFloor?: number;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchListDto
     */
    sectorLetter?: string;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchListDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchListDto
     */
    importExport?: TransitionSearchListDtoImportExportEnum;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchListDto
     */
    transitionSemitrailerLicensePlate?: string;
    /**
     * 
     * @type {number}
     * @memberof TransitionSearchListDto
     */
    medlogBoardId?: number;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchListDto
     */
    iluCode?: string;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchListDto
     */
    containerType?: TransitionSearchListDtoContainerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchListDto
     */
    shippingContainerType?: TransitionSearchListDtoShippingContainerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchListDto
     */
    semitrailerType?: TransitionSearchListDtoSemitrailerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchListDto
     */
    semitrailerLicencePlate?: string;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchListDto
     */
    shipownerName?: string;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchListDto
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchListDto
     */
    companyColor?: string;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchListDto
     */
    customerName?: string;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchListDto
     */
    customerColor?: string;
    /**
     * 
     * @type {TransitionCustomsInfoSearchListDto}
     * @memberof TransitionSearchListDto
     */
    transitionCustomsInfo?: TransitionCustomsInfoSearchListDto;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchListDto
     */
    trainName?: string;
    /**
     * 
     * @type {number}
     * @memberof TransitionSearchListDto
     */
    seatedTrainId?: number;
    /**
     * 
     * @type {number}
     * @memberof TransitionSearchListDto
     */
    unseatedTrainId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TransitionSearchListDto
     */
    lockdown?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchListDto
     */
    driverName?: string;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchListDto
     */
    carrierName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TransitionSearchListDto
     */
    instantGreenPass?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchListDto
     */
    driverPass?: TransitionSearchListDtoDriverPassEnum;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchListDto
     */
    goArrivalDatetime?: string;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchListDto
     */
    bookingReference?: string;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchListDto
     */
    medlogReference?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TransitionSearchListDto
     */
    hasProcessingStay?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum TransitionSearchListDtoQualityTypeEnum {
    New = 'NEW',
    Food = 'FOOD',
    Paper = 'PAPER',
    Standard = 'STANDARD',
    Scrap = 'SCRAP'
}
/**
    * @export
    * @enum {string}
    */
export enum TransitionSearchListDtoReceiptMethodEnum {
    Road = 'ROAD',
    Train = 'TRAIN'
}
/**
    * @export
    * @enum {string}
    */
export enum TransitionSearchListDtoDirectionEnum {
    In = 'IN',
    Out = 'OUT',
    Extern = 'EXTERN'
}
/**
    * @export
    * @enum {string}
    */
export enum TransitionSearchListDtoContainerStateEnum {
    PlannedGateIn = 'PLANNED_GATE_IN',
    ProcessingGateIn = 'PROCESSING_GATE_IN',
    FinishedGateIn = 'FINISHED_GATE_IN',
    ProcessingGateOut = 'PROCESSING_GATE_OUT',
    Before3RdCheckpointGo = 'BEFORE_3RD_CHECKPOINT_GO',
    Before2NdCheckpointGo = 'BEFORE_2ND_CHECKPOINT_GO',
    FinishedGateOut = 'FINISHED_GATE_OUT',
    ProcessingExtern = 'PROCESSING_EXTERN',
    FinishedExtern = 'FINISHED_EXTERN'
}
/**
    * @export
    * @enum {string}
    */
export enum TransitionSearchListDtoImportExportEnum {
    Import = 'IMPORT',
    Export = 'EXPORT',
    Positioning = 'POSITIONING',
    None = 'NONE'
}
/**
    * @export
    * @enum {string}
    */
export enum TransitionSearchListDtoContainerTypeEnum {
    ShippingContainer = 'SHIPPING_CONTAINER',
    Semitrailer = 'SEMITRAILER'
}
/**
    * @export
    * @enum {string}
    */
export enum TransitionSearchListDtoShippingContainerTypeEnum {
    Iso20G1 = 'ISO_20G1',
    Iso22G0 = 'ISO_22G0',
    Iso22G1 = 'ISO_22G1',
    Iso22K2 = 'ISO_22K2',
    Iso22P4 = 'ISO_22P4',
    Iso22R0 = 'ISO_22R0',
    Iso22R1 = 'ISO_22R1',
    Iso22U0 = 'ISO_22U0',
    Iso22U1 = 'ISO_22U1',
    Iso25G0 = 'ISO_25G0',
    Iso2Mn9 = 'ISO_2MN9',
    Iso42G0 = 'ISO_42G0',
    Iso42G1 = 'ISO_42G1',
    Iso42P1 = 'ISO_42P1',
    Iso42P4 = 'ISO_42P4',
    Iso42R0 = 'ISO_42R0',
    Iso42R1 = 'ISO_42R1',
    Iso42U0 = 'ISO_42U0',
    Iso42U1 = 'ISO_42U1',
    Iso45G0 = 'ISO_45G0',
    Iso45G1 = 'ISO_45G1',
    Iso45R1 = 'ISO_45R1',
    Iso45U1 = 'ISO_45U1',
    IsoL5G0 = 'ISO_L5G0',
    IsoL5G1 = 'ISO_L5G1',
    P20 = 'P20',
    P22 = 'P22',
    P28 = 'P28',
    Tc23 = 'TC23',
    Xm = 'XM',
    Xxm = 'XXM',
    Xl = 'XL',
    Xxl = 'XXL',
    XxlLight = 'XXL_LIGHT',
    XxlMtl = 'XXL_MTL',
    Mt = 'MT',
    RtOre = 'RT_ORE',
    Graintainer = 'GRAINTAINER',
    Xml = 'XML',
    HtM = 'HT_M',
    HtXxl = 'HT_XXL',
    AtOt = 'AT_OT',
    AtHt = 'AT_HT',
    Citytainer = 'CITYTAINER',
    XxlSd = 'XXL_SD',
    Chemietainer = 'CHEMIETAINER',
    Cemtainer = 'CEMTAINER',
    Xx20 = 'XX20',
    Sb = 'SB'
}
/**
    * @export
    * @enum {string}
    */
export enum TransitionSearchListDtoSemitrailerTypeEnum {
    Standard = 'STANDARD',
    Mega = 'MEGA'
}
/**
    * @export
    * @enum {string}
    */
export enum TransitionSearchListDtoDriverPassEnum {
    Green = 'GREEN',
    Orange = 'ORANGE',
    Red = 'RED'
}

/**
 * 
 * @export
 * @interface TransitionSearchListSearchDto
 */
export interface TransitionSearchListSearchDto {
    /**
     * 
     * @type {ContainerSearchDto}
     * @memberof TransitionSearchListSearchDto
     */
    container?: ContainerSearchDto;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchListSearchDto
     */
    direction?: TransitionSearchListSearchDtoDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchListSearchDto
     */
    containerState?: TransitionSearchListSearchDtoContainerStateEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof TransitionSearchListSearchDto
     */
    containerStates?: Array<TransitionSearchListSearchDtoContainerStatesEnum>;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchListSearchDto
     */
    receiptMethod?: TransitionSearchListSearchDtoReceiptMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchListSearchDto
     */
    transitionDateTime?: string;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchListSearchDto
     */
    transitionDateTimeFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchListSearchDto
     */
    transitionDateTimeTo?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TransitionSearchListSearchDto
     */
    gateInTransitionsWithoutGateOut?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof TransitionSearchListSearchDto
     */
    excludeIds?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof TransitionSearchListSearchDto
     */
    onSite?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TransitionSearchListSearchDto
     */
    repleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchListSearchDto
     */
    sectorLetter?: string;
    /**
     * 
     * @type {number}
     * @memberof TransitionSearchListSearchDto
     */
    sectorColumn?: number;
    /**
     * 
     * @type {number}
     * @memberof TransitionSearchListSearchDto
     */
    sectorRow?: number;
    /**
     * 
     * @type {number}
     * @memberof TransitionSearchListSearchDto
     */
    sectorFloor?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TransitionSearchListSearchDto
     */
    damaged?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TransitionSearchListSearchDto
     */
    loadedOnTrain?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchListSearchDto
     */
    qualityType?: TransitionSearchListSearchDtoQualityTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchListSearchDto
     */
    carrier?: string;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchListSearchDto
     */
    reference?: string;
    /**
     * 
     * @type {TransitionCustomsInfoSearchDto}
     * @memberof TransitionSearchListSearchDto
     */
    transitionCustomsInfo?: TransitionCustomsInfoSearchDto;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchListSearchDto
     */
    customsClearance?: TransitionSearchListSearchDtoCustomsClearanceEnum;
    /**
     * 
     * @type {Array<TransitionMrnSearchDto>}
     * @memberof TransitionSearchListSearchDto
     */
    transitionMrns?: Array<TransitionMrnSearchDto>;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchListSearchDto
     */
    trainName?: string;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchListSearchDto
     */
    mrn?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TransitionSearchListSearchDto
     */
    hasAdrRid?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchListSearchDto
     */
    truckLicencePlate?: string;
    /**
     * 
     * @type {number}
     * @memberof TransitionSearchListSearchDto
     */
    seatedTrainId?: number;
    /**
     * 
     * @type {number}
     * @memberof TransitionSearchListSearchDto
     */
    unseatedTrainId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TransitionSearchListSearchDto
     */
    weighted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TransitionSearchListSearchDto
     */
    repairState?: TransitionSearchListSearchDtoRepairStateEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum TransitionSearchListSearchDtoDirectionEnum {
    In = 'IN',
    Out = 'OUT',
    Extern = 'EXTERN'
}
/**
    * @export
    * @enum {string}
    */
export enum TransitionSearchListSearchDtoContainerStateEnum {
    PlannedGateIn = 'PLANNED_GATE_IN',
    ProcessingGateIn = 'PROCESSING_GATE_IN',
    FinishedGateIn = 'FINISHED_GATE_IN',
    ProcessingGateOut = 'PROCESSING_GATE_OUT',
    Before3RdCheckpointGo = 'BEFORE_3RD_CHECKPOINT_GO',
    Before2NdCheckpointGo = 'BEFORE_2ND_CHECKPOINT_GO',
    FinishedGateOut = 'FINISHED_GATE_OUT',
    ProcessingExtern = 'PROCESSING_EXTERN',
    FinishedExtern = 'FINISHED_EXTERN'
}
/**
    * @export
    * @enum {string}
    */
export enum TransitionSearchListSearchDtoContainerStatesEnum {
    PlannedGateIn = 'PLANNED_GATE_IN',
    ProcessingGateIn = 'PROCESSING_GATE_IN',
    FinishedGateIn = 'FINISHED_GATE_IN',
    ProcessingGateOut = 'PROCESSING_GATE_OUT',
    Before3RdCheckpointGo = 'BEFORE_3RD_CHECKPOINT_GO',
    Before2NdCheckpointGo = 'BEFORE_2ND_CHECKPOINT_GO',
    FinishedGateOut = 'FINISHED_GATE_OUT',
    ProcessingExtern = 'PROCESSING_EXTERN',
    FinishedExtern = 'FINISHED_EXTERN'
}
/**
    * @export
    * @enum {string}
    */
export enum TransitionSearchListSearchDtoReceiptMethodEnum {
    Road = 'ROAD',
    Train = 'TRAIN'
}
/**
    * @export
    * @enum {string}
    */
export enum TransitionSearchListSearchDtoQualityTypeEnum {
    New = 'NEW',
    Food = 'FOOD',
    Paper = 'PAPER',
    Standard = 'STANDARD',
    Scrap = 'SCRAP'
}
/**
    * @export
    * @enum {string}
    */
export enum TransitionSearchListSearchDtoCustomsClearanceEnum {
    All = 'ALL',
    DutyFree = 'DUTY_FREE',
    AllCustoms = 'ALL_CUSTOMS',
    Approved = 'APPROVED',
    T1 = 'T1',
    Stopped = 'STOPPED'
}
/**
    * @export
    * @enum {string}
    */
export enum TransitionSearchListSearchDtoRepairStateEnum {
    All = 'ALL',
    Without = 'WITHOUT',
    AllStays = 'ALL_STAYS',
    Processing = 'PROCESSING',
    Finished = 'FINISHED'
}

/**
 * 
 * @export
 * @interface TransitionSimpleDto
 */
export interface TransitionSimpleDto {
    /**
     * Id of object
     * @type {number}
     * @memberof TransitionSimpleDto
     */
    id?: number;
    /**
     * 
     * @type {ContainerSimpleDto}
     * @memberof TransitionSimpleDto
     */
    container: ContainerSimpleDto;
    /**
     * If container is repleted
     * @type {boolean}
     * @memberof TransitionSimpleDto
     */
    repleted?: boolean;
    /**
     * Container net weight
     * @type {number}
     * @memberof TransitionSimpleDto
     */
    netWeight?: number;
    /**
     * Brutto (net weight plus container tarra weight)
     * @type {number}
     * @memberof TransitionSimpleDto
     */
    grossWeight?: number;
    /**
     * Is transition loaded on train
     * @type {boolean}
     * @memberof TransitionSimpleDto
     */
    loadedOnTrain?: boolean;
}
/**
 * 
 * @export
 * @interface TransitionStoreDto
 */
export interface TransitionStoreDto {
    /**
     * Id of object
     * @type {number}
     * @memberof TransitionStoreDto
     */
    id?: number;
    /**
     * Sector letter where container is stored
     * @type {string}
     * @memberof TransitionStoreDto
     */
    sectorLetter?: string;
    /**
     * Column where container is stored
     * @type {number}
     * @memberof TransitionStoreDto
     */
    sectorColumn?: number;
    /**
     * Row where container is stored
     * @type {number}
     * @memberof TransitionStoreDto
     */
    sectorRow?: number;
    /**
     * Floor where container is stored
     * @type {number}
     * @memberof TransitionStoreDto
     */
    sectorFloor?: number;
}
/**
 * 
 * @export
 * @interface TransitionVgmDto
 */
export interface TransitionVgmDto {
    /**
     * Verified Gross Mass
     * @type {number}
     * @memberof TransitionVgmDto
     */
    vgm?: number;
}
/**
 * truck monitoring log object to create
 * @export
 * @interface TruckMonitoringLogDto
 */
export interface TruckMonitoringLogDto {
    /**
     * Id of object
     * @type {number}
     * @memberof TruckMonitoringLogDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TruckMonitoringLogDto
     */
    licensePlate: string;
    /**
     * 
     * @type {string}
     * @memberof TruckMonitoringLogDto
     */
    arrivedAt: string;
    /**
     * 
     * @type {string}
     * @memberof TruckMonitoringLogDto
     */
    leftAt?: string;
    /**
     * 
     * @type {string}
     * @memberof TruckMonitoringLogDto
     */
    checkpoint: TruckMonitoringLogDtoCheckpointEnum;
    /**
     * 
     * @type {string}
     * @memberof TruckMonitoringLogDto
     */
    direction?: TruckMonitoringLogDtoDirectionEnum;
    /**
     * 
     * @type {CarrierDto}
     * @memberof TruckMonitoringLogDto
     */
    carrier?: CarrierDto;
}

/**
    * @export
    * @enum {string}
    */
export enum TruckMonitoringLogDtoCheckpointEnum {
    _1 = 'CHECKPOINT_1',
    _2 = 'CHECKPOINT_2',
    _3 = 'CHECKPOINT_3'
}
/**
    * @export
    * @enum {string}
    */
export enum TruckMonitoringLogDtoDirectionEnum {
    In = 'IN',
    Out = 'OUT',
    Extern = 'EXTERN'
}

/**
 * 
 * @export
 * @interface TruckMonitoringLogSearchDto
 */
export interface TruckMonitoringLogSearchDto {
    /**
     * 
     * @type {string}
     * @memberof TruckMonitoringLogSearchDto
     */
    licensePlate?: string;
    /**
     * 
     * @type {string}
     * @memberof TruckMonitoringLogSearchDto
     */
    arrivedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof TruckMonitoringLogSearchDto
     */
    leftAt?: string;
    /**
     * 
     * @type {string}
     * @memberof TruckMonitoringLogSearchDto
     */
    checkpoint?: TruckMonitoringLogSearchDtoCheckpointEnum;
    /**
     * 
     * @type {number}
     * @memberof TruckMonitoringLogSearchDto
     */
    carrierId?: number;
    /**
     * 
     * @type {string}
     * @memberof TruckMonitoringLogSearchDto
     */
    direction?: TruckMonitoringLogSearchDtoDirectionEnum;
    /**
     * 
     * @type {boolean}
     * @memberof TruckMonitoringLogSearchDto
     */
    finished?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum TruckMonitoringLogSearchDtoCheckpointEnum {
    _1 = 'CHECKPOINT_1',
    _2 = 'CHECKPOINT_2',
    _3 = 'CHECKPOINT_3'
}
/**
    * @export
    * @enum {string}
    */
export enum TruckMonitoringLogSearchDtoDirectionEnum {
    In = 'IN',
    Out = 'OUT',
    Extern = 'EXTERN'
}

/**
 * 
 * @export
 * @interface UploadedFileDto
 */
export interface UploadedFileDto {
    /**
     * Id of object
     * @type {number}
     * @memberof UploadedFileDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UploadedFileDto
     */
    fileName?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadedFileDto
     */
    uploaded?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadedFileDto
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadedFileDto
     */
    fileType?: UploadedFileDtoFileTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UploadedFileDto
     */
    contentId?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum UploadedFileDtoFileTypeEnum {
    SealPhoto = 'SEAL_PHOTO',
    Other = 'OTHER'
}

/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * Id of object
     * @type {number}
     * @memberof UserDto
     */
    id?: number;
    /**
     * Username of user
     * @type {string}
     * @memberof UserDto
     */
    username: string;
    /**
     * Password of user
     * @type {string}
     * @memberof UserDto
     */
    password: string;
    /**
     * First name of user
     * @type {string}
     * @memberof UserDto
     */
    firstName: string;
    /**
     * Surname of user
     * @type {string}
     * @memberof UserDto
     */
    surname: string;
    /**
     * Email of user
     * @type {string}
     * @memberof UserDto
     */
    email?: string;
    /**
     * Role of user
     * @type {string}
     * @memberof UserDto
     */
    role: UserDtoRoleEnum;
    /**
     * Determines whether user has disabled notifications
     * @type {boolean}
     * @memberof UserDto
     */
    notificationsDisabled: boolean;
    /**
     * Determines whether user is disabled for autentication
     * @type {boolean}
     * @memberof UserDto
     */
    disabled: boolean;
    /**
     * 
     * @type {DriverDto}
     * @memberof UserDto
     */
    driver?: DriverDto;
    /**
     * 
     * @type {CarrierDto}
     * @memberof UserDto
     */
    carrier?: CarrierDto;
    /**
     * Phone number
     * @type {string}
     * @memberof UserDto
     */
    phoneNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    systemAccount?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum UserDtoRoleEnum {
    Operator = 'OPERATOR',
    Dispatcher = 'DISPATCHER',
    Manager = 'MANAGER',
    System = 'SYSTEM',
    CustomsOfficer = 'CUSTOMS_OFFICER',
    DispatcherTrain = 'DISPATCHER_TRAIN',
    DispatcherRoad = 'DISPATCHER_ROAD',
    Driver = 'DRIVER',
    Carrier = 'CARRIER',
    Kiosk = 'KIOSK'
}

/**
 * The user who last updated this customs info
 * @export
 * @interface UserNameDto
 */
export interface UserNameDto {
    /**
     * Id of object
     * @type {number}
     * @memberof UserNameDto
     */
    id?: number;
    /**
     * First name of user
     * @type {string}
     * @memberof UserNameDto
     */
    firstName?: string;
    /**
     * Surname of user
     * @type {string}
     * @memberof UserNameDto
     */
    surname?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserNameDto
     */
    systemAccount?: boolean;
}
/**
 * 
 * @export
 * @interface UserSearchDto
 */
export interface UserSearchDto {
    /**
     * 
     * @type {string}
     * @memberof UserSearchDto
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSearchDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSearchDto
     */
    surname?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSearchDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSearchDto
     */
    role?: UserSearchDtoRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof UserSearchDto
     */
    phoneNumber?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum UserSearchDtoRoleEnum {
    Operator = 'OPERATOR',
    Dispatcher = 'DISPATCHER',
    Manager = 'MANAGER',
    System = 'SYSTEM',
    CustomsOfficer = 'CUSTOMS_OFFICER',
    DispatcherTrain = 'DISPATCHER_TRAIN',
    DispatcherRoad = 'DISPATCHER_ROAD',
    Driver = 'DRIVER',
    Carrier = 'CARRIER',
    Kiosk = 'KIOSK'
}


/**
 * AsparkingControllerApi - axios parameter creator
 * @export
 */
export const AsparkingControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Send barcode and licence plate if parking ticket was printed by some device
         * @param {AsparkingBarcodeDto} asparkingBarcodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        barcodePrinted: async (asparkingBarcodeDto: AsparkingBarcodeDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'asparkingBarcodeDto' is not null or undefined
            if (asparkingBarcodeDto === null || asparkingBarcodeDto === undefined) {
                throw new RequiredError('asparkingBarcodeDto','Required parameter asparkingBarcodeDto was null or undefined when calling barcodePrinted.');
            }
            const localVarPath = `/asparking/barcode/printed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof asparkingBarcodeDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(asparkingBarcodeDto !== undefined ? asparkingBarcodeDto : {})
                : (asparkingBarcodeDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send barcode and licence plate if parking ticket was read by some device
         * @param {AsparkingBarcodeDto} asparkingBarcodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        barcodeRead: async (asparkingBarcodeDto: AsparkingBarcodeDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'asparkingBarcodeDto' is not null or undefined
            if (asparkingBarcodeDto === null || asparkingBarcodeDto === undefined) {
                throw new RequiredError('asparkingBarcodeDto','Required parameter asparkingBarcodeDto was null or undefined when calling barcodeRead.');
            }
            const localVarPath = `/asparking/barcode/read`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof asparkingBarcodeDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(asparkingBarcodeDto !== undefined ? asparkingBarcodeDto : {})
                : (asparkingBarcodeDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send state of gate if its changed
         * @param {AsparkingGatePositionDto} asparkingGatePositionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gatePositionChanged: async (asparkingGatePositionDto: AsparkingGatePositionDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'asparkingGatePositionDto' is not null or undefined
            if (asparkingGatePositionDto === null || asparkingGatePositionDto === undefined) {
                throw new RequiredError('asparkingGatePositionDto','Required parameter asparkingGatePositionDto was null or undefined when calling gatePositionChanged.');
            }
            const localVarPath = `/asparking/gate-position/changed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof asparkingGatePositionDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(asparkingGatePositionDto !== undefined ? asparkingGatePositionDto : {})
                : (asparkingGatePositionDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send state of car if its changed
         * @param {AsparkingPresenceCarDto} asparkingPresenceCarDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gatePresenceCarChanged: async (asparkingPresenceCarDto: AsparkingPresenceCarDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'asparkingPresenceCarDto' is not null or undefined
            if (asparkingPresenceCarDto === null || asparkingPresenceCarDto === undefined) {
                throw new RequiredError('asparkingPresenceCarDto','Required parameter asparkingPresenceCarDto was null or undefined when calling gatePresenceCarChanged.');
            }
            const localVarPath = `/asparking/presence-car/changed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof asparkingPresenceCarDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(asparkingPresenceCarDto !== undefined ? asparkingPresenceCarDto : {})
                : (asparkingPresenceCarDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send licence plate if its read by some device
         * @param {AsparkingLicencePlateDto} asparkingLicencePlateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        licencePlateRead: async (asparkingLicencePlateDto: AsparkingLicencePlateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'asparkingLicencePlateDto' is not null or undefined
            if (asparkingLicencePlateDto === null || asparkingLicencePlateDto === undefined) {
                throw new RequiredError('asparkingLicencePlateDto','Required parameter asparkingLicencePlateDto was null or undefined when calling licencePlateRead.');
            }
            const localVarPath = `/asparking/licence-plate/read`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof asparkingLicencePlateDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(asparkingLicencePlateDto !== undefined ? asparkingLicencePlateDto : {})
                : (asparkingLicencePlateDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update asparking bearer token
         * @param {string} newToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBearerToken: async (newToken: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'newToken' is not null or undefined
            if (newToken === null || newToken === undefined) {
                throw new RequiredError('newToken','Required parameter newToken was null or undefined when calling updateBearerToken.');
            }
            const localVarPath = `/asparking/token/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (newToken !== undefined) {
                localVarQueryParameter['newToken'] = newToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send card number if its read by some device
         * @param {AsparkingWiegandDto} asparkingWiegandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wiegandRead: async (asparkingWiegandDto: AsparkingWiegandDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'asparkingWiegandDto' is not null or undefined
            if (asparkingWiegandDto === null || asparkingWiegandDto === undefined) {
                throw new RequiredError('asparkingWiegandDto','Required parameter asparkingWiegandDto was null or undefined when calling wiegandRead.');
            }
            const localVarPath = `/asparking/wiegand/read`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof asparkingWiegandDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(asparkingWiegandDto !== undefined ? asparkingWiegandDto : {})
                : (asparkingWiegandDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AsparkingControllerApi - functional programming interface
 * @export
 */
export const AsparkingControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Send barcode and licence plate if parking ticket was printed by some device
         * @param {AsparkingBarcodeDto} asparkingBarcodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async barcodePrinted(asparkingBarcodeDto: AsparkingBarcodeDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await AsparkingControllerApiAxiosParamCreator(configuration).barcodePrinted(asparkingBarcodeDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Send barcode and licence plate if parking ticket was read by some device
         * @param {AsparkingBarcodeDto} asparkingBarcodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async barcodeRead(asparkingBarcodeDto: AsparkingBarcodeDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AsparkingReadResponseDto>> {
            const localVarAxiosArgs = await AsparkingControllerApiAxiosParamCreator(configuration).barcodeRead(asparkingBarcodeDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Send state of gate if its changed
         * @param {AsparkingGatePositionDto} asparkingGatePositionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gatePositionChanged(asparkingGatePositionDto: AsparkingGatePositionDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await AsparkingControllerApiAxiosParamCreator(configuration).gatePositionChanged(asparkingGatePositionDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Send state of car if its changed
         * @param {AsparkingPresenceCarDto} asparkingPresenceCarDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gatePresenceCarChanged(asparkingPresenceCarDto: AsparkingPresenceCarDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await AsparkingControllerApiAxiosParamCreator(configuration).gatePresenceCarChanged(asparkingPresenceCarDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Send licence plate if its read by some device
         * @param {AsparkingLicencePlateDto} asparkingLicencePlateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async licencePlateRead(asparkingLicencePlateDto: AsparkingLicencePlateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AsparkingReadResponseDto>> {
            const localVarAxiosArgs = await AsparkingControllerApiAxiosParamCreator(configuration).licencePlateRead(asparkingLicencePlateDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update asparking bearer token
         * @param {string} newToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBearerToken(newToken: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await AsparkingControllerApiAxiosParamCreator(configuration).updateBearerToken(newToken, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Send card number if its read by some device
         * @param {AsparkingWiegandDto} asparkingWiegandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wiegandRead(asparkingWiegandDto: AsparkingWiegandDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AsparkingReadResponseDto>> {
            const localVarAxiosArgs = await AsparkingControllerApiAxiosParamCreator(configuration).wiegandRead(asparkingWiegandDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AsparkingControllerApi - factory interface
 * @export
 */
export const AsparkingControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Send barcode and licence plate if parking ticket was printed by some device
         * @param {AsparkingBarcodeDto} asparkingBarcodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        barcodePrinted(asparkingBarcodeDto: AsparkingBarcodeDto, options?: any): AxiosPromise<string> {
            return AsparkingControllerApiFp(configuration).barcodePrinted(asparkingBarcodeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send barcode and licence plate if parking ticket was read by some device
         * @param {AsparkingBarcodeDto} asparkingBarcodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        barcodeRead(asparkingBarcodeDto: AsparkingBarcodeDto, options?: any): AxiosPromise<AsparkingReadResponseDto> {
            return AsparkingControllerApiFp(configuration).barcodeRead(asparkingBarcodeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send state of gate if its changed
         * @param {AsparkingGatePositionDto} asparkingGatePositionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gatePositionChanged(asparkingGatePositionDto: AsparkingGatePositionDto, options?: any): AxiosPromise<string> {
            return AsparkingControllerApiFp(configuration).gatePositionChanged(asparkingGatePositionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send state of car if its changed
         * @param {AsparkingPresenceCarDto} asparkingPresenceCarDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gatePresenceCarChanged(asparkingPresenceCarDto: AsparkingPresenceCarDto, options?: any): AxiosPromise<string> {
            return AsparkingControllerApiFp(configuration).gatePresenceCarChanged(asparkingPresenceCarDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send licence plate if its read by some device
         * @param {AsparkingLicencePlateDto} asparkingLicencePlateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        licencePlateRead(asparkingLicencePlateDto: AsparkingLicencePlateDto, options?: any): AxiosPromise<AsparkingReadResponseDto> {
            return AsparkingControllerApiFp(configuration).licencePlateRead(asparkingLicencePlateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update asparking bearer token
         * @param {string} newToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBearerToken(newToken: string, options?: any): AxiosPromise<string> {
            return AsparkingControllerApiFp(configuration).updateBearerToken(newToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send card number if its read by some device
         * @param {AsparkingWiegandDto} asparkingWiegandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wiegandRead(asparkingWiegandDto: AsparkingWiegandDto, options?: any): AxiosPromise<AsparkingReadResponseDto> {
            return AsparkingControllerApiFp(configuration).wiegandRead(asparkingWiegandDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AsparkingControllerApi - object-oriented interface
 * @export
 * @class AsparkingControllerApi
 * @extends {BaseAPI}
 */
export class AsparkingControllerApi extends BaseAPI {
    /**
     * 
     * @summary Send barcode and licence plate if parking ticket was printed by some device
     * @param {AsparkingBarcodeDto} asparkingBarcodeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsparkingControllerApi
     */
    public barcodePrinted(asparkingBarcodeDto: AsparkingBarcodeDto, options?: any) {
        return AsparkingControllerApiFp(this.configuration).barcodePrinted(asparkingBarcodeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send barcode and licence plate if parking ticket was read by some device
     * @param {AsparkingBarcodeDto} asparkingBarcodeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsparkingControllerApi
     */
    public barcodeRead(asparkingBarcodeDto: AsparkingBarcodeDto, options?: any) {
        return AsparkingControllerApiFp(this.configuration).barcodeRead(asparkingBarcodeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send state of gate if its changed
     * @param {AsparkingGatePositionDto} asparkingGatePositionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsparkingControllerApi
     */
    public gatePositionChanged(asparkingGatePositionDto: AsparkingGatePositionDto, options?: any) {
        return AsparkingControllerApiFp(this.configuration).gatePositionChanged(asparkingGatePositionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send state of car if its changed
     * @param {AsparkingPresenceCarDto} asparkingPresenceCarDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsparkingControllerApi
     */
    public gatePresenceCarChanged(asparkingPresenceCarDto: AsparkingPresenceCarDto, options?: any) {
        return AsparkingControllerApiFp(this.configuration).gatePresenceCarChanged(asparkingPresenceCarDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send licence plate if its read by some device
     * @param {AsparkingLicencePlateDto} asparkingLicencePlateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsparkingControllerApi
     */
    public licencePlateRead(asparkingLicencePlateDto: AsparkingLicencePlateDto, options?: any) {
        return AsparkingControllerApiFp(this.configuration).licencePlateRead(asparkingLicencePlateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update asparking bearer token
     * @param {string} newToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsparkingControllerApi
     */
    public updateBearerToken(newToken: string, options?: any) {
        return AsparkingControllerApiFp(this.configuration).updateBearerToken(newToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send card number if its read by some device
     * @param {AsparkingWiegandDto} asparkingWiegandDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsparkingControllerApi
     */
    public wiegandRead(asparkingWiegandDto: AsparkingWiegandDto, options?: any) {
        return AsparkingControllerApiFp(this.configuration).wiegandRead(asparkingWiegandDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AsparkingGateControlApi - axios parameter creator
 * @export
 */
export const AsparkingGateControlApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} targetDevice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asparkingGateControlCloseGate: async (targetDevice: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'targetDevice' is not null or undefined
            if (targetDevice === null || targetDevice === undefined) {
                throw new RequiredError('targetDevice','Required parameter targetDevice was null or undefined when calling asparkingGateControlCloseGate.');
            }
            const localVarPath = `/asparking/gate-control/close-gate/{targetDevice}`
                .replace(`{${"targetDevice"}}`, encodeURIComponent(String(targetDevice)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asparkingGateControlGetGateProperties: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/asparking/gate-control/properties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'OPEN' | 'OPEN_PERMANENT' | 'CLOSE'} gateControlMode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asparkingGateControlHandleAllGates: async (gateControlMode: 'OPEN' | 'OPEN_PERMANENT' | 'CLOSE', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gateControlMode' is not null or undefined
            if (gateControlMode === null || gateControlMode === undefined) {
                throw new RequiredError('gateControlMode','Required parameter gateControlMode was null or undefined when calling asparkingGateControlHandleAllGates.');
            }
            const localVarPath = `/asparking/gate-control/all-gates/{gateControlMode}`
                .replace(`{${"gateControlMode"}}`, encodeURIComponent(String(gateControlMode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} targetDevice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asparkingGateControlOpenGate: async (targetDevice: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'targetDevice' is not null or undefined
            if (targetDevice === null || targetDevice === undefined) {
                throw new RequiredError('targetDevice','Required parameter targetDevice was null or undefined when calling asparkingGateControlOpenGate.');
            }
            const localVarPath = `/asparking/gate-control/open-gate/{targetDevice}`
                .replace(`{${"targetDevice"}}`, encodeURIComponent(String(targetDevice)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} targetDevice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asparkingGateControlOpenGatePermanently: async (targetDevice: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'targetDevice' is not null or undefined
            if (targetDevice === null || targetDevice === undefined) {
                throw new RequiredError('targetDevice','Required parameter targetDevice was null or undefined when calling asparkingGateControlOpenGatePermanently.');
            }
            const localVarPath = `/asparking/gate-control/open-gate-permanently/{targetDevice}`
                .replace(`{${"targetDevice"}}`, encodeURIComponent(String(targetDevice)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AsparkingGateControlApi - functional programming interface
 * @export
 */
export const AsparkingGateControlApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} targetDevice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async asparkingGateControlCloseGate(targetDevice: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await AsparkingGateControlApiAxiosParamCreator(configuration).asparkingGateControlCloseGate(targetDevice, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async asparkingGateControlGetGateProperties(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AsparkingGatePropertiesDto>> {
            const localVarAxiosArgs = await AsparkingGateControlApiAxiosParamCreator(configuration).asparkingGateControlGetGateProperties(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {'OPEN' | 'OPEN_PERMANENT' | 'CLOSE'} gateControlMode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async asparkingGateControlHandleAllGates(gateControlMode: 'OPEN' | 'OPEN_PERMANENT' | 'CLOSE', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await AsparkingGateControlApiAxiosParamCreator(configuration).asparkingGateControlHandleAllGates(gateControlMode, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} targetDevice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async asparkingGateControlOpenGate(targetDevice: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await AsparkingGateControlApiAxiosParamCreator(configuration).asparkingGateControlOpenGate(targetDevice, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} targetDevice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async asparkingGateControlOpenGatePermanently(targetDevice: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await AsparkingGateControlApiAxiosParamCreator(configuration).asparkingGateControlOpenGatePermanently(targetDevice, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AsparkingGateControlApi - factory interface
 * @export
 */
export const AsparkingGateControlApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} targetDevice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asparkingGateControlCloseGate(targetDevice: number, options?: any): AxiosPromise<string> {
            return AsparkingGateControlApiFp(configuration).asparkingGateControlCloseGate(targetDevice, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asparkingGateControlGetGateProperties(options?: any): AxiosPromise<AsparkingGatePropertiesDto> {
            return AsparkingGateControlApiFp(configuration).asparkingGateControlGetGateProperties(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'OPEN' | 'OPEN_PERMANENT' | 'CLOSE'} gateControlMode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asparkingGateControlHandleAllGates(gateControlMode: 'OPEN' | 'OPEN_PERMANENT' | 'CLOSE', options?: any): AxiosPromise<string> {
            return AsparkingGateControlApiFp(configuration).asparkingGateControlHandleAllGates(gateControlMode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} targetDevice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asparkingGateControlOpenGate(targetDevice: number, options?: any): AxiosPromise<string> {
            return AsparkingGateControlApiFp(configuration).asparkingGateControlOpenGate(targetDevice, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} targetDevice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asparkingGateControlOpenGatePermanently(targetDevice: number, options?: any): AxiosPromise<string> {
            return AsparkingGateControlApiFp(configuration).asparkingGateControlOpenGatePermanently(targetDevice, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AsparkingGateControlApi - object-oriented interface
 * @export
 * @class AsparkingGateControlApi
 * @extends {BaseAPI}
 */
export class AsparkingGateControlApi extends BaseAPI {
    /**
     * 
     * @param {number} targetDevice 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsparkingGateControlApi
     */
    public asparkingGateControlCloseGate(targetDevice: number, options?: any) {
        return AsparkingGateControlApiFp(this.configuration).asparkingGateControlCloseGate(targetDevice, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsparkingGateControlApi
     */
    public asparkingGateControlGetGateProperties(options?: any) {
        return AsparkingGateControlApiFp(this.configuration).asparkingGateControlGetGateProperties(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'OPEN' | 'OPEN_PERMANENT' | 'CLOSE'} gateControlMode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsparkingGateControlApi
     */
    public asparkingGateControlHandleAllGates(gateControlMode: 'OPEN' | 'OPEN_PERMANENT' | 'CLOSE', options?: any) {
        return AsparkingGateControlApiFp(this.configuration).asparkingGateControlHandleAllGates(gateControlMode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} targetDevice 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsparkingGateControlApi
     */
    public asparkingGateControlOpenGate(targetDevice: number, options?: any) {
        return AsparkingGateControlApiFp(this.configuration).asparkingGateControlOpenGate(targetDevice, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} targetDevice 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsparkingGateControlApi
     */
    public asparkingGateControlOpenGatePermanently(targetDevice: number, options?: any) {
        return AsparkingGateControlApiFp(this.configuration).asparkingGateControlOpenGatePermanently(targetDevice, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AsparkingSemaphoreApi - axios parameter creator
 * @export
 */
export const AsparkingSemaphoreApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {'NO_CHANGE' | 'FORWARD_DIRECTION' | 'RIGHT_DIRECTION'} semaphoreMode 
         * @param {'CHECKPOINT_1_IN_CAR' | 'CHECKPOINT_1_IN_TRUCK' | 'CHECKPOINT_1_OUT_CAR' | 'CHECKPOINT_1_OUT_TRUCK' | 'MAIN_GATE' | 'CHECKPOINT_2_IN' | 'CHECKPOINT_2_OUT' | 'CHECKPOINT_3_IN' | 'CHECKPOINT_3_OUT'} asparkingCheckPoint 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asparkingSemaphoreSemaphoreControl: async (semaphoreMode: 'NO_CHANGE' | 'FORWARD_DIRECTION' | 'RIGHT_DIRECTION', asparkingCheckPoint: 'CHECKPOINT_1_IN_CAR' | 'CHECKPOINT_1_IN_TRUCK' | 'CHECKPOINT_1_OUT_CAR' | 'CHECKPOINT_1_OUT_TRUCK' | 'MAIN_GATE' | 'CHECKPOINT_2_IN' | 'CHECKPOINT_2_OUT' | 'CHECKPOINT_3_IN' | 'CHECKPOINT_3_OUT', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'semaphoreMode' is not null or undefined
            if (semaphoreMode === null || semaphoreMode === undefined) {
                throw new RequiredError('semaphoreMode','Required parameter semaphoreMode was null or undefined when calling asparkingSemaphoreSemaphoreControl.');
            }
            // verify required parameter 'asparkingCheckPoint' is not null or undefined
            if (asparkingCheckPoint === null || asparkingCheckPoint === undefined) {
                throw new RequiredError('asparkingCheckPoint','Required parameter asparkingCheckPoint was null or undefined when calling asparkingSemaphoreSemaphoreControl.');
            }
            const localVarPath = `/asparking-semaphore/control`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (semaphoreMode !== undefined) {
                localVarQueryParameter['semaphoreMode'] = semaphoreMode;
            }

            if (asparkingCheckPoint !== undefined) {
                localVarQueryParameter['asparkingCheckPoint'] = asparkingCheckPoint;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AsparkingSemaphoreApi - functional programming interface
 * @export
 */
export const AsparkingSemaphoreApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {'NO_CHANGE' | 'FORWARD_DIRECTION' | 'RIGHT_DIRECTION'} semaphoreMode 
         * @param {'CHECKPOINT_1_IN_CAR' | 'CHECKPOINT_1_IN_TRUCK' | 'CHECKPOINT_1_OUT_CAR' | 'CHECKPOINT_1_OUT_TRUCK' | 'MAIN_GATE' | 'CHECKPOINT_2_IN' | 'CHECKPOINT_2_OUT' | 'CHECKPOINT_3_IN' | 'CHECKPOINT_3_OUT'} asparkingCheckPoint 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async asparkingSemaphoreSemaphoreControl(semaphoreMode: 'NO_CHANGE' | 'FORWARD_DIRECTION' | 'RIGHT_DIRECTION', asparkingCheckPoint: 'CHECKPOINT_1_IN_CAR' | 'CHECKPOINT_1_IN_TRUCK' | 'CHECKPOINT_1_OUT_CAR' | 'CHECKPOINT_1_OUT_TRUCK' | 'MAIN_GATE' | 'CHECKPOINT_2_IN' | 'CHECKPOINT_2_OUT' | 'CHECKPOINT_3_IN' | 'CHECKPOINT_3_OUT', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await AsparkingSemaphoreApiAxiosParamCreator(configuration).asparkingSemaphoreSemaphoreControl(semaphoreMode, asparkingCheckPoint, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AsparkingSemaphoreApi - factory interface
 * @export
 */
export const AsparkingSemaphoreApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {'NO_CHANGE' | 'FORWARD_DIRECTION' | 'RIGHT_DIRECTION'} semaphoreMode 
         * @param {'CHECKPOINT_1_IN_CAR' | 'CHECKPOINT_1_IN_TRUCK' | 'CHECKPOINT_1_OUT_CAR' | 'CHECKPOINT_1_OUT_TRUCK' | 'MAIN_GATE' | 'CHECKPOINT_2_IN' | 'CHECKPOINT_2_OUT' | 'CHECKPOINT_3_IN' | 'CHECKPOINT_3_OUT'} asparkingCheckPoint 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asparkingSemaphoreSemaphoreControl(semaphoreMode: 'NO_CHANGE' | 'FORWARD_DIRECTION' | 'RIGHT_DIRECTION', asparkingCheckPoint: 'CHECKPOINT_1_IN_CAR' | 'CHECKPOINT_1_IN_TRUCK' | 'CHECKPOINT_1_OUT_CAR' | 'CHECKPOINT_1_OUT_TRUCK' | 'MAIN_GATE' | 'CHECKPOINT_2_IN' | 'CHECKPOINT_2_OUT' | 'CHECKPOINT_3_IN' | 'CHECKPOINT_3_OUT', options?: any): AxiosPromise<string> {
            return AsparkingSemaphoreApiFp(configuration).asparkingSemaphoreSemaphoreControl(semaphoreMode, asparkingCheckPoint, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AsparkingSemaphoreApi - object-oriented interface
 * @export
 * @class AsparkingSemaphoreApi
 * @extends {BaseAPI}
 */
export class AsparkingSemaphoreApi extends BaseAPI {
    /**
     * 
     * @param {'NO_CHANGE' | 'FORWARD_DIRECTION' | 'RIGHT_DIRECTION'} semaphoreMode 
     * @param {'CHECKPOINT_1_IN_CAR' | 'CHECKPOINT_1_IN_TRUCK' | 'CHECKPOINT_1_OUT_CAR' | 'CHECKPOINT_1_OUT_TRUCK' | 'MAIN_GATE' | 'CHECKPOINT_2_IN' | 'CHECKPOINT_2_OUT' | 'CHECKPOINT_3_IN' | 'CHECKPOINT_3_OUT'} asparkingCheckPoint 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsparkingSemaphoreApi
     */
    public asparkingSemaphoreSemaphoreControl(semaphoreMode: 'NO_CHANGE' | 'FORWARD_DIRECTION' | 'RIGHT_DIRECTION', asparkingCheckPoint: 'CHECKPOINT_1_IN_CAR' | 'CHECKPOINT_1_IN_TRUCK' | 'CHECKPOINT_1_OUT_CAR' | 'CHECKPOINT_1_OUT_TRUCK' | 'MAIN_GATE' | 'CHECKPOINT_2_IN' | 'CHECKPOINT_2_OUT' | 'CHECKPOINT_3_IN' | 'CHECKPOINT_3_OUT', options?: any) {
        return AsparkingSemaphoreApiFp(this.configuration).asparkingSemaphoreSemaphoreControl(semaphoreMode, asparkingCheckPoint, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AsparkingTicketApi - axios parameter creator
 * @export
 */
export const AsparkingTicketApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new asparkingTicket
         * @param {AsparkingTicketDto} asparkingTicketDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asparkingTicketCreate: async (asparkingTicketDto: AsparkingTicketDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'asparkingTicketDto' is not null or undefined
            if (asparkingTicketDto === null || asparkingTicketDto === undefined) {
                throw new RequiredError('asparkingTicketDto','Required parameter asparkingTicketDto was null or undefined when calling asparkingTicketCreate.');
            }
            const localVarPath = `/asparking-ticket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof asparkingTicketDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(asparkingTicketDto !== undefined ? asparkingTicketDto : {})
                : (asparkingTicketDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete asparkingTicket
         * @param {number} id Id of asparkingTicket to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asparkingTicketDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling asparkingTicketDelete.');
            }
            const localVarPath = `/asparking-ticket/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all asparkingTickets
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asparkingTicketFindAll: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/asparking-ticket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find a asparkingTicket by its id.
         * @param {number} id Id of asparkingTicket to be found
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asparkingTicketFindById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling asparkingTicketFindById.');
            }
            const localVarPath = `/asparking-ticket/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {AsparkingTicketSearchDto} [asparkingTicketSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asparkingTicketSearch: async (page?: number, size?: number, sort?: Array<string>, asparkingTicketSearchDto?: AsparkingTicketSearchDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/asparking-ticket/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof asparkingTicketSearchDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(asparkingTicketSearchDto !== undefined ? asparkingTicketSearchDto : {})
                : (asparkingTicketSearchDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update asparkingTicket
         * @param {number} id Id of asparkingTicket to be updated
         * @param {AsparkingTicketDto} asparkingTicketDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asparkingTicketUpdate: async (id: number, asparkingTicketDto: AsparkingTicketDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling asparkingTicketUpdate.');
            }
            // verify required parameter 'asparkingTicketDto' is not null or undefined
            if (asparkingTicketDto === null || asparkingTicketDto === undefined) {
                throw new RequiredError('asparkingTicketDto','Required parameter asparkingTicketDto was null or undefined when calling asparkingTicketUpdate.');
            }
            const localVarPath = `/asparking-ticket/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof asparkingTicketDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(asparkingTicketDto !== undefined ? asparkingTicketDto : {})
                : (asparkingTicketDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AsparkingTicketApi - functional programming interface
 * @export
 */
export const AsparkingTicketApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new asparkingTicket
         * @param {AsparkingTicketDto} asparkingTicketDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async asparkingTicketCreate(asparkingTicketDto: AsparkingTicketDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AsparkingTicketDto>> {
            const localVarAxiosArgs = await AsparkingTicketApiAxiosParamCreator(configuration).asparkingTicketCreate(asparkingTicketDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete asparkingTicket
         * @param {number} id Id of asparkingTicket to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async asparkingTicketDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AsparkingTicketApiAxiosParamCreator(configuration).asparkingTicketDelete(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Find all asparkingTickets
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async asparkingTicketFindAll(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageAsparkingTicketDto>> {
            const localVarAxiosArgs = await AsparkingTicketApiAxiosParamCreator(configuration).asparkingTicketFindAll(page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Find a asparkingTicket by its id.
         * @param {number} id Id of asparkingTicket to be found
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async asparkingTicketFindById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AsparkingTicketDto>> {
            const localVarAxiosArgs = await AsparkingTicketApiAxiosParamCreator(configuration).asparkingTicketFindById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {AsparkingTicketSearchDto} [asparkingTicketSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async asparkingTicketSearch(page?: number, size?: number, sort?: Array<string>, asparkingTicketSearchDto?: AsparkingTicketSearchDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageAsparkingTicketDto>> {
            const localVarAxiosArgs = await AsparkingTicketApiAxiosParamCreator(configuration).asparkingTicketSearch(page, size, sort, asparkingTicketSearchDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update asparkingTicket
         * @param {number} id Id of asparkingTicket to be updated
         * @param {AsparkingTicketDto} asparkingTicketDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async asparkingTicketUpdate(id: number, asparkingTicketDto: AsparkingTicketDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AsparkingTicketDto>> {
            const localVarAxiosArgs = await AsparkingTicketApiAxiosParamCreator(configuration).asparkingTicketUpdate(id, asparkingTicketDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AsparkingTicketApi - factory interface
 * @export
 */
export const AsparkingTicketApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create new asparkingTicket
         * @param {AsparkingTicketDto} asparkingTicketDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asparkingTicketCreate(asparkingTicketDto: AsparkingTicketDto, options?: any): AxiosPromise<AsparkingTicketDto> {
            return AsparkingTicketApiFp(configuration).asparkingTicketCreate(asparkingTicketDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete asparkingTicket
         * @param {number} id Id of asparkingTicket to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asparkingTicketDelete(id: number, options?: any): AxiosPromise<void> {
            return AsparkingTicketApiFp(configuration).asparkingTicketDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all asparkingTickets
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asparkingTicketFindAll(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageAsparkingTicketDto> {
            return AsparkingTicketApiFp(configuration).asparkingTicketFindAll(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find a asparkingTicket by its id.
         * @param {number} id Id of asparkingTicket to be found
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asparkingTicketFindById(id: number, options?: any): AxiosPromise<AsparkingTicketDto> {
            return AsparkingTicketApiFp(configuration).asparkingTicketFindById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {AsparkingTicketSearchDto} [asparkingTicketSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asparkingTicketSearch(page?: number, size?: number, sort?: Array<string>, asparkingTicketSearchDto?: AsparkingTicketSearchDto, options?: any): AxiosPromise<PageAsparkingTicketDto> {
            return AsparkingTicketApiFp(configuration).asparkingTicketSearch(page, size, sort, asparkingTicketSearchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update asparkingTicket
         * @param {number} id Id of asparkingTicket to be updated
         * @param {AsparkingTicketDto} asparkingTicketDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asparkingTicketUpdate(id: number, asparkingTicketDto: AsparkingTicketDto, options?: any): AxiosPromise<AsparkingTicketDto> {
            return AsparkingTicketApiFp(configuration).asparkingTicketUpdate(id, asparkingTicketDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AsparkingTicketApi - object-oriented interface
 * @export
 * @class AsparkingTicketApi
 * @extends {BaseAPI}
 */
export class AsparkingTicketApi extends BaseAPI {
    /**
     * 
     * @summary Create new asparkingTicket
     * @param {AsparkingTicketDto} asparkingTicketDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsparkingTicketApi
     */
    public asparkingTicketCreate(asparkingTicketDto: AsparkingTicketDto, options?: any) {
        return AsparkingTicketApiFp(this.configuration).asparkingTicketCreate(asparkingTicketDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete asparkingTicket
     * @param {number} id Id of asparkingTicket to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsparkingTicketApi
     */
    public asparkingTicketDelete(id: number, options?: any) {
        return AsparkingTicketApiFp(this.configuration).asparkingTicketDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all asparkingTickets
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsparkingTicketApi
     */
    public asparkingTicketFindAll(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return AsparkingTicketApiFp(this.configuration).asparkingTicketFindAll(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find a asparkingTicket by its id.
     * @param {number} id Id of asparkingTicket to be found
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsparkingTicketApi
     */
    public asparkingTicketFindById(id: number, options?: any) {
        return AsparkingTicketApiFp(this.configuration).asparkingTicketFindById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {AsparkingTicketSearchDto} [asparkingTicketSearchDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsparkingTicketApi
     */
    public asparkingTicketSearch(page?: number, size?: number, sort?: Array<string>, asparkingTicketSearchDto?: AsparkingTicketSearchDto, options?: any) {
        return AsparkingTicketApiFp(this.configuration).asparkingTicketSearch(page, size, sort, asparkingTicketSearchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update asparkingTicket
     * @param {number} id Id of asparkingTicket to be updated
     * @param {AsparkingTicketDto} asparkingTicketDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsparkingTicketApi
     */
    public asparkingTicketUpdate(id: number, asparkingTicketDto: AsparkingTicketDto, options?: any) {
        return AsparkingTicketApiFp(this.configuration).asparkingTicketUpdate(id, asparkingTicketDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuditLogApi - axios parameter creator
 * @export
 */
export const AuditLogApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates new audit log
         * @param {AuditLogDto} auditLogDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditLogCreate: async (auditLogDto: AuditLogDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditLogDto' is not null or undefined
            if (auditLogDto === null || auditLogDto === undefined) {
                throw new RequiredError('auditLogDto','Required parameter auditLogDto was null or undefined when calling auditLogCreate.');
            }
            const localVarPath = `/audit-log`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof auditLogDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(auditLogDto !== undefined ? auditLogDto : {})
                : (auditLogDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all audit logs
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditLogFindAll: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/audit-log`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {AuditLogSearchDto} [auditLogSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditLogSearch: async (page?: number, size?: number, sort?: Array<string>, auditLogSearchDto?: AuditLogSearchDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/audit-log/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof auditLogSearchDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(auditLogSearchDto !== undefined ? auditLogSearchDto : {})
                : (auditLogSearchDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuditLogApi - functional programming interface
 * @export
 */
export const AuditLogApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates new audit log
         * @param {AuditLogDto} auditLogDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async auditLogCreate(auditLogDto: AuditLogDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuditLogDto>> {
            const localVarAxiosArgs = await AuditLogApiAxiosParamCreator(configuration).auditLogCreate(auditLogDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Find all audit logs
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async auditLogFindAll(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageAuditLogDto>> {
            const localVarAxiosArgs = await AuditLogApiAxiosParamCreator(configuration).auditLogFindAll(page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {AuditLogSearchDto} [auditLogSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async auditLogSearch(page?: number, size?: number, sort?: Array<string>, auditLogSearchDto?: AuditLogSearchDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageAuditLogDto>> {
            const localVarAxiosArgs = await AuditLogApiAxiosParamCreator(configuration).auditLogSearch(page, size, sort, auditLogSearchDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AuditLogApi - factory interface
 * @export
 */
export const AuditLogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Creates new audit log
         * @param {AuditLogDto} auditLogDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditLogCreate(auditLogDto: AuditLogDto, options?: any): AxiosPromise<AuditLogDto> {
            return AuditLogApiFp(configuration).auditLogCreate(auditLogDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all audit logs
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditLogFindAll(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageAuditLogDto> {
            return AuditLogApiFp(configuration).auditLogFindAll(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {AuditLogSearchDto} [auditLogSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditLogSearch(page?: number, size?: number, sort?: Array<string>, auditLogSearchDto?: AuditLogSearchDto, options?: any): AxiosPromise<PageAuditLogDto> {
            return AuditLogApiFp(configuration).auditLogSearch(page, size, sort, auditLogSearchDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuditLogApi - object-oriented interface
 * @export
 * @class AuditLogApi
 * @extends {BaseAPI}
 */
export class AuditLogApi extends BaseAPI {
    /**
     * 
     * @summary Creates new audit log
     * @param {AuditLogDto} auditLogDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditLogApi
     */
    public auditLogCreate(auditLogDto: AuditLogDto, options?: any) {
        return AuditLogApiFp(this.configuration).auditLogCreate(auditLogDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all audit logs
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditLogApi
     */
    public auditLogFindAll(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return AuditLogApiFp(this.configuration).auditLogFindAll(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {AuditLogSearchDto} [auditLogSearchDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditLogApi
     */
    public auditLogSearch(page?: number, size?: number, sort?: Array<string>, auditLogSearchDto?: AuditLogSearchDto, options?: any) {
        return AuditLogApiFp(this.configuration).auditLogSearch(page, size, sort, auditLogSearchDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AutocompleteApi - axios parameter creator
 * @export
 */
export const AutocompleteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} name 
         * @param {'CONTAINER' | 'SEMITRAILER' | 'SHIPPING_CONTAINER' | 'CARRIER' | 'DRIVER' | 'LICENSE_PLATE' | 'FREE_DRIVER'} autocompleteType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autocompleteFind: async (name: string, autocompleteType: 'CONTAINER' | 'SEMITRAILER' | 'SHIPPING_CONTAINER' | 'CARRIER' | 'DRIVER' | 'LICENSE_PLATE' | 'FREE_DRIVER', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling autocompleteFind.');
            }
            // verify required parameter 'autocompleteType' is not null or undefined
            if (autocompleteType === null || autocompleteType === undefined) {
                throw new RequiredError('autocompleteType','Required parameter autocompleteType was null or undefined when calling autocompleteFind.');
            }
            const localVarPath = `/autocomplete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (autocompleteType !== undefined) {
                localVarQueryParameter['autocompleteType'] = autocompleteType;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AutocompleteApi - functional programming interface
 * @export
 */
export const AutocompleteApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} name 
         * @param {'CONTAINER' | 'SEMITRAILER' | 'SHIPPING_CONTAINER' | 'CARRIER' | 'DRIVER' | 'LICENSE_PLATE' | 'FREE_DRIVER'} autocompleteType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async autocompleteFind(name: string, autocompleteType: 'CONTAINER' | 'SEMITRAILER' | 'SHIPPING_CONTAINER' | 'CARRIER' | 'DRIVER' | 'LICENSE_PLATE' | 'FREE_DRIVER', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AutocompleteDto>>> {
            const localVarAxiosArgs = await AutocompleteApiAxiosParamCreator(configuration).autocompleteFind(name, autocompleteType, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AutocompleteApi - factory interface
 * @export
 */
export const AutocompleteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} name 
         * @param {'CONTAINER' | 'SEMITRAILER' | 'SHIPPING_CONTAINER' | 'CARRIER' | 'DRIVER' | 'LICENSE_PLATE' | 'FREE_DRIVER'} autocompleteType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autocompleteFind(name: string, autocompleteType: 'CONTAINER' | 'SEMITRAILER' | 'SHIPPING_CONTAINER' | 'CARRIER' | 'DRIVER' | 'LICENSE_PLATE' | 'FREE_DRIVER', options?: any): AxiosPromise<Array<AutocompleteDto>> {
            return AutocompleteApiFp(configuration).autocompleteFind(name, autocompleteType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AutocompleteApi - object-oriented interface
 * @export
 * @class AutocompleteApi
 * @extends {BaseAPI}
 */
export class AutocompleteApi extends BaseAPI {
    /**
     * 
     * @param {string} name 
     * @param {'CONTAINER' | 'SEMITRAILER' | 'SHIPPING_CONTAINER' | 'CARRIER' | 'DRIVER' | 'LICENSE_PLATE' | 'FREE_DRIVER'} autocompleteType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutocompleteApi
     */
    public autocompleteFind(name: string, autocompleteType: 'CONTAINER' | 'SEMITRAILER' | 'SHIPPING_CONTAINER' | 'CARRIER' | 'DRIVER' | 'LICENSE_PLATE' | 'FREE_DRIVER', options?: any) {
        return AutocompleteApiFp(this.configuration).autocompleteFind(name, autocompleteType, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CameraControllerApi - axios parameter creator
 * @export
 */
export const CameraControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Accepts train camera output and updates system
         * @param {M2mTrainDto} m2mTrainDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainCamera: async (m2mTrainDto: M2mTrainDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'm2mTrainDto' is not null or undefined
            if (m2mTrainDto === null || m2mTrainDto === undefined) {
                throw new RequiredError('m2mTrainDto','Required parameter m2mTrainDto was null or undefined when calling trainCamera.');
            }
            const localVarPath = `/camera/train`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof m2mTrainDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(m2mTrainDto !== undefined ? m2mTrainDto : {})
                : (m2mTrainDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Accepts truck camera output and updates system
         * @param {CameraTruckDto} cameraTruckDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        truckCamera: async (cameraTruckDto: CameraTruckDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cameraTruckDto' is not null or undefined
            if (cameraTruckDto === null || cameraTruckDto === undefined) {
                throw new RequiredError('cameraTruckDto','Required parameter cameraTruckDto was null or undefined when calling truckCamera.');
            }
            const localVarPath = `/camera/truck`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof cameraTruckDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(cameraTruckDto !== undefined ? cameraTruckDto : {})
                : (cameraTruckDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CameraControllerApi - functional programming interface
 * @export
 */
export const CameraControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Accepts train camera output and updates system
         * @param {M2mTrainDto} m2mTrainDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainCamera(m2mTrainDto: M2mTrainDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CameraControllerApiAxiosParamCreator(configuration).trainCamera(m2mTrainDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Accepts truck camera output and updates system
         * @param {CameraTruckDto} cameraTruckDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async truckCamera(cameraTruckDto: CameraTruckDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CameraControllerApiAxiosParamCreator(configuration).truckCamera(cameraTruckDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CameraControllerApi - factory interface
 * @export
 */
export const CameraControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Accepts train camera output and updates system
         * @param {M2mTrainDto} m2mTrainDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainCamera(m2mTrainDto: M2mTrainDto, options?: any): AxiosPromise<void> {
            return CameraControllerApiFp(configuration).trainCamera(m2mTrainDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Accepts truck camera output and updates system
         * @param {CameraTruckDto} cameraTruckDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        truckCamera(cameraTruckDto: CameraTruckDto, options?: any): AxiosPromise<void> {
            return CameraControllerApiFp(configuration).truckCamera(cameraTruckDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CameraControllerApi - object-oriented interface
 * @export
 * @class CameraControllerApi
 * @extends {BaseAPI}
 */
export class CameraControllerApi extends BaseAPI {
    /**
     * 
     * @summary Accepts train camera output and updates system
     * @param {M2mTrainDto} m2mTrainDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraControllerApi
     */
    public trainCamera(m2mTrainDto: M2mTrainDto, options?: any) {
        return CameraControllerApiFp(this.configuration).trainCamera(m2mTrainDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Accepts truck camera output and updates system
     * @param {CameraTruckDto} cameraTruckDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraControllerApi
     */
    public truckCamera(cameraTruckDto: CameraTruckDto, options?: any) {
        return CameraControllerApiFp(this.configuration).truckCamera(cameraTruckDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CardApi - axios parameter creator
 * @export
 */
export const CardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates new card
         * @param {AsparkingCardDto} asparkingCardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cardCreate: async (asparkingCardDto: AsparkingCardDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'asparkingCardDto' is not null or undefined
            if (asparkingCardDto === null || asparkingCardDto === undefined) {
                throw new RequiredError('asparkingCardDto','Required parameter asparkingCardDto was null or undefined when calling cardCreate.');
            }
            const localVarPath = `/card`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof asparkingCardDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(asparkingCardDto !== undefined ? asparkingCardDto : {})
                : (asparkingCardDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete card
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cardDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling cardDelete.');
            }
            const localVarPath = `/card/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all cards
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cardFindAll: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/card`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find card by its id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cardFindById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling cardFindById.');
            }
            const localVarPath = `/card/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {AsparkingCardSearchDto} [asparkingCardSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cardSearch: async (page?: number, size?: number, sort?: Array<string>, asparkingCardSearchDto?: AsparkingCardSearchDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/card/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof asparkingCardSearchDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(asparkingCardSearchDto !== undefined ? asparkingCardSearchDto : {})
                : (asparkingCardSearchDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates existing card
         * @param {number} id 
         * @param {AsparkingCardDto} asparkingCardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cardUpdate: async (id: number, asparkingCardDto: AsparkingCardDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling cardUpdate.');
            }
            // verify required parameter 'asparkingCardDto' is not null or undefined
            if (asparkingCardDto === null || asparkingCardDto === undefined) {
                throw new RequiredError('asparkingCardDto','Required parameter asparkingCardDto was null or undefined when calling cardUpdate.');
            }
            const localVarPath = `/card/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof asparkingCardDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(asparkingCardDto !== undefined ? asparkingCardDto : {})
                : (asparkingCardDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CardApi - functional programming interface
 * @export
 */
export const CardApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates new card
         * @param {AsparkingCardDto} asparkingCardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cardCreate(asparkingCardDto: AsparkingCardDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AsparkingCardDto>> {
            const localVarAxiosArgs = await CardApiAxiosParamCreator(configuration).cardCreate(asparkingCardDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete card
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cardDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CardApiAxiosParamCreator(configuration).cardDelete(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Find all cards
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cardFindAll(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageAsparkingCardDto>> {
            const localVarAxiosArgs = await CardApiAxiosParamCreator(configuration).cardFindAll(page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Find card by its id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cardFindById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AsparkingCardDto>> {
            const localVarAxiosArgs = await CardApiAxiosParamCreator(configuration).cardFindById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {AsparkingCardSearchDto} [asparkingCardSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cardSearch(page?: number, size?: number, sort?: Array<string>, asparkingCardSearchDto?: AsparkingCardSearchDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageAsparkingCardDto>> {
            const localVarAxiosArgs = await CardApiAxiosParamCreator(configuration).cardSearch(page, size, sort, asparkingCardSearchDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates existing card
         * @param {number} id 
         * @param {AsparkingCardDto} asparkingCardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cardUpdate(id: number, asparkingCardDto: AsparkingCardDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AsparkingCardDto>> {
            const localVarAxiosArgs = await CardApiAxiosParamCreator(configuration).cardUpdate(id, asparkingCardDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CardApi - factory interface
 * @export
 */
export const CardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Creates new card
         * @param {AsparkingCardDto} asparkingCardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cardCreate(asparkingCardDto: AsparkingCardDto, options?: any): AxiosPromise<AsparkingCardDto> {
            return CardApiFp(configuration).cardCreate(asparkingCardDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete card
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cardDelete(id: number, options?: any): AxiosPromise<void> {
            return CardApiFp(configuration).cardDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all cards
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cardFindAll(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageAsparkingCardDto> {
            return CardApiFp(configuration).cardFindAll(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find card by its id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cardFindById(id: number, options?: any): AxiosPromise<AsparkingCardDto> {
            return CardApiFp(configuration).cardFindById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {AsparkingCardSearchDto} [asparkingCardSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cardSearch(page?: number, size?: number, sort?: Array<string>, asparkingCardSearchDto?: AsparkingCardSearchDto, options?: any): AxiosPromise<PageAsparkingCardDto> {
            return CardApiFp(configuration).cardSearch(page, size, sort, asparkingCardSearchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates existing card
         * @param {number} id 
         * @param {AsparkingCardDto} asparkingCardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cardUpdate(id: number, asparkingCardDto: AsparkingCardDto, options?: any): AxiosPromise<AsparkingCardDto> {
            return CardApiFp(configuration).cardUpdate(id, asparkingCardDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CardApi - object-oriented interface
 * @export
 * @class CardApi
 * @extends {BaseAPI}
 */
export class CardApi extends BaseAPI {
    /**
     * 
     * @summary Creates new card
     * @param {AsparkingCardDto} asparkingCardDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardApi
     */
    public cardCreate(asparkingCardDto: AsparkingCardDto, options?: any) {
        return CardApiFp(this.configuration).cardCreate(asparkingCardDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete card
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardApi
     */
    public cardDelete(id: number, options?: any) {
        return CardApiFp(this.configuration).cardDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all cards
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardApi
     */
    public cardFindAll(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return CardApiFp(this.configuration).cardFindAll(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find card by its id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardApi
     */
    public cardFindById(id: number, options?: any) {
        return CardApiFp(this.configuration).cardFindById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {AsparkingCardSearchDto} [asparkingCardSearchDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardApi
     */
    public cardSearch(page?: number, size?: number, sort?: Array<string>, asparkingCardSearchDto?: AsparkingCardSearchDto, options?: any) {
        return CardApiFp(this.configuration).cardSearch(page, size, sort, asparkingCardSearchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates existing card
     * @param {number} id 
     * @param {AsparkingCardDto} asparkingCardDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardApi
     */
    public cardUpdate(id: number, asparkingCardDto: AsparkingCardDto, options?: any) {
        return CardApiFp(this.configuration).cardUpdate(id, asparkingCardDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CarrierApi - axios parameter creator
 * @export
 */
export const CarrierApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Assign driver to carrier.
         * @param {number} carrierId 
         * @param {number} driverId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carrierAssignCarrierDriver: async (carrierId: number, driverId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'carrierId' is not null or undefined
            if (carrierId === null || carrierId === undefined) {
                throw new RequiredError('carrierId','Required parameter carrierId was null or undefined when calling carrierAssignCarrierDriver.');
            }
            // verify required parameter 'driverId' is not null or undefined
            if (driverId === null || driverId === undefined) {
                throw new RequiredError('driverId','Required parameter driverId was null or undefined when calling carrierAssignCarrierDriver.');
            }
            const localVarPath = `/carrier/{carrierId}/driver/{driverId}`
                .replace(`{${"carrierId"}}`, encodeURIComponent(String(carrierId)))
                .replace(`{${"driverId"}}`, encodeURIComponent(String(driverId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new carrier
         * @param {CarrierDto} carrierDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carrierCreate: async (carrierDto: CarrierDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'carrierDto' is not null or undefined
            if (carrierDto === null || carrierDto === undefined) {
                throw new RequiredError('carrierDto','Required parameter carrierDto was null or undefined when calling carrierCreate.');
            }
            const localVarPath = `/carrier`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof carrierDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(carrierDto !== undefined ? carrierDto : {})
                : (carrierDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete carrier
         * @param {number} id Id of carrier to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carrierDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling carrierDelete.');
            }
            const localVarPath = `/carrier/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Disable carrier
         * @param {number} id Id of carrier to be disabled
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carrierDisableCardsAndLicensePlates: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling carrierDisableCardsAndLicensePlates.');
            }
            const localVarPath = `/carrier/disable-carrier/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CarrierSearchDto} [carrierSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carrierDownloadExport: async (carrierSearchDto?: CarrierSearchDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/carrier/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof carrierSearchDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(carrierSearchDto !== undefined ? carrierSearchDto : {})
                : (carrierSearchDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all carriers
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carrierFindAll: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/carrier`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find a carrier by its id.
         * @param {number} id Id of carrier to be found
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carrierFindById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling carrierFindById.');
            }
            const localVarPath = `/carrier/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get carrier drivers
         * @param {number} carrierId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carrierGetCarrierDrivers: async (carrierId: number, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'carrierId' is not null or undefined
            if (carrierId === null || carrierId === undefined) {
                throw new RequiredError('carrierId','Required parameter carrierId was null or undefined when calling carrierGetCarrierDrivers.');
            }
            const localVarPath = `/carrier/{carrierId}/drivers`
                .replace(`{${"carrierId"}}`, encodeURIComponent(String(carrierId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  Finds current carrier for logged user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carrierGetCurrentCarrier: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/carrier/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets search dto of logged user (carrier)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carrierGetSearchDtoOfCurrentCarrier: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/carrier/current/search-dto`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Merges carriers by updating references to the replaced one with the carrierId and deleting the replaced carrier
         * @param {number} carrierId 
         * @param {number} replacedCarrierId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carrierMergeCarriers: async (carrierId: number, replacedCarrierId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'carrierId' is not null or undefined
            if (carrierId === null || carrierId === undefined) {
                throw new RequiredError('carrierId','Required parameter carrierId was null or undefined when calling carrierMergeCarriers.');
            }
            // verify required parameter 'replacedCarrierId' is not null or undefined
            if (replacedCarrierId === null || replacedCarrierId === undefined) {
                throw new RequiredError('replacedCarrierId','Required parameter replacedCarrierId was null or undefined when calling carrierMergeCarriers.');
            }
            const localVarPath = `/carrier/merge-carriers/{carrierId}/{replacedCarrierId}`
                .replace(`{${"carrierId"}}`, encodeURIComponent(String(carrierId)))
                .replace(`{${"replacedCarrierId"}}`, encodeURIComponent(String(replacedCarrierId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove driver from carrier.
         * @param {number} carrierId 
         * @param {number} driverId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carrierRemoveCarrierDriver: async (carrierId: number, driverId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'carrierId' is not null or undefined
            if (carrierId === null || carrierId === undefined) {
                throw new RequiredError('carrierId','Required parameter carrierId was null or undefined when calling carrierRemoveCarrierDriver.');
            }
            // verify required parameter 'driverId' is not null or undefined
            if (driverId === null || driverId === undefined) {
                throw new RequiredError('driverId','Required parameter driverId was null or undefined when calling carrierRemoveCarrierDriver.');
            }
            const localVarPath = `/carrier/{carrierId}/driver/{driverId}`
                .replace(`{${"carrierId"}}`, encodeURIComponent(String(carrierId)))
                .replace(`{${"driverId"}}`, encodeURIComponent(String(driverId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {CarrierSearchDto} [carrierSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carrierSearch: async (page?: number, size?: number, sort?: Array<string>, carrierSearchDto?: CarrierSearchDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/carrier/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof carrierSearchDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(carrierSearchDto !== undefined ? carrierSearchDto : {})
                : (carrierSearchDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update carrier
         * @param {number} id Id of carrier to be updated
         * @param {CarrierDto} carrierDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carrierUpdate: async (id: number, carrierDto: CarrierDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling carrierUpdate.');
            }
            // verify required parameter 'carrierDto' is not null or undefined
            if (carrierDto === null || carrierDto === undefined) {
                throw new RequiredError('carrierDto','Required parameter carrierDto was null or undefined when calling carrierUpdate.');
            }
            const localVarPath = `/carrier/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof carrierDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(carrierDto !== undefined ? carrierDto : {})
                : (carrierDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CarrierApi - functional programming interface
 * @export
 */
export const CarrierApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Assign driver to carrier.
         * @param {number} carrierId 
         * @param {number} driverId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async carrierAssignCarrierDriver(carrierId: number, driverId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CarrierApiAxiosParamCreator(configuration).carrierAssignCarrierDriver(carrierId, driverId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create new carrier
         * @param {CarrierDto} carrierDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async carrierCreate(carrierDto: CarrierDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CarrierDto>> {
            const localVarAxiosArgs = await CarrierApiAxiosParamCreator(configuration).carrierCreate(carrierDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete carrier
         * @param {number} id Id of carrier to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async carrierDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CarrierApiAxiosParamCreator(configuration).carrierDelete(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Disable carrier
         * @param {number} id Id of carrier to be disabled
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async carrierDisableCardsAndLicensePlates(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CarrierApiAxiosParamCreator(configuration).carrierDisableCardsAndLicensePlates(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CarrierSearchDto} [carrierSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async carrierDownloadExport(carrierSearchDto?: CarrierSearchDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await CarrierApiAxiosParamCreator(configuration).carrierDownloadExport(carrierSearchDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Find all carriers
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async carrierFindAll(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageCarrierDto>> {
            const localVarAxiosArgs = await CarrierApiAxiosParamCreator(configuration).carrierFindAll(page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Find a carrier by its id.
         * @param {number} id Id of carrier to be found
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async carrierFindById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CarrierDto>> {
            const localVarAxiosArgs = await CarrierApiAxiosParamCreator(configuration).carrierFindById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get carrier drivers
         * @param {number} carrierId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async carrierGetCarrierDrivers(carrierId: number, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDriverDto>> {
            const localVarAxiosArgs = await CarrierApiAxiosParamCreator(configuration).carrierGetCarrierDrivers(carrierId, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary  Finds current carrier for logged user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async carrierGetCurrentCarrier(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CarrierDto>> {
            const localVarAxiosArgs = await CarrierApiAxiosParamCreator(configuration).carrierGetCurrentCarrier(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets search dto of logged user (carrier)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async carrierGetSearchDtoOfCurrentCarrier(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransitionSearchListSearchDto>> {
            const localVarAxiosArgs = await CarrierApiAxiosParamCreator(configuration).carrierGetSearchDtoOfCurrentCarrier(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Merges carriers by updating references to the replaced one with the carrierId and deleting the replaced carrier
         * @param {number} carrierId 
         * @param {number} replacedCarrierId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async carrierMergeCarriers(carrierId: number, replacedCarrierId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CarrierApiAxiosParamCreator(configuration).carrierMergeCarriers(carrierId, replacedCarrierId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Remove driver from carrier.
         * @param {number} carrierId 
         * @param {number} driverId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async carrierRemoveCarrierDriver(carrierId: number, driverId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CarrierApiAxiosParamCreator(configuration).carrierRemoveCarrierDriver(carrierId, driverId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {CarrierSearchDto} [carrierSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async carrierSearch(page?: number, size?: number, sort?: Array<string>, carrierSearchDto?: CarrierSearchDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageCarrierDto>> {
            const localVarAxiosArgs = await CarrierApiAxiosParamCreator(configuration).carrierSearch(page, size, sort, carrierSearchDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update carrier
         * @param {number} id Id of carrier to be updated
         * @param {CarrierDto} carrierDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async carrierUpdate(id: number, carrierDto: CarrierDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CarrierDto>> {
            const localVarAxiosArgs = await CarrierApiAxiosParamCreator(configuration).carrierUpdate(id, carrierDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CarrierApi - factory interface
 * @export
 */
export const CarrierApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Assign driver to carrier.
         * @param {number} carrierId 
         * @param {number} driverId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carrierAssignCarrierDriver(carrierId: number, driverId: number, options?: any): AxiosPromise<void> {
            return CarrierApiFp(configuration).carrierAssignCarrierDriver(carrierId, driverId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new carrier
         * @param {CarrierDto} carrierDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carrierCreate(carrierDto: CarrierDto, options?: any): AxiosPromise<CarrierDto> {
            return CarrierApiFp(configuration).carrierCreate(carrierDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete carrier
         * @param {number} id Id of carrier to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carrierDelete(id: number, options?: any): AxiosPromise<void> {
            return CarrierApiFp(configuration).carrierDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Disable carrier
         * @param {number} id Id of carrier to be disabled
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carrierDisableCardsAndLicensePlates(id: number, options?: any): AxiosPromise<void> {
            return CarrierApiFp(configuration).carrierDisableCardsAndLicensePlates(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CarrierSearchDto} [carrierSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carrierDownloadExport(carrierSearchDto?: CarrierSearchDto, options?: any): AxiosPromise<Array<string>> {
            return CarrierApiFp(configuration).carrierDownloadExport(carrierSearchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all carriers
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carrierFindAll(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageCarrierDto> {
            return CarrierApiFp(configuration).carrierFindAll(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find a carrier by its id.
         * @param {number} id Id of carrier to be found
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carrierFindById(id: number, options?: any): AxiosPromise<CarrierDto> {
            return CarrierApiFp(configuration).carrierFindById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get carrier drivers
         * @param {number} carrierId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carrierGetCarrierDrivers(carrierId: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageDriverDto> {
            return CarrierApiFp(configuration).carrierGetCarrierDrivers(carrierId, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  Finds current carrier for logged user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carrierGetCurrentCarrier(options?: any): AxiosPromise<CarrierDto> {
            return CarrierApiFp(configuration).carrierGetCurrentCarrier(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets search dto of logged user (carrier)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carrierGetSearchDtoOfCurrentCarrier(options?: any): AxiosPromise<TransitionSearchListSearchDto> {
            return CarrierApiFp(configuration).carrierGetSearchDtoOfCurrentCarrier(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Merges carriers by updating references to the replaced one with the carrierId and deleting the replaced carrier
         * @param {number} carrierId 
         * @param {number} replacedCarrierId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carrierMergeCarriers(carrierId: number, replacedCarrierId: number, options?: any): AxiosPromise<void> {
            return CarrierApiFp(configuration).carrierMergeCarriers(carrierId, replacedCarrierId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove driver from carrier.
         * @param {number} carrierId 
         * @param {number} driverId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carrierRemoveCarrierDriver(carrierId: number, driverId: number, options?: any): AxiosPromise<void> {
            return CarrierApiFp(configuration).carrierRemoveCarrierDriver(carrierId, driverId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {CarrierSearchDto} [carrierSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carrierSearch(page?: number, size?: number, sort?: Array<string>, carrierSearchDto?: CarrierSearchDto, options?: any): AxiosPromise<PageCarrierDto> {
            return CarrierApiFp(configuration).carrierSearch(page, size, sort, carrierSearchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update carrier
         * @param {number} id Id of carrier to be updated
         * @param {CarrierDto} carrierDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carrierUpdate(id: number, carrierDto: CarrierDto, options?: any): AxiosPromise<CarrierDto> {
            return CarrierApiFp(configuration).carrierUpdate(id, carrierDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CarrierApi - object-oriented interface
 * @export
 * @class CarrierApi
 * @extends {BaseAPI}
 */
export class CarrierApi extends BaseAPI {
    /**
     * 
     * @summary Assign driver to carrier.
     * @param {number} carrierId 
     * @param {number} driverId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarrierApi
     */
    public carrierAssignCarrierDriver(carrierId: number, driverId: number, options?: any) {
        return CarrierApiFp(this.configuration).carrierAssignCarrierDriver(carrierId, driverId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new carrier
     * @param {CarrierDto} carrierDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarrierApi
     */
    public carrierCreate(carrierDto: CarrierDto, options?: any) {
        return CarrierApiFp(this.configuration).carrierCreate(carrierDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete carrier
     * @param {number} id Id of carrier to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarrierApi
     */
    public carrierDelete(id: number, options?: any) {
        return CarrierApiFp(this.configuration).carrierDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Disable carrier
     * @param {number} id Id of carrier to be disabled
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarrierApi
     */
    public carrierDisableCardsAndLicensePlates(id: number, options?: any) {
        return CarrierApiFp(this.configuration).carrierDisableCardsAndLicensePlates(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CarrierSearchDto} [carrierSearchDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarrierApi
     */
    public carrierDownloadExport(carrierSearchDto?: CarrierSearchDto, options?: any) {
        return CarrierApiFp(this.configuration).carrierDownloadExport(carrierSearchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all carriers
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarrierApi
     */
    public carrierFindAll(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return CarrierApiFp(this.configuration).carrierFindAll(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find a carrier by its id.
     * @param {number} id Id of carrier to be found
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarrierApi
     */
    public carrierFindById(id: number, options?: any) {
        return CarrierApiFp(this.configuration).carrierFindById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get carrier drivers
     * @param {number} carrierId 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarrierApi
     */
    public carrierGetCarrierDrivers(carrierId: number, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return CarrierApiFp(this.configuration).carrierGetCarrierDrivers(carrierId, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  Finds current carrier for logged user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarrierApi
     */
    public carrierGetCurrentCarrier(options?: any) {
        return CarrierApiFp(this.configuration).carrierGetCurrentCarrier(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets search dto of logged user (carrier)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarrierApi
     */
    public carrierGetSearchDtoOfCurrentCarrier(options?: any) {
        return CarrierApiFp(this.configuration).carrierGetSearchDtoOfCurrentCarrier(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Merges carriers by updating references to the replaced one with the carrierId and deleting the replaced carrier
     * @param {number} carrierId 
     * @param {number} replacedCarrierId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarrierApi
     */
    public carrierMergeCarriers(carrierId: number, replacedCarrierId: number, options?: any) {
        return CarrierApiFp(this.configuration).carrierMergeCarriers(carrierId, replacedCarrierId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove driver from carrier.
     * @param {number} carrierId 
     * @param {number} driverId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarrierApi
     */
    public carrierRemoveCarrierDriver(carrierId: number, driverId: number, options?: any) {
        return CarrierApiFp(this.configuration).carrierRemoveCarrierDriver(carrierId, driverId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {CarrierSearchDto} [carrierSearchDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarrierApi
     */
    public carrierSearch(page?: number, size?: number, sort?: Array<string>, carrierSearchDto?: CarrierSearchDto, options?: any) {
        return CarrierApiFp(this.configuration).carrierSearch(page, size, sort, carrierSearchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update carrier
     * @param {number} id Id of carrier to be updated
     * @param {CarrierDto} carrierDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarrierApi
     */
    public carrierUpdate(id: number, carrierDto: CarrierDto, options?: any) {
        return CarrierApiFp(this.configuration).carrierUpdate(id, carrierDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CdCargoApi - axios parameter creator
 * @export
 */
export const CdCargoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create cd cargo template
         * @param {CdCargoTemplateDto} cdCargoTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cdCargoCreate: async (cdCargoTemplateDto: CdCargoTemplateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cdCargoTemplateDto' is not null or undefined
            if (cdCargoTemplateDto === null || cdCargoTemplateDto === undefined) {
                throw new RequiredError('cdCargoTemplateDto','Required parameter cdCargoTemplateDto was null or undefined when calling cdCargoCreate.');
            }
            const localVarPath = `/cd-cargo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof cdCargoTemplateDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(cdCargoTemplateDto !== undefined ? cdCargoTemplateDto : {})
                : (cdCargoTemplateDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all cd cargo templates
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cdCargoFindAll: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/cd-cargo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find one cd cargo template
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cdCargoFindById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling cdCargoFindById.');
            }
            const localVarPath = `/cd-cargo/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update cd cargo template
         * @param {number} id 
         * @param {CdCargoTemplateDto} cdCargoTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cdCargoUpdate: async (id: number, cdCargoTemplateDto: CdCargoTemplateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling cdCargoUpdate.');
            }
            // verify required parameter 'cdCargoTemplateDto' is not null or undefined
            if (cdCargoTemplateDto === null || cdCargoTemplateDto === undefined) {
                throw new RequiredError('cdCargoTemplateDto','Required parameter cdCargoTemplateDto was null or undefined when calling cdCargoUpdate.');
            }
            const localVarPath = `/cd-cargo/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof cdCargoTemplateDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(cdCargoTemplateDto !== undefined ? cdCargoTemplateDto : {})
                : (cdCargoTemplateDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CdCargoApi - functional programming interface
 * @export
 */
export const CdCargoApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create cd cargo template
         * @param {CdCargoTemplateDto} cdCargoTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cdCargoCreate(cdCargoTemplateDto: CdCargoTemplateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CdCargoTemplateDto>> {
            const localVarAxiosArgs = await CdCargoApiAxiosParamCreator(configuration).cdCargoCreate(cdCargoTemplateDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Find all cd cargo templates
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cdCargoFindAll(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageCdCargoTemplateDto>> {
            const localVarAxiosArgs = await CdCargoApiAxiosParamCreator(configuration).cdCargoFindAll(page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Find one cd cargo template
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cdCargoFindById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CdCargoTemplateDto>> {
            const localVarAxiosArgs = await CdCargoApiAxiosParamCreator(configuration).cdCargoFindById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update cd cargo template
         * @param {number} id 
         * @param {CdCargoTemplateDto} cdCargoTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cdCargoUpdate(id: number, cdCargoTemplateDto: CdCargoTemplateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CdCargoTemplateDto>> {
            const localVarAxiosArgs = await CdCargoApiAxiosParamCreator(configuration).cdCargoUpdate(id, cdCargoTemplateDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CdCargoApi - factory interface
 * @export
 */
export const CdCargoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create cd cargo template
         * @param {CdCargoTemplateDto} cdCargoTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cdCargoCreate(cdCargoTemplateDto: CdCargoTemplateDto, options?: any): AxiosPromise<CdCargoTemplateDto> {
            return CdCargoApiFp(configuration).cdCargoCreate(cdCargoTemplateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all cd cargo templates
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cdCargoFindAll(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageCdCargoTemplateDto> {
            return CdCargoApiFp(configuration).cdCargoFindAll(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find one cd cargo template
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cdCargoFindById(id: number, options?: any): AxiosPromise<CdCargoTemplateDto> {
            return CdCargoApiFp(configuration).cdCargoFindById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update cd cargo template
         * @param {number} id 
         * @param {CdCargoTemplateDto} cdCargoTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cdCargoUpdate(id: number, cdCargoTemplateDto: CdCargoTemplateDto, options?: any): AxiosPromise<CdCargoTemplateDto> {
            return CdCargoApiFp(configuration).cdCargoUpdate(id, cdCargoTemplateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CdCargoApi - object-oriented interface
 * @export
 * @class CdCargoApi
 * @extends {BaseAPI}
 */
export class CdCargoApi extends BaseAPI {
    /**
     * 
     * @summary Create cd cargo template
     * @param {CdCargoTemplateDto} cdCargoTemplateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CdCargoApi
     */
    public cdCargoCreate(cdCargoTemplateDto: CdCargoTemplateDto, options?: any) {
        return CdCargoApiFp(this.configuration).cdCargoCreate(cdCargoTemplateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all cd cargo templates
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CdCargoApi
     */
    public cdCargoFindAll(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return CdCargoApiFp(this.configuration).cdCargoFindAll(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find one cd cargo template
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CdCargoApi
     */
    public cdCargoFindById(id: number, options?: any) {
        return CdCargoApiFp(this.configuration).cdCargoFindById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update cd cargo template
     * @param {number} id 
     * @param {CdCargoTemplateDto} cdCargoTemplateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CdCargoApi
     */
    public cdCargoUpdate(id: number, cdCargoTemplateDto: CdCargoTemplateDto, options?: any) {
        return CdCargoApiFp(this.configuration).cdCargoUpdate(id, cdCargoTemplateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompanyApi - axios parameter creator
 * @export
 */
export const CompanyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new company
         * @param {CompanyDto} companyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyCreate: async (companyDto: CompanyDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyDto' is not null or undefined
            if (companyDto === null || companyDto === undefined) {
                throw new RequiredError('companyDto','Required parameter companyDto was null or undefined when calling companyCreate.');
            }
            const localVarPath = `/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof companyDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(companyDto !== undefined ? companyDto : {})
                : (companyDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete company
         * @param {number} id Id of company to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling companyDelete.');
            }
            const localVarPath = `/company/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all companies
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyFindAll: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find a company by its id.
         * @param {number} id Id of company to be found
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyFindById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling companyFindById.');
            }
            const localVarPath = `/company/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {CompanySearchDto} [companySearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companySearch: async (page?: number, size?: number, sort?: Array<string>, companySearchDto?: CompanySearchDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/company/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof companySearchDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(companySearchDto !== undefined ? companySearchDto : {})
                : (companySearchDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update company
         * @param {number} id Id of company to be updated
         * @param {CompanyDto} companyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyUpdate: async (id: number, companyDto: CompanyDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling companyUpdate.');
            }
            // verify required parameter 'companyDto' is not null or undefined
            if (companyDto === null || companyDto === undefined) {
                throw new RequiredError('companyDto','Required parameter companyDto was null or undefined when calling companyUpdate.');
            }
            const localVarPath = `/company/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof companyDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(companyDto !== undefined ? companyDto : {})
                : (companyDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyApi - functional programming interface
 * @export
 */
export const CompanyApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new company
         * @param {CompanyDto} companyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyCreate(companyDto: CompanyDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyDto>> {
            const localVarAxiosArgs = await CompanyApiAxiosParamCreator(configuration).companyCreate(companyDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete company
         * @param {number} id Id of company to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CompanyApiAxiosParamCreator(configuration).companyDelete(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Find all companies
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyFindAll(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageCompanyDto>> {
            const localVarAxiosArgs = await CompanyApiAxiosParamCreator(configuration).companyFindAll(page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Find a company by its id.
         * @param {number} id Id of company to be found
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyFindById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyDto>> {
            const localVarAxiosArgs = await CompanyApiAxiosParamCreator(configuration).companyFindById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {CompanySearchDto} [companySearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companySearch(page?: number, size?: number, sort?: Array<string>, companySearchDto?: CompanySearchDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageCompanyDto>> {
            const localVarAxiosArgs = await CompanyApiAxiosParamCreator(configuration).companySearch(page, size, sort, companySearchDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update company
         * @param {number} id Id of company to be updated
         * @param {CompanyDto} companyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyUpdate(id: number, companyDto: CompanyDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyDto>> {
            const localVarAxiosArgs = await CompanyApiAxiosParamCreator(configuration).companyUpdate(id, companyDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CompanyApi - factory interface
 * @export
 */
export const CompanyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create new company
         * @param {CompanyDto} companyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyCreate(companyDto: CompanyDto, options?: any): AxiosPromise<CompanyDto> {
            return CompanyApiFp(configuration).companyCreate(companyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete company
         * @param {number} id Id of company to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyDelete(id: number, options?: any): AxiosPromise<void> {
            return CompanyApiFp(configuration).companyDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all companies
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyFindAll(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageCompanyDto> {
            return CompanyApiFp(configuration).companyFindAll(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find a company by its id.
         * @param {number} id Id of company to be found
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyFindById(id: number, options?: any): AxiosPromise<CompanyDto> {
            return CompanyApiFp(configuration).companyFindById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {CompanySearchDto} [companySearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companySearch(page?: number, size?: number, sort?: Array<string>, companySearchDto?: CompanySearchDto, options?: any): AxiosPromise<PageCompanyDto> {
            return CompanyApiFp(configuration).companySearch(page, size, sort, companySearchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update company
         * @param {number} id Id of company to be updated
         * @param {CompanyDto} companyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyUpdate(id: number, companyDto: CompanyDto, options?: any): AxiosPromise<CompanyDto> {
            return CompanyApiFp(configuration).companyUpdate(id, companyDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyApi - object-oriented interface
 * @export
 * @class CompanyApi
 * @extends {BaseAPI}
 */
export class CompanyApi extends BaseAPI {
    /**
     * 
     * @summary Create new company
     * @param {CompanyDto} companyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyCreate(companyDto: CompanyDto, options?: any) {
        return CompanyApiFp(this.configuration).companyCreate(companyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete company
     * @param {number} id Id of company to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyDelete(id: number, options?: any) {
        return CompanyApiFp(this.configuration).companyDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all companies
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyFindAll(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return CompanyApiFp(this.configuration).companyFindAll(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find a company by its id.
     * @param {number} id Id of company to be found
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyFindById(id: number, options?: any) {
        return CompanyApiFp(this.configuration).companyFindById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {CompanySearchDto} [companySearchDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companySearch(page?: number, size?: number, sort?: Array<string>, companySearchDto?: CompanySearchDto, options?: any) {
        return CompanyApiFp(this.configuration).companySearch(page, size, sort, companySearchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update company
     * @param {number} id Id of company to be updated
     * @param {CompanyDto} companyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyUpdate(id: number, companyDto: CompanyDto, options?: any) {
        return CompanyApiFp(this.configuration).companyUpdate(id, companyDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContainerApi - axios parameter creator
 * @export
 */
export const ContainerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete container
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        containerDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling containerDelete.');
            }
            const localVarPath = `/container/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find a container by its id.
         * @param {number} id Id of container to be found
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        containerFindById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling containerFindById.');
            }
            const localVarPath = `/container/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all damages for container
         * @param {number} id Id of container
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        containerGetAllDamages: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling containerGetAllDamages.');
            }
            const localVarPath = `/container/damages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {ContainerSearchDto} [containerSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        containerSearch: async (page?: number, size?: number, sort?: Array<string>, containerSearchDto?: ContainerSearchDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/container/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof containerSearchDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(containerSearchDto !== undefined ? containerSearchDto : {})
                : (containerSearchDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContainerApi - functional programming interface
 * @export
 */
export const ContainerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete container
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async containerDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContainerApiAxiosParamCreator(configuration).containerDelete(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Find a container by its id.
         * @param {number} id Id of container to be found
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async containerFindById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContainerDto>> {
            const localVarAxiosArgs = await ContainerApiAxiosParamCreator(configuration).containerFindById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all damages for container
         * @param {number} id Id of container
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async containerGetAllDamages(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Set<DamageLogEntryDto>>> {
            const localVarAxiosArgs = await ContainerApiAxiosParamCreator(configuration).containerGetAllDamages(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {ContainerSearchDto} [containerSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async containerSearch(page?: number, size?: number, sort?: Array<string>, containerSearchDto?: ContainerSearchDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageContainerDto>> {
            const localVarAxiosArgs = await ContainerApiAxiosParamCreator(configuration).containerSearch(page, size, sort, containerSearchDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ContainerApi - factory interface
 * @export
 */
export const ContainerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Delete container
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        containerDelete(id: number, options?: any): AxiosPromise<void> {
            return ContainerApiFp(configuration).containerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find a container by its id.
         * @param {number} id Id of container to be found
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        containerFindById(id: number, options?: any): AxiosPromise<ContainerDto> {
            return ContainerApiFp(configuration).containerFindById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all damages for container
         * @param {number} id Id of container
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        containerGetAllDamages(id: number, options?: any): AxiosPromise<Set<DamageLogEntryDto>> {
            return ContainerApiFp(configuration).containerGetAllDamages(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {ContainerSearchDto} [containerSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        containerSearch(page?: number, size?: number, sort?: Array<string>, containerSearchDto?: ContainerSearchDto, options?: any): AxiosPromise<PageContainerDto> {
            return ContainerApiFp(configuration).containerSearch(page, size, sort, containerSearchDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContainerApi - object-oriented interface
 * @export
 * @class ContainerApi
 * @extends {BaseAPI}
 */
export class ContainerApi extends BaseAPI {
    /**
     * 
     * @summary Delete container
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContainerApi
     */
    public containerDelete(id: number, options?: any) {
        return ContainerApiFp(this.configuration).containerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find a container by its id.
     * @param {number} id Id of container to be found
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContainerApi
     */
    public containerFindById(id: number, options?: any) {
        return ContainerApiFp(this.configuration).containerFindById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all damages for container
     * @param {number} id Id of container
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContainerApi
     */
    public containerGetAllDamages(id: number, options?: any) {
        return ContainerApiFp(this.configuration).containerGetAllDamages(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {ContainerSearchDto} [containerSearchDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContainerApi
     */
    public containerSearch(page?: number, size?: number, sort?: Array<string>, containerSearchDto?: ContainerSearchDto, options?: any) {
        return ContainerApiFp(this.configuration).containerSearch(page, size, sort, containerSearchDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CoparnApi - axios parameter creator
 * @export
 */
export const CoparnApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel a Coparn.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coparnCancel: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling coparnCancel.');
            }
            const localVarPath = `/coparn/cancel/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a Coparn.
         * @param {CoparnDto} coparnDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coparnCreate: async (coparnDto: CoparnDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'coparnDto' is not null or undefined
            if (coparnDto === null || coparnDto === undefined) {
                throw new RequiredError('coparnDto','Required parameter coparnDto was null or undefined when calling coparnCreate.');
            }
            const localVarPath = `/coparn/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof coparnDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(coparnDto !== undefined ? coparnDto : {})
                : (coparnDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CoparnContainerDto} coparnContainerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coparnCreateCoparnContainerForCoparn: async (coparnContainerDto: CoparnContainerDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'coparnContainerDto' is not null or undefined
            if (coparnContainerDto === null || coparnContainerDto === undefined) {
                throw new RequiredError('coparnContainerDto','Required parameter coparnContainerDto was null or undefined when calling coparnCreateCoparnContainerForCoparn.');
            }
            const localVarPath = `/coparn`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof coparnContainerDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(coparnContainerDto !== undefined ? coparnContainerDto : {})
                : (coparnContainerDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CoparnTruckDto} coparnTruckDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coparnCreateCoparnTruck: async (coparnTruckDto: CoparnTruckDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'coparnTruckDto' is not null or undefined
            if (coparnTruckDto === null || coparnTruckDto === undefined) {
                throw new RequiredError('coparnTruckDto','Required parameter coparnTruckDto was null or undefined when calling coparnCreateCoparnTruck.');
            }
            const localVarPath = `/coparn/coparn-truck`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof coparnTruckDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(coparnTruckDto !== undefined ? coparnTruckDto : {})
                : (coparnTruckDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete coparnTruck
         * @param {number} id Id of coparnTruck to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coparnDeleteCoparnTruck: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling coparnDeleteCoparnTruck.');
            }
            const localVarPath = `/coparn/coparn-truck/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coparnFindAllUnassignedCoparnTrucks: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/coparn/coparn-truck/unassigned`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AvailableContainerDto} availableContainerDto 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coparnGetAllAvailableContainers: async (availableContainerDto: AvailableContainerDto, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'availableContainerDto' is not null or undefined
            if (availableContainerDto === null || availableContainerDto === undefined) {
                throw new RequiredError('availableContainerDto','Required parameter availableContainerDto was null or undefined when calling coparnGetAllAvailableContainers.');
            }
            const localVarPath = `/coparn/available`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof availableContainerDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(availableContainerDto !== undefined ? availableContainerDto : {})
                : (availableContainerDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coparnGetAllCoparnContainerForContainers: async (id: number, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling coparnGetAllCoparnContainerForContainers.');
            }
            const localVarPath = `/coparn/container/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coparnGetAllCoparns: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/coparn`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} coparnId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coparnGetAssignedCoparnContainersForOperator: async (coparnId: number, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'coparnId' is not null or undefined
            if (coparnId === null || coparnId === undefined) {
                throw new RequiredError('coparnId','Required parameter coparnId was null or undefined when calling coparnGetAssignedCoparnContainersForOperator.');
            }
            const localVarPath = `/coparn/operator/{coparnId}`
                .replace(`{${"coparnId"}}`, encodeURIComponent(String(coparnId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {CoparnSearchDto} [coparnSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coparnSearch: async (page?: number, size?: number, sort?: Array<string>, coparnSearchDto?: CoparnSearchDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/coparn/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof coparnSearchDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(coparnSearchDto !== undefined ? coparnSearchDto : {})
                : (coparnSearchDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CoparnApi - functional programming interface
 * @export
 */
export const CoparnApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel a Coparn.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coparnCancel(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CoparnApiAxiosParamCreator(configuration).coparnCancel(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create a Coparn.
         * @param {CoparnDto} coparnDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coparnCreate(coparnDto: CoparnDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoparnDto>> {
            const localVarAxiosArgs = await CoparnApiAxiosParamCreator(configuration).coparnCreate(coparnDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CoparnContainerDto} coparnContainerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coparnCreateCoparnContainerForCoparn(coparnContainerDto: CoparnContainerDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CoparnApiAxiosParamCreator(configuration).coparnCreateCoparnContainerForCoparn(coparnContainerDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CoparnTruckDto} coparnTruckDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coparnCreateCoparnTruck(coparnTruckDto: CoparnTruckDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CoparnApiAxiosParamCreator(configuration).coparnCreateCoparnTruck(coparnTruckDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete coparnTruck
         * @param {number} id Id of coparnTruck to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coparnDeleteCoparnTruck(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CoparnApiAxiosParamCreator(configuration).coparnDeleteCoparnTruck(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coparnFindAllUnassignedCoparnTrucks(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageCoparnTruckDto>> {
            const localVarAxiosArgs = await CoparnApiAxiosParamCreator(configuration).coparnFindAllUnassignedCoparnTrucks(page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {AvailableContainerDto} availableContainerDto 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coparnGetAllAvailableContainers(availableContainerDto: AvailableContainerDto, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTransitionDto>> {
            const localVarAxiosArgs = await CoparnApiAxiosParamCreator(configuration).coparnGetAllAvailableContainers(availableContainerDto, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coparnGetAllCoparnContainerForContainers(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageCoparnContainerDto>> {
            const localVarAxiosArgs = await CoparnApiAxiosParamCreator(configuration).coparnGetAllCoparnContainerForContainers(id, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coparnGetAllCoparns(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageCoparnDto>> {
            const localVarAxiosArgs = await CoparnApiAxiosParamCreator(configuration).coparnGetAllCoparns(page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} coparnId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coparnGetAssignedCoparnContainersForOperator(coparnId: number, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageCoparnContainerDto>> {
            const localVarAxiosArgs = await CoparnApiAxiosParamCreator(configuration).coparnGetAssignedCoparnContainersForOperator(coparnId, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {CoparnSearchDto} [coparnSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coparnSearch(page?: number, size?: number, sort?: Array<string>, coparnSearchDto?: CoparnSearchDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageCoparnDto>> {
            const localVarAxiosArgs = await CoparnApiAxiosParamCreator(configuration).coparnSearch(page, size, sort, coparnSearchDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CoparnApi - factory interface
 * @export
 */
export const CoparnApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Cancel a Coparn.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coparnCancel(id: number, options?: any): AxiosPromise<void> {
            return CoparnApiFp(configuration).coparnCancel(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a Coparn.
         * @param {CoparnDto} coparnDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coparnCreate(coparnDto: CoparnDto, options?: any): AxiosPromise<CoparnDto> {
            return CoparnApiFp(configuration).coparnCreate(coparnDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CoparnContainerDto} coparnContainerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coparnCreateCoparnContainerForCoparn(coparnContainerDto: CoparnContainerDto, options?: any): AxiosPromise<void> {
            return CoparnApiFp(configuration).coparnCreateCoparnContainerForCoparn(coparnContainerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CoparnTruckDto} coparnTruckDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coparnCreateCoparnTruck(coparnTruckDto: CoparnTruckDto, options?: any): AxiosPromise<void> {
            return CoparnApiFp(configuration).coparnCreateCoparnTruck(coparnTruckDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete coparnTruck
         * @param {number} id Id of coparnTruck to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coparnDeleteCoparnTruck(id: number, options?: any): AxiosPromise<void> {
            return CoparnApiFp(configuration).coparnDeleteCoparnTruck(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coparnFindAllUnassignedCoparnTrucks(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageCoparnTruckDto> {
            return CoparnApiFp(configuration).coparnFindAllUnassignedCoparnTrucks(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AvailableContainerDto} availableContainerDto 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coparnGetAllAvailableContainers(availableContainerDto: AvailableContainerDto, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageTransitionDto> {
            return CoparnApiFp(configuration).coparnGetAllAvailableContainers(availableContainerDto, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coparnGetAllCoparnContainerForContainers(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageCoparnContainerDto> {
            return CoparnApiFp(configuration).coparnGetAllCoparnContainerForContainers(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coparnGetAllCoparns(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageCoparnDto> {
            return CoparnApiFp(configuration).coparnGetAllCoparns(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} coparnId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coparnGetAssignedCoparnContainersForOperator(coparnId: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageCoparnContainerDto> {
            return CoparnApiFp(configuration).coparnGetAssignedCoparnContainersForOperator(coparnId, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {CoparnSearchDto} [coparnSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coparnSearch(page?: number, size?: number, sort?: Array<string>, coparnSearchDto?: CoparnSearchDto, options?: any): AxiosPromise<PageCoparnDto> {
            return CoparnApiFp(configuration).coparnSearch(page, size, sort, coparnSearchDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CoparnApi - object-oriented interface
 * @export
 * @class CoparnApi
 * @extends {BaseAPI}
 */
export class CoparnApi extends BaseAPI {
    /**
     * 
     * @summary Cancel a Coparn.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoparnApi
     */
    public coparnCancel(id: number, options?: any) {
        return CoparnApiFp(this.configuration).coparnCancel(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a Coparn.
     * @param {CoparnDto} coparnDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoparnApi
     */
    public coparnCreate(coparnDto: CoparnDto, options?: any) {
        return CoparnApiFp(this.configuration).coparnCreate(coparnDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoparnContainerDto} coparnContainerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoparnApi
     */
    public coparnCreateCoparnContainerForCoparn(coparnContainerDto: CoparnContainerDto, options?: any) {
        return CoparnApiFp(this.configuration).coparnCreateCoparnContainerForCoparn(coparnContainerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoparnTruckDto} coparnTruckDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoparnApi
     */
    public coparnCreateCoparnTruck(coparnTruckDto: CoparnTruckDto, options?: any) {
        return CoparnApiFp(this.configuration).coparnCreateCoparnTruck(coparnTruckDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete coparnTruck
     * @param {number} id Id of coparnTruck to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoparnApi
     */
    public coparnDeleteCoparnTruck(id: number, options?: any) {
        return CoparnApiFp(this.configuration).coparnDeleteCoparnTruck(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoparnApi
     */
    public coparnFindAllUnassignedCoparnTrucks(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return CoparnApiFp(this.configuration).coparnFindAllUnassignedCoparnTrucks(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AvailableContainerDto} availableContainerDto 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoparnApi
     */
    public coparnGetAllAvailableContainers(availableContainerDto: AvailableContainerDto, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return CoparnApiFp(this.configuration).coparnGetAllAvailableContainers(availableContainerDto, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoparnApi
     */
    public coparnGetAllCoparnContainerForContainers(id: number, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return CoparnApiFp(this.configuration).coparnGetAllCoparnContainerForContainers(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoparnApi
     */
    public coparnGetAllCoparns(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return CoparnApiFp(this.configuration).coparnGetAllCoparns(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} coparnId 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoparnApi
     */
    public coparnGetAssignedCoparnContainersForOperator(coparnId: number, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return CoparnApiFp(this.configuration).coparnGetAssignedCoparnContainersForOperator(coparnId, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {CoparnSearchDto} [coparnSearchDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoparnApi
     */
    public coparnSearch(page?: number, size?: number, sort?: Array<string>, coparnSearchDto?: CoparnSearchDto, options?: any) {
        return CoparnApiFp(this.configuration).coparnSearch(page, size, sort, coparnSearchDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DemoApi - axios parameter creator
 * @export
 */
export const DemoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        demoError: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/demo/error`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {DemoDto} [demoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        demoSearch: async (page?: number, size?: number, sort?: Array<string>, demoDto?: DemoDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/demo/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof demoDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(demoDto !== undefined ? demoDto : {})
                : (demoDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DemoApi - functional programming interface
 * @export
 */
export const DemoApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async demoError(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DemoApiAxiosParamCreator(configuration).demoError(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {DemoDto} [demoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async demoSearch(page?: number, size?: number, sort?: Array<string>, demoDto?: DemoDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDemoDto>> {
            const localVarAxiosArgs = await DemoApiAxiosParamCreator(configuration).demoSearch(page, size, sort, demoDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DemoApi - factory interface
 * @export
 */
export const DemoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        demoError(options?: any): AxiosPromise<void> {
            return DemoApiFp(configuration).demoError(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {DemoDto} [demoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        demoSearch(page?: number, size?: number, sort?: Array<string>, demoDto?: DemoDto, options?: any): AxiosPromise<PageDemoDto> {
            return DemoApiFp(configuration).demoSearch(page, size, sort, demoDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DemoApi - object-oriented interface
 * @export
 * @class DemoApi
 * @extends {BaseAPI}
 */
export class DemoApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemoApi
     */
    public demoError(options?: any) {
        return DemoApiFp(this.configuration).demoError(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {DemoDto} [demoDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemoApi
     */
    public demoSearch(page?: number, size?: number, sort?: Array<string>, demoDto?: DemoDto, options?: any) {
        return DemoApiFp(this.configuration).demoSearch(page, size, sort, demoDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DriverApi - axios parameter creator
 * @export
 */
export const DriverApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new driver
         * @param {DriverDto} driverDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverCreate: async (driverDto: DriverDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'driverDto' is not null or undefined
            if (driverDto === null || driverDto === undefined) {
                throw new RequiredError('driverDto','Required parameter driverDto was null or undefined when calling driverCreate.');
            }
            const localVarPath = `/driver`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof driverDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(driverDto !== undefined ? driverDto : {})
                : (driverDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete driver
         * @param {number} id Id of driver to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling driverDelete.');
            }
            const localVarPath = `/driver/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all drivers
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverFindAll: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/driver`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find a driver by its id.
         * @param {number} id Id of driver to be found
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverFindById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling driverFindById.');
            }
            const localVarPath = `/driver/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {DriverSearchDto} [driverSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverSearch: async (page?: number, size?: number, sort?: Array<string>, driverSearchDto?: DriverSearchDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/driver/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof driverSearchDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(driverSearchDto !== undefined ? driverSearchDto : {})
                : (driverSearchDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update driver
         * @param {number} id Id of driver to be updated
         * @param {DriverDto} driverDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverUpdate: async (id: number, driverDto: DriverDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling driverUpdate.');
            }
            // verify required parameter 'driverDto' is not null or undefined
            if (driverDto === null || driverDto === undefined) {
                throw new RequiredError('driverDto','Required parameter driverDto was null or undefined when calling driverUpdate.');
            }
            const localVarPath = `/driver/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof driverDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(driverDto !== undefined ? driverDto : {})
                : (driverDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DriverApi - functional programming interface
 * @export
 */
export const DriverApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new driver
         * @param {DriverDto} driverDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverCreate(driverDto: DriverDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DriverDto>> {
            const localVarAxiosArgs = await DriverApiAxiosParamCreator(configuration).driverCreate(driverDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete driver
         * @param {number} id Id of driver to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DriverApiAxiosParamCreator(configuration).driverDelete(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Find all drivers
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverFindAll(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDriverDto>> {
            const localVarAxiosArgs = await DriverApiAxiosParamCreator(configuration).driverFindAll(page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Find a driver by its id.
         * @param {number} id Id of driver to be found
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverFindById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DriverDto>> {
            const localVarAxiosArgs = await DriverApiAxiosParamCreator(configuration).driverFindById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {DriverSearchDto} [driverSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverSearch(page?: number, size?: number, sort?: Array<string>, driverSearchDto?: DriverSearchDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDriverDto>> {
            const localVarAxiosArgs = await DriverApiAxiosParamCreator(configuration).driverSearch(page, size, sort, driverSearchDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update driver
         * @param {number} id Id of driver to be updated
         * @param {DriverDto} driverDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverUpdate(id: number, driverDto: DriverDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DriverDto>> {
            const localVarAxiosArgs = await DriverApiAxiosParamCreator(configuration).driverUpdate(id, driverDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DriverApi - factory interface
 * @export
 */
export const DriverApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create new driver
         * @param {DriverDto} driverDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverCreate(driverDto: DriverDto, options?: any): AxiosPromise<DriverDto> {
            return DriverApiFp(configuration).driverCreate(driverDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete driver
         * @param {number} id Id of driver to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverDelete(id: number, options?: any): AxiosPromise<void> {
            return DriverApiFp(configuration).driverDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all drivers
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverFindAll(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageDriverDto> {
            return DriverApiFp(configuration).driverFindAll(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find a driver by its id.
         * @param {number} id Id of driver to be found
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverFindById(id: number, options?: any): AxiosPromise<DriverDto> {
            return DriverApiFp(configuration).driverFindById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {DriverSearchDto} [driverSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverSearch(page?: number, size?: number, sort?: Array<string>, driverSearchDto?: DriverSearchDto, options?: any): AxiosPromise<PageDriverDto> {
            return DriverApiFp(configuration).driverSearch(page, size, sort, driverSearchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update driver
         * @param {number} id Id of driver to be updated
         * @param {DriverDto} driverDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverUpdate(id: number, driverDto: DriverDto, options?: any): AxiosPromise<DriverDto> {
            return DriverApiFp(configuration).driverUpdate(id, driverDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DriverApi - object-oriented interface
 * @export
 * @class DriverApi
 * @extends {BaseAPI}
 */
export class DriverApi extends BaseAPI {
    /**
     * 
     * @summary Create new driver
     * @param {DriverDto} driverDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public driverCreate(driverDto: DriverDto, options?: any) {
        return DriverApiFp(this.configuration).driverCreate(driverDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete driver
     * @param {number} id Id of driver to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public driverDelete(id: number, options?: any) {
        return DriverApiFp(this.configuration).driverDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all drivers
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public driverFindAll(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return DriverApiFp(this.configuration).driverFindAll(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find a driver by its id.
     * @param {number} id Id of driver to be found
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public driverFindById(id: number, options?: any) {
        return DriverApiFp(this.configuration).driverFindById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {DriverSearchDto} [driverSearchDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public driverSearch(page?: number, size?: number, sort?: Array<string>, driverSearchDto?: DriverSearchDto, options?: any) {
        return DriverApiFp(this.configuration).driverSearch(page, size, sort, driverSearchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update driver
     * @param {number} id Id of driver to be updated
     * @param {DriverDto} driverDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public driverUpdate(id: number, driverDto: DriverDto, options?: any) {
        return DriverApiFp(this.configuration).driverUpdate(id, driverDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DriverArrivalApi - axios parameter creator
 * @export
 */
export const DriverArrivalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel a Driver arrival.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverArrivalCancel: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling driverArrivalCancel.');
            }
            const localVarPath = `/driver-arrival/cancel/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a Driver arrival.
         * @param {DriverArrivalDto} driverArrivalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverArrivalCreateDriverArrival: async (driverArrivalDto: DriverArrivalDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'driverArrivalDto' is not null or undefined
            if (driverArrivalDto === null || driverArrivalDto === undefined) {
                throw new RequiredError('driverArrivalDto','Required parameter driverArrivalDto was null or undefined when calling driverArrivalCreateDriverArrival.');
            }
            const localVarPath = `/driver-arrival`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof driverArrivalDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(driverArrivalDto !== undefined ? driverArrivalDto : {})
                : (driverArrivalDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a Driver arrival by its Carrier
         * @param {number} driverId 
         * @param {DriverArrivalDto} driverArrivalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverArrivalCreateDriverArrivalByCarrier: async (driverId: number, driverArrivalDto: DriverArrivalDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'driverId' is not null or undefined
            if (driverId === null || driverId === undefined) {
                throw new RequiredError('driverId','Required parameter driverId was null or undefined when calling driverArrivalCreateDriverArrivalByCarrier.');
            }
            // verify required parameter 'driverArrivalDto' is not null or undefined
            if (driverArrivalDto === null || driverArrivalDto === undefined) {
                throw new RequiredError('driverArrivalDto','Required parameter driverArrivalDto was null or undefined when calling driverArrivalCreateDriverArrivalByCarrier.');
            }
            const localVarPath = `/driver-arrival/{driverId}`
                .replace(`{${"driverId"}}`, encodeURIComponent(String(driverId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof driverArrivalDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(driverArrivalDto !== undefined ? driverArrivalDto : {})
                : (driverArrivalDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes Gate IN or Gate OUT from Driver Arrival.
         * @param {number} id 
         * @param {'IN' | 'OUT' | 'EXTERN'} direction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverArrivalDeleteGateInOrOut: async (id: number, direction: 'IN' | 'OUT' | 'EXTERN', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling driverArrivalDeleteGateInOrOut.');
            }
            // verify required parameter 'direction' is not null or undefined
            if (direction === null || direction === undefined) {
                throw new RequiredError('direction','Required parameter direction was null or undefined when calling driverArrivalDeleteGateInOrOut.');
            }
            const localVarPath = `/driver-arrival/delete-gate-in-out/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find a Driver arrival by planned transition
         * @param {number} transitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverArrivalFindByPlannedTransitionIdAndNew: async (transitionId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transitionId' is not null or undefined
            if (transitionId === null || transitionId === undefined) {
                throw new RequiredError('transitionId','Required parameter transitionId was null or undefined when calling driverArrivalFindByPlannedTransitionIdAndNew.');
            }
            const localVarPath = `/driver-arrival/transition/{transitionId}`
                .replace(`{${"transitionId"}}`, encodeURIComponent(String(transitionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find a Driver arrival page for carrier
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {DriverCarrierSearchDto} [driverCarrierSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverArrivalGetCarriersDriverArrival: async (page?: number, size?: number, sort?: Array<string>, driverCarrierSearchDto?: DriverCarrierSearchDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/driver-arrival/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof driverCarrierSearchDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(driverCarrierSearchDto !== undefined ? driverCarrierSearchDto : {})
                : (driverCarrierSearchDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find a Driver arrival.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverArrivalGetDriverArrival: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/driver-arrival`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find a Driver arrival for Carrier
         * @param {number} driverArrivalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverArrivalGetDriverArrivalById: async (driverArrivalId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'driverArrivalId' is not null or undefined
            if (driverArrivalId === null || driverArrivalId === undefined) {
                throw new RequiredError('driverArrivalId','Required parameter driverArrivalId was null or undefined when calling driverArrivalGetDriverArrivalById.');
            }
            const localVarPath = `/driver-arrival/{driverArrivalId}`
                .replace(`{${"driverArrivalId"}}`, encodeURIComponent(String(driverArrivalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Modify instant green pass of a Driver arrival.
         * @param {number} driverArrivalId 
         * @param {boolean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverArrivalModifyInstantGreenPass: async (driverArrivalId: number, body: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'driverArrivalId' is not null or undefined
            if (driverArrivalId === null || driverArrivalId === undefined) {
                throw new RequiredError('driverArrivalId','Required parameter driverArrivalId was null or undefined when calling driverArrivalModifyInstantGreenPass.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling driverArrivalModifyInstantGreenPass.');
            }
            const localVarPath = `/driver-arrival/{driverArrivalId}/instant-green-pass`
                .replace(`{${"driverArrivalId"}}`, encodeURIComponent(String(driverArrivalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof body !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(body !== undefined ? body : {})
                : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a Driver arrival.
         * @param {number} id 
         * @param {DriverArrivalDto} driverArrivalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverArrivalUpdateDriverArrival: async (id: number, driverArrivalDto: DriverArrivalDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling driverArrivalUpdateDriverArrival.');
            }
            // verify required parameter 'driverArrivalDto' is not null or undefined
            if (driverArrivalDto === null || driverArrivalDto === undefined) {
                throw new RequiredError('driverArrivalDto','Required parameter driverArrivalDto was null or undefined when calling driverArrivalUpdateDriverArrival.');
            }
            const localVarPath = `/driver-arrival/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof driverArrivalDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(driverArrivalDto !== undefined ? driverArrivalDto : {})
                : (driverArrivalDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DriverArrivalApi - functional programming interface
 * @export
 */
export const DriverArrivalApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel a Driver arrival.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverArrivalCancel(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DriverArrivalApiAxiosParamCreator(configuration).driverArrivalCancel(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create a Driver arrival.
         * @param {DriverArrivalDto} driverArrivalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverArrivalCreateDriverArrival(driverArrivalDto: DriverArrivalDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DriverArrivalDto>> {
            const localVarAxiosArgs = await DriverArrivalApiAxiosParamCreator(configuration).driverArrivalCreateDriverArrival(driverArrivalDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create a Driver arrival by its Carrier
         * @param {number} driverId 
         * @param {DriverArrivalDto} driverArrivalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverArrivalCreateDriverArrivalByCarrier(driverId: number, driverArrivalDto: DriverArrivalDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DriverArrivalDto>> {
            const localVarAxiosArgs = await DriverArrivalApiAxiosParamCreator(configuration).driverArrivalCreateDriverArrivalByCarrier(driverId, driverArrivalDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Deletes Gate IN or Gate OUT from Driver Arrival.
         * @param {number} id 
         * @param {'IN' | 'OUT' | 'EXTERN'} direction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverArrivalDeleteGateInOrOut(id: number, direction: 'IN' | 'OUT' | 'EXTERN', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DriverArrivalApiAxiosParamCreator(configuration).driverArrivalDeleteGateInOrOut(id, direction, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Find a Driver arrival by planned transition
         * @param {number} transitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverArrivalFindByPlannedTransitionIdAndNew(transitionId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DriverArrivalDto>> {
            const localVarAxiosArgs = await DriverArrivalApiAxiosParamCreator(configuration).driverArrivalFindByPlannedTransitionIdAndNew(transitionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Find a Driver arrival page for carrier
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {DriverCarrierSearchDto} [driverCarrierSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverArrivalGetCarriersDriverArrival(page?: number, size?: number, sort?: Array<string>, driverCarrierSearchDto?: DriverCarrierSearchDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDriverCarrierDto>> {
            const localVarAxiosArgs = await DriverArrivalApiAxiosParamCreator(configuration).driverArrivalGetCarriersDriverArrival(page, size, sort, driverCarrierSearchDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Find a Driver arrival.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverArrivalGetDriverArrival(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DriverArrivalDto>> {
            const localVarAxiosArgs = await DriverArrivalApiAxiosParamCreator(configuration).driverArrivalGetDriverArrival(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Find a Driver arrival for Carrier
         * @param {number} driverArrivalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverArrivalGetDriverArrivalById(driverArrivalId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DriverArrivalDto>> {
            const localVarAxiosArgs = await DriverArrivalApiAxiosParamCreator(configuration).driverArrivalGetDriverArrivalById(driverArrivalId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Modify instant green pass of a Driver arrival.
         * @param {number} driverArrivalId 
         * @param {boolean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverArrivalModifyInstantGreenPass(driverArrivalId: number, body: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DriverArrivalApiAxiosParamCreator(configuration).driverArrivalModifyInstantGreenPass(driverArrivalId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update a Driver arrival.
         * @param {number} id 
         * @param {DriverArrivalDto} driverArrivalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverArrivalUpdateDriverArrival(id: number, driverArrivalDto: DriverArrivalDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DriverArrivalDto>> {
            const localVarAxiosArgs = await DriverArrivalApiAxiosParamCreator(configuration).driverArrivalUpdateDriverArrival(id, driverArrivalDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DriverArrivalApi - factory interface
 * @export
 */
export const DriverArrivalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Cancel a Driver arrival.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverArrivalCancel(id: number, options?: any): AxiosPromise<void> {
            return DriverArrivalApiFp(configuration).driverArrivalCancel(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a Driver arrival.
         * @param {DriverArrivalDto} driverArrivalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverArrivalCreateDriverArrival(driverArrivalDto: DriverArrivalDto, options?: any): AxiosPromise<DriverArrivalDto> {
            return DriverArrivalApiFp(configuration).driverArrivalCreateDriverArrival(driverArrivalDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a Driver arrival by its Carrier
         * @param {number} driverId 
         * @param {DriverArrivalDto} driverArrivalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverArrivalCreateDriverArrivalByCarrier(driverId: number, driverArrivalDto: DriverArrivalDto, options?: any): AxiosPromise<DriverArrivalDto> {
            return DriverArrivalApiFp(configuration).driverArrivalCreateDriverArrivalByCarrier(driverId, driverArrivalDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes Gate IN or Gate OUT from Driver Arrival.
         * @param {number} id 
         * @param {'IN' | 'OUT' | 'EXTERN'} direction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverArrivalDeleteGateInOrOut(id: number, direction: 'IN' | 'OUT' | 'EXTERN', options?: any): AxiosPromise<void> {
            return DriverArrivalApiFp(configuration).driverArrivalDeleteGateInOrOut(id, direction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find a Driver arrival by planned transition
         * @param {number} transitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverArrivalFindByPlannedTransitionIdAndNew(transitionId: number, options?: any): AxiosPromise<DriverArrivalDto> {
            return DriverArrivalApiFp(configuration).driverArrivalFindByPlannedTransitionIdAndNew(transitionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find a Driver arrival page for carrier
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {DriverCarrierSearchDto} [driverCarrierSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverArrivalGetCarriersDriverArrival(page?: number, size?: number, sort?: Array<string>, driverCarrierSearchDto?: DriverCarrierSearchDto, options?: any): AxiosPromise<PageDriverCarrierDto> {
            return DriverArrivalApiFp(configuration).driverArrivalGetCarriersDriverArrival(page, size, sort, driverCarrierSearchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find a Driver arrival.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverArrivalGetDriverArrival(options?: any): AxiosPromise<DriverArrivalDto> {
            return DriverArrivalApiFp(configuration).driverArrivalGetDriverArrival(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find a Driver arrival for Carrier
         * @param {number} driverArrivalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverArrivalGetDriverArrivalById(driverArrivalId: number, options?: any): AxiosPromise<DriverArrivalDto> {
            return DriverArrivalApiFp(configuration).driverArrivalGetDriverArrivalById(driverArrivalId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Modify instant green pass of a Driver arrival.
         * @param {number} driverArrivalId 
         * @param {boolean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverArrivalModifyInstantGreenPass(driverArrivalId: number, body: boolean, options?: any): AxiosPromise<void> {
            return DriverArrivalApiFp(configuration).driverArrivalModifyInstantGreenPass(driverArrivalId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a Driver arrival.
         * @param {number} id 
         * @param {DriverArrivalDto} driverArrivalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverArrivalUpdateDriverArrival(id: number, driverArrivalDto: DriverArrivalDto, options?: any): AxiosPromise<DriverArrivalDto> {
            return DriverArrivalApiFp(configuration).driverArrivalUpdateDriverArrival(id, driverArrivalDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DriverArrivalApi - object-oriented interface
 * @export
 * @class DriverArrivalApi
 * @extends {BaseAPI}
 */
export class DriverArrivalApi extends BaseAPI {
    /**
     * 
     * @summary Cancel a Driver arrival.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverArrivalApi
     */
    public driverArrivalCancel(id: number, options?: any) {
        return DriverArrivalApiFp(this.configuration).driverArrivalCancel(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a Driver arrival.
     * @param {DriverArrivalDto} driverArrivalDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverArrivalApi
     */
    public driverArrivalCreateDriverArrival(driverArrivalDto: DriverArrivalDto, options?: any) {
        return DriverArrivalApiFp(this.configuration).driverArrivalCreateDriverArrival(driverArrivalDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a Driver arrival by its Carrier
     * @param {number} driverId 
     * @param {DriverArrivalDto} driverArrivalDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverArrivalApi
     */
    public driverArrivalCreateDriverArrivalByCarrier(driverId: number, driverArrivalDto: DriverArrivalDto, options?: any) {
        return DriverArrivalApiFp(this.configuration).driverArrivalCreateDriverArrivalByCarrier(driverId, driverArrivalDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes Gate IN or Gate OUT from Driver Arrival.
     * @param {number} id 
     * @param {'IN' | 'OUT' | 'EXTERN'} direction 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverArrivalApi
     */
    public driverArrivalDeleteGateInOrOut(id: number, direction: 'IN' | 'OUT' | 'EXTERN', options?: any) {
        return DriverArrivalApiFp(this.configuration).driverArrivalDeleteGateInOrOut(id, direction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find a Driver arrival by planned transition
     * @param {number} transitionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverArrivalApi
     */
    public driverArrivalFindByPlannedTransitionIdAndNew(transitionId: number, options?: any) {
        return DriverArrivalApiFp(this.configuration).driverArrivalFindByPlannedTransitionIdAndNew(transitionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find a Driver arrival page for carrier
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {DriverCarrierSearchDto} [driverCarrierSearchDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverArrivalApi
     */
    public driverArrivalGetCarriersDriverArrival(page?: number, size?: number, sort?: Array<string>, driverCarrierSearchDto?: DriverCarrierSearchDto, options?: any) {
        return DriverArrivalApiFp(this.configuration).driverArrivalGetCarriersDriverArrival(page, size, sort, driverCarrierSearchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find a Driver arrival.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverArrivalApi
     */
    public driverArrivalGetDriverArrival(options?: any) {
        return DriverArrivalApiFp(this.configuration).driverArrivalGetDriverArrival(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find a Driver arrival for Carrier
     * @param {number} driverArrivalId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverArrivalApi
     */
    public driverArrivalGetDriverArrivalById(driverArrivalId: number, options?: any) {
        return DriverArrivalApiFp(this.configuration).driverArrivalGetDriverArrivalById(driverArrivalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Modify instant green pass of a Driver arrival.
     * @param {number} driverArrivalId 
     * @param {boolean} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverArrivalApi
     */
    public driverArrivalModifyInstantGreenPass(driverArrivalId: number, body: boolean, options?: any) {
        return DriverArrivalApiFp(this.configuration).driverArrivalModifyInstantGreenPass(driverArrivalId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a Driver arrival.
     * @param {number} id 
     * @param {DriverArrivalDto} driverArrivalDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverArrivalApi
     */
    public driverArrivalUpdateDriverArrival(id: number, driverArrivalDto: DriverArrivalDto, options?: any) {
        return DriverArrivalApiFp(this.configuration).driverArrivalUpdateDriverArrival(id, driverArrivalDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DriverArrivalKioskApi - axios parameter creator
 * @export
 */
export const DriverArrivalKioskApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel a Driver arrival.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverArrivalKioskCancel: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling driverArrivalKioskCancel.');
            }
            const localVarPath = `/driver-arrival-kiosk/cancel/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a Driver arrival.
         * @param {DriverArrivalDto} driverArrivalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverArrivalKioskCreateDriverArrival: async (driverArrivalDto: DriverArrivalDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'driverArrivalDto' is not null or undefined
            if (driverArrivalDto === null || driverArrivalDto === undefined) {
                throw new RequiredError('driverArrivalDto','Required parameter driverArrivalDto was null or undefined when calling driverArrivalKioskCreateDriverArrival.');
            }
            const localVarPath = `/driver-arrival-kiosk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof driverArrivalDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(driverArrivalDto !== undefined ? driverArrivalDto : {})
                : (driverArrivalDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Finish a Driver arrival and send a notification
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverArrivalKioskFinish: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling driverArrivalKioskFinish.');
            }
            const localVarPath = `/driver-arrival-kiosk/finish/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find a Driver arrival by its id.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverArrivalKioskGetDriverArrival: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling driverArrivalKioskGetDriverArrival.');
            }
            const localVarPath = `/driver-arrival-kiosk/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a Driver arrival.
         * @param {number} id 
         * @param {DriverArrivalDto} driverArrivalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverArrivalKioskUpdateDriverArrival: async (id: number, driverArrivalDto: DriverArrivalDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling driverArrivalKioskUpdateDriverArrival.');
            }
            // verify required parameter 'driverArrivalDto' is not null or undefined
            if (driverArrivalDto === null || driverArrivalDto === undefined) {
                throw new RequiredError('driverArrivalDto','Required parameter driverArrivalDto was null or undefined when calling driverArrivalKioskUpdateDriverArrival.');
            }
            const localVarPath = `/driver-arrival-kiosk/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof driverArrivalDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(driverArrivalDto !== undefined ? driverArrivalDto : {})
                : (driverArrivalDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DriverArrivalKioskApi - functional programming interface
 * @export
 */
export const DriverArrivalKioskApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel a Driver arrival.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverArrivalKioskCancel(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DriverArrivalKioskApiAxiosParamCreator(configuration).driverArrivalKioskCancel(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create a Driver arrival.
         * @param {DriverArrivalDto} driverArrivalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverArrivalKioskCreateDriverArrival(driverArrivalDto: DriverArrivalDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DriverArrivalDto>> {
            const localVarAxiosArgs = await DriverArrivalKioskApiAxiosParamCreator(configuration).driverArrivalKioskCreateDriverArrival(driverArrivalDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Finish a Driver arrival and send a notification
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverArrivalKioskFinish(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DriverArrivalKioskApiAxiosParamCreator(configuration).driverArrivalKioskFinish(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Find a Driver arrival by its id.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverArrivalKioskGetDriverArrival(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DriverArrivalDto>> {
            const localVarAxiosArgs = await DriverArrivalKioskApiAxiosParamCreator(configuration).driverArrivalKioskGetDriverArrival(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update a Driver arrival.
         * @param {number} id 
         * @param {DriverArrivalDto} driverArrivalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverArrivalKioskUpdateDriverArrival(id: number, driverArrivalDto: DriverArrivalDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DriverArrivalDto>> {
            const localVarAxiosArgs = await DriverArrivalKioskApiAxiosParamCreator(configuration).driverArrivalKioskUpdateDriverArrival(id, driverArrivalDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DriverArrivalKioskApi - factory interface
 * @export
 */
export const DriverArrivalKioskApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Cancel a Driver arrival.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverArrivalKioskCancel(id: number, options?: any): AxiosPromise<void> {
            return DriverArrivalKioskApiFp(configuration).driverArrivalKioskCancel(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a Driver arrival.
         * @param {DriverArrivalDto} driverArrivalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverArrivalKioskCreateDriverArrival(driverArrivalDto: DriverArrivalDto, options?: any): AxiosPromise<DriverArrivalDto> {
            return DriverArrivalKioskApiFp(configuration).driverArrivalKioskCreateDriverArrival(driverArrivalDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Finish a Driver arrival and send a notification
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverArrivalKioskFinish(id: number, options?: any): AxiosPromise<void> {
            return DriverArrivalKioskApiFp(configuration).driverArrivalKioskFinish(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find a Driver arrival by its id.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverArrivalKioskGetDriverArrival(id: number, options?: any): AxiosPromise<DriverArrivalDto> {
            return DriverArrivalKioskApiFp(configuration).driverArrivalKioskGetDriverArrival(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a Driver arrival.
         * @param {number} id 
         * @param {DriverArrivalDto} driverArrivalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverArrivalKioskUpdateDriverArrival(id: number, driverArrivalDto: DriverArrivalDto, options?: any): AxiosPromise<DriverArrivalDto> {
            return DriverArrivalKioskApiFp(configuration).driverArrivalKioskUpdateDriverArrival(id, driverArrivalDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DriverArrivalKioskApi - object-oriented interface
 * @export
 * @class DriverArrivalKioskApi
 * @extends {BaseAPI}
 */
export class DriverArrivalKioskApi extends BaseAPI {
    /**
     * 
     * @summary Cancel a Driver arrival.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverArrivalKioskApi
     */
    public driverArrivalKioskCancel(id: number, options?: any) {
        return DriverArrivalKioskApiFp(this.configuration).driverArrivalKioskCancel(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a Driver arrival.
     * @param {DriverArrivalDto} driverArrivalDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverArrivalKioskApi
     */
    public driverArrivalKioskCreateDriverArrival(driverArrivalDto: DriverArrivalDto, options?: any) {
        return DriverArrivalKioskApiFp(this.configuration).driverArrivalKioskCreateDriverArrival(driverArrivalDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Finish a Driver arrival and send a notification
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverArrivalKioskApi
     */
    public driverArrivalKioskFinish(id: number, options?: any) {
        return DriverArrivalKioskApiFp(this.configuration).driverArrivalKioskFinish(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find a Driver arrival by its id.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverArrivalKioskApi
     */
    public driverArrivalKioskGetDriverArrival(id: number, options?: any) {
        return DriverArrivalKioskApiFp(this.configuration).driverArrivalKioskGetDriverArrival(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a Driver arrival.
     * @param {number} id 
     * @param {DriverArrivalDto} driverArrivalDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverArrivalKioskApi
     */
    public driverArrivalKioskUpdateDriverArrival(id: number, driverArrivalDto: DriverArrivalDto, options?: any) {
        return DriverArrivalKioskApiFp(this.configuration).driverArrivalKioskUpdateDriverArrival(id, driverArrivalDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExtraApi - axios parameter creator
 * @export
 */
export const ExtraApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {number} price 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        extraChangePrice: async (id: number, price: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling extraChangePrice.');
            }
            // verify required parameter 'price' is not null or undefined
            if (price === null || price === undefined) {
                throw new RequiredError('price','Required parameter price was null or undefined when calling extraChangePrice.');
            }
            const localVarPath = `/extra/{id}/{price}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"price"}}`, encodeURIComponent(String(price)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        extraFindAll: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/extra`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        extraFindById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling extraFindById.');
            }
            const localVarPath = `/extra/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExtraApi - functional programming interface
 * @export
 */
export const ExtraApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {number} price 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async extraChangePrice(id: number, price: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExtraDto>> {
            const localVarAxiosArgs = await ExtraApiAxiosParamCreator(configuration).extraChangePrice(id, price, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async extraFindAll(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageExtraDto>> {
            const localVarAxiosArgs = await ExtraApiAxiosParamCreator(configuration).extraFindAll(page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async extraFindById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExtraDto>> {
            const localVarAxiosArgs = await ExtraApiAxiosParamCreator(configuration).extraFindById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ExtraApi - factory interface
 * @export
 */
export const ExtraApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {number} price 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        extraChangePrice(id: number, price: number, options?: any): AxiosPromise<ExtraDto> {
            return ExtraApiFp(configuration).extraChangePrice(id, price, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        extraFindAll(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageExtraDto> {
            return ExtraApiFp(configuration).extraFindAll(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        extraFindById(id: number, options?: any): AxiosPromise<ExtraDto> {
            return ExtraApiFp(configuration).extraFindById(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExtraApi - object-oriented interface
 * @export
 * @class ExtraApi
 * @extends {BaseAPI}
 */
export class ExtraApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {number} price 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtraApi
     */
    public extraChangePrice(id: number, price: number, options?: any) {
        return ExtraApiFp(this.configuration).extraChangePrice(id, price, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtraApi
     */
    public extraFindAll(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return ExtraApiFp(this.configuration).extraFindAll(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtraApi
     */
    public extraFindById(id: number, options?: any) {
        return ExtraApiFp(this.configuration).extraFindById(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GateApi - axios parameter creator
 * @export
 */
export const GateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Find all gates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gateFindAll: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/gates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GateApi - functional programming interface
 * @export
 */
export const GateApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Find all gates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gateFindAll(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GateDto>>> {
            const localVarAxiosArgs = await GateApiAxiosParamCreator(configuration).gateFindAll(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * GateApi - factory interface
 * @export
 */
export const GateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Find all gates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gateFindAll(options?: any): AxiosPromise<Array<GateDto>> {
            return GateApiFp(configuration).gateFindAll(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GateApi - object-oriented interface
 * @export
 * @class GateApi
 * @extends {BaseAPI}
 */
export class GateApi extends BaseAPI {
    /**
     * 
     * @summary Find all gates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GateApi
     */
    public gateFindAll(options?: any) {
        return GateApiFp(this.configuration).gateFindAll(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GateInApi - axios parameter creator
 * @export
 */
export const GateInApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates new planned Gate IN
         * @param {number} driverArrivalId 
         * @param {TransitionDto} transitionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gateInCreateByDriver: async (driverArrivalId: number, transitionDto: TransitionDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'driverArrivalId' is not null or undefined
            if (driverArrivalId === null || driverArrivalId === undefined) {
                throw new RequiredError('driverArrivalId','Required parameter driverArrivalId was null or undefined when calling gateInCreateByDriver.');
            }
            // verify required parameter 'transitionDto' is not null or undefined
            if (transitionDto === null || transitionDto === undefined) {
                throw new RequiredError('transitionDto','Required parameter transitionDto was null or undefined when calling gateInCreateByDriver.');
            }
            const localVarPath = `/gate-in/driver-arrival/{driverArrivalId}`
                .replace(`{${"driverArrivalId"}}`, encodeURIComponent(String(driverArrivalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof transitionDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(transitionDto !== undefined ? transitionDto : {})
                : (transitionDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates new simple Gate IN
         * @param {TransitionDto} transitionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gateInCreateSimpleGateIn: async (transitionDto: TransitionDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transitionDto' is not null or undefined
            if (transitionDto === null || transitionDto === undefined) {
                throw new RequiredError('transitionDto','Required parameter transitionDto was null or undefined when calling gateInCreateSimpleGateIn.');
            }
            const localVarPath = `/gate-in/simple`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof transitionDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(transitionDto !== undefined ? transitionDto : {})
                : (transitionDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gateInDownloadDamageLog: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling gateInDownloadDamageLog.');
            }
            const localVarPath = `/gate-in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} iluCode 
         * @param {'SHIPPING_CONTAINER' | 'SEMITRAILER'} containerType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gateInGetIluCodesForAutocomplete: async (iluCode: string, containerType: 'SHIPPING_CONTAINER' | 'SEMITRAILER', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'iluCode' is not null or undefined
            if (iluCode === null || iluCode === undefined) {
                throw new RequiredError('iluCode','Required parameter iluCode was null or undefined when calling gateInGetIluCodesForAutocomplete.');
            }
            // verify required parameter 'containerType' is not null or undefined
            if (containerType === null || containerType === undefined) {
                throw new RequiredError('containerType','Required parameter containerType was null or undefined when calling gateInGetIluCodesForAutocomplete.');
            }
            const localVarPath = `/gate-in/ilu-codes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (iluCode !== undefined) {
                localVarQueryParameter['iluCode'] = iluCode;
            }

            if (containerType !== undefined) {
                localVarQueryParameter['containerType'] = containerType;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Finds or creates new planned Gate IN
         * @param {number} driverArrivalId 
         * @param {number} containerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gateInGetPlannedTransitionForContainerId: async (driverArrivalId: number, containerId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'driverArrivalId' is not null or undefined
            if (driverArrivalId === null || driverArrivalId === undefined) {
                throw new RequiredError('driverArrivalId','Required parameter driverArrivalId was null or undefined when calling gateInGetPlannedTransitionForContainerId.');
            }
            // verify required parameter 'containerId' is not null or undefined
            if (containerId === null || containerId === undefined) {
                throw new RequiredError('containerId','Required parameter containerId was null or undefined when calling gateInGetPlannedTransitionForContainerId.');
            }
            const localVarPath = `/gate-in/planned-transition/container/{containerId}/driver-arrival/{driverArrivalId}`
                .replace(`{${"driverArrivalId"}}`, encodeURIComponent(String(driverArrivalId)))
                .replace(`{${"containerId"}}`, encodeURIComponent(String(containerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id Id of transition
         * @param {TransitionStoreDto} transitionStoreDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gateInStore: async (id: number, transitionStoreDto: TransitionStoreDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling gateInStore.');
            }
            // verify required parameter 'transitionStoreDto' is not null or undefined
            if (transitionStoreDto === null || transitionStoreDto === undefined) {
                throw new RequiredError('transitionStoreDto','Required parameter transitionStoreDto was null or undefined when calling gateInStore.');
            }
            const localVarPath = `/gate-in/store/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof transitionStoreDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(transitionStoreDto !== undefined ? transitionStoreDto : {})
                : (transitionStoreDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates planned transitions from given Excel file
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gateInUploadMedlogBoard: async (file: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling gateInUploadMedlogBoard.');
            }
            const localVarPath = `/gate-in/medlog-board/model`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GateInApi - functional programming interface
 * @export
 */
export const GateInApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates new planned Gate IN
         * @param {number} driverArrivalId 
         * @param {TransitionDto} transitionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gateInCreateByDriver(driverArrivalId: number, transitionDto: TransitionDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransitionDto>> {
            const localVarAxiosArgs = await GateInApiAxiosParamCreator(configuration).gateInCreateByDriver(driverArrivalId, transitionDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Creates new simple Gate IN
         * @param {TransitionDto} transitionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gateInCreateSimpleGateIn(transitionDto: TransitionDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransitionDto>> {
            const localVarAxiosArgs = await GateInApiAxiosParamCreator(configuration).gateInCreateSimpleGateIn(transitionDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gateInDownloadDamageLog(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await GateInApiAxiosParamCreator(configuration).gateInDownloadDamageLog(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} iluCode 
         * @param {'SHIPPING_CONTAINER' | 'SEMITRAILER'} containerType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gateInGetIluCodesForAutocomplete(iluCode: string, containerType: 'SHIPPING_CONTAINER' | 'SEMITRAILER', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await GateInApiAxiosParamCreator(configuration).gateInGetIluCodesForAutocomplete(iluCode, containerType, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Finds or creates new planned Gate IN
         * @param {number} driverArrivalId 
         * @param {number} containerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gateInGetPlannedTransitionForContainerId(driverArrivalId: number, containerId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransitionDto>> {
            const localVarAxiosArgs = await GateInApiAxiosParamCreator(configuration).gateInGetPlannedTransitionForContainerId(driverArrivalId, containerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id Id of transition
         * @param {TransitionStoreDto} transitionStoreDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gateInStore(id: number, transitionStoreDto: TransitionStoreDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransitionDto>> {
            const localVarAxiosArgs = await GateInApiAxiosParamCreator(configuration).gateInStore(id, transitionStoreDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Creates planned transitions from given Excel file
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gateInUploadMedlogBoard(file: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await GateInApiAxiosParamCreator(configuration).gateInUploadMedlogBoard(file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * GateInApi - factory interface
 * @export
 */
export const GateInApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Creates new planned Gate IN
         * @param {number} driverArrivalId 
         * @param {TransitionDto} transitionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gateInCreateByDriver(driverArrivalId: number, transitionDto: TransitionDto, options?: any): AxiosPromise<TransitionDto> {
            return GateInApiFp(configuration).gateInCreateByDriver(driverArrivalId, transitionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates new simple Gate IN
         * @param {TransitionDto} transitionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gateInCreateSimpleGateIn(transitionDto: TransitionDto, options?: any): AxiosPromise<TransitionDto> {
            return GateInApiFp(configuration).gateInCreateSimpleGateIn(transitionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gateInDownloadDamageLog(id: number, options?: any): AxiosPromise<Array<string>> {
            return GateInApiFp(configuration).gateInDownloadDamageLog(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} iluCode 
         * @param {'SHIPPING_CONTAINER' | 'SEMITRAILER'} containerType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gateInGetIluCodesForAutocomplete(iluCode: string, containerType: 'SHIPPING_CONTAINER' | 'SEMITRAILER', options?: any): AxiosPromise<Array<string>> {
            return GateInApiFp(configuration).gateInGetIluCodesForAutocomplete(iluCode, containerType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Finds or creates new planned Gate IN
         * @param {number} driverArrivalId 
         * @param {number} containerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gateInGetPlannedTransitionForContainerId(driverArrivalId: number, containerId: number, options?: any): AxiosPromise<TransitionDto> {
            return GateInApiFp(configuration).gateInGetPlannedTransitionForContainerId(driverArrivalId, containerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id Id of transition
         * @param {TransitionStoreDto} transitionStoreDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gateInStore(id: number, transitionStoreDto: TransitionStoreDto, options?: any): AxiosPromise<TransitionDto> {
            return GateInApiFp(configuration).gateInStore(id, transitionStoreDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates planned transitions from given Excel file
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gateInUploadMedlogBoard(file: any, options?: any): AxiosPromise<string> {
            return GateInApiFp(configuration).gateInUploadMedlogBoard(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GateInApi - object-oriented interface
 * @export
 * @class GateInApi
 * @extends {BaseAPI}
 */
export class GateInApi extends BaseAPI {
    /**
     * 
     * @summary Creates new planned Gate IN
     * @param {number} driverArrivalId 
     * @param {TransitionDto} transitionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GateInApi
     */
    public gateInCreateByDriver(driverArrivalId: number, transitionDto: TransitionDto, options?: any) {
        return GateInApiFp(this.configuration).gateInCreateByDriver(driverArrivalId, transitionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates new simple Gate IN
     * @param {TransitionDto} transitionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GateInApi
     */
    public gateInCreateSimpleGateIn(transitionDto: TransitionDto, options?: any) {
        return GateInApiFp(this.configuration).gateInCreateSimpleGateIn(transitionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GateInApi
     */
    public gateInDownloadDamageLog(id: number, options?: any) {
        return GateInApiFp(this.configuration).gateInDownloadDamageLog(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} iluCode 
     * @param {'SHIPPING_CONTAINER' | 'SEMITRAILER'} containerType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GateInApi
     */
    public gateInGetIluCodesForAutocomplete(iluCode: string, containerType: 'SHIPPING_CONTAINER' | 'SEMITRAILER', options?: any) {
        return GateInApiFp(this.configuration).gateInGetIluCodesForAutocomplete(iluCode, containerType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Finds or creates new planned Gate IN
     * @param {number} driverArrivalId 
     * @param {number} containerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GateInApi
     */
    public gateInGetPlannedTransitionForContainerId(driverArrivalId: number, containerId: number, options?: any) {
        return GateInApiFp(this.configuration).gateInGetPlannedTransitionForContainerId(driverArrivalId, containerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id Id of transition
     * @param {TransitionStoreDto} transitionStoreDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GateInApi
     */
    public gateInStore(id: number, transitionStoreDto: TransitionStoreDto, options?: any) {
        return GateInApiFp(this.configuration).gateInStore(id, transitionStoreDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates planned transitions from given Excel file
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GateInApi
     */
    public gateInUploadMedlogBoard(file: any, options?: any) {
        return GateInApiFp(this.configuration).gateInUploadMedlogBoard(file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GateOutApi - axios parameter creator
 * @export
 */
export const GateOutApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates Gate Out by operator
         * @param {JoinCoparnTruckContainerDto} joinCoparnTruckContainerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gateOutCreateGoFromSelectedContainer: async (joinCoparnTruckContainerDto: JoinCoparnTruckContainerDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'joinCoparnTruckContainerDto' is not null or undefined
            if (joinCoparnTruckContainerDto === null || joinCoparnTruckContainerDto === undefined) {
                throw new RequiredError('joinCoparnTruckContainerDto','Required parameter joinCoparnTruckContainerDto was null or undefined when calling gateOutCreateGoFromSelectedContainer.');
            }
            const localVarPath = `/gate-out/coparn/choose-container`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof joinCoparnTruckContainerDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(joinCoparnTruckContainerDto !== undefined ? joinCoparnTruckContainerDto : {})
                : (joinCoparnTruckContainerDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates new Gate OUT
         * @param {TransitionDto} transitionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gateOutCreateSimple: async (transitionDto: TransitionDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transitionDto' is not null or undefined
            if (transitionDto === null || transitionDto === undefined) {
                throw new RequiredError('transitionDto','Required parameter transitionDto was null or undefined when calling gateOutCreateSimple.');
            }
            const localVarPath = `/gate-out/simple`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof transitionDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(transitionDto !== undefined ? transitionDto : {})
                : (transitionDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns Transition by id and sets receipt method.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gateOutFindTransitionForRoadGateOut: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling gateOutFindTransitionForRoadGateOut.');
            }
            const localVarPath = `/gate-out/receipt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id Id of transition
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gateOutFinishGateOut: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling gateOutFinishGateOut.');
            }
            const localVarPath = `/gate-out/finish-gate-out/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns gate-out coparn truck with prefilled values from driver app
         * @param {number} driverId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gateOutGetDriverGateOutCoparnTruck: async (driverId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'driverId' is not null or undefined
            if (driverId === null || driverId === undefined) {
                throw new RequiredError('driverId','Required parameter driverId was null or undefined when calling gateOutGetDriverGateOutCoparnTruck.');
            }
            const localVarPath = `/gate-out/driver/{driverId}/coparn-gate-out`
                .replace(`{${"driverId"}}`, encodeURIComponent(String(driverId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns gate-out transition filled in by driver
         * @param {number} driverId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gateOutGetDriverGateOutTransition: async (driverId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'driverId' is not null or undefined
            if (driverId === null || driverId === undefined) {
                throw new RequiredError('driverId','Required parameter driverId was null or undefined when calling gateOutGetDriverGateOutTransition.');
            }
            const localVarPath = `/gate-out/driver/{driverId}/transition-gate-out`
                .replace(`{${"driverId"}}`, encodeURIComponent(String(driverId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns type of the prefilled gate-out of the driver
         * @param {number} driverId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gateOutGetDriverGateOutType: async (driverId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'driverId' is not null or undefined
            if (driverId === null || driverId === undefined) {
                throw new RequiredError('driverId','Required parameter driverId was null or undefined when calling gateOutGetDriverGateOutType.');
            }
            const localVarPath = `/gate-out/driver/{driverId}/check-gate-out`
                .replace(`{${"driverId"}}`, encodeURIComponent(String(driverId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets list of night ARCESE gate-out semitrailers
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {TransitionSearchListSearchDto} [transitionSearchListSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gateOutNightTransitionList: async (page?: number, size?: number, sort?: Array<string>, transitionSearchListSearchDto?: TransitionSearchListSearchDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/gate-out/night-transition-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof transitionSearchListSearchDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(transitionSearchListSearchDto !== undefined ? transitionSearchListSearchDto : {})
                : (transitionSearchListSearchDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id Id of transition
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gateOutReleaseContainer: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling gateOutReleaseContainer.');
            }
            const localVarPath = `/gate-out/release-container/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of transition for simple Gate OUT
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {TransitionSearchListSearchDto} [transitionSearchListSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gateOutSimpleTransitionList: async (page?: number, size?: number, sort?: Array<string>, transitionSearchListSearchDto?: TransitionSearchListSearchDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/gate-out/simple-transition-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof transitionSearchListSearchDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(transitionSearchListSearchDto !== undefined ? transitionSearchListSearchDto : {})
                : (transitionSearchListSearchDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send Codeco for transition
         * @param {number} transitionId Id of transition to send Codeco for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gateOutUpdate: async (transitionId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transitionId' is not null or undefined
            if (transitionId === null || transitionId === undefined) {
                throw new RequiredError('transitionId','Required parameter transitionId was null or undefined when calling gateOutUpdate.');
            }
            const localVarPath = `/gate-out/send-codeco/{transitionId}`
                .replace(`{${"transitionId"}}`, encodeURIComponent(String(transitionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GateOutApi - functional programming interface
 * @export
 */
export const GateOutApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates Gate Out by operator
         * @param {JoinCoparnTruckContainerDto} joinCoparnTruckContainerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gateOutCreateGoFromSelectedContainer(joinCoparnTruckContainerDto: JoinCoparnTruckContainerDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await GateOutApiAxiosParamCreator(configuration).gateOutCreateGoFromSelectedContainer(joinCoparnTruckContainerDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Creates new Gate OUT
         * @param {TransitionDto} transitionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gateOutCreateSimple(transitionDto: TransitionDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransitionDto>> {
            const localVarAxiosArgs = await GateOutApiAxiosParamCreator(configuration).gateOutCreateSimple(transitionDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Returns Transition by id and sets receipt method.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gateOutFindTransitionForRoadGateOut(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransitionDto>> {
            const localVarAxiosArgs = await GateOutApiAxiosParamCreator(configuration).gateOutFindTransitionForRoadGateOut(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id Id of transition
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gateOutFinishGateOut(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransitionDto>> {
            const localVarAxiosArgs = await GateOutApiAxiosParamCreator(configuration).gateOutFinishGateOut(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Returns gate-out coparn truck with prefilled values from driver app
         * @param {number} driverId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gateOutGetDriverGateOutCoparnTruck(driverId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoparnTruckDto>> {
            const localVarAxiosArgs = await GateOutApiAxiosParamCreator(configuration).gateOutGetDriverGateOutCoparnTruck(driverId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Returns gate-out transition filled in by driver
         * @param {number} driverId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gateOutGetDriverGateOutTransition(driverId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransitionDto>> {
            const localVarAxiosArgs = await GateOutApiAxiosParamCreator(configuration).gateOutGetDriverGateOutTransition(driverId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Returns type of the prefilled gate-out of the driver
         * @param {number} driverId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gateOutGetDriverGateOutType(driverId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await GateOutApiAxiosParamCreator(configuration).gateOutGetDriverGateOutType(driverId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets list of night ARCESE gate-out semitrailers
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {TransitionSearchListSearchDto} [transitionSearchListSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gateOutNightTransitionList(page?: number, size?: number, sort?: Array<string>, transitionSearchListSearchDto?: TransitionSearchListSearchDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTransitionSearchListDto>> {
            const localVarAxiosArgs = await GateOutApiAxiosParamCreator(configuration).gateOutNightTransitionList(page, size, sort, transitionSearchListSearchDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id Id of transition
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gateOutReleaseContainer(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransitionDto>> {
            const localVarAxiosArgs = await GateOutApiAxiosParamCreator(configuration).gateOutReleaseContainer(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get list of transition for simple Gate OUT
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {TransitionSearchListSearchDto} [transitionSearchListSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gateOutSimpleTransitionList(page?: number, size?: number, sort?: Array<string>, transitionSearchListSearchDto?: TransitionSearchListSearchDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTransitionSearchListDto>> {
            const localVarAxiosArgs = await GateOutApiAxiosParamCreator(configuration).gateOutSimpleTransitionList(page, size, sort, transitionSearchListSearchDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Send Codeco for transition
         * @param {number} transitionId Id of transition to send Codeco for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gateOutUpdate(transitionId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await GateOutApiAxiosParamCreator(configuration).gateOutUpdate(transitionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * GateOutApi - factory interface
 * @export
 */
export const GateOutApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Creates Gate Out by operator
         * @param {JoinCoparnTruckContainerDto} joinCoparnTruckContainerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gateOutCreateGoFromSelectedContainer(joinCoparnTruckContainerDto: JoinCoparnTruckContainerDto, options?: any): AxiosPromise<void> {
            return GateOutApiFp(configuration).gateOutCreateGoFromSelectedContainer(joinCoparnTruckContainerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates new Gate OUT
         * @param {TransitionDto} transitionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gateOutCreateSimple(transitionDto: TransitionDto, options?: any): AxiosPromise<TransitionDto> {
            return GateOutApiFp(configuration).gateOutCreateSimple(transitionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns Transition by id and sets receipt method.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gateOutFindTransitionForRoadGateOut(id: number, options?: any): AxiosPromise<TransitionDto> {
            return GateOutApiFp(configuration).gateOutFindTransitionForRoadGateOut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id Id of transition
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gateOutFinishGateOut(id: number, options?: any): AxiosPromise<TransitionDto> {
            return GateOutApiFp(configuration).gateOutFinishGateOut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns gate-out coparn truck with prefilled values from driver app
         * @param {number} driverId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gateOutGetDriverGateOutCoparnTruck(driverId: number, options?: any): AxiosPromise<CoparnTruckDto> {
            return GateOutApiFp(configuration).gateOutGetDriverGateOutCoparnTruck(driverId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns gate-out transition filled in by driver
         * @param {number} driverId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gateOutGetDriverGateOutTransition(driverId: number, options?: any): AxiosPromise<TransitionDto> {
            return GateOutApiFp(configuration).gateOutGetDriverGateOutTransition(driverId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns type of the prefilled gate-out of the driver
         * @param {number} driverId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gateOutGetDriverGateOutType(driverId: number, options?: any): AxiosPromise<string> {
            return GateOutApiFp(configuration).gateOutGetDriverGateOutType(driverId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets list of night ARCESE gate-out semitrailers
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {TransitionSearchListSearchDto} [transitionSearchListSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gateOutNightTransitionList(page?: number, size?: number, sort?: Array<string>, transitionSearchListSearchDto?: TransitionSearchListSearchDto, options?: any): AxiosPromise<PageTransitionSearchListDto> {
            return GateOutApiFp(configuration).gateOutNightTransitionList(page, size, sort, transitionSearchListSearchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id Id of transition
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gateOutReleaseContainer(id: number, options?: any): AxiosPromise<TransitionDto> {
            return GateOutApiFp(configuration).gateOutReleaseContainer(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of transition for simple Gate OUT
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {TransitionSearchListSearchDto} [transitionSearchListSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gateOutSimpleTransitionList(page?: number, size?: number, sort?: Array<string>, transitionSearchListSearchDto?: TransitionSearchListSearchDto, options?: any): AxiosPromise<PageTransitionSearchListDto> {
            return GateOutApiFp(configuration).gateOutSimpleTransitionList(page, size, sort, transitionSearchListSearchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send Codeco for transition
         * @param {number} transitionId Id of transition to send Codeco for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gateOutUpdate(transitionId: number, options?: any): AxiosPromise<void> {
            return GateOutApiFp(configuration).gateOutUpdate(transitionId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GateOutApi - object-oriented interface
 * @export
 * @class GateOutApi
 * @extends {BaseAPI}
 */
export class GateOutApi extends BaseAPI {
    /**
     * 
     * @summary Creates Gate Out by operator
     * @param {JoinCoparnTruckContainerDto} joinCoparnTruckContainerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GateOutApi
     */
    public gateOutCreateGoFromSelectedContainer(joinCoparnTruckContainerDto: JoinCoparnTruckContainerDto, options?: any) {
        return GateOutApiFp(this.configuration).gateOutCreateGoFromSelectedContainer(joinCoparnTruckContainerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates new Gate OUT
     * @param {TransitionDto} transitionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GateOutApi
     */
    public gateOutCreateSimple(transitionDto: TransitionDto, options?: any) {
        return GateOutApiFp(this.configuration).gateOutCreateSimple(transitionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns Transition by id and sets receipt method.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GateOutApi
     */
    public gateOutFindTransitionForRoadGateOut(id: number, options?: any) {
        return GateOutApiFp(this.configuration).gateOutFindTransitionForRoadGateOut(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id Id of transition
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GateOutApi
     */
    public gateOutFinishGateOut(id: number, options?: any) {
        return GateOutApiFp(this.configuration).gateOutFinishGateOut(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns gate-out coparn truck with prefilled values from driver app
     * @param {number} driverId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GateOutApi
     */
    public gateOutGetDriverGateOutCoparnTruck(driverId: number, options?: any) {
        return GateOutApiFp(this.configuration).gateOutGetDriverGateOutCoparnTruck(driverId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns gate-out transition filled in by driver
     * @param {number} driverId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GateOutApi
     */
    public gateOutGetDriverGateOutTransition(driverId: number, options?: any) {
        return GateOutApiFp(this.configuration).gateOutGetDriverGateOutTransition(driverId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns type of the prefilled gate-out of the driver
     * @param {number} driverId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GateOutApi
     */
    public gateOutGetDriverGateOutType(driverId: number, options?: any) {
        return GateOutApiFp(this.configuration).gateOutGetDriverGateOutType(driverId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets list of night ARCESE gate-out semitrailers
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {TransitionSearchListSearchDto} [transitionSearchListSearchDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GateOutApi
     */
    public gateOutNightTransitionList(page?: number, size?: number, sort?: Array<string>, transitionSearchListSearchDto?: TransitionSearchListSearchDto, options?: any) {
        return GateOutApiFp(this.configuration).gateOutNightTransitionList(page, size, sort, transitionSearchListSearchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id Id of transition
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GateOutApi
     */
    public gateOutReleaseContainer(id: number, options?: any) {
        return GateOutApiFp(this.configuration).gateOutReleaseContainer(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of transition for simple Gate OUT
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {TransitionSearchListSearchDto} [transitionSearchListSearchDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GateOutApi
     */
    public gateOutSimpleTransitionList(page?: number, size?: number, sort?: Array<string>, transitionSearchListSearchDto?: TransitionSearchListSearchDto, options?: any) {
        return GateOutApiFp(this.configuration).gateOutSimpleTransitionList(page, size, sort, transitionSearchListSearchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send Codeco for transition
     * @param {number} transitionId Id of transition to send Codeco for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GateOutApi
     */
    public gateOutUpdate(transitionId: number, options?: any) {
        return GateOutApiFp(this.configuration).gateOutUpdate(transitionId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InfoApi - axios parameter creator
 * @export
 */
export const InfoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve application info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InfoApi - functional programming interface
 * @export
 */
export const InfoApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve application info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async infoInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuildProperties>> {
            const localVarAxiosArgs = await InfoApiAxiosParamCreator(configuration).infoInfo(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * InfoApi - factory interface
 * @export
 */
export const InfoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Retrieve application info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoInfo(options?: any): AxiosPromise<BuildProperties> {
            return InfoApiFp(configuration).infoInfo(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InfoApi - object-oriented interface
 * @export
 * @class InfoApi
 * @extends {BaseAPI}
 */
export class InfoApi extends BaseAPI {
    /**
     * 
     * @summary Retrieve application info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InfoApi
     */
    public infoInfo(options?: any) {
        return InfoApiFp(this.configuration).infoInfo(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InvoiceApi - axios parameter creator
 * @export
 */
export const InvoiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Generate invoice data file
         * @param {InvoiceDataRequestDto} invoiceDataRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceGenerateInvoiceDataFile: async (invoiceDataRequestDto: InvoiceDataRequestDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceDataRequestDto' is not null or undefined
            if (invoiceDataRequestDto === null || invoiceDataRequestDto === undefined) {
                throw new RequiredError('invoiceDataRequestDto','Required parameter invoiceDataRequestDto was null or undefined when calling invoiceGenerateInvoiceDataFile.');
            }
            const localVarPath = `/invoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof invoiceDataRequestDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(invoiceDataRequestDto !== undefined ? invoiceDataRequestDto : {})
                : (invoiceDataRequestDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoiceApi - functional programming interface
 * @export
 */
export const InvoiceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Generate invoice data file
         * @param {InvoiceDataRequestDto} invoiceDataRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoiceGenerateInvoiceDataFile(invoiceDataRequestDto: InvoiceDataRequestDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await InvoiceApiAxiosParamCreator(configuration).invoiceGenerateInvoiceDataFile(invoiceDataRequestDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * InvoiceApi - factory interface
 * @export
 */
export const InvoiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Generate invoice data file
         * @param {InvoiceDataRequestDto} invoiceDataRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceGenerateInvoiceDataFile(invoiceDataRequestDto: InvoiceDataRequestDto, options?: any): AxiosPromise<Array<string>> {
            return InvoiceApiFp(configuration).invoiceGenerateInvoiceDataFile(invoiceDataRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvoiceApi - object-oriented interface
 * @export
 * @class InvoiceApi
 * @extends {BaseAPI}
 */
export class InvoiceApi extends BaseAPI {
    /**
     * 
     * @summary Generate invoice data file
     * @param {InvoiceDataRequestDto} invoiceDataRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public invoiceGenerateInvoiceDataFile(invoiceDataRequestDto: InvoiceDataRequestDto, options?: any) {
        return InvoiceApiFp(this.configuration).invoiceGenerateInvoiceDataFile(invoiceDataRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LayoutApi - axios parameter creator
 * @export
 */
export const LayoutApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets side container layout for selected column
         * @param {ColumnSelectDto} columnSelectDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layoutGetSideLayout: async (columnSelectDto: ColumnSelectDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'columnSelectDto' is not null or undefined
            if (columnSelectDto === null || columnSelectDto === undefined) {
                throw new RequiredError('columnSelectDto','Required parameter columnSelectDto was null or undefined when calling layoutGetSideLayout.');
            }
            const localVarPath = `/layout/column`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof columnSelectDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(columnSelectDto !== undefined ? columnSelectDto : {})
                : (columnSelectDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets vertical container layout (floors) for selected coordinates
         * @param {StackContainerSearchDto} stackContainerSearchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layoutGetStackLayout: async (stackContainerSearchDto: StackContainerSearchDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'stackContainerSearchDto' is not null or undefined
            if (stackContainerSearchDto === null || stackContainerSearchDto === undefined) {
                throw new RequiredError('stackContainerSearchDto','Required parameter stackContainerSearchDto was null or undefined when calling layoutGetStackLayout.');
            }
            const localVarPath = `/layout/stack`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof stackContainerSearchDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(stackContainerSearchDto !== undefined ? stackContainerSearchDto : {})
                : (stackContainerSearchDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the current layout state on terminal - all sectors
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layoutGetTerminalLayout: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/layout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets side container layout for selected column
         * @param {SectorSelectDto} sectorSelectDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layoutGetUnorderedLayout: async (sectorSelectDto: SectorSelectDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sectorSelectDto' is not null or undefined
            if (sectorSelectDto === null || sectorSelectDto === undefined) {
                throw new RequiredError('sectorSelectDto','Required parameter sectorSelectDto was null or undefined when calling layoutGetUnorderedLayout.');
            }
            const localVarPath = `/layout/unordered`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof sectorSelectDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(sectorSelectDto !== undefined ? sectorSelectDto : {})
                : (sectorSelectDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LayoutApi - functional programming interface
 * @export
 */
export const LayoutApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets side container layout for selected column
         * @param {ColumnSelectDto} columnSelectDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async layoutGetSideLayout(columnSelectDto: ColumnSelectDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Array<LayoutContainerDto>>>> {
            const localVarAxiosArgs = await LayoutApiAxiosParamCreator(configuration).layoutGetSideLayout(columnSelectDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets vertical container layout (floors) for selected coordinates
         * @param {StackContainerSearchDto} stackContainerSearchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async layoutGetStackLayout(stackContainerSearchDto: StackContainerSearchDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StackContainerDto>>> {
            const localVarAxiosArgs = await LayoutApiAxiosParamCreator(configuration).layoutGetStackLayout(stackContainerSearchDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets the current layout state on terminal - all sectors
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async layoutGetTerminalLayout(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TerminalSectorLayoutDto>>> {
            const localVarAxiosArgs = await LayoutApiAxiosParamCreator(configuration).layoutGetTerminalLayout(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets side container layout for selected column
         * @param {SectorSelectDto} sectorSelectDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async layoutGetUnorderedLayout(sectorSelectDto: SectorSelectDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LayoutContainerDto>>> {
            const localVarAxiosArgs = await LayoutApiAxiosParamCreator(configuration).layoutGetUnorderedLayout(sectorSelectDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LayoutApi - factory interface
 * @export
 */
export const LayoutApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Gets side container layout for selected column
         * @param {ColumnSelectDto} columnSelectDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layoutGetSideLayout(columnSelectDto: ColumnSelectDto, options?: any): AxiosPromise<Array<Array<LayoutContainerDto>>> {
            return LayoutApiFp(configuration).layoutGetSideLayout(columnSelectDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets vertical container layout (floors) for selected coordinates
         * @param {StackContainerSearchDto} stackContainerSearchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layoutGetStackLayout(stackContainerSearchDto: StackContainerSearchDto, options?: any): AxiosPromise<Array<StackContainerDto>> {
            return LayoutApiFp(configuration).layoutGetStackLayout(stackContainerSearchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the current layout state on terminal - all sectors
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layoutGetTerminalLayout(options?: any): AxiosPromise<Array<TerminalSectorLayoutDto>> {
            return LayoutApiFp(configuration).layoutGetTerminalLayout(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets side container layout for selected column
         * @param {SectorSelectDto} sectorSelectDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layoutGetUnorderedLayout(sectorSelectDto: SectorSelectDto, options?: any): AxiosPromise<Array<LayoutContainerDto>> {
            return LayoutApiFp(configuration).layoutGetUnorderedLayout(sectorSelectDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LayoutApi - object-oriented interface
 * @export
 * @class LayoutApi
 * @extends {BaseAPI}
 */
export class LayoutApi extends BaseAPI {
    /**
     * 
     * @summary Gets side container layout for selected column
     * @param {ColumnSelectDto} columnSelectDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutApi
     */
    public layoutGetSideLayout(columnSelectDto: ColumnSelectDto, options?: any) {
        return LayoutApiFp(this.configuration).layoutGetSideLayout(columnSelectDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets vertical container layout (floors) for selected coordinates
     * @param {StackContainerSearchDto} stackContainerSearchDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutApi
     */
    public layoutGetStackLayout(stackContainerSearchDto: StackContainerSearchDto, options?: any) {
        return LayoutApiFp(this.configuration).layoutGetStackLayout(stackContainerSearchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the current layout state on terminal - all sectors
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutApi
     */
    public layoutGetTerminalLayout(options?: any) {
        return LayoutApiFp(this.configuration).layoutGetTerminalLayout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets side container layout for selected column
     * @param {SectorSelectDto} sectorSelectDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutApi
     */
    public layoutGetUnorderedLayout(sectorSelectDto: SectorSelectDto, options?: any) {
        return LayoutApiFp(this.configuration).layoutGetUnorderedLayout(sectorSelectDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LicensePlateApi - axios parameter creator
 * @export
 */
export const LicensePlateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create LicensePlate
         * @param {AsparkingLicensePlateDto} asparkingLicensePlateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        licensePlateCreate: async (asparkingLicensePlateDto: AsparkingLicensePlateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'asparkingLicensePlateDto' is not null or undefined
            if (asparkingLicensePlateDto === null || asparkingLicensePlateDto === undefined) {
                throw new RequiredError('asparkingLicensePlateDto','Required parameter asparkingLicensePlateDto was null or undefined when calling licensePlateCreate.');
            }
            const localVarPath = `/license-plate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof asparkingLicensePlateDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(asparkingLicensePlateDto !== undefined ? asparkingLicensePlateDto : {})
                : (asparkingLicensePlateDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete LicensePlate
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        licensePlateDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling licensePlateDelete.');
            }
            const localVarPath = `/license-plate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all LicensePlates
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        licensePlateFindAll: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/license-plate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find one LicensePlate by id.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        licensePlateFindById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling licensePlateFindById.');
            }
            const localVarPath = `/license-plate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch License Plate
         * @param {number} id 
         * @param {AsparkingLicensePlatePatchDto} asparkingLicensePlatePatchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        licensePlatePatch: async (id: number, asparkingLicensePlatePatchDto: AsparkingLicensePlatePatchDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling licensePlatePatch.');
            }
            // verify required parameter 'asparkingLicensePlatePatchDto' is not null or undefined
            if (asparkingLicensePlatePatchDto === null || asparkingLicensePlatePatchDto === undefined) {
                throw new RequiredError('asparkingLicensePlatePatchDto','Required parameter asparkingLicensePlatePatchDto was null or undefined when calling licensePlatePatch.');
            }
            const localVarPath = `/license-plate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof asparkingLicensePlatePatchDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(asparkingLicensePlatePatchDto !== undefined ? asparkingLicensePlatePatchDto : {})
                : (asparkingLicensePlatePatchDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search LicensePlate
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {AsparkingLicensePlateSearchDto} [asparkingLicensePlateSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        licensePlateSearch: async (page?: number, size?: number, sort?: Array<string>, asparkingLicensePlateSearchDto?: AsparkingLicensePlateSearchDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/license-plate/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof asparkingLicensePlateSearchDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(asparkingLicensePlateSearchDto !== undefined ? asparkingLicensePlateSearchDto : {})
                : (asparkingLicensePlateSearchDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update LicensePlate
         * @param {number} id 
         * @param {AsparkingLicensePlateDto} asparkingLicensePlateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        licensePlateUpdate: async (id: number, asparkingLicensePlateDto: AsparkingLicensePlateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling licensePlateUpdate.');
            }
            // verify required parameter 'asparkingLicensePlateDto' is not null or undefined
            if (asparkingLicensePlateDto === null || asparkingLicensePlateDto === undefined) {
                throw new RequiredError('asparkingLicensePlateDto','Required parameter asparkingLicensePlateDto was null or undefined when calling licensePlateUpdate.');
            }
            const localVarPath = `/license-plate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof asparkingLicensePlateDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(asparkingLicensePlateDto !== undefined ? asparkingLicensePlateDto : {})
                : (asparkingLicensePlateDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LicensePlateApi - functional programming interface
 * @export
 */
export const LicensePlateApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create LicensePlate
         * @param {AsparkingLicensePlateDto} asparkingLicensePlateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async licensePlateCreate(asparkingLicensePlateDto: AsparkingLicensePlateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AsparkingLicensePlateDto>> {
            const localVarAxiosArgs = await LicensePlateApiAxiosParamCreator(configuration).licensePlateCreate(asparkingLicensePlateDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete LicensePlate
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async licensePlateDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await LicensePlateApiAxiosParamCreator(configuration).licensePlateDelete(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Find all LicensePlates
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async licensePlateFindAll(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageAsparkingLicensePlateDto>> {
            const localVarAxiosArgs = await LicensePlateApiAxiosParamCreator(configuration).licensePlateFindAll(page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Find one LicensePlate by id.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async licensePlateFindById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AsparkingLicensePlateDto>> {
            const localVarAxiosArgs = await LicensePlateApiAxiosParamCreator(configuration).licensePlateFindById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Patch License Plate
         * @param {number} id 
         * @param {AsparkingLicensePlatePatchDto} asparkingLicensePlatePatchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async licensePlatePatch(id: number, asparkingLicensePlatePatchDto: AsparkingLicensePlatePatchDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AsparkingLicensePlateDto>> {
            const localVarAxiosArgs = await LicensePlateApiAxiosParamCreator(configuration).licensePlatePatch(id, asparkingLicensePlatePatchDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Search LicensePlate
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {AsparkingLicensePlateSearchDto} [asparkingLicensePlateSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async licensePlateSearch(page?: number, size?: number, sort?: Array<string>, asparkingLicensePlateSearchDto?: AsparkingLicensePlateSearchDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageAsparkingLicensePlateDto>> {
            const localVarAxiosArgs = await LicensePlateApiAxiosParamCreator(configuration).licensePlateSearch(page, size, sort, asparkingLicensePlateSearchDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update LicensePlate
         * @param {number} id 
         * @param {AsparkingLicensePlateDto} asparkingLicensePlateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async licensePlateUpdate(id: number, asparkingLicensePlateDto: AsparkingLicensePlateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AsparkingLicensePlateDto>> {
            const localVarAxiosArgs = await LicensePlateApiAxiosParamCreator(configuration).licensePlateUpdate(id, asparkingLicensePlateDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LicensePlateApi - factory interface
 * @export
 */
export const LicensePlateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create LicensePlate
         * @param {AsparkingLicensePlateDto} asparkingLicensePlateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        licensePlateCreate(asparkingLicensePlateDto: AsparkingLicensePlateDto, options?: any): AxiosPromise<AsparkingLicensePlateDto> {
            return LicensePlateApiFp(configuration).licensePlateCreate(asparkingLicensePlateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete LicensePlate
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        licensePlateDelete(id: number, options?: any): AxiosPromise<void> {
            return LicensePlateApiFp(configuration).licensePlateDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all LicensePlates
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        licensePlateFindAll(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageAsparkingLicensePlateDto> {
            return LicensePlateApiFp(configuration).licensePlateFindAll(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find one LicensePlate by id.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        licensePlateFindById(id: number, options?: any): AxiosPromise<AsparkingLicensePlateDto> {
            return LicensePlateApiFp(configuration).licensePlateFindById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch License Plate
         * @param {number} id 
         * @param {AsparkingLicensePlatePatchDto} asparkingLicensePlatePatchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        licensePlatePatch(id: number, asparkingLicensePlatePatchDto: AsparkingLicensePlatePatchDto, options?: any): AxiosPromise<AsparkingLicensePlateDto> {
            return LicensePlateApiFp(configuration).licensePlatePatch(id, asparkingLicensePlatePatchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search LicensePlate
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {AsparkingLicensePlateSearchDto} [asparkingLicensePlateSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        licensePlateSearch(page?: number, size?: number, sort?: Array<string>, asparkingLicensePlateSearchDto?: AsparkingLicensePlateSearchDto, options?: any): AxiosPromise<PageAsparkingLicensePlateDto> {
            return LicensePlateApiFp(configuration).licensePlateSearch(page, size, sort, asparkingLicensePlateSearchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update LicensePlate
         * @param {number} id 
         * @param {AsparkingLicensePlateDto} asparkingLicensePlateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        licensePlateUpdate(id: number, asparkingLicensePlateDto: AsparkingLicensePlateDto, options?: any): AxiosPromise<AsparkingLicensePlateDto> {
            return LicensePlateApiFp(configuration).licensePlateUpdate(id, asparkingLicensePlateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LicensePlateApi - object-oriented interface
 * @export
 * @class LicensePlateApi
 * @extends {BaseAPI}
 */
export class LicensePlateApi extends BaseAPI {
    /**
     * 
     * @summary Create LicensePlate
     * @param {AsparkingLicensePlateDto} asparkingLicensePlateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicensePlateApi
     */
    public licensePlateCreate(asparkingLicensePlateDto: AsparkingLicensePlateDto, options?: any) {
        return LicensePlateApiFp(this.configuration).licensePlateCreate(asparkingLicensePlateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete LicensePlate
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicensePlateApi
     */
    public licensePlateDelete(id: number, options?: any) {
        return LicensePlateApiFp(this.configuration).licensePlateDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all LicensePlates
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicensePlateApi
     */
    public licensePlateFindAll(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return LicensePlateApiFp(this.configuration).licensePlateFindAll(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find one LicensePlate by id.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicensePlateApi
     */
    public licensePlateFindById(id: number, options?: any) {
        return LicensePlateApiFp(this.configuration).licensePlateFindById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch License Plate
     * @param {number} id 
     * @param {AsparkingLicensePlatePatchDto} asparkingLicensePlatePatchDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicensePlateApi
     */
    public licensePlatePatch(id: number, asparkingLicensePlatePatchDto: AsparkingLicensePlatePatchDto, options?: any) {
        return LicensePlateApiFp(this.configuration).licensePlatePatch(id, asparkingLicensePlatePatchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search LicensePlate
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {AsparkingLicensePlateSearchDto} [asparkingLicensePlateSearchDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicensePlateApi
     */
    public licensePlateSearch(page?: number, size?: number, sort?: Array<string>, asparkingLicensePlateSearchDto?: AsparkingLicensePlateSearchDto, options?: any) {
        return LicensePlateApiFp(this.configuration).licensePlateSearch(page, size, sort, asparkingLicensePlateSearchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update LicensePlate
     * @param {number} id 
     * @param {AsparkingLicensePlateDto} asparkingLicensePlateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicensePlateApi
     */
    public licensePlateUpdate(id: number, asparkingLicensePlateDto: AsparkingLicensePlateDto, options?: any) {
        return LicensePlateApiFp(this.configuration).licensePlateUpdate(id, asparkingLicensePlateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * M2mTrainApi - axios parameter creator
 * @export
 */
export const M2mTrainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} trainId Id of configuration
         * @param {number} m2mTrainId Id of train
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        m2mTrainCompare: async (trainId: number, m2mTrainId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'trainId' is not null or undefined
            if (trainId === null || trainId === undefined) {
                throw new RequiredError('trainId','Required parameter trainId was null or undefined when calling m2mTrainCompare.');
            }
            // verify required parameter 'm2mTrainId' is not null or undefined
            if (m2mTrainId === null || m2mTrainId === undefined) {
                throw new RequiredError('m2mTrainId','Required parameter m2mTrainId was null or undefined when calling m2mTrainCompare.');
            }
            const localVarPath = `/m2m-train/compare/{trainId}/{m2mTrainId}`
                .replace(`{${"trainId"}}`, encodeURIComponent(String(trainId)))
                .replace(`{${"m2mTrainId"}}`, encodeURIComponent(String(m2mTrainId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} trainId Id of configuration
         * @param {number} m2mTrainId Id of train
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        m2mTrainDownloadExport: async (trainId: number, m2mTrainId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'trainId' is not null or undefined
            if (trainId === null || trainId === undefined) {
                throw new RequiredError('trainId','Required parameter trainId was null or undefined when calling m2mTrainDownloadExport.');
            }
            // verify required parameter 'm2mTrainId' is not null or undefined
            if (m2mTrainId === null || m2mTrainId === undefined) {
                throw new RequiredError('m2mTrainId','Required parameter m2mTrainId was null or undefined when calling m2mTrainDownloadExport.');
            }
            const localVarPath = `/m2m-train/compare/export/{trainId}/{m2mTrainId}`
                .replace(`{${"trainId"}}`, encodeURIComponent(String(trainId)))
                .replace(`{${"m2mTrainId"}}`, encodeURIComponent(String(m2mTrainId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {M2mTrainSearchDto} [m2mTrainSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        m2mTrainSearch: async (page?: number, size?: number, sort?: Array<string>, m2mTrainSearchDto?: M2mTrainSearchDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/m2m-train/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof m2mTrainSearchDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(m2mTrainSearchDto !== undefined ? m2mTrainSearchDto : {})
                : (m2mTrainSearchDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * M2mTrainApi - functional programming interface
 * @export
 */
export const M2mTrainApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} trainId Id of configuration
         * @param {number} m2mTrainId Id of train
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async m2mTrainCompare(trainId: number, m2mTrainId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrainCompareResultDto>> {
            const localVarAxiosArgs = await M2mTrainApiAxiosParamCreator(configuration).m2mTrainCompare(trainId, m2mTrainId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} trainId Id of configuration
         * @param {number} m2mTrainId Id of train
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async m2mTrainDownloadExport(trainId: number, m2mTrainId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await M2mTrainApiAxiosParamCreator(configuration).m2mTrainDownloadExport(trainId, m2mTrainId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {M2mTrainSearchDto} [m2mTrainSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async m2mTrainSearch(page?: number, size?: number, sort?: Array<string>, m2mTrainSearchDto?: M2mTrainSearchDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageM2mTrainDto>> {
            const localVarAxiosArgs = await M2mTrainApiAxiosParamCreator(configuration).m2mTrainSearch(page, size, sort, m2mTrainSearchDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * M2mTrainApi - factory interface
 * @export
 */
export const M2mTrainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} trainId Id of configuration
         * @param {number} m2mTrainId Id of train
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        m2mTrainCompare(trainId: number, m2mTrainId: number, options?: any): AxiosPromise<TrainCompareResultDto> {
            return M2mTrainApiFp(configuration).m2mTrainCompare(trainId, m2mTrainId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} trainId Id of configuration
         * @param {number} m2mTrainId Id of train
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        m2mTrainDownloadExport(trainId: number, m2mTrainId: number, options?: any): AxiosPromise<Array<string>> {
            return M2mTrainApiFp(configuration).m2mTrainDownloadExport(trainId, m2mTrainId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {M2mTrainSearchDto} [m2mTrainSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        m2mTrainSearch(page?: number, size?: number, sort?: Array<string>, m2mTrainSearchDto?: M2mTrainSearchDto, options?: any): AxiosPromise<PageM2mTrainDto> {
            return M2mTrainApiFp(configuration).m2mTrainSearch(page, size, sort, m2mTrainSearchDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * M2mTrainApi - object-oriented interface
 * @export
 * @class M2mTrainApi
 * @extends {BaseAPI}
 */
export class M2mTrainApi extends BaseAPI {
    /**
     * 
     * @param {number} trainId Id of configuration
     * @param {number} m2mTrainId Id of train
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof M2mTrainApi
     */
    public m2mTrainCompare(trainId: number, m2mTrainId: number, options?: any) {
        return M2mTrainApiFp(this.configuration).m2mTrainCompare(trainId, m2mTrainId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} trainId Id of configuration
     * @param {number} m2mTrainId Id of train
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof M2mTrainApi
     */
    public m2mTrainDownloadExport(trainId: number, m2mTrainId: number, options?: any) {
        return M2mTrainApiFp(this.configuration).m2mTrainDownloadExport(trainId, m2mTrainId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {M2mTrainSearchDto} [m2mTrainSearchDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof M2mTrainApi
     */
    public m2mTrainSearch(page?: number, size?: number, sort?: Array<string>, m2mTrainSearchDto?: M2mTrainSearchDto, options?: any) {
        return M2mTrainApiFp(this.configuration).m2mTrainSearch(page, size, sort, m2mTrainSearchDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ManipulationRequestApi - axios parameter creator
 * @export
 */
export const ManipulationRequestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel manipulation request
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manipulationRequestCancel: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling manipulationRequestCancel.');
            }
            const localVarPath = `/manipulation-request/cancel/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates new manipulation request
         * @param {ManipulationRequestDto} manipulationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manipulationRequestCreate: async (manipulationRequestDto: ManipulationRequestDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'manipulationRequestDto' is not null or undefined
            if (manipulationRequestDto === null || manipulationRequestDto === undefined) {
                throw new RequiredError('manipulationRequestDto','Required parameter manipulationRequestDto was null or undefined when calling manipulationRequestCreate.');
            }
            const localVarPath = `/manipulation-request/new`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof manipulationRequestDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(manipulationRequestDto !== undefined ? manipulationRequestDto : {})
                : (manipulationRequestDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all pending manipulation requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manipulationRequestGetPending: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/manipulation-request/pending`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Finish manipulation request
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manipulationRequestStore: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling manipulationRequestStore.');
            }
            const localVarPath = `/manipulation-request/store/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ManipulationRequestApi - functional programming interface
 * @export
 */
export const ManipulationRequestApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel manipulation request
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manipulationRequestCancel(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManipulationRequestDto>> {
            const localVarAxiosArgs = await ManipulationRequestApiAxiosParamCreator(configuration).manipulationRequestCancel(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Creates new manipulation request
         * @param {ManipulationRequestDto} manipulationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manipulationRequestCreate(manipulationRequestDto: ManipulationRequestDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManipulationRequestDto>> {
            const localVarAxiosArgs = await ManipulationRequestApiAxiosParamCreator(configuration).manipulationRequestCreate(manipulationRequestDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all pending manipulation requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manipulationRequestGetPending(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ManipulationRequestDto>>> {
            const localVarAxiosArgs = await ManipulationRequestApiAxiosParamCreator(configuration).manipulationRequestGetPending(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Finish manipulation request
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manipulationRequestStore(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManipulationRequestDto>> {
            const localVarAxiosArgs = await ManipulationRequestApiAxiosParamCreator(configuration).manipulationRequestStore(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ManipulationRequestApi - factory interface
 * @export
 */
export const ManipulationRequestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Cancel manipulation request
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manipulationRequestCancel(id: number, options?: any): AxiosPromise<ManipulationRequestDto> {
            return ManipulationRequestApiFp(configuration).manipulationRequestCancel(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates new manipulation request
         * @param {ManipulationRequestDto} manipulationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manipulationRequestCreate(manipulationRequestDto: ManipulationRequestDto, options?: any): AxiosPromise<ManipulationRequestDto> {
            return ManipulationRequestApiFp(configuration).manipulationRequestCreate(manipulationRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all pending manipulation requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manipulationRequestGetPending(options?: any): AxiosPromise<Array<ManipulationRequestDto>> {
            return ManipulationRequestApiFp(configuration).manipulationRequestGetPending(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Finish manipulation request
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manipulationRequestStore(id: number, options?: any): AxiosPromise<ManipulationRequestDto> {
            return ManipulationRequestApiFp(configuration).manipulationRequestStore(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ManipulationRequestApi - object-oriented interface
 * @export
 * @class ManipulationRequestApi
 * @extends {BaseAPI}
 */
export class ManipulationRequestApi extends BaseAPI {
    /**
     * 
     * @summary Cancel manipulation request
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManipulationRequestApi
     */
    public manipulationRequestCancel(id: number, options?: any) {
        return ManipulationRequestApiFp(this.configuration).manipulationRequestCancel(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates new manipulation request
     * @param {ManipulationRequestDto} manipulationRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManipulationRequestApi
     */
    public manipulationRequestCreate(manipulationRequestDto: ManipulationRequestDto, options?: any) {
        return ManipulationRequestApiFp(this.configuration).manipulationRequestCreate(manipulationRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all pending manipulation requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManipulationRequestApi
     */
    public manipulationRequestGetPending(options?: any) {
        return ManipulationRequestApiFp(this.configuration).manipulationRequestGetPending(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Finish manipulation request
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManipulationRequestApi
     */
    public manipulationRequestStore(id: number, options?: any) {
        return ManipulationRequestApiFp(this.configuration).manipulationRequestStore(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NhmApi - axios parameter creator
 * @export
 */
export const NhmApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nhmFindAllCodes: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/nhm/code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nhmFindAllCommodityGroups: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/nhm/commodity-group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nhmFindAllGroups: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/nhm/group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {NhmCodeSearchDto} [nhmCodeSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nhmSearchCode: async (page?: number, size?: number, sort?: Array<string>, nhmCodeSearchDto?: NhmCodeSearchDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/nhm/code/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof nhmCodeSearchDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(nhmCodeSearchDto !== undefined ? nhmCodeSearchDto : {})
                : (nhmCodeSearchDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {NhmCommodityGroupSearchDto} [nhmCommodityGroupSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nhmSearchCommodityGroup: async (page?: number, size?: number, sort?: Array<string>, nhmCommodityGroupSearchDto?: NhmCommodityGroupSearchDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/nhm/commodity-group/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof nhmCommodityGroupSearchDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(nhmCommodityGroupSearchDto !== undefined ? nhmCommodityGroupSearchDto : {})
                : (nhmCommodityGroupSearchDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {NhmGroupSearchDto} [nhmGroupSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nhmSearchGroup: async (page?: number, size?: number, sort?: Array<string>, nhmGroupSearchDto?: NhmGroupSearchDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/nhm/group/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof nhmGroupSearchDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(nhmGroupSearchDto !== undefined ? nhmGroupSearchDto : {})
                : (nhmGroupSearchDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NhmApi - functional programming interface
 * @export
 */
export const NhmApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nhmFindAllCodes(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageNhmCodeDto>> {
            const localVarAxiosArgs = await NhmApiAxiosParamCreator(configuration).nhmFindAllCodes(page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nhmFindAllCommodityGroups(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageNhmCommodityGroupDto>> {
            const localVarAxiosArgs = await NhmApiAxiosParamCreator(configuration).nhmFindAllCommodityGroups(page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nhmFindAllGroups(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageNhmGroupDto>> {
            const localVarAxiosArgs = await NhmApiAxiosParamCreator(configuration).nhmFindAllGroups(page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {NhmCodeSearchDto} [nhmCodeSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nhmSearchCode(page?: number, size?: number, sort?: Array<string>, nhmCodeSearchDto?: NhmCodeSearchDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageNhmCodeDto>> {
            const localVarAxiosArgs = await NhmApiAxiosParamCreator(configuration).nhmSearchCode(page, size, sort, nhmCodeSearchDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {NhmCommodityGroupSearchDto} [nhmCommodityGroupSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nhmSearchCommodityGroup(page?: number, size?: number, sort?: Array<string>, nhmCommodityGroupSearchDto?: NhmCommodityGroupSearchDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageNhmCommodityGroupDto>> {
            const localVarAxiosArgs = await NhmApiAxiosParamCreator(configuration).nhmSearchCommodityGroup(page, size, sort, nhmCommodityGroupSearchDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {NhmGroupSearchDto} [nhmGroupSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nhmSearchGroup(page?: number, size?: number, sort?: Array<string>, nhmGroupSearchDto?: NhmGroupSearchDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageNhmGroupDto>> {
            const localVarAxiosArgs = await NhmApiAxiosParamCreator(configuration).nhmSearchGroup(page, size, sort, nhmGroupSearchDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * NhmApi - factory interface
 * @export
 */
export const NhmApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nhmFindAllCodes(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageNhmCodeDto> {
            return NhmApiFp(configuration).nhmFindAllCodes(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nhmFindAllCommodityGroups(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageNhmCommodityGroupDto> {
            return NhmApiFp(configuration).nhmFindAllCommodityGroups(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nhmFindAllGroups(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageNhmGroupDto> {
            return NhmApiFp(configuration).nhmFindAllGroups(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {NhmCodeSearchDto} [nhmCodeSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nhmSearchCode(page?: number, size?: number, sort?: Array<string>, nhmCodeSearchDto?: NhmCodeSearchDto, options?: any): AxiosPromise<PageNhmCodeDto> {
            return NhmApiFp(configuration).nhmSearchCode(page, size, sort, nhmCodeSearchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {NhmCommodityGroupSearchDto} [nhmCommodityGroupSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nhmSearchCommodityGroup(page?: number, size?: number, sort?: Array<string>, nhmCommodityGroupSearchDto?: NhmCommodityGroupSearchDto, options?: any): AxiosPromise<PageNhmCommodityGroupDto> {
            return NhmApiFp(configuration).nhmSearchCommodityGroup(page, size, sort, nhmCommodityGroupSearchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {NhmGroupSearchDto} [nhmGroupSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nhmSearchGroup(page?: number, size?: number, sort?: Array<string>, nhmGroupSearchDto?: NhmGroupSearchDto, options?: any): AxiosPromise<PageNhmGroupDto> {
            return NhmApiFp(configuration).nhmSearchGroup(page, size, sort, nhmGroupSearchDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NhmApi - object-oriented interface
 * @export
 * @class NhmApi
 * @extends {BaseAPI}
 */
export class NhmApi extends BaseAPI {
    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NhmApi
     */
    public nhmFindAllCodes(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return NhmApiFp(this.configuration).nhmFindAllCodes(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NhmApi
     */
    public nhmFindAllCommodityGroups(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return NhmApiFp(this.configuration).nhmFindAllCommodityGroups(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NhmApi
     */
    public nhmFindAllGroups(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return NhmApiFp(this.configuration).nhmFindAllGroups(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {NhmCodeSearchDto} [nhmCodeSearchDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NhmApi
     */
    public nhmSearchCode(page?: number, size?: number, sort?: Array<string>, nhmCodeSearchDto?: NhmCodeSearchDto, options?: any) {
        return NhmApiFp(this.configuration).nhmSearchCode(page, size, sort, nhmCodeSearchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {NhmCommodityGroupSearchDto} [nhmCommodityGroupSearchDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NhmApi
     */
    public nhmSearchCommodityGroup(page?: number, size?: number, sort?: Array<string>, nhmCommodityGroupSearchDto?: NhmCommodityGroupSearchDto, options?: any) {
        return NhmApiFp(this.configuration).nhmSearchCommodityGroup(page, size, sort, nhmCommodityGroupSearchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {NhmGroupSearchDto} [nhmGroupSearchDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NhmApi
     */
    public nhmSearchGroup(page?: number, size?: number, sort?: Array<string>, nhmGroupSearchDto?: NhmGroupSearchDto, options?: any) {
        return NhmApiFp(this.configuration).nhmSearchGroup(page, size, sort, nhmGroupSearchDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationControllerApi - axios parameter creator
 * @export
 */
export const NotificationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete notification
         * @param {number} id Id of notification to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling _delete.');
            }
            const localVarPath = `/notification/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new notification
         * @param {NotificationDto} notificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (notificationDto: NotificationDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationDto' is not null or undefined
            if (notificationDto === null || notificationDto === undefined) {
                throw new RequiredError('notificationDto','Required parameter notificationDto was null or undefined when calling create.');
            }
            const localVarPath = `/notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof notificationDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(notificationDto !== undefined ? notificationDto : {})
                : (notificationDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all notifications
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all non-resolved notifications on road
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllForRoadDispatcher: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/notification/road`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all non-resolved notifications on trains
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllForTrainDispatcher: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/notification/train`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find notification by its id.
         * @param {number} id Id of notification to be found
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findById.');
            }
            const localVarPath = `/notification/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns unprocessed notifications for classic dispatcher
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNew: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/notification/new`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns unprocessed notifications for road dispatcher
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewRoad: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/notification/new/road`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns unprocessed notifications for train dispatcher
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewTrain: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/notification/new/train`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove all notifications
         * @param {Array<number>} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAllNotifications: async (ids: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            if (ids === null || ids === undefined) {
                throw new RequiredError('ids','Required parameter ids was null or undefined when calling removeAllNotifications.');
            }
            const localVarPath = `/notification/remove-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {NotificationSearchDto} [notificationSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search: async (page?: number, size?: number, sort?: Array<string>, notificationSearchDto?: NotificationSearchDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/notification/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof notificationSearchDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(notificationSearchDto !== undefined ? notificationSearchDto : {})
                : (notificationSearchDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update notification
         * @param {number} id Id of notification to be updated
         * @param {NotificationDto} notificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: number, notificationDto: NotificationDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling update.');
            }
            // verify required parameter 'notificationDto' is not null or undefined
            if (notificationDto === null || notificationDto === undefined) {
                throw new RequiredError('notificationDto','Required parameter notificationDto was null or undefined when calling update.');
            }
            const localVarPath = `/notification/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof notificationDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(notificationDto !== undefined ? notificationDto : {})
                : (notificationDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationControllerApi - functional programming interface
 * @export
 */
export const NotificationControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete notification
         * @param {number} id Id of notification to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await NotificationControllerApiAxiosParamCreator(configuration)._delete(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create new notification
         * @param {NotificationDto} notificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(notificationDto: NotificationDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationDto>> {
            const localVarAxiosArgs = await NotificationControllerApiAxiosParamCreator(configuration).create(notificationDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Find all notifications
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAll(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageNotificationDto>> {
            const localVarAxiosArgs = await NotificationControllerApiAxiosParamCreator(configuration).findAll(page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Find all non-resolved notifications on road
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllForRoadDispatcher(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageNotificationDto>> {
            const localVarAxiosArgs = await NotificationControllerApiAxiosParamCreator(configuration).findAllForRoadDispatcher(page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Find all non-resolved notifications on trains
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllForTrainDispatcher(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageNotificationDto>> {
            const localVarAxiosArgs = await NotificationControllerApiAxiosParamCreator(configuration).findAllForTrainDispatcher(page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Find notification by its id.
         * @param {number} id Id of notification to be found
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationDto>> {
            const localVarAxiosArgs = await NotificationControllerApiAxiosParamCreator(configuration).findById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Returns unprocessed notifications for classic dispatcher
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNew(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NotificationDto>>> {
            const localVarAxiosArgs = await NotificationControllerApiAxiosParamCreator(configuration).getNew(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Returns unprocessed notifications for road dispatcher
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNewRoad(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NotificationDto>>> {
            const localVarAxiosArgs = await NotificationControllerApiAxiosParamCreator(configuration).getNewRoad(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Returns unprocessed notifications for train dispatcher
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNewTrain(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NotificationDto>>> {
            const localVarAxiosArgs = await NotificationControllerApiAxiosParamCreator(configuration).getNewTrain(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Remove all notifications
         * @param {Array<number>} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeAllNotifications(ids: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await NotificationControllerApiAxiosParamCreator(configuration).removeAllNotifications(ids, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {NotificationSearchDto} [notificationSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async search(page?: number, size?: number, sort?: Array<string>, notificationSearchDto?: NotificationSearchDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageNotificationDto>> {
            const localVarAxiosArgs = await NotificationControllerApiAxiosParamCreator(configuration).search(page, size, sort, notificationSearchDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update notification
         * @param {number} id Id of notification to be updated
         * @param {NotificationDto} notificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: number, notificationDto: NotificationDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationDto>> {
            const localVarAxiosArgs = await NotificationControllerApiAxiosParamCreator(configuration).update(id, notificationDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * NotificationControllerApi - factory interface
 * @export
 */
export const NotificationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Delete notification
         * @param {number} id Id of notification to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(id: number, options?: any): AxiosPromise<void> {
            return NotificationControllerApiFp(configuration)._delete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new notification
         * @param {NotificationDto} notificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(notificationDto: NotificationDto, options?: any): AxiosPromise<NotificationDto> {
            return NotificationControllerApiFp(configuration).create(notificationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all notifications
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageNotificationDto> {
            return NotificationControllerApiFp(configuration).findAll(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all non-resolved notifications on road
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllForRoadDispatcher(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageNotificationDto> {
            return NotificationControllerApiFp(configuration).findAllForRoadDispatcher(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all non-resolved notifications on trains
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllForTrainDispatcher(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageNotificationDto> {
            return NotificationControllerApiFp(configuration).findAllForTrainDispatcher(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find notification by its id.
         * @param {number} id Id of notification to be found
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findById(id: number, options?: any): AxiosPromise<NotificationDto> {
            return NotificationControllerApiFp(configuration).findById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns unprocessed notifications for classic dispatcher
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNew(options?: any): AxiosPromise<Array<NotificationDto>> {
            return NotificationControllerApiFp(configuration).getNew(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns unprocessed notifications for road dispatcher
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewRoad(options?: any): AxiosPromise<Array<NotificationDto>> {
            return NotificationControllerApiFp(configuration).getNewRoad(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns unprocessed notifications for train dispatcher
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewTrain(options?: any): AxiosPromise<Array<NotificationDto>> {
            return NotificationControllerApiFp(configuration).getNewTrain(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove all notifications
         * @param {Array<number>} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAllNotifications(ids: Array<number>, options?: any): AxiosPromise<void> {
            return NotificationControllerApiFp(configuration).removeAllNotifications(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {NotificationSearchDto} [notificationSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search(page?: number, size?: number, sort?: Array<string>, notificationSearchDto?: NotificationSearchDto, options?: any): AxiosPromise<PageNotificationDto> {
            return NotificationControllerApiFp(configuration).search(page, size, sort, notificationSearchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update notification
         * @param {number} id Id of notification to be updated
         * @param {NotificationDto} notificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: number, notificationDto: NotificationDto, options?: any): AxiosPromise<NotificationDto> {
            return NotificationControllerApiFp(configuration).update(id, notificationDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationControllerApi - object-oriented interface
 * @export
 * @class NotificationControllerApi
 * @extends {BaseAPI}
 */
export class NotificationControllerApi extends BaseAPI {
    /**
     * 
     * @summary Delete notification
     * @param {number} id Id of notification to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public _delete(id: number, options?: any) {
        return NotificationControllerApiFp(this.configuration)._delete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new notification
     * @param {NotificationDto} notificationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public create(notificationDto: NotificationDto, options?: any) {
        return NotificationControllerApiFp(this.configuration).create(notificationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all notifications
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public findAll(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return NotificationControllerApiFp(this.configuration).findAll(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all non-resolved notifications on road
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public findAllForRoadDispatcher(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return NotificationControllerApiFp(this.configuration).findAllForRoadDispatcher(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all non-resolved notifications on trains
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public findAllForTrainDispatcher(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return NotificationControllerApiFp(this.configuration).findAllForTrainDispatcher(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find notification by its id.
     * @param {number} id Id of notification to be found
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public findById(id: number, options?: any) {
        return NotificationControllerApiFp(this.configuration).findById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns unprocessed notifications for classic dispatcher
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public getNew(options?: any) {
        return NotificationControllerApiFp(this.configuration).getNew(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns unprocessed notifications for road dispatcher
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public getNewRoad(options?: any) {
        return NotificationControllerApiFp(this.configuration).getNewRoad(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns unprocessed notifications for train dispatcher
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public getNewTrain(options?: any) {
        return NotificationControllerApiFp(this.configuration).getNewTrain(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove all notifications
     * @param {Array<number>} ids 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public removeAllNotifications(ids: Array<number>, options?: any) {
        return NotificationControllerApiFp(this.configuration).removeAllNotifications(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {NotificationSearchDto} [notificationSearchDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public search(page?: number, size?: number, sort?: Array<string>, notificationSearchDto?: NotificationSearchDto, options?: any) {
        return NotificationControllerApiFp(this.configuration).search(page, size, sort, notificationSearchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update notification
     * @param {number} id Id of notification to be updated
     * @param {NotificationDto} notificationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public update(id: number, notificationDto: NotificationDto, options?: any) {
        return NotificationControllerApiFp(this.configuration).update(id, notificationDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PriceListApi - axios parameter creator
 * @export
 */
export const PriceListApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Find all price list items
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceListFindAll: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/price-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find a price list item by its id.
         * @param {number} id Id of price list item to be found
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceListFindById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling priceListFindById.');
            }
            const localVarPath = `/price-list/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update price list item
         * @param {number} id Id of price list item to be updated
         * @param {PriceListDto} priceListDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceListUpdate: async (id: number, priceListDto: PriceListDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling priceListUpdate.');
            }
            // verify required parameter 'priceListDto' is not null or undefined
            if (priceListDto === null || priceListDto === undefined) {
                throw new RequiredError('priceListDto','Required parameter priceListDto was null or undefined when calling priceListUpdate.');
            }
            const localVarPath = `/price-list/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof priceListDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(priceListDto !== undefined ? priceListDto : {})
                : (priceListDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PriceListApi - functional programming interface
 * @export
 */
export const PriceListApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Find all price list items
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async priceListFindAll(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagePriceListDto>> {
            const localVarAxiosArgs = await PriceListApiAxiosParamCreator(configuration).priceListFindAll(page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Find a price list item by its id.
         * @param {number} id Id of price list item to be found
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async priceListFindById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceListDto>> {
            const localVarAxiosArgs = await PriceListApiAxiosParamCreator(configuration).priceListFindById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update price list item
         * @param {number} id Id of price list item to be updated
         * @param {PriceListDto} priceListDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async priceListUpdate(id: number, priceListDto: PriceListDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceListDto>> {
            const localVarAxiosArgs = await PriceListApiAxiosParamCreator(configuration).priceListUpdate(id, priceListDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PriceListApi - factory interface
 * @export
 */
export const PriceListApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Find all price list items
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceListFindAll(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PagePriceListDto> {
            return PriceListApiFp(configuration).priceListFindAll(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find a price list item by its id.
         * @param {number} id Id of price list item to be found
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceListFindById(id: number, options?: any): AxiosPromise<PriceListDto> {
            return PriceListApiFp(configuration).priceListFindById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update price list item
         * @param {number} id Id of price list item to be updated
         * @param {PriceListDto} priceListDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceListUpdate(id: number, priceListDto: PriceListDto, options?: any): AxiosPromise<PriceListDto> {
            return PriceListApiFp(configuration).priceListUpdate(id, priceListDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PriceListApi - object-oriented interface
 * @export
 * @class PriceListApi
 * @extends {BaseAPI}
 */
export class PriceListApi extends BaseAPI {
    /**
     * 
     * @summary Find all price list items
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceListApi
     */
    public priceListFindAll(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return PriceListApiFp(this.configuration).priceListFindAll(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find a price list item by its id.
     * @param {number} id Id of price list item to be found
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceListApi
     */
    public priceListFindById(id: number, options?: any) {
        return PriceListApiFp(this.configuration).priceListFindById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update price list item
     * @param {number} id Id of price list item to be updated
     * @param {PriceListDto} priceListDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceListApi
     */
    public priceListUpdate(id: number, priceListDto: PriceListDto, options?: any) {
        return PriceListApiFp(this.configuration).priceListUpdate(id, priceListDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProcessedFileApi - axios parameter creator
 * @export
 */
export const ProcessedFileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processedFileDownloadProcessedFile: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling processedFileDownloadProcessedFile.');
            }
            const localVarPath = `/processed-file/{id}/download`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {ProcessedFileSearchDto} [processedFileSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processedFileSearch: async (page?: number, size?: number, sort?: Array<string>, processedFileSearchDto?: ProcessedFileSearchDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/processed-file/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof processedFileSearchDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(processedFileSearchDto !== undefined ? processedFileSearchDto : {})
                : (processedFileSearchDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} trainId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processedFileSearchLatestCdCargo: async (trainId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'trainId' is not null or undefined
            if (trainId === null || trainId === undefined) {
                throw new RequiredError('trainId','Required parameter trainId was null or undefined when calling processedFileSearchLatestCdCargo.');
            }
            const localVarPath = `/processed-file/cdcargo/{trainId}`
                .replace(`{${"trainId"}}`, encodeURIComponent(String(trainId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} transitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processedFileSearchLatestCodeco: async (transitionId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transitionId' is not null or undefined
            if (transitionId === null || transitionId === undefined) {
                throw new RequiredError('transitionId','Required parameter transitionId was null or undefined when calling processedFileSearchLatestCodeco.');
            }
            const localVarPath = `/processed-file/codeco/{transitionId}`
                .replace(`{${"transitionId"}}`, encodeURIComponent(String(transitionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} trainId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processedFileSearchLatestVeronaMessage12: async (trainId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'trainId' is not null or undefined
            if (trainId === null || trainId === undefined) {
                throw new RequiredError('trainId','Required parameter trainId was null or undefined when calling processedFileSearchLatestVeronaMessage12.');
            }
            const localVarPath = `/processed-file/verona-message12/{trainId}`
                .replace(`{${"trainId"}}`, encodeURIComponent(String(trainId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} transitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processedFileSearchLatestVgmProtocol: async (transitionId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transitionId' is not null or undefined
            if (transitionId === null || transitionId === undefined) {
                throw new RequiredError('transitionId','Required parameter transitionId was null or undefined when calling processedFileSearchLatestVgmProtocol.');
            }
            const localVarPath = `/processed-file/vgm/{transitionId}`
                .replace(`{${"transitionId"}}`, encodeURIComponent(String(transitionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProcessedFileApi - functional programming interface
 * @export
 */
export const ProcessedFileApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processedFileDownloadProcessedFile(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await ProcessedFileApiAxiosParamCreator(configuration).processedFileDownloadProcessedFile(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {ProcessedFileSearchDto} [processedFileSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processedFileSearch(page?: number, size?: number, sort?: Array<string>, processedFileSearchDto?: ProcessedFileSearchDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageProcessedFileDto>> {
            const localVarAxiosArgs = await ProcessedFileApiAxiosParamCreator(configuration).processedFileSearch(page, size, sort, processedFileSearchDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} trainId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processedFileSearchLatestCdCargo(trainId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProcessedFileDto>> {
            const localVarAxiosArgs = await ProcessedFileApiAxiosParamCreator(configuration).processedFileSearchLatestCdCargo(trainId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} transitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processedFileSearchLatestCodeco(transitionId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProcessedFileDto>> {
            const localVarAxiosArgs = await ProcessedFileApiAxiosParamCreator(configuration).processedFileSearchLatestCodeco(transitionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} trainId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processedFileSearchLatestVeronaMessage12(trainId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProcessedFileDto>> {
            const localVarAxiosArgs = await ProcessedFileApiAxiosParamCreator(configuration).processedFileSearchLatestVeronaMessage12(trainId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} transitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processedFileSearchLatestVgmProtocol(transitionId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProcessedFileDto>> {
            const localVarAxiosArgs = await ProcessedFileApiAxiosParamCreator(configuration).processedFileSearchLatestVgmProtocol(transitionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ProcessedFileApi - factory interface
 * @export
 */
export const ProcessedFileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processedFileDownloadProcessedFile(id: string, options?: any): AxiosPromise<Array<string>> {
            return ProcessedFileApiFp(configuration).processedFileDownloadProcessedFile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {ProcessedFileSearchDto} [processedFileSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processedFileSearch(page?: number, size?: number, sort?: Array<string>, processedFileSearchDto?: ProcessedFileSearchDto, options?: any): AxiosPromise<PageProcessedFileDto> {
            return ProcessedFileApiFp(configuration).processedFileSearch(page, size, sort, processedFileSearchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} trainId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processedFileSearchLatestCdCargo(trainId: number, options?: any): AxiosPromise<ProcessedFileDto> {
            return ProcessedFileApiFp(configuration).processedFileSearchLatestCdCargo(trainId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} transitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processedFileSearchLatestCodeco(transitionId: number, options?: any): AxiosPromise<ProcessedFileDto> {
            return ProcessedFileApiFp(configuration).processedFileSearchLatestCodeco(transitionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} trainId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processedFileSearchLatestVeronaMessage12(trainId: number, options?: any): AxiosPromise<ProcessedFileDto> {
            return ProcessedFileApiFp(configuration).processedFileSearchLatestVeronaMessage12(trainId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} transitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processedFileSearchLatestVgmProtocol(transitionId: number, options?: any): AxiosPromise<ProcessedFileDto> {
            return ProcessedFileApiFp(configuration).processedFileSearchLatestVgmProtocol(transitionId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProcessedFileApi - object-oriented interface
 * @export
 * @class ProcessedFileApi
 * @extends {BaseAPI}
 */
export class ProcessedFileApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessedFileApi
     */
    public processedFileDownloadProcessedFile(id: string, options?: any) {
        return ProcessedFileApiFp(this.configuration).processedFileDownloadProcessedFile(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {ProcessedFileSearchDto} [processedFileSearchDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessedFileApi
     */
    public processedFileSearch(page?: number, size?: number, sort?: Array<string>, processedFileSearchDto?: ProcessedFileSearchDto, options?: any) {
        return ProcessedFileApiFp(this.configuration).processedFileSearch(page, size, sort, processedFileSearchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} trainId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessedFileApi
     */
    public processedFileSearchLatestCdCargo(trainId: number, options?: any) {
        return ProcessedFileApiFp(this.configuration).processedFileSearchLatestCdCargo(trainId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} transitionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessedFileApi
     */
    public processedFileSearchLatestCodeco(transitionId: number, options?: any) {
        return ProcessedFileApiFp(this.configuration).processedFileSearchLatestCodeco(transitionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} trainId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessedFileApi
     */
    public processedFileSearchLatestVeronaMessage12(trainId: number, options?: any) {
        return ProcessedFileApiFp(this.configuration).processedFileSearchLatestVeronaMessage12(trainId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} transitionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessedFileApi
     */
    public processedFileSearchLatestVgmProtocol(transitionId: number, options?: any) {
        return ProcessedFileApiFp(this.configuration).processedFileSearchLatestVgmProtocol(transitionId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RidApi - axios parameter creator
 * @export
 */
export const RidApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ridFindAllCodes: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/rid/code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {AdrRidCodeSearchDto} [adrRidCodeSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ridSearchCode: async (page?: number, size?: number, sort?: Array<string>, adrRidCodeSearchDto?: AdrRidCodeSearchDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/rid/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof adrRidCodeSearchDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(adrRidCodeSearchDto !== undefined ? adrRidCodeSearchDto : {})
                : (adrRidCodeSearchDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RidApi - functional programming interface
 * @export
 */
export const RidApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ridFindAllCodes(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageAdrRidCodeDto>> {
            const localVarAxiosArgs = await RidApiAxiosParamCreator(configuration).ridFindAllCodes(page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {AdrRidCodeSearchDto} [adrRidCodeSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ridSearchCode(page?: number, size?: number, sort?: Array<string>, adrRidCodeSearchDto?: AdrRidCodeSearchDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageAdrRidCodeDto>> {
            const localVarAxiosArgs = await RidApiAxiosParamCreator(configuration).ridSearchCode(page, size, sort, adrRidCodeSearchDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * RidApi - factory interface
 * @export
 */
export const RidApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ridFindAllCodes(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageAdrRidCodeDto> {
            return RidApiFp(configuration).ridFindAllCodes(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {AdrRidCodeSearchDto} [adrRidCodeSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ridSearchCode(page?: number, size?: number, sort?: Array<string>, adrRidCodeSearchDto?: AdrRidCodeSearchDto, options?: any): AxiosPromise<PageAdrRidCodeDto> {
            return RidApiFp(configuration).ridSearchCode(page, size, sort, adrRidCodeSearchDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RidApi - object-oriented interface
 * @export
 * @class RidApi
 * @extends {BaseAPI}
 */
export class RidApi extends BaseAPI {
    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RidApi
     */
    public ridFindAllCodes(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return RidApiFp(this.configuration).ridFindAllCodes(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {AdrRidCodeSearchDto} [adrRidCodeSearchDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RidApi
     */
    public ridSearchCode(page?: number, size?: number, sort?: Array<string>, adrRidCodeSearchDto?: AdrRidCodeSearchDto, options?: any) {
        return RidApiFp(this.configuration).ridSearchCode(page, size, sort, adrRidCodeSearchDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ShipownerApi - axios parameter creator
 * @export
 */
export const ShipownerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new shipowner
         * @param {ShipownerDto} shipownerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shipownerCreate: async (shipownerDto: ShipownerDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'shipownerDto' is not null or undefined
            if (shipownerDto === null || shipownerDto === undefined) {
                throw new RequiredError('shipownerDto','Required parameter shipownerDto was null or undefined when calling shipownerCreate.');
            }
            const localVarPath = `/shipowner`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof shipownerDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(shipownerDto !== undefined ? shipownerDto : {})
                : (shipownerDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete shipowner
         * @param {number} id Id of shipowner to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shipownerDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling shipownerDelete.');
            }
            const localVarPath = `/shipowner/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all shipowners
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shipownerFindAll: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/shipowner`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find a shipowner by its id.
         * @param {number} id Id of shipowner to be found
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shipownerFindById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling shipownerFindById.');
            }
            const localVarPath = `/shipowner/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {ShipownerSearchDto} [shipownerSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shipownerSearch: async (page?: number, size?: number, sort?: Array<string>, shipownerSearchDto?: ShipownerSearchDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/shipowner/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof shipownerSearchDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(shipownerSearchDto !== undefined ? shipownerSearchDto : {})
                : (shipownerSearchDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update shipowner
         * @param {number} id Id of shipowner to be updated
         * @param {ShipownerDto} shipownerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shipownerUpdate: async (id: number, shipownerDto: ShipownerDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling shipownerUpdate.');
            }
            // verify required parameter 'shipownerDto' is not null or undefined
            if (shipownerDto === null || shipownerDto === undefined) {
                throw new RequiredError('shipownerDto','Required parameter shipownerDto was null or undefined when calling shipownerUpdate.');
            }
            const localVarPath = `/shipowner/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof shipownerDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(shipownerDto !== undefined ? shipownerDto : {})
                : (shipownerDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShipownerApi - functional programming interface
 * @export
 */
export const ShipownerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new shipowner
         * @param {ShipownerDto} shipownerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shipownerCreate(shipownerDto: ShipownerDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShipownerDto>> {
            const localVarAxiosArgs = await ShipownerApiAxiosParamCreator(configuration).shipownerCreate(shipownerDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete shipowner
         * @param {number} id Id of shipowner to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shipownerDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ShipownerApiAxiosParamCreator(configuration).shipownerDelete(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Find all shipowners
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shipownerFindAll(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageShipownerDto>> {
            const localVarAxiosArgs = await ShipownerApiAxiosParamCreator(configuration).shipownerFindAll(page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Find a shipowner by its id.
         * @param {number} id Id of shipowner to be found
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shipownerFindById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShipownerDto>> {
            const localVarAxiosArgs = await ShipownerApiAxiosParamCreator(configuration).shipownerFindById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {ShipownerSearchDto} [shipownerSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shipownerSearch(page?: number, size?: number, sort?: Array<string>, shipownerSearchDto?: ShipownerSearchDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageShipownerDto>> {
            const localVarAxiosArgs = await ShipownerApiAxiosParamCreator(configuration).shipownerSearch(page, size, sort, shipownerSearchDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update shipowner
         * @param {number} id Id of shipowner to be updated
         * @param {ShipownerDto} shipownerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shipownerUpdate(id: number, shipownerDto: ShipownerDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShipownerDto>> {
            const localVarAxiosArgs = await ShipownerApiAxiosParamCreator(configuration).shipownerUpdate(id, shipownerDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ShipownerApi - factory interface
 * @export
 */
export const ShipownerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create new shipowner
         * @param {ShipownerDto} shipownerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shipownerCreate(shipownerDto: ShipownerDto, options?: any): AxiosPromise<ShipownerDto> {
            return ShipownerApiFp(configuration).shipownerCreate(shipownerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete shipowner
         * @param {number} id Id of shipowner to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shipownerDelete(id: number, options?: any): AxiosPromise<void> {
            return ShipownerApiFp(configuration).shipownerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all shipowners
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shipownerFindAll(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageShipownerDto> {
            return ShipownerApiFp(configuration).shipownerFindAll(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find a shipowner by its id.
         * @param {number} id Id of shipowner to be found
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shipownerFindById(id: number, options?: any): AxiosPromise<ShipownerDto> {
            return ShipownerApiFp(configuration).shipownerFindById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {ShipownerSearchDto} [shipownerSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shipownerSearch(page?: number, size?: number, sort?: Array<string>, shipownerSearchDto?: ShipownerSearchDto, options?: any): AxiosPromise<PageShipownerDto> {
            return ShipownerApiFp(configuration).shipownerSearch(page, size, sort, shipownerSearchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update shipowner
         * @param {number} id Id of shipowner to be updated
         * @param {ShipownerDto} shipownerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shipownerUpdate(id: number, shipownerDto: ShipownerDto, options?: any): AxiosPromise<ShipownerDto> {
            return ShipownerApiFp(configuration).shipownerUpdate(id, shipownerDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShipownerApi - object-oriented interface
 * @export
 * @class ShipownerApi
 * @extends {BaseAPI}
 */
export class ShipownerApi extends BaseAPI {
    /**
     * 
     * @summary Create new shipowner
     * @param {ShipownerDto} shipownerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShipownerApi
     */
    public shipownerCreate(shipownerDto: ShipownerDto, options?: any) {
        return ShipownerApiFp(this.configuration).shipownerCreate(shipownerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete shipowner
     * @param {number} id Id of shipowner to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShipownerApi
     */
    public shipownerDelete(id: number, options?: any) {
        return ShipownerApiFp(this.configuration).shipownerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all shipowners
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShipownerApi
     */
    public shipownerFindAll(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return ShipownerApiFp(this.configuration).shipownerFindAll(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find a shipowner by its id.
     * @param {number} id Id of shipowner to be found
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShipownerApi
     */
    public shipownerFindById(id: number, options?: any) {
        return ShipownerApiFp(this.configuration).shipownerFindById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {ShipownerSearchDto} [shipownerSearchDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShipownerApi
     */
    public shipownerSearch(page?: number, size?: number, sort?: Array<string>, shipownerSearchDto?: ShipownerSearchDto, options?: any) {
        return ShipownerApiFp(this.configuration).shipownerSearch(page, size, sort, shipownerSearchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update shipowner
     * @param {number} id Id of shipowner to be updated
     * @param {ShipownerDto} shipownerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShipownerApi
     */
    public shipownerUpdate(id: number, shipownerDto: ShipownerDto, options?: any) {
        return ShipownerApiFp(this.configuration).shipownerUpdate(id, shipownerDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ShortTermPassageApi - axios parameter creator
 * @export
 */
export const ShortTermPassageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new company
         * @param {ShortTermPassageDto} shortTermPassageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shortTermPassageCreate: async (shortTermPassageDto: ShortTermPassageDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'shortTermPassageDto' is not null or undefined
            if (shortTermPassageDto === null || shortTermPassageDto === undefined) {
                throw new RequiredError('shortTermPassageDto','Required parameter shortTermPassageDto was null or undefined when calling shortTermPassageCreate.');
            }
            const localVarPath = `/short-term-passage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof shortTermPassageDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(shortTermPassageDto !== undefined ? shortTermPassageDto : {})
                : (shortTermPassageDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete short term passage
         * @param {number} id Id of short term passage to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shortTermPassageDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling shortTermPassageDelete.');
            }
            const localVarPath = `/short-term-passage/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all short term passages
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shortTermPassageFindAll: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/short-term-passage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find a short term passage by its id.
         * @param {number} id Id of short term passage to be found
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shortTermPassageFindById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling shortTermPassageFindById.');
            }
            const localVarPath = `/short-term-passage/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {ShortTermPassageSearchDto} [shortTermPassageSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shortTermPassageSearch: async (page?: number, size?: number, sort?: Array<string>, shortTermPassageSearchDto?: ShortTermPassageSearchDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/short-term-passage/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof shortTermPassageSearchDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(shortTermPassageSearchDto !== undefined ? shortTermPassageSearchDto : {})
                : (shortTermPassageSearchDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update short term passage
         * @param {number} id Id of short term passage to be updated
         * @param {ShortTermPassageDto} shortTermPassageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shortTermPassageUpdate: async (id: number, shortTermPassageDto: ShortTermPassageDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling shortTermPassageUpdate.');
            }
            // verify required parameter 'shortTermPassageDto' is not null or undefined
            if (shortTermPassageDto === null || shortTermPassageDto === undefined) {
                throw new RequiredError('shortTermPassageDto','Required parameter shortTermPassageDto was null or undefined when calling shortTermPassageUpdate.');
            }
            const localVarPath = `/short-term-passage/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof shortTermPassageDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(shortTermPassageDto !== undefined ? shortTermPassageDto : {})
                : (shortTermPassageDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShortTermPassageApi - functional programming interface
 * @export
 */
export const ShortTermPassageApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new company
         * @param {ShortTermPassageDto} shortTermPassageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shortTermPassageCreate(shortTermPassageDto: ShortTermPassageDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShortTermPassageDto>> {
            const localVarAxiosArgs = await ShortTermPassageApiAxiosParamCreator(configuration).shortTermPassageCreate(shortTermPassageDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete short term passage
         * @param {number} id Id of short term passage to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shortTermPassageDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ShortTermPassageApiAxiosParamCreator(configuration).shortTermPassageDelete(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Find all short term passages
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shortTermPassageFindAll(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageShortTermPassageDto>> {
            const localVarAxiosArgs = await ShortTermPassageApiAxiosParamCreator(configuration).shortTermPassageFindAll(page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Find a short term passage by its id.
         * @param {number} id Id of short term passage to be found
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shortTermPassageFindById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShortTermPassageDto>> {
            const localVarAxiosArgs = await ShortTermPassageApiAxiosParamCreator(configuration).shortTermPassageFindById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {ShortTermPassageSearchDto} [shortTermPassageSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shortTermPassageSearch(page?: number, size?: number, sort?: Array<string>, shortTermPassageSearchDto?: ShortTermPassageSearchDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageShortTermPassageDto>> {
            const localVarAxiosArgs = await ShortTermPassageApiAxiosParamCreator(configuration).shortTermPassageSearch(page, size, sort, shortTermPassageSearchDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update short term passage
         * @param {number} id Id of short term passage to be updated
         * @param {ShortTermPassageDto} shortTermPassageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shortTermPassageUpdate(id: number, shortTermPassageDto: ShortTermPassageDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShortTermPassageDto>> {
            const localVarAxiosArgs = await ShortTermPassageApiAxiosParamCreator(configuration).shortTermPassageUpdate(id, shortTermPassageDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ShortTermPassageApi - factory interface
 * @export
 */
export const ShortTermPassageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create new company
         * @param {ShortTermPassageDto} shortTermPassageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shortTermPassageCreate(shortTermPassageDto: ShortTermPassageDto, options?: any): AxiosPromise<ShortTermPassageDto> {
            return ShortTermPassageApiFp(configuration).shortTermPassageCreate(shortTermPassageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete short term passage
         * @param {number} id Id of short term passage to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shortTermPassageDelete(id: number, options?: any): AxiosPromise<void> {
            return ShortTermPassageApiFp(configuration).shortTermPassageDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all short term passages
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shortTermPassageFindAll(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageShortTermPassageDto> {
            return ShortTermPassageApiFp(configuration).shortTermPassageFindAll(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find a short term passage by its id.
         * @param {number} id Id of short term passage to be found
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shortTermPassageFindById(id: number, options?: any): AxiosPromise<ShortTermPassageDto> {
            return ShortTermPassageApiFp(configuration).shortTermPassageFindById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {ShortTermPassageSearchDto} [shortTermPassageSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shortTermPassageSearch(page?: number, size?: number, sort?: Array<string>, shortTermPassageSearchDto?: ShortTermPassageSearchDto, options?: any): AxiosPromise<PageShortTermPassageDto> {
            return ShortTermPassageApiFp(configuration).shortTermPassageSearch(page, size, sort, shortTermPassageSearchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update short term passage
         * @param {number} id Id of short term passage to be updated
         * @param {ShortTermPassageDto} shortTermPassageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shortTermPassageUpdate(id: number, shortTermPassageDto: ShortTermPassageDto, options?: any): AxiosPromise<ShortTermPassageDto> {
            return ShortTermPassageApiFp(configuration).shortTermPassageUpdate(id, shortTermPassageDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShortTermPassageApi - object-oriented interface
 * @export
 * @class ShortTermPassageApi
 * @extends {BaseAPI}
 */
export class ShortTermPassageApi extends BaseAPI {
    /**
     * 
     * @summary Create new company
     * @param {ShortTermPassageDto} shortTermPassageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTermPassageApi
     */
    public shortTermPassageCreate(shortTermPassageDto: ShortTermPassageDto, options?: any) {
        return ShortTermPassageApiFp(this.configuration).shortTermPassageCreate(shortTermPassageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete short term passage
     * @param {number} id Id of short term passage to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTermPassageApi
     */
    public shortTermPassageDelete(id: number, options?: any) {
        return ShortTermPassageApiFp(this.configuration).shortTermPassageDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all short term passages
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTermPassageApi
     */
    public shortTermPassageFindAll(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return ShortTermPassageApiFp(this.configuration).shortTermPassageFindAll(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find a short term passage by its id.
     * @param {number} id Id of short term passage to be found
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTermPassageApi
     */
    public shortTermPassageFindById(id: number, options?: any) {
        return ShortTermPassageApiFp(this.configuration).shortTermPassageFindById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {ShortTermPassageSearchDto} [shortTermPassageSearchDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTermPassageApi
     */
    public shortTermPassageSearch(page?: number, size?: number, sort?: Array<string>, shortTermPassageSearchDto?: ShortTermPassageSearchDto, options?: any) {
        return ShortTermPassageApiFp(this.configuration).shortTermPassageSearch(page, size, sort, shortTermPassageSearchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update short term passage
     * @param {number} id Id of short term passage to be updated
     * @param {ShortTermPassageDto} shortTermPassageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTermPassageApi
     */
    public shortTermPassageUpdate(id: number, shortTermPassageDto: ShortTermPassageDto, options?: any) {
        return ShortTermPassageApiFp(this.configuration).shortTermPassageUpdate(id, shortTermPassageDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TrainApi - axios parameter creator
 * @export
 */
export const TrainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Change state of existing train
         * @param {number} id 
         * @param {'INCOMING_PLANNED' | 'INCOMING_ARRIVAL_CUSTOMS' | 'INCOMING_ACCEPTED' | 'INCOMING_FINISHED' | 'OUTGOING_PLANNED' | 'OUTGOING_PREPARED' | 'OUTGOING_LOADED' | 'OUTGOING_DISPATCHED'} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainChangeState: async (id: number, state: 'INCOMING_PLANNED' | 'INCOMING_ARRIVAL_CUSTOMS' | 'INCOMING_ACCEPTED' | 'INCOMING_FINISHED' | 'OUTGOING_PLANNED' | 'OUTGOING_PREPARED' | 'OUTGOING_LOADED' | 'OUTGOING_DISPATCHED', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling trainChangeState.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling trainChangeState.');
            }
            const localVarPath = `/train/{id}/change-state`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete train
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling trainDelete.');
            }
            const localVarPath = `/train/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id Id of train
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainDownloadExport: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling trainDownloadExport.');
            }
            const localVarPath = `/train/export/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find a train by its id.
         * @param {number} id Id of train to be found
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainFindById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling trainFindById.');
            }
            const localVarPath = `/train/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns list of invalid ILU codes on train
         * @param {TrainDto} trainDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainGetTrainInvalidIluCodes: async (trainDto: TrainDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'trainDto' is not null or undefined
            if (trainDto === null || trainDto === undefined) {
                throw new RequiredError('trainDto','Required parameter trainDto was null or undefined when calling trainGetTrainInvalidIluCodes.');
            }
            const localVarPath = `/train/validate-train-ilu-codes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof trainDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(trainDto !== undefined ? trainDto : {})
                : (trainDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {TrainSearchDto} [trainSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainSearch: async (page?: number, size?: number, sort?: Array<string>, trainSearchDto?: TrainSearchDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/train/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof trainSearchDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(trainSearchDto !== undefined ? trainSearchDto : {})
                : (trainSearchDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {TrainSearchDto} [trainSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainSearchCoparn: async (page?: number, size?: number, sort?: Array<string>, trainSearchDto?: TrainSearchDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/train/search-coparn`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof trainSearchDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(trainSearchDto !== undefined ? trainSearchDto : {})
                : (trainSearchDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send codeco files of all transitions on a train
         * @param {number} id Id of train to send Codecos for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainSendCodecos: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling trainSendCodecos.');
            }
            const localVarPath = `/train/{id}/send-codecos`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates existing train
         * @param {number} id 
         * @param {TrainDto} trainDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainUpdate: async (id: number, trainDto: TrainDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling trainUpdate.');
            }
            // verify required parameter 'trainDto' is not null or undefined
            if (trainDto === null || trainDto === undefined) {
                throw new RequiredError('trainDto','Required parameter trainDto was null or undefined when calling trainUpdate.');
            }
            const localVarPath = `/train/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof trainDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(trainDto !== undefined ? trainDto : {})
                : (trainDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrainApi - functional programming interface
 * @export
 */
export const TrainApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Change state of existing train
         * @param {number} id 
         * @param {'INCOMING_PLANNED' | 'INCOMING_ARRIVAL_CUSTOMS' | 'INCOMING_ACCEPTED' | 'INCOMING_FINISHED' | 'OUTGOING_PLANNED' | 'OUTGOING_PREPARED' | 'OUTGOING_LOADED' | 'OUTGOING_DISPATCHED'} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainChangeState(id: number, state: 'INCOMING_PLANNED' | 'INCOMING_ARRIVAL_CUSTOMS' | 'INCOMING_ACCEPTED' | 'INCOMING_FINISHED' | 'OUTGOING_PLANNED' | 'OUTGOING_PREPARED' | 'OUTGOING_LOADED' | 'OUTGOING_DISPATCHED', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrainDto>> {
            const localVarAxiosArgs = await TrainApiAxiosParamCreator(configuration).trainChangeState(id, state, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete train
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TrainApiAxiosParamCreator(configuration).trainDelete(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id Id of train
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainDownloadExport(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await TrainApiAxiosParamCreator(configuration).trainDownloadExport(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Find a train by its id.
         * @param {number} id Id of train to be found
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainFindById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrainDto>> {
            const localVarAxiosArgs = await TrainApiAxiosParamCreator(configuration).trainFindById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Returns list of invalid ILU codes on train
         * @param {TrainDto} trainDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainGetTrainInvalidIluCodes(trainDto: TrainDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await TrainApiAxiosParamCreator(configuration).trainGetTrainInvalidIluCodes(trainDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {TrainSearchDto} [trainSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainSearch(page?: number, size?: number, sort?: Array<string>, trainSearchDto?: TrainSearchDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTrainDto>> {
            const localVarAxiosArgs = await TrainApiAxiosParamCreator(configuration).trainSearch(page, size, sort, trainSearchDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {TrainSearchDto} [trainSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainSearchCoparn(page?: number, size?: number, sort?: Array<string>, trainSearchDto?: TrainSearchDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTrainDto>> {
            const localVarAxiosArgs = await TrainApiAxiosParamCreator(configuration).trainSearchCoparn(page, size, sort, trainSearchDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Send codeco files of all transitions on a train
         * @param {number} id Id of train to send Codecos for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainSendCodecos(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TrainApiAxiosParamCreator(configuration).trainSendCodecos(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates existing train
         * @param {number} id 
         * @param {TrainDto} trainDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainUpdate(id: number, trainDto: TrainDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrainDto>> {
            const localVarAxiosArgs = await TrainApiAxiosParamCreator(configuration).trainUpdate(id, trainDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TrainApi - factory interface
 * @export
 */
export const TrainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Change state of existing train
         * @param {number} id 
         * @param {'INCOMING_PLANNED' | 'INCOMING_ARRIVAL_CUSTOMS' | 'INCOMING_ACCEPTED' | 'INCOMING_FINISHED' | 'OUTGOING_PLANNED' | 'OUTGOING_PREPARED' | 'OUTGOING_LOADED' | 'OUTGOING_DISPATCHED'} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainChangeState(id: number, state: 'INCOMING_PLANNED' | 'INCOMING_ARRIVAL_CUSTOMS' | 'INCOMING_ACCEPTED' | 'INCOMING_FINISHED' | 'OUTGOING_PLANNED' | 'OUTGOING_PREPARED' | 'OUTGOING_LOADED' | 'OUTGOING_DISPATCHED', options?: any): AxiosPromise<TrainDto> {
            return TrainApiFp(configuration).trainChangeState(id, state, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete train
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainDelete(id: number, options?: any): AxiosPromise<void> {
            return TrainApiFp(configuration).trainDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id Id of train
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainDownloadExport(id: number, options?: any): AxiosPromise<Array<string>> {
            return TrainApiFp(configuration).trainDownloadExport(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find a train by its id.
         * @param {number} id Id of train to be found
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainFindById(id: number, options?: any): AxiosPromise<TrainDto> {
            return TrainApiFp(configuration).trainFindById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns list of invalid ILU codes on train
         * @param {TrainDto} trainDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainGetTrainInvalidIluCodes(trainDto: TrainDto, options?: any): AxiosPromise<Array<string>> {
            return TrainApiFp(configuration).trainGetTrainInvalidIluCodes(trainDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {TrainSearchDto} [trainSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainSearch(page?: number, size?: number, sort?: Array<string>, trainSearchDto?: TrainSearchDto, options?: any): AxiosPromise<PageTrainDto> {
            return TrainApiFp(configuration).trainSearch(page, size, sort, trainSearchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {TrainSearchDto} [trainSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainSearchCoparn(page?: number, size?: number, sort?: Array<string>, trainSearchDto?: TrainSearchDto, options?: any): AxiosPromise<PageTrainDto> {
            return TrainApiFp(configuration).trainSearchCoparn(page, size, sort, trainSearchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send codeco files of all transitions on a train
         * @param {number} id Id of train to send Codecos for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainSendCodecos(id: number, options?: any): AxiosPromise<void> {
            return TrainApiFp(configuration).trainSendCodecos(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates existing train
         * @param {number} id 
         * @param {TrainDto} trainDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainUpdate(id: number, trainDto: TrainDto, options?: any): AxiosPromise<TrainDto> {
            return TrainApiFp(configuration).trainUpdate(id, trainDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TrainApi - object-oriented interface
 * @export
 * @class TrainApi
 * @extends {BaseAPI}
 */
export class TrainApi extends BaseAPI {
    /**
     * 
     * @summary Change state of existing train
     * @param {number} id 
     * @param {'INCOMING_PLANNED' | 'INCOMING_ARRIVAL_CUSTOMS' | 'INCOMING_ACCEPTED' | 'INCOMING_FINISHED' | 'OUTGOING_PLANNED' | 'OUTGOING_PREPARED' | 'OUTGOING_LOADED' | 'OUTGOING_DISPATCHED'} state 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainApi
     */
    public trainChangeState(id: number, state: 'INCOMING_PLANNED' | 'INCOMING_ARRIVAL_CUSTOMS' | 'INCOMING_ACCEPTED' | 'INCOMING_FINISHED' | 'OUTGOING_PLANNED' | 'OUTGOING_PREPARED' | 'OUTGOING_LOADED' | 'OUTGOING_DISPATCHED', options?: any) {
        return TrainApiFp(this.configuration).trainChangeState(id, state, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete train
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainApi
     */
    public trainDelete(id: number, options?: any) {
        return TrainApiFp(this.configuration).trainDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id Id of train
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainApi
     */
    public trainDownloadExport(id: number, options?: any) {
        return TrainApiFp(this.configuration).trainDownloadExport(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find a train by its id.
     * @param {number} id Id of train to be found
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainApi
     */
    public trainFindById(id: number, options?: any) {
        return TrainApiFp(this.configuration).trainFindById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns list of invalid ILU codes on train
     * @param {TrainDto} trainDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainApi
     */
    public trainGetTrainInvalidIluCodes(trainDto: TrainDto, options?: any) {
        return TrainApiFp(this.configuration).trainGetTrainInvalidIluCodes(trainDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {TrainSearchDto} [trainSearchDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainApi
     */
    public trainSearch(page?: number, size?: number, sort?: Array<string>, trainSearchDto?: TrainSearchDto, options?: any) {
        return TrainApiFp(this.configuration).trainSearch(page, size, sort, trainSearchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {TrainSearchDto} [trainSearchDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainApi
     */
    public trainSearchCoparn(page?: number, size?: number, sort?: Array<string>, trainSearchDto?: TrainSearchDto, options?: any) {
        return TrainApiFp(this.configuration).trainSearchCoparn(page, size, sort, trainSearchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send codeco files of all transitions on a train
     * @param {number} id Id of train to send Codecos for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainApi
     */
    public trainSendCodecos(id: number, options?: any) {
        return TrainApiFp(this.configuration).trainSendCodecos(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates existing train
     * @param {number} id 
     * @param {TrainDto} trainDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainApi
     */
    public trainUpdate(id: number, trainDto: TrainDto, options?: any) {
        return TrainApiFp(this.configuration).trainUpdate(id, trainDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TrainExportConfigurationApi - axios parameter creator
 * @export
 */
export const TrainExportConfigurationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new configuration
         * @param {TrainExportConfigurationDto} trainExportConfigurationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainExportConfigurationCreate: async (trainExportConfigurationDto: TrainExportConfigurationDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'trainExportConfigurationDto' is not null or undefined
            if (trainExportConfigurationDto === null || trainExportConfigurationDto === undefined) {
                throw new RequiredError('trainExportConfigurationDto','Required parameter trainExportConfigurationDto was null or undefined when calling trainExportConfigurationCreate.');
            }
            const localVarPath = `/train-export-configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof trainExportConfigurationDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(trainExportConfigurationDto !== undefined ? trainExportConfigurationDto : {})
                : (trainExportConfigurationDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete configuration
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainExportConfigurationDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling trainExportConfigurationDelete.');
            }
            const localVarPath = `/train-export-configuration/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Duplicate  configuration
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainExportConfigurationDuplicate: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling trainExportConfigurationDuplicate.');
            }
            const localVarPath = `/train-export-configuration/create-from/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all configurations
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainExportConfigurationFindAll: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/train-export-configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find a configuration by its id.
         * @param {number} id Id of configuration to be found
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainExportConfigurationFindById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling trainExportConfigurationFindById.');
            }
            const localVarPath = `/train-export-configuration/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {TrainSearchDto} [trainSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainExportConfigurationSearch: async (page?: number, size?: number, sort?: Array<string>, trainSearchDto?: TrainSearchDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/train-export-configuration/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof trainSearchDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(trainSearchDto !== undefined ? trainSearchDto : {})
                : (trainSearchDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates existing configuration
         * @param {number} id 
         * @param {TrainExportConfigurationDto} trainExportConfigurationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainExportConfigurationUpdate: async (id: number, trainExportConfigurationDto: TrainExportConfigurationDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling trainExportConfigurationUpdate.');
            }
            // verify required parameter 'trainExportConfigurationDto' is not null or undefined
            if (trainExportConfigurationDto === null || trainExportConfigurationDto === undefined) {
                throw new RequiredError('trainExportConfigurationDto','Required parameter trainExportConfigurationDto was null or undefined when calling trainExportConfigurationUpdate.');
            }
            const localVarPath = `/train-export-configuration/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof trainExportConfigurationDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(trainExportConfigurationDto !== undefined ? trainExportConfigurationDto : {})
                : (trainExportConfigurationDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrainExportConfigurationApi - functional programming interface
 * @export
 */
export const TrainExportConfigurationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new configuration
         * @param {TrainExportConfigurationDto} trainExportConfigurationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainExportConfigurationCreate(trainExportConfigurationDto: TrainExportConfigurationDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrainExportConfigurationDto>> {
            const localVarAxiosArgs = await TrainExportConfigurationApiAxiosParamCreator(configuration).trainExportConfigurationCreate(trainExportConfigurationDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete configuration
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainExportConfigurationDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TrainExportConfigurationApiAxiosParamCreator(configuration).trainExportConfigurationDelete(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Duplicate  configuration
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainExportConfigurationDuplicate(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrainExportConfigurationDto>> {
            const localVarAxiosArgs = await TrainExportConfigurationApiAxiosParamCreator(configuration).trainExportConfigurationDuplicate(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Find all configurations
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainExportConfigurationFindAll(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTrainExportConfigurationDto>> {
            const localVarAxiosArgs = await TrainExportConfigurationApiAxiosParamCreator(configuration).trainExportConfigurationFindAll(page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Find a configuration by its id.
         * @param {number} id Id of configuration to be found
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainExportConfigurationFindById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrainExportConfigurationDto>> {
            const localVarAxiosArgs = await TrainExportConfigurationApiAxiosParamCreator(configuration).trainExportConfigurationFindById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {TrainSearchDto} [trainSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainExportConfigurationSearch(page?: number, size?: number, sort?: Array<string>, trainSearchDto?: TrainSearchDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTrainExportConfigurationDto>> {
            const localVarAxiosArgs = await TrainExportConfigurationApiAxiosParamCreator(configuration).trainExportConfigurationSearch(page, size, sort, trainSearchDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates existing configuration
         * @param {number} id 
         * @param {TrainExportConfigurationDto} trainExportConfigurationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainExportConfigurationUpdate(id: number, trainExportConfigurationDto: TrainExportConfigurationDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrainExportConfigurationDto>> {
            const localVarAxiosArgs = await TrainExportConfigurationApiAxiosParamCreator(configuration).trainExportConfigurationUpdate(id, trainExportConfigurationDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TrainExportConfigurationApi - factory interface
 * @export
 */
export const TrainExportConfigurationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create new configuration
         * @param {TrainExportConfigurationDto} trainExportConfigurationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainExportConfigurationCreate(trainExportConfigurationDto: TrainExportConfigurationDto, options?: any): AxiosPromise<TrainExportConfigurationDto> {
            return TrainExportConfigurationApiFp(configuration).trainExportConfigurationCreate(trainExportConfigurationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete configuration
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainExportConfigurationDelete(id: number, options?: any): AxiosPromise<void> {
            return TrainExportConfigurationApiFp(configuration).trainExportConfigurationDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Duplicate  configuration
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainExportConfigurationDuplicate(id: number, options?: any): AxiosPromise<TrainExportConfigurationDto> {
            return TrainExportConfigurationApiFp(configuration).trainExportConfigurationDuplicate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all configurations
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainExportConfigurationFindAll(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageTrainExportConfigurationDto> {
            return TrainExportConfigurationApiFp(configuration).trainExportConfigurationFindAll(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find a configuration by its id.
         * @param {number} id Id of configuration to be found
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainExportConfigurationFindById(id: number, options?: any): AxiosPromise<TrainExportConfigurationDto> {
            return TrainExportConfigurationApiFp(configuration).trainExportConfigurationFindById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {TrainSearchDto} [trainSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainExportConfigurationSearch(page?: number, size?: number, sort?: Array<string>, trainSearchDto?: TrainSearchDto, options?: any): AxiosPromise<PageTrainExportConfigurationDto> {
            return TrainExportConfigurationApiFp(configuration).trainExportConfigurationSearch(page, size, sort, trainSearchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates existing configuration
         * @param {number} id 
         * @param {TrainExportConfigurationDto} trainExportConfigurationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainExportConfigurationUpdate(id: number, trainExportConfigurationDto: TrainExportConfigurationDto, options?: any): AxiosPromise<TrainExportConfigurationDto> {
            return TrainExportConfigurationApiFp(configuration).trainExportConfigurationUpdate(id, trainExportConfigurationDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TrainExportConfigurationApi - object-oriented interface
 * @export
 * @class TrainExportConfigurationApi
 * @extends {BaseAPI}
 */
export class TrainExportConfigurationApi extends BaseAPI {
    /**
     * 
     * @summary Create new configuration
     * @param {TrainExportConfigurationDto} trainExportConfigurationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainExportConfigurationApi
     */
    public trainExportConfigurationCreate(trainExportConfigurationDto: TrainExportConfigurationDto, options?: any) {
        return TrainExportConfigurationApiFp(this.configuration).trainExportConfigurationCreate(trainExportConfigurationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete configuration
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainExportConfigurationApi
     */
    public trainExportConfigurationDelete(id: number, options?: any) {
        return TrainExportConfigurationApiFp(this.configuration).trainExportConfigurationDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Duplicate  configuration
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainExportConfigurationApi
     */
    public trainExportConfigurationDuplicate(id: number, options?: any) {
        return TrainExportConfigurationApiFp(this.configuration).trainExportConfigurationDuplicate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all configurations
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainExportConfigurationApi
     */
    public trainExportConfigurationFindAll(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return TrainExportConfigurationApiFp(this.configuration).trainExportConfigurationFindAll(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find a configuration by its id.
     * @param {number} id Id of configuration to be found
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainExportConfigurationApi
     */
    public trainExportConfigurationFindById(id: number, options?: any) {
        return TrainExportConfigurationApiFp(this.configuration).trainExportConfigurationFindById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {TrainSearchDto} [trainSearchDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainExportConfigurationApi
     */
    public trainExportConfigurationSearch(page?: number, size?: number, sort?: Array<string>, trainSearchDto?: TrainSearchDto, options?: any) {
        return TrainExportConfigurationApiFp(this.configuration).trainExportConfigurationSearch(page, size, sort, trainSearchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates existing configuration
     * @param {number} id 
     * @param {TrainExportConfigurationDto} trainExportConfigurationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainExportConfigurationApi
     */
    public trainExportConfigurationUpdate(id: number, trainExportConfigurationDto: TrainExportConfigurationDto, options?: any) {
        return TrainExportConfigurationApiFp(this.configuration).trainExportConfigurationUpdate(id, trainExportConfigurationDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TrainGateInApi - axios parameter creator
 * @export
 */
export const TrainGateInApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates new Gate IN using train
         * @param {boolean} onTerminal 
         * @param {TrainDto} trainDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainGateInCreateTrainGateIn: async (onTerminal: boolean, trainDto: TrainDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'onTerminal' is not null or undefined
            if (onTerminal === null || onTerminal === undefined) {
                throw new RequiredError('onTerminal','Required parameter onTerminal was null or undefined when calling trainGateInCreateTrainGateIn.');
            }
            // verify required parameter 'trainDto' is not null or undefined
            if (trainDto === null || trainDto === undefined) {
                throw new RequiredError('trainDto','Required parameter trainDto was null or undefined when calling trainGateInCreateTrainGateIn.');
            }
            const localVarPath = `/train-gate-in/train`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (onTerminal !== undefined) {
                localVarQueryParameter['onTerminal'] = onTerminal;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof trainDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(trainDto !== undefined ? trainDto : {})
                : (trainDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns train view with company names and departure delay
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainGateInGetTrainView: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/train-gate-in/train-view`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates train template from given Excel file
         * @param {number} id 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainGateInImportUpdateTrainIn: async (id: number, file: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling trainGateInImportUpdateTrainIn.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling trainGateInImportUpdateTrainIn.');
            }
            const localVarPath = `/train-gate-in/{id}/model`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates new Gate IN using train
         * @param {number} wagonId 
         * @param {number} order 
         * @param {TransitionDto} transitionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainGateInLoadOnWagon: async (wagonId: number, order: number, transitionDto: TransitionDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'wagonId' is not null or undefined
            if (wagonId === null || wagonId === undefined) {
                throw new RequiredError('wagonId','Required parameter wagonId was null or undefined when calling trainGateInLoadOnWagon.');
            }
            // verify required parameter 'order' is not null or undefined
            if (order === null || order === undefined) {
                throw new RequiredError('order','Required parameter order was null or undefined when calling trainGateInLoadOnWagon.');
            }
            // verify required parameter 'transitionDto' is not null or undefined
            if (transitionDto === null || transitionDto === undefined) {
                throw new RequiredError('transitionDto','Required parameter transitionDto was null or undefined when calling trainGateInLoadOnWagon.');
            }
            const localVarPath = `/train-gate-in/load-on-wagon/{wagonId}/{order}`
                .replace(`{${"wagonId"}}`, encodeURIComponent(String(wagonId)))
                .replace(`{${"order"}}`, encodeURIComponent(String(order)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof transitionDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(transitionDto !== undefined ? transitionDto : {})
                : (transitionDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates new Gate IN using train
         * @param {number} wagonId 
         * @param {number} order 
         * @param {number} transitionId 
         * @param {'NEW' | 'FOOD' | 'PAPER' | 'STANDARD' | 'SCRAP'} [qualityType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainGateInLoadPlannedOnWagon: async (wagonId: number, order: number, transitionId: number, qualityType?: 'NEW' | 'FOOD' | 'PAPER' | 'STANDARD' | 'SCRAP', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'wagonId' is not null or undefined
            if (wagonId === null || wagonId === undefined) {
                throw new RequiredError('wagonId','Required parameter wagonId was null or undefined when calling trainGateInLoadPlannedOnWagon.');
            }
            // verify required parameter 'order' is not null or undefined
            if (order === null || order === undefined) {
                throw new RequiredError('order','Required parameter order was null or undefined when calling trainGateInLoadPlannedOnWagon.');
            }
            // verify required parameter 'transitionId' is not null or undefined
            if (transitionId === null || transitionId === undefined) {
                throw new RequiredError('transitionId','Required parameter transitionId was null or undefined when calling trainGateInLoadPlannedOnWagon.');
            }
            const localVarPath = `/train-gate-in/load-on-wagon/{wagonId}/{order}/{transitionId}`
                .replace(`{${"wagonId"}}`, encodeURIComponent(String(wagonId)))
                .replace(`{${"order"}}`, encodeURIComponent(String(order)))
                .replace(`{${"transitionId"}}`, encodeURIComponent(String(transitionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (qualityType !== undefined) {
                localVarQueryParameter['qualityType'] = qualityType;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove wagon from train, transitions from wagon and adjust wagon order
         * @param {number} trainId 
         * @param {number} wagonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainGateInRemoveWagon: async (trainId: number, wagonId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'trainId' is not null or undefined
            if (trainId === null || trainId === undefined) {
                throw new RequiredError('trainId','Required parameter trainId was null or undefined when calling trainGateInRemoveWagon.');
            }
            // verify required parameter 'wagonId' is not null or undefined
            if (wagonId === null || wagonId === undefined) {
                throw new RequiredError('wagonId','Required parameter wagonId was null or undefined when calling trainGateInRemoveWagon.');
            }
            const localVarPath = `/train-gate-in/{trainId}/wagon/{wagonId}`
                .replace(`{${"trainId"}}`, encodeURIComponent(String(trainId)))
                .replace(`{${"wagonId"}}`, encodeURIComponent(String(wagonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Stores all containers and places them to a selected coordinate
         * @param {number} id Id of train to store transitions
         * @param {TransitionStoreDto} transitionStoreDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainGateInStoreContainers: async (id: number, transitionStoreDto: TransitionStoreDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling trainGateInStoreContainers.');
            }
            // verify required parameter 'transitionStoreDto' is not null or undefined
            if (transitionStoreDto === null || transitionStoreDto === undefined) {
                throw new RequiredError('transitionStoreDto','Required parameter transitionStoreDto was null or undefined when calling trainGateInStoreContainers.');
            }
            const localVarPath = `/train-gate-in/store-containers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof transitionStoreDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(transitionStoreDto !== undefined ? transitionStoreDto : {})
                : (transitionStoreDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrainGateInApi - functional programming interface
 * @export
 */
export const TrainGateInApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates new Gate IN using train
         * @param {boolean} onTerminal 
         * @param {TrainDto} trainDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainGateInCreateTrainGateIn(onTerminal: boolean, trainDto: TrainDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrainDto>> {
            const localVarAxiosArgs = await TrainGateInApiAxiosParamCreator(configuration).trainGateInCreateTrainGateIn(onTerminal, trainDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Returns train view with company names and departure delay
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainGateInGetTrainView(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTrainViewDto>> {
            const localVarAxiosArgs = await TrainGateInApiAxiosParamCreator(configuration).trainGateInGetTrainView(page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Creates train template from given Excel file
         * @param {number} id 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainGateInImportUpdateTrainIn(id: number, file: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrainDto>> {
            const localVarAxiosArgs = await TrainGateInApiAxiosParamCreator(configuration).trainGateInImportUpdateTrainIn(id, file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Creates new Gate IN using train
         * @param {number} wagonId 
         * @param {number} order 
         * @param {TransitionDto} transitionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainGateInLoadOnWagon(wagonId: number, order: number, transitionDto: TransitionDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrainWagonContentDto>> {
            const localVarAxiosArgs = await TrainGateInApiAxiosParamCreator(configuration).trainGateInLoadOnWagon(wagonId, order, transitionDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Creates new Gate IN using train
         * @param {number} wagonId 
         * @param {number} order 
         * @param {number} transitionId 
         * @param {'NEW' | 'FOOD' | 'PAPER' | 'STANDARD' | 'SCRAP'} [qualityType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainGateInLoadPlannedOnWagon(wagonId: number, order: number, transitionId: number, qualityType?: 'NEW' | 'FOOD' | 'PAPER' | 'STANDARD' | 'SCRAP', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrainWagonContentDto>> {
            const localVarAxiosArgs = await TrainGateInApiAxiosParamCreator(configuration).trainGateInLoadPlannedOnWagon(wagonId, order, transitionId, qualityType, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Remove wagon from train, transitions from wagon and adjust wagon order
         * @param {number} trainId 
         * @param {number} wagonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainGateInRemoveWagon(trainId: number, wagonId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrainDto>> {
            const localVarAxiosArgs = await TrainGateInApiAxiosParamCreator(configuration).trainGateInRemoveWagon(trainId, wagonId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Stores all containers and places them to a selected coordinate
         * @param {number} id Id of train to store transitions
         * @param {TransitionStoreDto} transitionStoreDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainGateInStoreContainers(id: number, transitionStoreDto: TransitionStoreDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TrainGateInApiAxiosParamCreator(configuration).trainGateInStoreContainers(id, transitionStoreDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TrainGateInApi - factory interface
 * @export
 */
export const TrainGateInApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Creates new Gate IN using train
         * @param {boolean} onTerminal 
         * @param {TrainDto} trainDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainGateInCreateTrainGateIn(onTerminal: boolean, trainDto: TrainDto, options?: any): AxiosPromise<TrainDto> {
            return TrainGateInApiFp(configuration).trainGateInCreateTrainGateIn(onTerminal, trainDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns train view with company names and departure delay
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainGateInGetTrainView(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageTrainViewDto> {
            return TrainGateInApiFp(configuration).trainGateInGetTrainView(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates train template from given Excel file
         * @param {number} id 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainGateInImportUpdateTrainIn(id: number, file: any, options?: any): AxiosPromise<TrainDto> {
            return TrainGateInApiFp(configuration).trainGateInImportUpdateTrainIn(id, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates new Gate IN using train
         * @param {number} wagonId 
         * @param {number} order 
         * @param {TransitionDto} transitionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainGateInLoadOnWagon(wagonId: number, order: number, transitionDto: TransitionDto, options?: any): AxiosPromise<TrainWagonContentDto> {
            return TrainGateInApiFp(configuration).trainGateInLoadOnWagon(wagonId, order, transitionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates new Gate IN using train
         * @param {number} wagonId 
         * @param {number} order 
         * @param {number} transitionId 
         * @param {'NEW' | 'FOOD' | 'PAPER' | 'STANDARD' | 'SCRAP'} [qualityType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainGateInLoadPlannedOnWagon(wagonId: number, order: number, transitionId: number, qualityType?: 'NEW' | 'FOOD' | 'PAPER' | 'STANDARD' | 'SCRAP', options?: any): AxiosPromise<TrainWagonContentDto> {
            return TrainGateInApiFp(configuration).trainGateInLoadPlannedOnWagon(wagonId, order, transitionId, qualityType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove wagon from train, transitions from wagon and adjust wagon order
         * @param {number} trainId 
         * @param {number} wagonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainGateInRemoveWagon(trainId: number, wagonId: number, options?: any): AxiosPromise<TrainDto> {
            return TrainGateInApiFp(configuration).trainGateInRemoveWagon(trainId, wagonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Stores all containers and places them to a selected coordinate
         * @param {number} id Id of train to store transitions
         * @param {TransitionStoreDto} transitionStoreDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainGateInStoreContainers(id: number, transitionStoreDto: TransitionStoreDto, options?: any): AxiosPromise<void> {
            return TrainGateInApiFp(configuration).trainGateInStoreContainers(id, transitionStoreDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TrainGateInApi - object-oriented interface
 * @export
 * @class TrainGateInApi
 * @extends {BaseAPI}
 */
export class TrainGateInApi extends BaseAPI {
    /**
     * 
     * @summary Creates new Gate IN using train
     * @param {boolean} onTerminal 
     * @param {TrainDto} trainDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainGateInApi
     */
    public trainGateInCreateTrainGateIn(onTerminal: boolean, trainDto: TrainDto, options?: any) {
        return TrainGateInApiFp(this.configuration).trainGateInCreateTrainGateIn(onTerminal, trainDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns train view with company names and departure delay
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainGateInApi
     */
    public trainGateInGetTrainView(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return TrainGateInApiFp(this.configuration).trainGateInGetTrainView(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates train template from given Excel file
     * @param {number} id 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainGateInApi
     */
    public trainGateInImportUpdateTrainIn(id: number, file: any, options?: any) {
        return TrainGateInApiFp(this.configuration).trainGateInImportUpdateTrainIn(id, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates new Gate IN using train
     * @param {number} wagonId 
     * @param {number} order 
     * @param {TransitionDto} transitionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainGateInApi
     */
    public trainGateInLoadOnWagon(wagonId: number, order: number, transitionDto: TransitionDto, options?: any) {
        return TrainGateInApiFp(this.configuration).trainGateInLoadOnWagon(wagonId, order, transitionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates new Gate IN using train
     * @param {number} wagonId 
     * @param {number} order 
     * @param {number} transitionId 
     * @param {'NEW' | 'FOOD' | 'PAPER' | 'STANDARD' | 'SCRAP'} [qualityType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainGateInApi
     */
    public trainGateInLoadPlannedOnWagon(wagonId: number, order: number, transitionId: number, qualityType?: 'NEW' | 'FOOD' | 'PAPER' | 'STANDARD' | 'SCRAP', options?: any) {
        return TrainGateInApiFp(this.configuration).trainGateInLoadPlannedOnWagon(wagonId, order, transitionId, qualityType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove wagon from train, transitions from wagon and adjust wagon order
     * @param {number} trainId 
     * @param {number} wagonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainGateInApi
     */
    public trainGateInRemoveWagon(trainId: number, wagonId: number, options?: any) {
        return TrainGateInApiFp(this.configuration).trainGateInRemoveWagon(trainId, wagonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Stores all containers and places them to a selected coordinate
     * @param {number} id Id of train to store transitions
     * @param {TransitionStoreDto} transitionStoreDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainGateInApi
     */
    public trainGateInStoreContainers(id: number, transitionStoreDto: TransitionStoreDto, options?: any) {
        return TrainGateInApiFp(this.configuration).trainGateInStoreContainers(id, transitionStoreDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TrainGateOutApi - axios parameter creator
 * @export
 */
export const TrainGateOutApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates or remove gate out transitions and joins them to the train as unseated
         * @param {number} id 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainGateOutChangeUnseatedTransitions: async (id: number, requestBody: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling trainGateOutChangeUnseatedTransitions.');
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling trainGateOutChangeUnseatedTransitions.');
            }
            const localVarPath = `/train-gate-out/{id}/unseated/change`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof requestBody !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(requestBody !== undefined ? requestBody : {})
                : (requestBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} coparnId 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainGateOutCreateGateOutTransitionsCoparnUnseated: async (id: number, coparnId: number, requestBody: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling trainGateOutCreateGateOutTransitionsCoparnUnseated.');
            }
            // verify required parameter 'coparnId' is not null or undefined
            if (coparnId === null || coparnId === undefined) {
                throw new RequiredError('coparnId','Required parameter coparnId was null or undefined when calling trainGateOutCreateGateOutTransitionsCoparnUnseated.');
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling trainGateOutCreateGateOutTransitionsCoparnUnseated.');
            }
            const localVarPath = `/train-gate-out/{id}/unseated-coparn/{coparnId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"coparnId"}}`, encodeURIComponent(String(coparnId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof requestBody !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(requestBody !== undefined ? requestBody : {})
                : (requestBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates new Gate OUT using train
         * @param {TrainDto} trainDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainGateOutCreateTrain: async (trainDto: TrainDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'trainDto' is not null or undefined
            if (trainDto === null || trainDto === undefined) {
                throw new RequiredError('trainDto','Required parameter trainDto was null or undefined when calling trainGateOutCreateTrain.');
            }
            const localVarPath = `/train-gate-out/train`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof trainDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(trainDto !== undefined ? trainDto : {})
                : (trainDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} coparnId 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainGateOutCreateTrainWithUnseatedTransitions: async (coparnId: number, requestBody: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'coparnId' is not null or undefined
            if (coparnId === null || coparnId === undefined) {
                throw new RequiredError('coparnId','Required parameter coparnId was null or undefined when calling trainGateOutCreateTrainWithUnseatedTransitions.');
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling trainGateOutCreateTrainWithUnseatedTransitions.');
            }
            const localVarPath = `/train-gate-out/create-with-unseated/{coparnId}`
                .replace(`{${"coparnId"}}`, encodeURIComponent(String(coparnId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof requestBody !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(requestBody !== undefined ? requestBody : {})
                : (requestBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id Id of configuration
         * @param {number} trainId Id of train
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainGateOutDownloadCimTrainFile: async (id: number, trainId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling trainGateOutDownloadCimTrainFile.');
            }
            // verify required parameter 'trainId' is not null or undefined
            if (trainId === null || trainId === undefined) {
                throw new RequiredError('trainId','Required parameter trainId was null or undefined when calling trainGateOutDownloadCimTrainFile.');
            }
            const localVarPath = `/train-gate-out/cim-train-file/{id}/{trainId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"trainId"}}`, encodeURIComponent(String(trainId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id Id of configuration
         * @param {number} trainId Id of train
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainGateOutDownloadWagonReport: async (id: number, trainId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling trainGateOutDownloadWagonReport.');
            }
            // verify required parameter 'trainId' is not null or undefined
            if (trainId === null || trainId === undefined) {
                throw new RequiredError('trainId','Required parameter trainId was null or undefined when calling trainGateOutDownloadWagonReport.');
            }
            const localVarPath = `/train-gate-out/wagon-report/{id}/{trainId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"trainId"}}`, encodeURIComponent(String(trainId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns transitions with finished gate in and setted receiptMethod as TRAIN.
         * @param {TransitionSearchListSearchDto} transitionSearchListSearchDto 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainGateOutFindFinishedGateInForTrainGateOut: async (transitionSearchListSearchDto: TransitionSearchListSearchDto, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transitionSearchListSearchDto' is not null or undefined
            if (transitionSearchListSearchDto === null || transitionSearchListSearchDto === undefined) {
                throw new RequiredError('transitionSearchListSearchDto','Required parameter transitionSearchListSearchDto was null or undefined when calling trainGateOutFindFinishedGateInForTrainGateOut.');
            }
            const localVarPath = `/train-gate-out/receipt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof transitionSearchListSearchDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(transitionSearchListSearchDto !== undefined ? transitionSearchListSearchDto : {})
                : (transitionSearchListSearchDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CdCargoRequestDto} cdCargoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainGateOutGenerateCimDocument: async (cdCargoRequestDto: CdCargoRequestDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cdCargoRequestDto' is not null or undefined
            if (cdCargoRequestDto === null || cdCargoRequestDto === undefined) {
                throw new RequiredError('cdCargoRequestDto','Required parameter cdCargoRequestDto was null or undefined when calling trainGateOutGenerateCimDocument.');
            }
            const localVarPath = `/train-gate-out/cd-cargo/generate-cim`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof cdCargoRequestDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(cdCargoRequestDto !== undefined ? cdCargoRequestDto : {})
                : (cdCargoRequestDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} trainId 
         * @param {number} wagonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainGateOutRemoveWagon: async (trainId: number, wagonId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'trainId' is not null or undefined
            if (trainId === null || trainId === undefined) {
                throw new RequiredError('trainId','Required parameter trainId was null or undefined when calling trainGateOutRemoveWagon.');
            }
            // verify required parameter 'wagonId' is not null or undefined
            if (wagonId === null || wagonId === undefined) {
                throw new RequiredError('wagonId','Required parameter wagonId was null or undefined when calling trainGateOutRemoveWagon.');
            }
            const localVarPath = `/train-gate-out/{trainId}/wagon/{wagonId}`
                .replace(`{${"trainId"}}`, encodeURIComponent(String(trainId)))
                .replace(`{${"wagonId"}}`, encodeURIComponent(String(wagonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CdCargoRequestDto} cdCargoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainGateOutSendCimDocument: async (cdCargoRequestDto: CdCargoRequestDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cdCargoRequestDto' is not null or undefined
            if (cdCargoRequestDto === null || cdCargoRequestDto === undefined) {
                throw new RequiredError('cdCargoRequestDto','Required parameter cdCargoRequestDto was null or undefined when calling trainGateOutSendCimDocument.');
            }
            const localVarPath = `/train-gate-out/cd-cargo/send-cim`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof cdCargoRequestDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(cdCargoRequestDto !== undefined ? cdCargoRequestDto : {})
                : (cdCargoRequestDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates train template from given Excel file
         * @param {number} id 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainGateOutUploadFile: async (id: number, file: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling trainGateOutUploadFile.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling trainGateOutUploadFile.');
            }
            const localVarPath = `/train-gate-out/{id}/model`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrainGateOutApi - functional programming interface
 * @export
 */
export const TrainGateOutApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates or remove gate out transitions and joins them to the train as unseated
         * @param {number} id 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainGateOutChangeUnseatedTransitions(id: number, requestBody: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrainDto>> {
            const localVarAxiosArgs = await TrainGateOutApiAxiosParamCreator(configuration).trainGateOutChangeUnseatedTransitions(id, requestBody, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} coparnId 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainGateOutCreateGateOutTransitionsCoparnUnseated(id: number, coparnId: number, requestBody: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrainDto>> {
            const localVarAxiosArgs = await TrainGateOutApiAxiosParamCreator(configuration).trainGateOutCreateGateOutTransitionsCoparnUnseated(id, coparnId, requestBody, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Creates new Gate OUT using train
         * @param {TrainDto} trainDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainGateOutCreateTrain(trainDto: TrainDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrainDto>> {
            const localVarAxiosArgs = await TrainGateOutApiAxiosParamCreator(configuration).trainGateOutCreateTrain(trainDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} coparnId 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainGateOutCreateTrainWithUnseatedTransitions(coparnId: number, requestBody: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrainDto>> {
            const localVarAxiosArgs = await TrainGateOutApiAxiosParamCreator(configuration).trainGateOutCreateTrainWithUnseatedTransitions(coparnId, requestBody, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id Id of configuration
         * @param {number} trainId Id of train
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainGateOutDownloadCimTrainFile(id: number, trainId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await TrainGateOutApiAxiosParamCreator(configuration).trainGateOutDownloadCimTrainFile(id, trainId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id Id of configuration
         * @param {number} trainId Id of train
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainGateOutDownloadWagonReport(id: number, trainId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await TrainGateOutApiAxiosParamCreator(configuration).trainGateOutDownloadWagonReport(id, trainId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Returns transitions with finished gate in and setted receiptMethod as TRAIN.
         * @param {TransitionSearchListSearchDto} transitionSearchListSearchDto 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainGateOutFindFinishedGateInForTrainGateOut(transitionSearchListSearchDto: TransitionSearchListSearchDto, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTransitionSearchListDto>> {
            const localVarAxiosArgs = await TrainGateOutApiAxiosParamCreator(configuration).trainGateOutFindFinishedGateInForTrainGateOut(transitionSearchListSearchDto, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CdCargoRequestDto} cdCargoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainGateOutGenerateCimDocument(cdCargoRequestDto: CdCargoRequestDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await TrainGateOutApiAxiosParamCreator(configuration).trainGateOutGenerateCimDocument(cdCargoRequestDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} trainId 
         * @param {number} wagonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainGateOutRemoveWagon(trainId: number, wagonId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrainDto>> {
            const localVarAxiosArgs = await TrainGateOutApiAxiosParamCreator(configuration).trainGateOutRemoveWagon(trainId, wagonId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CdCargoRequestDto} cdCargoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainGateOutSendCimDocument(cdCargoRequestDto: CdCargoRequestDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await TrainGateOutApiAxiosParamCreator(configuration).trainGateOutSendCimDocument(cdCargoRequestDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Creates train template from given Excel file
         * @param {number} id 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainGateOutUploadFile(id: number, file: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrainDto>> {
            const localVarAxiosArgs = await TrainGateOutApiAxiosParamCreator(configuration).trainGateOutUploadFile(id, file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TrainGateOutApi - factory interface
 * @export
 */
export const TrainGateOutApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Creates or remove gate out transitions and joins them to the train as unseated
         * @param {number} id 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainGateOutChangeUnseatedTransitions(id: number, requestBody: Array<number>, options?: any): AxiosPromise<TrainDto> {
            return TrainGateOutApiFp(configuration).trainGateOutChangeUnseatedTransitions(id, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} coparnId 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainGateOutCreateGateOutTransitionsCoparnUnseated(id: number, coparnId: number, requestBody: Array<number>, options?: any): AxiosPromise<TrainDto> {
            return TrainGateOutApiFp(configuration).trainGateOutCreateGateOutTransitionsCoparnUnseated(id, coparnId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates new Gate OUT using train
         * @param {TrainDto} trainDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainGateOutCreateTrain(trainDto: TrainDto, options?: any): AxiosPromise<TrainDto> {
            return TrainGateOutApiFp(configuration).trainGateOutCreateTrain(trainDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} coparnId 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainGateOutCreateTrainWithUnseatedTransitions(coparnId: number, requestBody: Array<number>, options?: any): AxiosPromise<TrainDto> {
            return TrainGateOutApiFp(configuration).trainGateOutCreateTrainWithUnseatedTransitions(coparnId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id Id of configuration
         * @param {number} trainId Id of train
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainGateOutDownloadCimTrainFile(id: number, trainId: number, options?: any): AxiosPromise<Array<string>> {
            return TrainGateOutApiFp(configuration).trainGateOutDownloadCimTrainFile(id, trainId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id Id of configuration
         * @param {number} trainId Id of train
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainGateOutDownloadWagonReport(id: number, trainId: number, options?: any): AxiosPromise<Array<string>> {
            return TrainGateOutApiFp(configuration).trainGateOutDownloadWagonReport(id, trainId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns transitions with finished gate in and setted receiptMethod as TRAIN.
         * @param {TransitionSearchListSearchDto} transitionSearchListSearchDto 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainGateOutFindFinishedGateInForTrainGateOut(transitionSearchListSearchDto: TransitionSearchListSearchDto, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageTransitionSearchListDto> {
            return TrainGateOutApiFp(configuration).trainGateOutFindFinishedGateInForTrainGateOut(transitionSearchListSearchDto, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CdCargoRequestDto} cdCargoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainGateOutGenerateCimDocument(cdCargoRequestDto: CdCargoRequestDto, options?: any): AxiosPromise<Array<string>> {
            return TrainGateOutApiFp(configuration).trainGateOutGenerateCimDocument(cdCargoRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} trainId 
         * @param {number} wagonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainGateOutRemoveWagon(trainId: number, wagonId: number, options?: any): AxiosPromise<TrainDto> {
            return TrainGateOutApiFp(configuration).trainGateOutRemoveWagon(trainId, wagonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CdCargoRequestDto} cdCargoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainGateOutSendCimDocument(cdCargoRequestDto: CdCargoRequestDto, options?: any): AxiosPromise<string> {
            return TrainGateOutApiFp(configuration).trainGateOutSendCimDocument(cdCargoRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates train template from given Excel file
         * @param {number} id 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainGateOutUploadFile(id: number, file: any, options?: any): AxiosPromise<TrainDto> {
            return TrainGateOutApiFp(configuration).trainGateOutUploadFile(id, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TrainGateOutApi - object-oriented interface
 * @export
 * @class TrainGateOutApi
 * @extends {BaseAPI}
 */
export class TrainGateOutApi extends BaseAPI {
    /**
     * 
     * @summary Creates or remove gate out transitions and joins them to the train as unseated
     * @param {number} id 
     * @param {Array<number>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainGateOutApi
     */
    public trainGateOutChangeUnseatedTransitions(id: number, requestBody: Array<number>, options?: any) {
        return TrainGateOutApiFp(this.configuration).trainGateOutChangeUnseatedTransitions(id, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} coparnId 
     * @param {Array<number>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainGateOutApi
     */
    public trainGateOutCreateGateOutTransitionsCoparnUnseated(id: number, coparnId: number, requestBody: Array<number>, options?: any) {
        return TrainGateOutApiFp(this.configuration).trainGateOutCreateGateOutTransitionsCoparnUnseated(id, coparnId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates new Gate OUT using train
     * @param {TrainDto} trainDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainGateOutApi
     */
    public trainGateOutCreateTrain(trainDto: TrainDto, options?: any) {
        return TrainGateOutApiFp(this.configuration).trainGateOutCreateTrain(trainDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} coparnId 
     * @param {Array<number>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainGateOutApi
     */
    public trainGateOutCreateTrainWithUnseatedTransitions(coparnId: number, requestBody: Array<number>, options?: any) {
        return TrainGateOutApiFp(this.configuration).trainGateOutCreateTrainWithUnseatedTransitions(coparnId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id Id of configuration
     * @param {number} trainId Id of train
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainGateOutApi
     */
    public trainGateOutDownloadCimTrainFile(id: number, trainId: number, options?: any) {
        return TrainGateOutApiFp(this.configuration).trainGateOutDownloadCimTrainFile(id, trainId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id Id of configuration
     * @param {number} trainId Id of train
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainGateOutApi
     */
    public trainGateOutDownloadWagonReport(id: number, trainId: number, options?: any) {
        return TrainGateOutApiFp(this.configuration).trainGateOutDownloadWagonReport(id, trainId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns transitions with finished gate in and setted receiptMethod as TRAIN.
     * @param {TransitionSearchListSearchDto} transitionSearchListSearchDto 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainGateOutApi
     */
    public trainGateOutFindFinishedGateInForTrainGateOut(transitionSearchListSearchDto: TransitionSearchListSearchDto, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return TrainGateOutApiFp(this.configuration).trainGateOutFindFinishedGateInForTrainGateOut(transitionSearchListSearchDto, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CdCargoRequestDto} cdCargoRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainGateOutApi
     */
    public trainGateOutGenerateCimDocument(cdCargoRequestDto: CdCargoRequestDto, options?: any) {
        return TrainGateOutApiFp(this.configuration).trainGateOutGenerateCimDocument(cdCargoRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} trainId 
     * @param {number} wagonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainGateOutApi
     */
    public trainGateOutRemoveWagon(trainId: number, wagonId: number, options?: any) {
        return TrainGateOutApiFp(this.configuration).trainGateOutRemoveWagon(trainId, wagonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CdCargoRequestDto} cdCargoRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainGateOutApi
     */
    public trainGateOutSendCimDocument(cdCargoRequestDto: CdCargoRequestDto, options?: any) {
        return TrainGateOutApiFp(this.configuration).trainGateOutSendCimDocument(cdCargoRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates train template from given Excel file
     * @param {number} id 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainGateOutApi
     */
    public trainGateOutUploadFile(id: number, file: any, options?: any) {
        return TrainGateOutApiFp(this.configuration).trainGateOutUploadFile(id, file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TransitionApi - axios parameter creator
 * @export
 */
export const TransitionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {TransitionSearchListSearchDto} [transitionSearchListSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionCarrierSearch: async (page?: number, size?: number, sort?: Array<string>, transitionSearchListSearchDto?: TransitionSearchListSearchDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/transition/carrier/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof transitionSearchListSearchDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(transitionSearchListSearchDto !== undefined ? transitionSearchListSearchDto : {})
                : (transitionSearchListSearchDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns last finished GO transition with iluCode
         * @param {number} id 
         * @param {TransitionStoreDto} transitionStoreDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionChangeContainerPosition: async (id: number, transitionStoreDto: TransitionStoreDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling transitionChangeContainerPosition.');
            }
            // verify required parameter 'transitionStoreDto' is not null or undefined
            if (transitionStoreDto === null || transitionStoreDto === undefined) {
                throw new RequiredError('transitionStoreDto','Required parameter transitionStoreDto was null or undefined when calling transitionChangeContainerPosition.');
            }
            const localVarPath = `/transition/{id}/position`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof transitionStoreDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(transitionStoreDto !== undefined ? transitionStoreDto : {})
                : (transitionStoreDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ContainerStateDto} containerStateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionChangeContainerState: async (id: number, containerStateDto: ContainerStateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling transitionChangeContainerState.');
            }
            // verify required parameter 'containerStateDto' is not null or undefined
            if (containerStateDto === null || containerStateDto === undefined) {
                throw new RequiredError('containerStateDto','Required parameter containerStateDto was null or undefined when calling transitionChangeContainerState.');
            }
            const localVarPath = `/transition/{id}/status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof containerStateDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(containerStateDto !== undefined ? containerStateDto : {})
                : (containerStateDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} stateChanged 
         * @param {Array<TransitionDto>} transitionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionChangeCustomsInfo: async (stateChanged: boolean, transitionDto: Array<TransitionDto>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'stateChanged' is not null or undefined
            if (stateChanged === null || stateChanged === undefined) {
                throw new RequiredError('stateChanged','Required parameter stateChanged was null or undefined when calling transitionChangeCustomsInfo.');
            }
            // verify required parameter 'transitionDto' is not null or undefined
            if (transitionDto === null || transitionDto === undefined) {
                throw new RequiredError('transitionDto','Required parameter transitionDto was null or undefined when calling transitionChangeCustomsInfo.');
            }
            const localVarPath = `/transition/customs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stateChanged !== undefined) {
                localVarQueryParameter['stateChanged'] = stateChanged;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof transitionDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(transitionDto !== undefined ? transitionDto : {})
                : (transitionDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} loadedOnTrain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionChangeLoadedOnTrain: async (id: number, loadedOnTrain: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling transitionChangeLoadedOnTrain.');
            }
            // verify required parameter 'loadedOnTrain' is not null or undefined
            if (loadedOnTrain === null || loadedOnTrain === undefined) {
                throw new RequiredError('loadedOnTrain','Required parameter loadedOnTrain was null or undefined when calling transitionChangeLoadedOnTrain.');
            }
            const localVarPath = `/transition/{id}/loadedOnTrain`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (loadedOnTrain !== undefined) {
                localVarQueryParameter['loadedOnTrain'] = loadedOnTrain;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates new customs info
         * @param {number} transitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionCreateCustomsInfo: async (transitionId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transitionId' is not null or undefined
            if (transitionId === null || transitionId === undefined) {
                throw new RequiredError('transitionId','Required parameter transitionId was null or undefined when calling transitionCreateCustomsInfo.');
            }
            const localVarPath = `/transition/customs/{transitionId}/new`
                .replace(`{${"transitionId"}}`, encodeURIComponent(String(transitionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates new extern transition
         * @param {TransitionDto} transitionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionCreateExtern: async (transitionDto: TransitionDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transitionDto' is not null or undefined
            if (transitionDto === null || transitionDto === undefined) {
                throw new RequiredError('transitionDto','Required parameter transitionDto was null or undefined when calling transitionCreateExtern.');
            }
            const localVarPath = `/transition/extern/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof transitionDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(transitionDto !== undefined ? transitionDto : {})
                : (transitionDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {TransitionSearchDto} [transitionSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionCustomsSearch: async (page?: number, size?: number, sort?: Array<string>, transitionSearchDto?: TransitionSearchDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/transition/customs/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof transitionSearchDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(transitionSearchDto !== undefined ? transitionSearchDto : {})
                : (transitionSearchDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {TransitionSearchDto} [transitionSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionCustomsSearchHistory: async (page?: number, size?: number, sort?: Array<string>, transitionSearchDto?: TransitionSearchDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/transition/customs/search-history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof transitionSearchDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(transitionSearchDto !== undefined ? transitionSearchDto : {})
                : (transitionSearchDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete transition
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling transitionDelete.');
            }
            const localVarPath = `/transition/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete customs info
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionDeleteCustomsInfo: async (requestBody: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling transitionDeleteCustomsInfo.');
            }
            const localVarPath = `/transition/customs/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof requestBody !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(requestBody !== undefined ? requestBody : {})
                : (requestBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'STANDARD' | 'ADR_RID'} exportType 
         * @param {'CZECH' | 'ENGLISH'} language 
         * @param {TransitionSearchDto} [transitionSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionDownloadExport: async (exportType: 'STANDARD' | 'ADR_RID', language: 'CZECH' | 'ENGLISH', transitionSearchDto?: TransitionSearchDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'exportType' is not null or undefined
            if (exportType === null || exportType === undefined) {
                throw new RequiredError('exportType','Required parameter exportType was null or undefined when calling transitionDownloadExport.');
            }
            // verify required parameter 'language' is not null or undefined
            if (language === null || language === undefined) {
                throw new RequiredError('language','Required parameter language was null or undefined when calling transitionDownloadExport.');
            }
            const localVarPath = `/transition/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (exportType !== undefined) {
                localVarQueryParameter['exportType'] = exportType;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof transitionSearchDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(transitionSearchDto !== undefined ? transitionSearchDto : {})
                : (transitionSearchDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionDownloadPdf: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling transitionDownloadPdf.');
            }
            const localVarPath = `/transition/{id}/export`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find a transition by its id.
         * @param {number} id Id of transition to be found
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionFindById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling transitionFindById.');
            }
            const localVarPath = `/transition/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find a transition by its id.
         * @param {number} id Id of TransitionSearchListDto to be found
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionFindByIdOnSite: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling transitionFindByIdOnSite.');
            }
            const localVarPath = `/transition/on-site/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns last finished GO transition with iluCode
         * @param {string} iluCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionFindLastByIlu: async (iluCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'iluCode' is not null or undefined
            if (iluCode === null || iluCode === undefined) {
                throw new RequiredError('iluCode','Required parameter iluCode was null or undefined when calling transitionFindLastByIlu.');
            }
            const localVarPath = `/transition/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (iluCode !== undefined) {
                localVarQueryParameter['iluCode'] = iluCode;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return info if transitions has not gate-out
         * @param {number} transitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionIsTransitionWithoutGo: async (transitionId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transitionId' is not null or undefined
            if (transitionId === null || transitionId === undefined) {
                throw new RequiredError('transitionId','Required parameter transitionId was null or undefined when calling transitionIsTransitionWithoutGo.');
            }
            const localVarPath = `/transition/customs/{transitionId}/go-free`
                .replace(`{${"transitionId"}}`, encodeURIComponent(String(transitionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns true if ILU code is valid
         * @param {string} iluCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionIsValidIluCode: async (iluCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'iluCode' is not null or undefined
            if (iluCode === null || iluCode === undefined) {
                throw new RequiredError('iluCode','Required parameter iluCode was null or undefined when calling transitionIsValidIluCode.');
            }
            const localVarPath = `/transition/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (iluCode !== undefined) {
                localVarQueryParameter['iluCode'] = iluCode;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {OperatorTransitionSearchDto} [operatorTransitionSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionOperatorSearch: async (page?: number, size?: number, sort?: Array<string>, operatorTransitionSearchDto?: OperatorTransitionSearchDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/transition/operator/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof operatorTransitionSearchDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(operatorTransitionSearchDto !== undefined ? operatorTransitionSearchDto : {})
                : (operatorTransitionSearchDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {TransitionSearchDto} [transitionSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionSearch: async (page?: number, size?: number, sort?: Array<string>, transitionSearchDto?: TransitionSearchDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/transition/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof transitionSearchDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(transitionSearchDto !== undefined ? transitionSearchDto : {})
                : (transitionSearchDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {TransitionSearchListSearchDto} [transitionSearchListSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionSearchForList: async (page?: number, size?: number, sort?: Array<string>, transitionSearchListSearchDto?: TransitionSearchListSearchDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/transition/search-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof transitionSearchListSearchDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(transitionSearchListSearchDto !== undefined ? transitionSearchListSearchDto : {})
                : (transitionSearchListSearchDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {TransitionSearchListSearchDto} [transitionSearchListSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionSearchForListPlanned: async (page?: number, size?: number, sort?: Array<string>, transitionSearchListSearchDto?: TransitionSearchListSearchDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/transition/search-list-planned`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof transitionSearchListSearchDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(transitionSearchListSearchDto !== undefined ? transitionSearchListSearchDto : {})
                : (transitionSearchListSearchDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates existing transition
         * @param {number} id 
         * @param {TransitionDto} transitionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionUpdate: async (id: number, transitionDto: TransitionDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling transitionUpdate.');
            }
            // verify required parameter 'transitionDto' is not null or undefined
            if (transitionDto === null || transitionDto === undefined) {
                throw new RequiredError('transitionDto','Required parameter transitionDto was null or undefined when calling transitionUpdate.');
            }
            const localVarPath = `/transition/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof transitionDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(transitionDto !== undefined ? transitionDto : {})
                : (transitionDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates existing transition
         * @param {number} id 
         * @param {number} driverArrivalId 
         * @param {TransitionDto} transitionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionUpdateByDriver: async (id: number, driverArrivalId: number, transitionDto: TransitionDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling transitionUpdateByDriver.');
            }
            // verify required parameter 'driverArrivalId' is not null or undefined
            if (driverArrivalId === null || driverArrivalId === undefined) {
                throw new RequiredError('driverArrivalId','Required parameter driverArrivalId was null or undefined when calling transitionUpdateByDriver.');
            }
            // verify required parameter 'transitionDto' is not null or undefined
            if (transitionDto === null || transitionDto === undefined) {
                throw new RequiredError('transitionDto','Required parameter transitionDto was null or undefined when calling transitionUpdateByDriver.');
            }
            const localVarPath = `/transition/driver-transition/{id}/driver-arrival/{driverArrivalId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"driverArrivalId"}}`, encodeURIComponent(String(driverArrivalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof transitionDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(transitionDto !== undefined ? transitionDto : {})
                : (transitionDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {TransitionVgmDto} transitionVgmDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionUpdateVgm: async (id: number, transitionVgmDto: TransitionVgmDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling transitionUpdateVgm.');
            }
            // verify required parameter 'transitionVgmDto' is not null or undefined
            if (transitionVgmDto === null || transitionVgmDto === undefined) {
                throw new RequiredError('transitionVgmDto','Required parameter transitionVgmDto was null or undefined when calling transitionUpdateVgm.');
            }
            const localVarPath = `/transition/{id}/vgm`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof transitionVgmDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(transitionVgmDto !== undefined ? transitionVgmDto : {})
                : (transitionVgmDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransitionApi - functional programming interface
 * @export
 */
export const TransitionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {TransitionSearchListSearchDto} [transitionSearchListSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transitionCarrierSearch(page?: number, size?: number, sort?: Array<string>, transitionSearchListSearchDto?: TransitionSearchListSearchDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTransitionSearchListDto>> {
            const localVarAxiosArgs = await TransitionApiAxiosParamCreator(configuration).transitionCarrierSearch(page, size, sort, transitionSearchListSearchDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Returns last finished GO transition with iluCode
         * @param {number} id 
         * @param {TransitionStoreDto} transitionStoreDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transitionChangeContainerPosition(id: number, transitionStoreDto: TransitionStoreDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransitionDto>> {
            const localVarAxiosArgs = await TransitionApiAxiosParamCreator(configuration).transitionChangeContainerPosition(id, transitionStoreDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ContainerStateDto} containerStateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transitionChangeContainerState(id: number, containerStateDto: ContainerStateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransitionDto>> {
            const localVarAxiosArgs = await TransitionApiAxiosParamCreator(configuration).transitionChangeContainerState(id, containerStateDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {boolean} stateChanged 
         * @param {Array<TransitionDto>} transitionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transitionChangeCustomsInfo(stateChanged: boolean, transitionDto: Array<TransitionDto>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TransitionDto>>> {
            const localVarAxiosArgs = await TransitionApiAxiosParamCreator(configuration).transitionChangeCustomsInfo(stateChanged, transitionDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} loadedOnTrain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transitionChangeLoadedOnTrain(id: number, loadedOnTrain: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransitionDto>> {
            const localVarAxiosArgs = await TransitionApiAxiosParamCreator(configuration).transitionChangeLoadedOnTrain(id, loadedOnTrain, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Creates new customs info
         * @param {number} transitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transitionCreateCustomsInfo(transitionId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransitionDto>> {
            const localVarAxiosArgs = await TransitionApiAxiosParamCreator(configuration).transitionCreateCustomsInfo(transitionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Creates new extern transition
         * @param {TransitionDto} transitionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transitionCreateExtern(transitionDto: TransitionDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransitionDto>> {
            const localVarAxiosArgs = await TransitionApiAxiosParamCreator(configuration).transitionCreateExtern(transitionDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {TransitionSearchDto} [transitionSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transitionCustomsSearch(page?: number, size?: number, sort?: Array<string>, transitionSearchDto?: TransitionSearchDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTransitionDto>> {
            const localVarAxiosArgs = await TransitionApiAxiosParamCreator(configuration).transitionCustomsSearch(page, size, sort, transitionSearchDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {TransitionSearchDto} [transitionSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transitionCustomsSearchHistory(page?: number, size?: number, sort?: Array<string>, transitionSearchDto?: TransitionSearchDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTransitionDto>> {
            const localVarAxiosArgs = await TransitionApiAxiosParamCreator(configuration).transitionCustomsSearchHistory(page, size, sort, transitionSearchDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete transition
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transitionDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TransitionApiAxiosParamCreator(configuration).transitionDelete(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete customs info
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transitionDeleteCustomsInfo(requestBody: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TransitionApiAxiosParamCreator(configuration).transitionDeleteCustomsInfo(requestBody, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {'STANDARD' | 'ADR_RID'} exportType 
         * @param {'CZECH' | 'ENGLISH'} language 
         * @param {TransitionSearchDto} [transitionSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transitionDownloadExport(exportType: 'STANDARD' | 'ADR_RID', language: 'CZECH' | 'ENGLISH', transitionSearchDto?: TransitionSearchDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await TransitionApiAxiosParamCreator(configuration).transitionDownloadExport(exportType, language, transitionSearchDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transitionDownloadPdf(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await TransitionApiAxiosParamCreator(configuration).transitionDownloadPdf(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Find a transition by its id.
         * @param {number} id Id of transition to be found
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transitionFindById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransitionDto>> {
            const localVarAxiosArgs = await TransitionApiAxiosParamCreator(configuration).transitionFindById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Find a transition by its id.
         * @param {number} id Id of TransitionSearchListDto to be found
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transitionFindByIdOnSite(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransitionSearchListDto>> {
            const localVarAxiosArgs = await TransitionApiAxiosParamCreator(configuration).transitionFindByIdOnSite(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Returns last finished GO transition with iluCode
         * @param {string} iluCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transitionFindLastByIlu(iluCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransitionDto>> {
            const localVarAxiosArgs = await TransitionApiAxiosParamCreator(configuration).transitionFindLastByIlu(iluCode, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return info if transitions has not gate-out
         * @param {number} transitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transitionIsTransitionWithoutGo(transitionId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await TransitionApiAxiosParamCreator(configuration).transitionIsTransitionWithoutGo(transitionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Returns true if ILU code is valid
         * @param {string} iluCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transitionIsValidIluCode(iluCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await TransitionApiAxiosParamCreator(configuration).transitionIsValidIluCode(iluCode, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {OperatorTransitionSearchDto} [operatorTransitionSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transitionOperatorSearch(page?: number, size?: number, sort?: Array<string>, operatorTransitionSearchDto?: OperatorTransitionSearchDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOperatorTransitionDto>> {
            const localVarAxiosArgs = await TransitionApiAxiosParamCreator(configuration).transitionOperatorSearch(page, size, sort, operatorTransitionSearchDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {TransitionSearchDto} [transitionSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transitionSearch(page?: number, size?: number, sort?: Array<string>, transitionSearchDto?: TransitionSearchDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTransitionDto>> {
            const localVarAxiosArgs = await TransitionApiAxiosParamCreator(configuration).transitionSearch(page, size, sort, transitionSearchDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {TransitionSearchListSearchDto} [transitionSearchListSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transitionSearchForList(page?: number, size?: number, sort?: Array<string>, transitionSearchListSearchDto?: TransitionSearchListSearchDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTransitionSearchListDto>> {
            const localVarAxiosArgs = await TransitionApiAxiosParamCreator(configuration).transitionSearchForList(page, size, sort, transitionSearchListSearchDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {TransitionSearchListSearchDto} [transitionSearchListSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transitionSearchForListPlanned(page?: number, size?: number, sort?: Array<string>, transitionSearchListSearchDto?: TransitionSearchListSearchDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTransitionSearchListDto>> {
            const localVarAxiosArgs = await TransitionApiAxiosParamCreator(configuration).transitionSearchForListPlanned(page, size, sort, transitionSearchListSearchDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates existing transition
         * @param {number} id 
         * @param {TransitionDto} transitionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transitionUpdate(id: number, transitionDto: TransitionDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransitionDto>> {
            const localVarAxiosArgs = await TransitionApiAxiosParamCreator(configuration).transitionUpdate(id, transitionDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates existing transition
         * @param {number} id 
         * @param {number} driverArrivalId 
         * @param {TransitionDto} transitionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transitionUpdateByDriver(id: number, driverArrivalId: number, transitionDto: TransitionDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransitionDto>> {
            const localVarAxiosArgs = await TransitionApiAxiosParamCreator(configuration).transitionUpdateByDriver(id, driverArrivalId, transitionDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {TransitionVgmDto} transitionVgmDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transitionUpdateVgm(id: number, transitionVgmDto: TransitionVgmDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransitionDto>> {
            const localVarAxiosArgs = await TransitionApiAxiosParamCreator(configuration).transitionUpdateVgm(id, transitionVgmDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TransitionApi - factory interface
 * @export
 */
export const TransitionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {TransitionSearchListSearchDto} [transitionSearchListSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionCarrierSearch(page?: number, size?: number, sort?: Array<string>, transitionSearchListSearchDto?: TransitionSearchListSearchDto, options?: any): AxiosPromise<PageTransitionSearchListDto> {
            return TransitionApiFp(configuration).transitionCarrierSearch(page, size, sort, transitionSearchListSearchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns last finished GO transition with iluCode
         * @param {number} id 
         * @param {TransitionStoreDto} transitionStoreDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionChangeContainerPosition(id: number, transitionStoreDto: TransitionStoreDto, options?: any): AxiosPromise<TransitionDto> {
            return TransitionApiFp(configuration).transitionChangeContainerPosition(id, transitionStoreDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ContainerStateDto} containerStateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionChangeContainerState(id: number, containerStateDto: ContainerStateDto, options?: any): AxiosPromise<TransitionDto> {
            return TransitionApiFp(configuration).transitionChangeContainerState(id, containerStateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} stateChanged 
         * @param {Array<TransitionDto>} transitionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionChangeCustomsInfo(stateChanged: boolean, transitionDto: Array<TransitionDto>, options?: any): AxiosPromise<Array<TransitionDto>> {
            return TransitionApiFp(configuration).transitionChangeCustomsInfo(stateChanged, transitionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} loadedOnTrain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionChangeLoadedOnTrain(id: number, loadedOnTrain: boolean, options?: any): AxiosPromise<TransitionDto> {
            return TransitionApiFp(configuration).transitionChangeLoadedOnTrain(id, loadedOnTrain, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates new customs info
         * @param {number} transitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionCreateCustomsInfo(transitionId: number, options?: any): AxiosPromise<TransitionDto> {
            return TransitionApiFp(configuration).transitionCreateCustomsInfo(transitionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates new extern transition
         * @param {TransitionDto} transitionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionCreateExtern(transitionDto: TransitionDto, options?: any): AxiosPromise<TransitionDto> {
            return TransitionApiFp(configuration).transitionCreateExtern(transitionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {TransitionSearchDto} [transitionSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionCustomsSearch(page?: number, size?: number, sort?: Array<string>, transitionSearchDto?: TransitionSearchDto, options?: any): AxiosPromise<PageTransitionDto> {
            return TransitionApiFp(configuration).transitionCustomsSearch(page, size, sort, transitionSearchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {TransitionSearchDto} [transitionSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionCustomsSearchHistory(page?: number, size?: number, sort?: Array<string>, transitionSearchDto?: TransitionSearchDto, options?: any): AxiosPromise<PageTransitionDto> {
            return TransitionApiFp(configuration).transitionCustomsSearchHistory(page, size, sort, transitionSearchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete transition
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionDelete(id: number, options?: any): AxiosPromise<void> {
            return TransitionApiFp(configuration).transitionDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete customs info
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionDeleteCustomsInfo(requestBody: Array<number>, options?: any): AxiosPromise<void> {
            return TransitionApiFp(configuration).transitionDeleteCustomsInfo(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'STANDARD' | 'ADR_RID'} exportType 
         * @param {'CZECH' | 'ENGLISH'} language 
         * @param {TransitionSearchDto} [transitionSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionDownloadExport(exportType: 'STANDARD' | 'ADR_RID', language: 'CZECH' | 'ENGLISH', transitionSearchDto?: TransitionSearchDto, options?: any): AxiosPromise<Array<string>> {
            return TransitionApiFp(configuration).transitionDownloadExport(exportType, language, transitionSearchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionDownloadPdf(id: number, options?: any): AxiosPromise<Array<string>> {
            return TransitionApiFp(configuration).transitionDownloadPdf(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find a transition by its id.
         * @param {number} id Id of transition to be found
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionFindById(id: number, options?: any): AxiosPromise<TransitionDto> {
            return TransitionApiFp(configuration).transitionFindById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find a transition by its id.
         * @param {number} id Id of TransitionSearchListDto to be found
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionFindByIdOnSite(id: number, options?: any): AxiosPromise<TransitionSearchListDto> {
            return TransitionApiFp(configuration).transitionFindByIdOnSite(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns last finished GO transition with iluCode
         * @param {string} iluCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionFindLastByIlu(iluCode: string, options?: any): AxiosPromise<TransitionDto> {
            return TransitionApiFp(configuration).transitionFindLastByIlu(iluCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return info if transitions has not gate-out
         * @param {number} transitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionIsTransitionWithoutGo(transitionId: number, options?: any): AxiosPromise<boolean> {
            return TransitionApiFp(configuration).transitionIsTransitionWithoutGo(transitionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns true if ILU code is valid
         * @param {string} iluCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionIsValidIluCode(iluCode: string, options?: any): AxiosPromise<boolean> {
            return TransitionApiFp(configuration).transitionIsValidIluCode(iluCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {OperatorTransitionSearchDto} [operatorTransitionSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionOperatorSearch(page?: number, size?: number, sort?: Array<string>, operatorTransitionSearchDto?: OperatorTransitionSearchDto, options?: any): AxiosPromise<PageOperatorTransitionDto> {
            return TransitionApiFp(configuration).transitionOperatorSearch(page, size, sort, operatorTransitionSearchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {TransitionSearchDto} [transitionSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionSearch(page?: number, size?: number, sort?: Array<string>, transitionSearchDto?: TransitionSearchDto, options?: any): AxiosPromise<PageTransitionDto> {
            return TransitionApiFp(configuration).transitionSearch(page, size, sort, transitionSearchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {TransitionSearchListSearchDto} [transitionSearchListSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionSearchForList(page?: number, size?: number, sort?: Array<string>, transitionSearchListSearchDto?: TransitionSearchListSearchDto, options?: any): AxiosPromise<PageTransitionSearchListDto> {
            return TransitionApiFp(configuration).transitionSearchForList(page, size, sort, transitionSearchListSearchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {TransitionSearchListSearchDto} [transitionSearchListSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionSearchForListPlanned(page?: number, size?: number, sort?: Array<string>, transitionSearchListSearchDto?: TransitionSearchListSearchDto, options?: any): AxiosPromise<PageTransitionSearchListDto> {
            return TransitionApiFp(configuration).transitionSearchForListPlanned(page, size, sort, transitionSearchListSearchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates existing transition
         * @param {number} id 
         * @param {TransitionDto} transitionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionUpdate(id: number, transitionDto: TransitionDto, options?: any): AxiosPromise<TransitionDto> {
            return TransitionApiFp(configuration).transitionUpdate(id, transitionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates existing transition
         * @param {number} id 
         * @param {number} driverArrivalId 
         * @param {TransitionDto} transitionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionUpdateByDriver(id: number, driverArrivalId: number, transitionDto: TransitionDto, options?: any): AxiosPromise<TransitionDto> {
            return TransitionApiFp(configuration).transitionUpdateByDriver(id, driverArrivalId, transitionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {TransitionVgmDto} transitionVgmDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionUpdateVgm(id: number, transitionVgmDto: TransitionVgmDto, options?: any): AxiosPromise<TransitionDto> {
            return TransitionApiFp(configuration).transitionUpdateVgm(id, transitionVgmDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TransitionApi - object-oriented interface
 * @export
 * @class TransitionApi
 * @extends {BaseAPI}
 */
export class TransitionApi extends BaseAPI {
    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {TransitionSearchListSearchDto} [transitionSearchListSearchDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransitionApi
     */
    public transitionCarrierSearch(page?: number, size?: number, sort?: Array<string>, transitionSearchListSearchDto?: TransitionSearchListSearchDto, options?: any) {
        return TransitionApiFp(this.configuration).transitionCarrierSearch(page, size, sort, transitionSearchListSearchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns last finished GO transition with iluCode
     * @param {number} id 
     * @param {TransitionStoreDto} transitionStoreDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransitionApi
     */
    public transitionChangeContainerPosition(id: number, transitionStoreDto: TransitionStoreDto, options?: any) {
        return TransitionApiFp(this.configuration).transitionChangeContainerPosition(id, transitionStoreDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ContainerStateDto} containerStateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransitionApi
     */
    public transitionChangeContainerState(id: number, containerStateDto: ContainerStateDto, options?: any) {
        return TransitionApiFp(this.configuration).transitionChangeContainerState(id, containerStateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} stateChanged 
     * @param {Array<TransitionDto>} transitionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransitionApi
     */
    public transitionChangeCustomsInfo(stateChanged: boolean, transitionDto: Array<TransitionDto>, options?: any) {
        return TransitionApiFp(this.configuration).transitionChangeCustomsInfo(stateChanged, transitionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {boolean} loadedOnTrain 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransitionApi
     */
    public transitionChangeLoadedOnTrain(id: number, loadedOnTrain: boolean, options?: any) {
        return TransitionApiFp(this.configuration).transitionChangeLoadedOnTrain(id, loadedOnTrain, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates new customs info
     * @param {number} transitionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransitionApi
     */
    public transitionCreateCustomsInfo(transitionId: number, options?: any) {
        return TransitionApiFp(this.configuration).transitionCreateCustomsInfo(transitionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates new extern transition
     * @param {TransitionDto} transitionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransitionApi
     */
    public transitionCreateExtern(transitionDto: TransitionDto, options?: any) {
        return TransitionApiFp(this.configuration).transitionCreateExtern(transitionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {TransitionSearchDto} [transitionSearchDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransitionApi
     */
    public transitionCustomsSearch(page?: number, size?: number, sort?: Array<string>, transitionSearchDto?: TransitionSearchDto, options?: any) {
        return TransitionApiFp(this.configuration).transitionCustomsSearch(page, size, sort, transitionSearchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {TransitionSearchDto} [transitionSearchDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransitionApi
     */
    public transitionCustomsSearchHistory(page?: number, size?: number, sort?: Array<string>, transitionSearchDto?: TransitionSearchDto, options?: any) {
        return TransitionApiFp(this.configuration).transitionCustomsSearchHistory(page, size, sort, transitionSearchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete transition
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransitionApi
     */
    public transitionDelete(id: number, options?: any) {
        return TransitionApiFp(this.configuration).transitionDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete customs info
     * @param {Array<number>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransitionApi
     */
    public transitionDeleteCustomsInfo(requestBody: Array<number>, options?: any) {
        return TransitionApiFp(this.configuration).transitionDeleteCustomsInfo(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'STANDARD' | 'ADR_RID'} exportType 
     * @param {'CZECH' | 'ENGLISH'} language 
     * @param {TransitionSearchDto} [transitionSearchDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransitionApi
     */
    public transitionDownloadExport(exportType: 'STANDARD' | 'ADR_RID', language: 'CZECH' | 'ENGLISH', transitionSearchDto?: TransitionSearchDto, options?: any) {
        return TransitionApiFp(this.configuration).transitionDownloadExport(exportType, language, transitionSearchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransitionApi
     */
    public transitionDownloadPdf(id: number, options?: any) {
        return TransitionApiFp(this.configuration).transitionDownloadPdf(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find a transition by its id.
     * @param {number} id Id of transition to be found
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransitionApi
     */
    public transitionFindById(id: number, options?: any) {
        return TransitionApiFp(this.configuration).transitionFindById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find a transition by its id.
     * @param {number} id Id of TransitionSearchListDto to be found
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransitionApi
     */
    public transitionFindByIdOnSite(id: number, options?: any) {
        return TransitionApiFp(this.configuration).transitionFindByIdOnSite(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns last finished GO transition with iluCode
     * @param {string} iluCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransitionApi
     */
    public transitionFindLastByIlu(iluCode: string, options?: any) {
        return TransitionApiFp(this.configuration).transitionFindLastByIlu(iluCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return info if transitions has not gate-out
     * @param {number} transitionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransitionApi
     */
    public transitionIsTransitionWithoutGo(transitionId: number, options?: any) {
        return TransitionApiFp(this.configuration).transitionIsTransitionWithoutGo(transitionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns true if ILU code is valid
     * @param {string} iluCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransitionApi
     */
    public transitionIsValidIluCode(iluCode: string, options?: any) {
        return TransitionApiFp(this.configuration).transitionIsValidIluCode(iluCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {OperatorTransitionSearchDto} [operatorTransitionSearchDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransitionApi
     */
    public transitionOperatorSearch(page?: number, size?: number, sort?: Array<string>, operatorTransitionSearchDto?: OperatorTransitionSearchDto, options?: any) {
        return TransitionApiFp(this.configuration).transitionOperatorSearch(page, size, sort, operatorTransitionSearchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {TransitionSearchDto} [transitionSearchDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransitionApi
     */
    public transitionSearch(page?: number, size?: number, sort?: Array<string>, transitionSearchDto?: TransitionSearchDto, options?: any) {
        return TransitionApiFp(this.configuration).transitionSearch(page, size, sort, transitionSearchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {TransitionSearchListSearchDto} [transitionSearchListSearchDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransitionApi
     */
    public transitionSearchForList(page?: number, size?: number, sort?: Array<string>, transitionSearchListSearchDto?: TransitionSearchListSearchDto, options?: any) {
        return TransitionApiFp(this.configuration).transitionSearchForList(page, size, sort, transitionSearchListSearchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {TransitionSearchListSearchDto} [transitionSearchListSearchDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransitionApi
     */
    public transitionSearchForListPlanned(page?: number, size?: number, sort?: Array<string>, transitionSearchListSearchDto?: TransitionSearchListSearchDto, options?: any) {
        return TransitionApiFp(this.configuration).transitionSearchForListPlanned(page, size, sort, transitionSearchListSearchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates existing transition
     * @param {number} id 
     * @param {TransitionDto} transitionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransitionApi
     */
    public transitionUpdate(id: number, transitionDto: TransitionDto, options?: any) {
        return TransitionApiFp(this.configuration).transitionUpdate(id, transitionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates existing transition
     * @param {number} id 
     * @param {number} driverArrivalId 
     * @param {TransitionDto} transitionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransitionApi
     */
    public transitionUpdateByDriver(id: number, driverArrivalId: number, transitionDto: TransitionDto, options?: any) {
        return TransitionApiFp(this.configuration).transitionUpdateByDriver(id, driverArrivalId, transitionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {TransitionVgmDto} transitionVgmDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransitionApi
     */
    public transitionUpdateVgm(id: number, transitionVgmDto: TransitionVgmDto, options?: any) {
        return TransitionApiFp(this.configuration).transitionUpdateVgm(id, transitionVgmDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TruckMonitoringLogApi - axios parameter creator
 * @export
 */
export const TruckMonitoringLogApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates new truck monitoring log
         * @param {TruckMonitoringLogDto} truckMonitoringLogDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        truckMonitoringLogCreate: async (truckMonitoringLogDto: TruckMonitoringLogDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'truckMonitoringLogDto' is not null or undefined
            if (truckMonitoringLogDto === null || truckMonitoringLogDto === undefined) {
                throw new RequiredError('truckMonitoringLogDto','Required parameter truckMonitoringLogDto was null or undefined when calling truckMonitoringLogCreate.');
            }
            const localVarPath = `/truck-monitoring-log`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof truckMonitoringLogDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(truckMonitoringLogDto !== undefined ? truckMonitoringLogDto : {})
                : (truckMonitoringLogDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete truck monitoring log
         * @param {number} id Id of truck monitoring log to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        truckMonitoringLogDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling truckMonitoringLogDelete.');
            }
            const localVarPath = `/truck-monitoring-log/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all truck monitoring logs
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        truckMonitoringLogFindAll: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/truck-monitoring-log`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find a truck monitoring log by its id.
         * @param {number} id Id of truck monitoring log to be found
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        truckMonitoringLogFindById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling truckMonitoringLogFindById.');
            }
            const localVarPath = `/truck-monitoring-log/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {TruckMonitoringLogSearchDto} [truckMonitoringLogSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        truckMonitoringLogSearch: async (page?: number, size?: number, sort?: Array<string>, truckMonitoringLogSearchDto?: TruckMonitoringLogSearchDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/truck-monitoring-log/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof truckMonitoringLogSearchDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(truckMonitoringLogSearchDto !== undefined ? truckMonitoringLogSearchDto : {})
                : (truckMonitoringLogSearchDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update truck monitoring log
         * @param {number} id Id of truck monitoring log to be updated
         * @param {TruckMonitoringLogDto} truckMonitoringLogDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        truckMonitoringLogUpdate: async (id: number, truckMonitoringLogDto: TruckMonitoringLogDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling truckMonitoringLogUpdate.');
            }
            // verify required parameter 'truckMonitoringLogDto' is not null or undefined
            if (truckMonitoringLogDto === null || truckMonitoringLogDto === undefined) {
                throw new RequiredError('truckMonitoringLogDto','Required parameter truckMonitoringLogDto was null or undefined when calling truckMonitoringLogUpdate.');
            }
            const localVarPath = `/truck-monitoring-log/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof truckMonitoringLogDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(truckMonitoringLogDto !== undefined ? truckMonitoringLogDto : {})
                : (truckMonitoringLogDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TruckMonitoringLogApi - functional programming interface
 * @export
 */
export const TruckMonitoringLogApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates new truck monitoring log
         * @param {TruckMonitoringLogDto} truckMonitoringLogDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async truckMonitoringLogCreate(truckMonitoringLogDto: TruckMonitoringLogDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TruckMonitoringLogDto>> {
            const localVarAxiosArgs = await TruckMonitoringLogApiAxiosParamCreator(configuration).truckMonitoringLogCreate(truckMonitoringLogDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete truck monitoring log
         * @param {number} id Id of truck monitoring log to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async truckMonitoringLogDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TruckMonitoringLogApiAxiosParamCreator(configuration).truckMonitoringLogDelete(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Find all truck monitoring logs
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async truckMonitoringLogFindAll(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTruckMonitoringLogDto>> {
            const localVarAxiosArgs = await TruckMonitoringLogApiAxiosParamCreator(configuration).truckMonitoringLogFindAll(page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Find a truck monitoring log by its id.
         * @param {number} id Id of truck monitoring log to be found
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async truckMonitoringLogFindById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TruckMonitoringLogDto>> {
            const localVarAxiosArgs = await TruckMonitoringLogApiAxiosParamCreator(configuration).truckMonitoringLogFindById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {TruckMonitoringLogSearchDto} [truckMonitoringLogSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async truckMonitoringLogSearch(page?: number, size?: number, sort?: Array<string>, truckMonitoringLogSearchDto?: TruckMonitoringLogSearchDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTruckMonitoringLogDto>> {
            const localVarAxiosArgs = await TruckMonitoringLogApiAxiosParamCreator(configuration).truckMonitoringLogSearch(page, size, sort, truckMonitoringLogSearchDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update truck monitoring log
         * @param {number} id Id of truck monitoring log to be updated
         * @param {TruckMonitoringLogDto} truckMonitoringLogDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async truckMonitoringLogUpdate(id: number, truckMonitoringLogDto: TruckMonitoringLogDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TruckMonitoringLogDto>> {
            const localVarAxiosArgs = await TruckMonitoringLogApiAxiosParamCreator(configuration).truckMonitoringLogUpdate(id, truckMonitoringLogDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TruckMonitoringLogApi - factory interface
 * @export
 */
export const TruckMonitoringLogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Creates new truck monitoring log
         * @param {TruckMonitoringLogDto} truckMonitoringLogDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        truckMonitoringLogCreate(truckMonitoringLogDto: TruckMonitoringLogDto, options?: any): AxiosPromise<TruckMonitoringLogDto> {
            return TruckMonitoringLogApiFp(configuration).truckMonitoringLogCreate(truckMonitoringLogDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete truck monitoring log
         * @param {number} id Id of truck monitoring log to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        truckMonitoringLogDelete(id: number, options?: any): AxiosPromise<void> {
            return TruckMonitoringLogApiFp(configuration).truckMonitoringLogDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all truck monitoring logs
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        truckMonitoringLogFindAll(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageTruckMonitoringLogDto> {
            return TruckMonitoringLogApiFp(configuration).truckMonitoringLogFindAll(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find a truck monitoring log by its id.
         * @param {number} id Id of truck monitoring log to be found
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        truckMonitoringLogFindById(id: number, options?: any): AxiosPromise<TruckMonitoringLogDto> {
            return TruckMonitoringLogApiFp(configuration).truckMonitoringLogFindById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {TruckMonitoringLogSearchDto} [truckMonitoringLogSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        truckMonitoringLogSearch(page?: number, size?: number, sort?: Array<string>, truckMonitoringLogSearchDto?: TruckMonitoringLogSearchDto, options?: any): AxiosPromise<PageTruckMonitoringLogDto> {
            return TruckMonitoringLogApiFp(configuration).truckMonitoringLogSearch(page, size, sort, truckMonitoringLogSearchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update truck monitoring log
         * @param {number} id Id of truck monitoring log to be updated
         * @param {TruckMonitoringLogDto} truckMonitoringLogDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        truckMonitoringLogUpdate(id: number, truckMonitoringLogDto: TruckMonitoringLogDto, options?: any): AxiosPromise<TruckMonitoringLogDto> {
            return TruckMonitoringLogApiFp(configuration).truckMonitoringLogUpdate(id, truckMonitoringLogDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TruckMonitoringLogApi - object-oriented interface
 * @export
 * @class TruckMonitoringLogApi
 * @extends {BaseAPI}
 */
export class TruckMonitoringLogApi extends BaseAPI {
    /**
     * 
     * @summary Creates new truck monitoring log
     * @param {TruckMonitoringLogDto} truckMonitoringLogDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TruckMonitoringLogApi
     */
    public truckMonitoringLogCreate(truckMonitoringLogDto: TruckMonitoringLogDto, options?: any) {
        return TruckMonitoringLogApiFp(this.configuration).truckMonitoringLogCreate(truckMonitoringLogDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete truck monitoring log
     * @param {number} id Id of truck monitoring log to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TruckMonitoringLogApi
     */
    public truckMonitoringLogDelete(id: number, options?: any) {
        return TruckMonitoringLogApiFp(this.configuration).truckMonitoringLogDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all truck monitoring logs
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TruckMonitoringLogApi
     */
    public truckMonitoringLogFindAll(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return TruckMonitoringLogApiFp(this.configuration).truckMonitoringLogFindAll(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find a truck monitoring log by its id.
     * @param {number} id Id of truck monitoring log to be found
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TruckMonitoringLogApi
     */
    public truckMonitoringLogFindById(id: number, options?: any) {
        return TruckMonitoringLogApiFp(this.configuration).truckMonitoringLogFindById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {TruckMonitoringLogSearchDto} [truckMonitoringLogSearchDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TruckMonitoringLogApi
     */
    public truckMonitoringLogSearch(page?: number, size?: number, sort?: Array<string>, truckMonitoringLogSearchDto?: TruckMonitoringLogSearchDto, options?: any) {
        return TruckMonitoringLogApiFp(this.configuration).truckMonitoringLogSearch(page, size, sort, truckMonitoringLogSearchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update truck monitoring log
     * @param {number} id Id of truck monitoring log to be updated
     * @param {TruckMonitoringLogDto} truckMonitoringLogDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TruckMonitoringLogApi
     */
    public truckMonitoringLogUpdate(id: number, truckMonitoringLogDto: TruckMonitoringLogDto, options?: any) {
        return TruckMonitoringLogApiFp(this.configuration).truckMonitoringLogUpdate(id, truckMonitoringLogDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UploadDownloadApi - axios parameter creator
 * @export
 */
export const UploadDownloadApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} transitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDownloadDeleteCustomsFile: async (transitionId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transitionId' is not null or undefined
            if (transitionId === null || transitionId === undefined) {
                throw new RequiredError('transitionId','Required parameter transitionId was null or undefined when calling uploadDownloadDeleteCustomsFile.');
            }
            const localVarPath = `/upload-download/{transitionId}/customs/delete`
                .replace(`{${"transitionId"}}`, encodeURIComponent(String(transitionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} transitionId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDownloadUploadCustomsFile: async (transitionId: number, file: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transitionId' is not null or undefined
            if (transitionId === null || transitionId === undefined) {
                throw new RequiredError('transitionId','Required parameter transitionId was null or undefined when calling uploadDownloadUploadCustomsFile.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling uploadDownloadUploadCustomsFile.');
            }
            const localVarPath = `/upload-download/{transitionId}/customs`
                .replace(`{${"transitionId"}}`, encodeURIComponent(String(transitionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDownloadUploadFile: async (file: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling uploadDownloadUploadFile.');
            }
            const localVarPath = `/upload-download/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UploadDownloadApi - functional programming interface
 * @export
 */
export const UploadDownloadApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} transitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadDownloadDeleteCustomsFile(transitionId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UploadDownloadApiAxiosParamCreator(configuration).uploadDownloadDeleteCustomsFile(transitionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} transitionId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadDownloadUploadCustomsFile(transitionId: number, file: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UploadDownloadApiAxiosParamCreator(configuration).uploadDownloadUploadCustomsFile(transitionId, file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadDownloadUploadFile(file: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UploadDownloadApiAxiosParamCreator(configuration).uploadDownloadUploadFile(file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UploadDownloadApi - factory interface
 * @export
 */
export const UploadDownloadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} transitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDownloadDeleteCustomsFile(transitionId: number, options?: any): AxiosPromise<void> {
            return UploadDownloadApiFp(configuration).uploadDownloadDeleteCustomsFile(transitionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} transitionId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDownloadUploadCustomsFile(transitionId: number, file: any, options?: any): AxiosPromise<void> {
            return UploadDownloadApiFp(configuration).uploadDownloadUploadCustomsFile(transitionId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDownloadUploadFile(file: any, options?: any): AxiosPromise<void> {
            return UploadDownloadApiFp(configuration).uploadDownloadUploadFile(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UploadDownloadApi - object-oriented interface
 * @export
 * @class UploadDownloadApi
 * @extends {BaseAPI}
 */
export class UploadDownloadApi extends BaseAPI {
    /**
     * 
     * @param {number} transitionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadDownloadApi
     */
    public uploadDownloadDeleteCustomsFile(transitionId: number, options?: any) {
        return UploadDownloadApiFp(this.configuration).uploadDownloadDeleteCustomsFile(transitionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} transitionId 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadDownloadApi
     */
    public uploadDownloadUploadCustomsFile(transitionId: number, file: any, options?: any) {
        return UploadDownloadApiFp(this.configuration).uploadDownloadUploadCustomsFile(transitionId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadDownloadApi
     */
    public uploadDownloadUploadFile(file: any, options?: any) {
        return UploadDownloadApiFp(this.configuration).uploadDownloadUploadFile(file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UploadedFileApi - axios parameter creator
 * @export
 */
export const UploadedFileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Assign signet photo to transition
         * @param {number} transitionId 
         * @param {UploadedFileDto} uploadedFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadedFileAssignSignetPhoto: async (transitionId: number, uploadedFileDto: UploadedFileDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transitionId' is not null or undefined
            if (transitionId === null || transitionId === undefined) {
                throw new RequiredError('transitionId','Required parameter transitionId was null or undefined when calling uploadedFileAssignSignetPhoto.');
            }
            // verify required parameter 'uploadedFileDto' is not null or undefined
            if (uploadedFileDto === null || uploadedFileDto === undefined) {
                throw new RequiredError('uploadedFileDto','Required parameter uploadedFileDto was null or undefined when calling uploadedFileAssignSignetPhoto.');
            }
            const localVarPath = `/uploaded-file/signet/{transitionId}`
                .replace(`{${"transitionId"}}`, encodeURIComponent(String(transitionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof uploadedFileDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(uploadedFileDto !== undefined ? uploadedFileDto : {})
                : (uploadedFileDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete signet photos of the transition
         * @param {number} transitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadedFileDeleteSignetPhoto: async (transitionId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transitionId' is not null or undefined
            if (transitionId === null || transitionId === undefined) {
                throw new RequiredError('transitionId','Required parameter transitionId was null or undefined when calling uploadedFileDeleteSignetPhoto.');
            }
            const localVarPath = `/uploaded-file/signet/{transitionId}`
                .replace(`{${"transitionId"}}`, encodeURIComponent(String(transitionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get signet photo of the transition
         * @param {number} transitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadedFileGetSignetPhoto: async (transitionId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transitionId' is not null or undefined
            if (transitionId === null || transitionId === undefined) {
                throw new RequiredError('transitionId','Required parameter transitionId was null or undefined when calling uploadedFileGetSignetPhoto.');
            }
            const localVarPath = `/uploaded-file/signet/{transitionId}`
                .replace(`{${"transitionId"}}`, encodeURIComponent(String(transitionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UploadedFileApi - functional programming interface
 * @export
 */
export const UploadedFileApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Assign signet photo to transition
         * @param {number} transitionId 
         * @param {UploadedFileDto} uploadedFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadedFileAssignSignetPhoto(transitionId: number, uploadedFileDto: UploadedFileDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UploadedFileApiAxiosParamCreator(configuration).uploadedFileAssignSignetPhoto(transitionId, uploadedFileDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete signet photos of the transition
         * @param {number} transitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadedFileDeleteSignetPhoto(transitionId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UploadedFileApiAxiosParamCreator(configuration).uploadedFileDeleteSignetPhoto(transitionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get signet photo of the transition
         * @param {number} transitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadedFileGetSignetPhoto(transitionId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadedFileDto>> {
            const localVarAxiosArgs = await UploadedFileApiAxiosParamCreator(configuration).uploadedFileGetSignetPhoto(transitionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UploadedFileApi - factory interface
 * @export
 */
export const UploadedFileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Assign signet photo to transition
         * @param {number} transitionId 
         * @param {UploadedFileDto} uploadedFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadedFileAssignSignetPhoto(transitionId: number, uploadedFileDto: UploadedFileDto, options?: any): AxiosPromise<void> {
            return UploadedFileApiFp(configuration).uploadedFileAssignSignetPhoto(transitionId, uploadedFileDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete signet photos of the transition
         * @param {number} transitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadedFileDeleteSignetPhoto(transitionId: number, options?: any): AxiosPromise<void> {
            return UploadedFileApiFp(configuration).uploadedFileDeleteSignetPhoto(transitionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get signet photo of the transition
         * @param {number} transitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadedFileGetSignetPhoto(transitionId: number, options?: any): AxiosPromise<UploadedFileDto> {
            return UploadedFileApiFp(configuration).uploadedFileGetSignetPhoto(transitionId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UploadedFileApi - object-oriented interface
 * @export
 * @class UploadedFileApi
 * @extends {BaseAPI}
 */
export class UploadedFileApi extends BaseAPI {
    /**
     * 
     * @summary Assign signet photo to transition
     * @param {number} transitionId 
     * @param {UploadedFileDto} uploadedFileDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadedFileApi
     */
    public uploadedFileAssignSignetPhoto(transitionId: number, uploadedFileDto: UploadedFileDto, options?: any) {
        return UploadedFileApiFp(this.configuration).uploadedFileAssignSignetPhoto(transitionId, uploadedFileDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete signet photos of the transition
     * @param {number} transitionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadedFileApi
     */
    public uploadedFileDeleteSignetPhoto(transitionId: number, options?: any) {
        return UploadedFileApiFp(this.configuration).uploadedFileDeleteSignetPhoto(transitionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get signet photo of the transition
     * @param {number} transitionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadedFileApi
     */
    public uploadedFileGetSignetPhoto(transitionId: number, options?: any) {
        return UploadedFileApiFp(this.configuration).uploadedFileGetSignetPhoto(transitionId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Change the preferred language of this user
         * @param {number} id 
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userChangeLanguage: async (id: number, language: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling userChangeLanguage.');
            }
            // verify required parameter 'language' is not null or undefined
            if (language === null || language === undefined) {
                throw new RequiredError('language','Required parameter language was null or undefined when calling userChangeLanguage.');
            }
            const localVarPath = `/user/{id}/language`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates new user
         * @param {UserDto} userDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCreate: async (userDto: UserDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userDto' is not null or undefined
            if (userDto === null || userDto === undefined) {
                throw new RequiredError('userDto','Required parameter userDto was null or undefined when calling userCreate.');
            }
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof userDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(userDto !== undefined ? userDto : {})
                : (userDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete user
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling userDelete.');
            }
            const localVarPath = `/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserSearchDto} [userSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDownloadExport: async (userSearchDto?: UserSearchDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof userSearchDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(userSearchDto !== undefined ? userSearchDto : {})
                : (userSearchDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all users
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userFindAll: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find user by its id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userFindById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling userFindById.');
            }
            const localVarPath = `/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {UserSearchDto} [userSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSearch: async (page?: number, size?: number, sort?: Array<string>, userSearchDto?: UserSearchDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof userSearchDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(userSearchDto !== undefined ? userSearchDto : {})
                : (userSearchDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates existing user
         * @param {number} id 
         * @param {UserDto} userDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdate: async (id: number, userDto: UserDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling userUpdate.');
            }
            // verify required parameter 'userDto' is not null or undefined
            if (userDto === null || userDto === undefined) {
                throw new RequiredError('userDto','Required parameter userDto was null or undefined when calling userUpdate.');
            }
            const localVarPath = `/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof userDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(userDto !== undefined ? userDto : {})
                : (userDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates notification configuration for user
         * @param {number} id 
         * @param {boolean} notificationsDisabled 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdateNotificationsDisabled: async (id: number, notificationsDisabled: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling userUpdateNotificationsDisabled.');
            }
            // verify required parameter 'notificationsDisabled' is not null or undefined
            if (notificationsDisabled === null || notificationsDisabled === undefined) {
                throw new RequiredError('notificationsDisabled','Required parameter notificationsDisabled was null or undefined when calling userUpdateNotificationsDisabled.');
            }
            const localVarPath = `/user/notifications-disabled/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (notificationsDisabled !== undefined) {
                localVarQueryParameter['notificationsDisabled'] = notificationsDisabled;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Change the preferred language of this user
         * @param {number} id 
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userChangeLanguage(id: number, language: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).userChangeLanguage(id, language, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Creates new user
         * @param {UserDto} userDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userCreate(userDto: UserDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).userCreate(userDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete user
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).userDelete(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UserSearchDto} [userSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userDownloadExport(userSearchDto?: UserSearchDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).userDownloadExport(userSearchDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Find all users
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userFindAll(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageUserDto>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).userFindAll(page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Find user by its id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userFindById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).userFindById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {UserSearchDto} [userSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSearch(page?: number, size?: number, sort?: Array<string>, userSearchDto?: UserSearchDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageUserDto>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).userSearch(page, size, sort, userSearchDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates existing user
         * @param {number} id 
         * @param {UserDto} userDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userUpdate(id: number, userDto: UserDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).userUpdate(id, userDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates notification configuration for user
         * @param {number} id 
         * @param {boolean} notificationsDisabled 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userUpdateNotificationsDisabled(id: number, notificationsDisabled: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).userUpdateNotificationsDisabled(id, notificationsDisabled, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Change the preferred language of this user
         * @param {number} id 
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userChangeLanguage(id: number, language: string, options?: any): AxiosPromise<void> {
            return UserApiFp(configuration).userChangeLanguage(id, language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates new user
         * @param {UserDto} userDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCreate(userDto: UserDto, options?: any): AxiosPromise<UserDto> {
            return UserApiFp(configuration).userCreate(userDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete user
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDelete(id: number, options?: any): AxiosPromise<void> {
            return UserApiFp(configuration).userDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserSearchDto} [userSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDownloadExport(userSearchDto?: UserSearchDto, options?: any): AxiosPromise<Array<string>> {
            return UserApiFp(configuration).userDownloadExport(userSearchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all users
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userFindAll(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageUserDto> {
            return UserApiFp(configuration).userFindAll(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find user by its id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userFindById(id: number, options?: any): AxiosPromise<UserDto> {
            return UserApiFp(configuration).userFindById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {UserSearchDto} [userSearchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSearch(page?: number, size?: number, sort?: Array<string>, userSearchDto?: UserSearchDto, options?: any): AxiosPromise<PageUserDto> {
            return UserApiFp(configuration).userSearch(page, size, sort, userSearchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates existing user
         * @param {number} id 
         * @param {UserDto} userDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdate(id: number, userDto: UserDto, options?: any): AxiosPromise<UserDto> {
            return UserApiFp(configuration).userUpdate(id, userDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates notification configuration for user
         * @param {number} id 
         * @param {boolean} notificationsDisabled 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdateNotificationsDisabled(id: number, notificationsDisabled: boolean, options?: any): AxiosPromise<void> {
            return UserApiFp(configuration).userUpdateNotificationsDisabled(id, notificationsDisabled, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Change the preferred language of this user
     * @param {number} id 
     * @param {string} language 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userChangeLanguage(id: number, language: string, options?: any) {
        return UserApiFp(this.configuration).userChangeLanguage(id, language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates new user
     * @param {UserDto} userDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userCreate(userDto: UserDto, options?: any) {
        return UserApiFp(this.configuration).userCreate(userDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete user
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userDelete(id: number, options?: any) {
        return UserApiFp(this.configuration).userDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserSearchDto} [userSearchDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userDownloadExport(userSearchDto?: UserSearchDto, options?: any) {
        return UserApiFp(this.configuration).userDownloadExport(userSearchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all users
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userFindAll(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return UserApiFp(this.configuration).userFindAll(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find user by its id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userFindById(id: number, options?: any) {
        return UserApiFp(this.configuration).userFindById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {UserSearchDto} [userSearchDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userSearch(page?: number, size?: number, sort?: Array<string>, userSearchDto?: UserSearchDto, options?: any) {
        return UserApiFp(this.configuration).userSearch(page, size, sort, userSearchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates existing user
     * @param {number} id 
     * @param {UserDto} userDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userUpdate(id: number, userDto: UserDto, options?: any) {
        return UserApiFp(this.configuration).userUpdate(id, userDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates notification configuration for user
     * @param {number} id 
     * @param {boolean} notificationsDisabled 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userUpdateNotificationsDisabled(id: number, notificationsDisabled: boolean, options?: any) {
        return UserApiFp(this.configuration).userUpdateNotificationsDisabled(id, notificationsDisabled, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VeronaApi - axios parameter creator
 * @export
 */
export const VeronaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Send train CIM to Verona terminal
         * @param {number} trainId Id of train to send train CIM
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        veronaSendVeronaTrain: async (trainId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'trainId' is not null or undefined
            if (trainId === null || trainId === undefined) {
                throw new RequiredError('trainId','Required parameter trainId was null or undefined when calling veronaSendVeronaTrain.');
            }
            const localVarPath = `/verona/send-train/{trainId}`
                .replace(`{${"trainId"}}`, encodeURIComponent(String(trainId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VeronaApi - functional programming interface
 * @export
 */
export const VeronaApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Send train CIM to Verona terminal
         * @param {number} trainId Id of train to send train CIM
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async veronaSendVeronaTrain(trainId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await VeronaApiAxiosParamCreator(configuration).veronaSendVeronaTrain(trainId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * VeronaApi - factory interface
 * @export
 */
export const VeronaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Send train CIM to Verona terminal
         * @param {number} trainId Id of train to send train CIM
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        veronaSendVeronaTrain(trainId: number, options?: any): AxiosPromise<void> {
            return VeronaApiFp(configuration).veronaSendVeronaTrain(trainId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VeronaApi - object-oriented interface
 * @export
 * @class VeronaApi
 * @extends {BaseAPI}
 */
export class VeronaApi extends BaseAPI {
    /**
     * 
     * @summary Send train CIM to Verona terminal
     * @param {number} trainId Id of train to send train CIM
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VeronaApi
     */
    public veronaSendVeronaTrain(trainId: number, options?: any) {
        return VeronaApiFp(this.configuration).veronaSendVeronaTrain(trainId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VgmApi - axios parameter creator
 * @export
 */
export const VgmApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Generates VGM protocol for transition, stores it onto S3
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vgmDownloadProcessedFile: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling vgmDownloadProcessedFile.');
            }
            const localVarPath = `/vgm/generate/{id}/download`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VgmApi - functional programming interface
 * @export
 */
export const VgmApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Generates VGM protocol for transition, stores it onto S3
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vgmDownloadProcessedFile(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await VgmApiAxiosParamCreator(configuration).vgmDownloadProcessedFile(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * VgmApi - factory interface
 * @export
 */
export const VgmApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Generates VGM protocol for transition, stores it onto S3
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vgmDownloadProcessedFile(id: number, options?: any): AxiosPromise<Array<string>> {
            return VgmApiFp(configuration).vgmDownloadProcessedFile(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VgmApi - object-oriented interface
 * @export
 * @class VgmApi
 * @extends {BaseAPI}
 */
export class VgmApi extends BaseAPI {
    /**
     * 
     * @summary Generates VGM protocol for transition, stores it onto S3
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VgmApi
     */
    public vgmDownloadProcessedFile(id: number, options?: any) {
        return VgmApiFp(this.configuration).vgmDownloadProcessedFile(id, options).then((request) => request(this.axios, this.basePath));
    }
}


